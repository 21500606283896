import React, { Component } from "react";
import {
	View,
	Text,
	TextInput,
	TouchableOpacity,
	ActivityIndicator,
	Modal,
	ScrollView,
} from "react-native";
import { APP_COLOURS, StatusColour } from "../../APP_VARS";
import { crudUpdate, crudDelete } from "../../CRUDHELPER";
import moment from "moment";
import CalendarStatus from "./CalendarStatus";

export default class OverviewSection extends Component {
	constructor(props) {
		super(props);
		this.state = {
			objectives: this.props.data.objectives || [],
			focusThisMonth: this.props.data.focusThisMonth || [],
			importantDates: this.props.data.importantDates || [],
			title: this.props.data.title || "",
			description: this.props.data.description || "",
			hasChanges: false,
			saving: false,
			editingField: null,
			isEditing: false,
			status: this.props.data.status || "Planning",
			showPreviewModal: false,
			emailAddress: "",
			previewHtml: "",
		};
	}

	// Add status options
	statuses = [
		"Planning",
		"Design",
		"Copy",
		"Awaiting Approval",
		"Approved",
		"Scheduled",
		"Published",
	];

	toggleEditMode = () => {
		this.setState((prevState) => ({
			isEditing: !prevState.isEditing,
			hasChanges: false,
		}));
	};

	handleArrayInput = (field, value, index) => {
		const newArray = [...this.state[field]];
		if (index === newArray.length) {
			// Add new item
			newArray.push(value);
		} else {
			// Update existing item
			newArray[index] = value;
		}

		this.setState({
			[field]: newArray,
			hasChanges: true,
		});
	};

	removeArrayItem = (field, index) => {
		const newArray = [...this.state[field]];
		newArray.splice(index, 1);
		this.setState({
			[field]: newArray,
			hasChanges: true,
		});
	};

	handleInputChange = (field, value) => {
		this.setState({
			[field]: value,
			hasChanges: true,
		});
	};

	saveChanges = () => {
		this.setState({ saving: true });

		const updates = {
			id: this.props.data.id,
			objectives: this.state.objectives,
			focusThisMonth: this.state.focusThisMonth,
			importantDates: this.state.importantDates,
			title: this.state.title,
			description: this.state.description,
			updatedAt: moment().toISOString(),
		};

		crudUpdate(
			"contentcalendars",

			updates,
			(status) => {
				if (status === 200) {
					this.setState({
						hasChanges: false,
						saving: false,
					});
					if (this.props.onUpdate) {
						this.props.onUpdate(updates);
					}
				} else {
					console.error("Error saving changes");
					this.setState({ saving: false });
				}
			},
		);
	};

	handleDelete = () => {
		if (
			window.confirm(
				"Are you sure you want to delete this calendar? This action cannot be undone.",
			)
		) {
			this.setState({ saving: true });

			crudDelete(
				"contentcalendars",
				{ id: this.props.data.id },
				(status) => {
					if (status === 200) {
						if (this.props.onDelete) {
							this.props.onDelete();
						}
					} else {
						console.error("Error deleting calendar");
						this.setState({ saving: false });
					}
				},
			);
		}
	};

	handleStatusChange = () => {
		const currentIndex = this.statuses.indexOf(this.state.status);
		const nextStatus =
			this.statuses[(currentIndex + 1) % this.statuses.length];

		this.setState({ saving: true });

		const updates = {
			id: this.props.data.id,
			status: nextStatus,
			updatedAt: moment().toISOString(),
		};

		crudUpdate(
			"contentcalendars",

			updates,
			(status) => {
				if (status === 200) {
					this.setState({
						status: nextStatus,
						saving: false,
					});
					if (this.props.onUpdate) {
						this.props.onUpdate(updates);
					}
				} else {
					console.error("Error updating status");
					this.setState({ saving: false });
				}
			},
		);
	};

	renderArrayInputs = (field, title) => {
		const array = this.state[field];

		return (
			<View style={styles.section}>
				<Text style={styles.sectionTitle}>{title}</Text>
				{array.map((item, index) => (
					<View key={index} style={styles.inputRow}>
						<TextInput
							style={styles.input}
							value={item}
							onChangeText={(text) =>
								this.handleArrayInput(field, text, index)
							}
							placeholder={`Add ${title}`}
							multiline
						/>
						<TouchableOpacity
							style={styles.removeButton}
							onPress={() => this.removeArrayItem(field, index)}>
							<Text style={styles.removeButtonText}>×</Text>
						</TouchableOpacity>
					</View>
				))}
				<TouchableOpacity
					style={styles.addButton}
					onPress={() =>
						this.handleArrayInput(field, "", array.length)
					}>
					<Text style={styles.addButtonText}>+ Add {title}</Text>
				</TouchableOpacity>
			</View>
		);
	};

	renderArrayItems = (field, title) => {
		const array = this.state[field];

		if (!this.state.isEditing) {
			return (
				<View style={styles.section}>
					<Text style={styles.sectionTitle}>{title}</Text>
					{array.length === 0 ? (
						<Text style={styles.emptyText}>No items added</Text>
					) : (
						array.map((item, index) => (
							<Text key={index} style={styles.itemText}>
								• {item}
							</Text>
						))
					)}
				</View>
			);
		}

		return this.renderArrayInputs(field, title);
	};

	// Add new method to generate HTML preview
	generatePreviewHtml = () => {
		const { data, contentItems } = this.props;
		const {
			title,
			description,
			objectives,
			focusThisMonth,
			importantDates,
			status,
		} = this.state;

		// Sort content items chronologically
		const sortedContent =
			contentItems?.sort(
				(a, b) =>
					moment(a.publishDate).valueOf() -
					moment(b.publishDate).valueOf(),
			) || [];

		// Group content by month
		const contentByMonth = sortedContent.reduce((acc, item) => {
			const monthKey = moment(item.publishDate).format("MMMM YYYY");
			if (!acc[monthKey]) {
				acc[monthKey] = [];
			}
			acc[monthKey].push(item);
			return acc;
		}, {});

		return `
			<html>
				<head>
					<style>
						@page {
							size: A4;
							margin: 0;
						}
						* {
							box-sizing: border-box;
							-webkit-print-color-adjust: exact !important;
							print-color-adjust: exact !important;
						}
						body { 
							font-family: Arial, sans-serif;
							width: 210mm;
							min-height: 297mm;
							margin: 0 auto;
							padding: 20mm;
							background: white;
							color: #333;
							line-height: 1.5;
						}
						.header { 
							background: #f8f9fa;
							padding: 30px;
							border-radius: 12px;
							margin-bottom: 40px;
							border: 1px solid #e9ecef;
						}
						.header h1 {
							margin: 0 0 15px 0;
							color: #1a1a1a;
							font-size: 32px;
						}
						.header-meta {
							display: flex;
							justify-content: space-between;
							align-items: center;
							margin-top: 20px;
							padding-top: 20px;
							border-top: 1px solid #dee2e6;
						}
						.section {
							margin: 40px 0;
							page-break-inside: avoid;
						}
						.section h2 {
							color: #1a1a1a;
							font-size: 24px;
							margin-bottom: 20px;
							padding-bottom: 10px;
							border-bottom: 2px solid #e9ecef;
						}
						.description {
							background: #fff;
							padding: 20px;
							border-radius: 8px;
							border: 1px solid #e9ecef;
							margin: 20px 0;
						}
						.list-section {
							display: grid;
							grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
							gap: 20px;
							margin: 20px 0;
						}
						.list-card {
							background: #fff;
							padding: 20px;
							border-radius: 8px;
							border: 1px solid #e9ecef;
						}
						.list-card h3 {
							color: #1a1a1a;
							margin-top: 0;
							margin-bottom: 15px;
							font-size: 18px;
						}
						.list-card ul {
							margin: 0;
							padding-left: 20px;
						}
						.list-card li {
							margin-bottom: 8px;
							color: #495057;
						}
						.month-section {
							margin: 40px 0;
							page-break-inside: avoid;
						}
						.month-title {
							font-size: 28px;
							color: #1a1a1a;
							margin: 30px 0 20px;
							padding: 15px;
							background: #f8f9fa;
							border-radius: 8px;
							text-align: center;
						}
						.timeline {
							display: flex;
							flex-direction: column;
							gap: 25px;
						}
						.timeline-item {
							display: flex;
							gap: 20px;
							background: #fff;
							border: 1px solid #e9ecef;
							border-radius: 12px;
							overflow: hidden;
							box-shadow: 0 2px 4px rgba(0,0,0,0.05);
						}
						.timeline-date {
							min-width: 140px;
							background: #f8f9fa;
							padding: 20px;
							display: flex;
							flex-direction: column;
							align-items: center;
							justify-content: center;
							border-right: 1px solid #e9ecef;
						}
						.date-badge {
							font-size: 18px;
							font-weight: bold;
							color: #1a1a1a;
							margin-bottom: 5px;
						}
						.time-badge {
							font-size: 16px;
							color: #6c757d;
						}
						.timeline-content {
							flex: 1;
							padding: 20px;
							display: flex;
							gap: 25px;
						}
						.timeline-image {
							width: 200px;
							height: 200px;
							overflow: hidden;
							border-radius: 8px;
							border: 1px solid #e9ecef;
						}
						.timeline-image img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
						.timeline-details {
							flex: 1;
						}
						.timeline-header {
							display: flex;
							justify-content: space-between;
							align-items: center;
							margin-bottom: 15px;
						}
						.timeline-header h4 {
							margin: 0;
							color: #1a1a1a;
							font-size: 20px;
						}
						.platform-badge {
							padding: 6px 15px;
							border-radius: 20px;
							font-size: 14px;
							font-weight: 500;
						}
						.platform-badge.instagram { background: #E1306C; color: white; }
						.platform-badge.facebook { background: #4267B2; color: white; }
						.platform-badge.twitter { background: #1DA1F2; color: white; }
						.platform-badge.linkedin { background: #0077B5; color: white; }
						.platform-badge.tiktok { background: #000000; color: white; }
						.caption {
							color: #495057;
							margin: 15px 0;
							line-height: 1.6;
							font-size: 16px;
						}
						.hashtags {
							margin: 15px 0;
						}
						.hashtag {
							display: inline-block;
							color: #1DA1F2;
							margin-right: 10px;
							font-size: 14px;
						}
						.post-metadata {
							display: flex;
							align-items: center;
							gap: 20px;
							margin-top: 15px;
							padding-top: 15px;
							border-top: 1px solid #e9ecef;
						}
						.status-indicator {
							display: flex;
							align-items: center;
							gap: 8px;
						}
						.status-dot {
							width: 10px;
							height: 10px;
							border-radius: 50%;
							display: inline-block;
						}
						.status-dot.draft { background: #FFA500; }
						.status-dot.scheduled { background: #4CAF50; }
						.status-dot.published { background: #2196F3; }
						.status-text {
							font-size: 14px;
							color: #6c757d;
						}
						.assigned-to {
							font-size: 14px;
							color: #6c757d;
						}
						@media print {
							.timeline-item {
								break-inside: avoid;
							}
						}
					</style>
				</head>
				<body>
					<div class="header">
						<h1>${title}</h1>
						<p>${description}</p>
						<div class="header-meta">
							<div class="status-badge">${status}</div>
							<div>Generated: ${moment().format("MMMM Do YYYY, h:mm a")}</div>
						</div>
					</div>

					<div class="list-section">
						<div class="list-card">
							<h3>Objectives</h3>
							${
								objectives.length
									? `
								<ul>${objectives.map((obj) => `<li>${obj}</li>`).join("")}</ul>
							`
									: "<p>No objectives defined</p>"
							}
						</div>

						<div class="list-card">
							<h3>This Month's Focus</h3>
							${
								focusThisMonth.length
									? `
								<ul>${focusThisMonth.map((focus) => `<li>${focus}</li>`).join("")}</ul>
							`
									: "<p>No focus points defined</p>"
							}
						</div>

						<div class="list-card">
							<h3>Important Dates</h3>
							${
								importantDates.length
									? `
								<ul>${importantDates.map((date) => `<li>${date}</li>`).join("")}</ul>
							`
									: "<p>No important dates defined</p>"
							}
						</div>
					</div>

					<div class="section">
						<h2>Content Timeline</h2>
						${Object.entries(contentByMonth)
							.map(
								([month, items]) => `
							<div class="month-section">
								<h3 class="month-title">${month}</h3>
								<div class="timeline">
									${items
										.map(
											(item) => `
										<div class="timeline-item">
											<div class="timeline-date">
												<div class="date-badge">${moment(item.publishDate).format("ddd, MMM D")}</div>
												<div class="time-badge">${moment(item.publishDate).format("h:mm A")}</div>
											</div>
											<div class="timeline-content">
												${
													item.image
														? `
													<div class="timeline-image">
														<img src="${item.image}" alt="${item.title || "Content image"}" />
													</div>
												`
														: ""
												}
												<div class="timeline-details">
													<div class="timeline-header">
														<h4>${item.title || "Untitled"}</h4>
														<span class="platform-badge ${item.platform?.toLowerCase()}">${
												item.platform || "Not specified"
											}</span>
													</div>
													<p class="caption">${item.caption || "No caption provided"}</p>
													${
														item.hashtags
															? `
														<div class="hashtags">
															${item.hashtags.map((tag) => `<span class="hashtag">#${tag}</span>`).join(" ")}
														</div>
													`
															: ""
													}
													<div class="post-metadata">
														<div class="status-indicator">
															<span class="status-dot ${item.status?.toLowerCase() || "draft"}"></span>
															<span class="status-text">${item.status || "Draft"}</span>
														</div>
														${
															item.assignedTo
																? `
															<span class="assigned-to">
																<span class="metadata-label">Assigned to:</span> ${item.assignedTo}
															</span>
														`
																: ""
														}
													</div>
												</div>
											</div>
										</div>
									`,
										)
										.join("")}
								</div>
							</div>
						`,
							)
							.join("")}
					</div>
				</body>
			</html>
		`;
	};

	// Add method to handle email sending
	handleSendPreview = () => {
		const { emailAddress, previewHtml } = this.state;

		// You'll need to implement your email sending logic here
		// This is a placeholder for the API call
		fetch("/api/send-preview", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				email: emailAddress,
				html: previewHtml,
				calendarId: this.props.data.id,
			}),
		})
			.then((response) => response.json())
			.then((data) => {
				alert("Preview sent successfully!");
				this.setState({ showPreviewModal: false, emailAddress: "" });
			})
			.catch((error) => {
				console.error("Error sending preview:", error);
				alert("Error sending preview. Please try again.");
			});
	};

	// Add preview modal component
	renderPreviewModal = () => {
		return (
			<Modal
				visible={this.state.showPreviewModal}
				animationType='slide'
				transparent={true}
				onRequestClose={() =>
					this.setState({ showPreviewModal: false })
				}>
				<View
					style={{
						flex: 1,
						backgroundColor: "rgba(0,0,0,0.5)",
						justifyContent: "center",
						alignItems: "center",
					}}>
					<View
						style={{
							backgroundColor: "#FFF",
							borderRadius: 10,
							padding: 20,
							width: "90%",
							maxWidth: 800,
							maxHeight: "90%",
						}}>
						<Text
							style={{
								fontSize: 18,
								fontWeight: "600",
								marginBottom: 15,
							}}>
							Preview Calendar
						</Text>

						<ScrollView style={{ flex: 1, marginBottom: 15 }}>
							<iframe
								srcDoc={this.state.previewHtml}
								style={{
									width: "100%",
									height: 700,
									border: "none",
									borderRadius: 8,
								}}
							/>
						</ScrollView>

						<TextInput
							style={{
								borderWidth: 1,
								borderColor: APP_COLOURS.BORDER,
								borderRadius: 8,
								padding: 10,
								marginBottom: 15,
							}}
							placeholder='Enter email address for approval'
							value={this.state.emailAddress}
							onChangeText={(text) =>
								this.setState({ emailAddress: text })
							}
							keyboardType='email-address'
						/>

						<View
							style={{
								flexDirection: "row",
								justifyContent: "flex-end",
								gap: 10,
							}}>
							<TouchableOpacity
								style={{
									padding: 10,
									borderRadius: 8,
									backgroundColor: APP_COLOURS.BORDER,
								}}
								onPress={() => {
									const printWindow = window.open(
										"",
										"_blank",
									);
									printWindow.document.write(
										this.state.previewHtml,
									);
									printWindow.document.close();
									printWindow.print();
								}}>
								<Text style={{ color: "#FFF" }}>Print</Text>
							</TouchableOpacity>

							<TouchableOpacity
								style={{
									padding: 10,
									borderRadius: 8,
									backgroundColor: APP_COLOURS.RED,
								}}
								onPress={() =>
									this.setState({ showPreviewModal: false })
								}>
								<Text style={{ color: "#FFF" }}>Close</Text>
							</TouchableOpacity>

							<TouchableOpacity
								style={{
									padding: 10,
									borderRadius: 8,
									backgroundColor: APP_COLOURS.PRIMARY,
								}}
								onPress={this.handleSendPreview}>
								<Text style={{ color: "#FFF" }}>
									Send for Approval
								</Text>
							</TouchableOpacity>
						</View>
					</View>
				</View>
			</Modal>
		);
	};

	render() {
		return (
			<View style={styles.container}>
				<View style={styles.header}>
					<Text style={styles.headerTitle}>Overview</Text>

					<View style={styles.headerButtons}>
						{/* Add Export button */}
						<TouchableOpacity
							style={[
								styles.editButton,
								{ backgroundColor: APP_COLOURS.GREEN },
							]}
							onPress={() => {
								const previewHtml = this.generatePreviewHtml();
								this.setState({
									showPreviewModal: true,
									previewHtml,
								});
							}}>
							<Text style={styles.editButtonText}>Export</Text>
						</TouchableOpacity>

						<TouchableOpacity
							style={[
								styles.editButton,
								this.state.isEditing && styles.editButtonActive,
							]}
							onPress={this.toggleEditMode}>
							<Text style={styles.editButtonText}>
								{this.state.isEditing ? "Cancel" : "Edit"}
							</Text>
						</TouchableOpacity>

						<CalendarStatus
							status={this.props.data.status}
							calendarId={this.props.data.id}
							onUpdate={(updates) => {
								if (this.props.onUpdate) {
									this.props.onUpdate(updates);
								}
								// Force refresh calendars list
								if (this.props.refreshCalendars) {
									this.props.refreshCalendars();
								}
							}}
						/>

						<TouchableOpacity
							style={styles.deleteButton}
							onPress={this.handleDelete}>
							<Text style={styles.deleteButtonText}>Delete</Text>
						</TouchableOpacity>
					</View>
				</View>

				<View style={styles.section}>
					<Text style={styles.sectionTitle}>Title</Text>
					{this.state.isEditing ? (
						<TextInput
							style={styles.input}
							value={this.state.title}
							onChangeText={(text) =>
								this.handleInputChange("title", text)
							}
							placeholder='Enter title'
						/>
					) : (
						<Text style={styles.contentText}>
							{this.state.title}
						</Text>
					)}
				</View>

				<View style={styles.section}>
					<Text style={styles.sectionTitle}>Description</Text>
					{this.state.isEditing ? (
						<TextInput
							style={[styles.input, styles.textArea]}
							value={this.state.description}
							onChangeText={(text) =>
								this.handleInputChange("description", text)
							}
							placeholder='Enter description'
							multiline
							numberOfLines={4}
						/>
					) : (
						<Text style={styles.contentText}>
							{this.state.description}
						</Text>
					)}
				</View>

				{this.renderArrayItems("objectives", "Objectives")}
				{this.renderArrayItems("focusThisMonth", "This Month's Focus")}
				{this.renderArrayItems("importantDates", "Important Dates")}

				{this.state.isEditing && this.state.hasChanges && (
					<TouchableOpacity
						style={styles.saveButton}
						onPress={() => {
							this.saveChanges();
							this.toggleEditMode();
						}}
						disabled={this.state.saving}>
						{this.state.saving ? (
							<ActivityIndicator color='#FFF' />
						) : (
							<Text style={styles.saveButtonText}>
								Save Changes
							</Text>
						)}
					</TouchableOpacity>
				)}

				{this.renderPreviewModal()}
			</View>
		);
	}
}

const styles = {
	container: {
		flex: 1,
		padding: 20,
		borderRadius: 10,
		maxWidth: 400,
	},
	section: {
		marginBottom: 20,
	},
	sectionTitle: {
		fontSize: 16,
		fontWeight: "600",
		color: APP_COLOURS.BG2,
		marginBottom: 10,
	},
	inputRow: {
		flexDirection: "row",
		alignItems: "center",
		marginBottom: 8,
	},
	input: {
		flex: 1,
		borderWidth: 1,
		borderColor: APP_COLOURS.BORDER,
		borderRadius: 8,
		padding: 10,
		marginRight: 8,
		fontSize: 14,
		color: APP_COLOURS.BG2,
	},
	removeButton: {
		width: 30,
		height: 30,
		borderRadius: 15,
		backgroundColor: APP_COLOURS.RED,
		justifyContent: "center",
		alignItems: "center",
	},
	removeButtonText: {
		color: "#FFF",
		fontSize: 20,
		fontWeight: "600",
	},
	addButton: {
		padding: 10,
		borderRadius: 8,
		borderWidth: 1,
		borderStyle: "dashed",
		borderColor: APP_COLOURS.PRIMARY,
		alignItems: "center",
		marginTop: 8,
	},
	addButtonText: {
		color: APP_COLOURS.PRIMARY,
		fontSize: 14,
		fontWeight: "500",
	},
	saveButton: {
		backgroundColor: APP_COLOURS.PRIMARY,
		padding: 15,
		borderRadius: 8,
		alignItems: "center",
		marginTop: 20,
	},
	saveButtonText: {
		color: "#FFF",
		fontSize: 16,
		fontWeight: "600",
	},
	header: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: 20,
	},
	headerTitle: {
		fontSize: 20,
		fontWeight: "600",
		color: APP_COLOURS.BG2,
	},
	editButton: {
		paddingHorizontal: 15,
		paddingVertical: 8,
		borderRadius: 20,
		backgroundColor: APP_COLOURS.PRIMARY,
	},
	editButtonActive: {
		backgroundColor: APP_COLOURS.RED,
	},
	editButtonText: {
		color: "#FFF",
		fontSize: 14,
		fontWeight: "500",
	},
	itemText: {
		fontSize: 14,
		color: APP_COLOURS.BG2,
		marginBottom: 8,
		lineHeight: 20,
	},
	emptyText: {
		fontSize: 14,
		color: APP_COLOURS.BORDER,
		fontStyle: "italic",
	},
	headerButtons: {
		flexDirection: "row",
		alignItems: "center",
		gap: 10,
	},
	deleteButton: {
		paddingHorizontal: 15,
		paddingVertical: 8,
		borderRadius: 20,
		backgroundColor: APP_COLOURS.RED,
	},
	deleteButtonText: {
		color: "#FFF",
		fontSize: 14,
		fontWeight: "500",
	},
	textArea: {
		height: 100,
		textAlignVertical: "top",
	},
	contentText: {
		fontSize: 14,
		color: APP_COLOURS.BG2,
		marginBottom: 8,
		lineHeight: 20,
	},
};
