import React, { Component } from "react";
import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	Image,
	Modal,
	TextInput,
	ActivityIndicator,
} from "react-native";
import moment from "moment";
import { APP_COLOURS } from "../../APP_VARS";
import { Avatars, StatusColour, TaskStatus } from "../../APP_VARS";
import StatusItem from "../DailyPlanner/StatusItem";
import Lottie from "react-lottie";
import {
	crudCreate,
	crudDelete,
	crudGetcalendars,
	crudRead,
	crudReadAll,
	crudReadUnass,
	crudUpdate,
} from "../../CRUDHELPER";
import Fade from "react-reveal/Fade";
import debbie from "../../../assets/images/cloud.png";
import AutoHeightImage from "../AutoHeightImageComp";
import ContentCalendarItem from "./ContentCalendarItem";
import * as animationData from "../../../assets/lottie/team.json";
import PostCalendar from "./PostCalendar";
import AddContent from "./AddContent";
import AddContentCalendar from "./AddContentCalendar";
import OverviewSection from "./OverviewSection";
import InstagramGridView from "./InstagramGridView";
import LeftSidebar from "./LeftSidebar";
import TikTokGridView from "./TikTokGridView";

const styles = {
	viewToggleContainer: {
		flexDirection: "row",
		justifyContent: "center",
		marginBottom: 20,
		padding: 5,
		backgroundColor: APP_COLOURS.WHITE_TRANSPARENT,
		borderRadius: 25,
	},
	toggleButton: {
		paddingVertical: 8,
		paddingHorizontal: 16,
		borderRadius: 20,
		marginHorizontal: 5,
	},
	toggleButtonActive: {
		backgroundColor: APP_COLOURS.PRIMARY,
	},
	toggleButtonText: {
		color: APP_COLOURS.BG2,
		fontSize: 14,
		fontWeight: "500",
	},
	toggleButtonTextActive: {
		color: "#FFF",
	},
};

function uuid() {
	return "xxxxxxxxx".replace(/[xy]/g, function (c) {
		var r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

const crudDocumentName = "contentcalendar_content";

export default class ContentCalendar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user_data: this.props.user_data,
			loading: true,
			addNewContent: false,
			addContentCalendar: false,
			selectedContentCalendar: "",
			calendars: [],
			selectedUser: "",
			contentItems: [],
			viewMode: "calendar",
			brand: null,
		};
	}

	componentDidMount() {
		this.getcalendars();
		console.log("this.user_data.user_data", this.props.user_data);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.user_data !== this.props.user_data) {
			this.setState({
				user_data: this.props.user_data,
			});
		}
	}

	getcalendars = () => {
		this.setState({ loading: true });

		crudReadUnass("contentcalendars", "", (status, calendars) => {
			if (status === 200) {
				const transformedCalendars = calendars.map((calendar) => ({
					...calendar,
					contentList: Array.isArray(calendar.contentList)
						? calendar.contentList
						: [],
					calendarDate: calendar.start_date
						? moment(calendar.start_date).format("DD/MM/YYYY")
						: moment().format("DD/MM/YYYY"),
					client_name: calendar.client_name || "",
					client_id: calendar.client_id || "",
					objectives: Array.isArray(calendar.objectives)
						? calendar.objectives
						: [],
					focusThisMonth: Array.isArray(calendar.focusThisMonth)
						? calendar.focusThisMonth
						: [],
					importantDates: Array.isArray(calendar.importantDates)
						? calendar.importantDates
						: [],
					stage: calendar.stage || 1,
					status: calendar.status || "Planning",
					description: calendar.description || "",
					handle: calendar.handle || "",
					createdAt: calendar.createdAt || moment().format(),
					createdBy: calendar.createdBy || "",
					publishDate: calendar.publishDate || moment().format(),
				}));

				this.setState({
					calendars: transformedCalendars || [],
					loading: false,
				});
			} else {
				console.error("Error fetching calendars:", status);
				this.setState({
					loading: false,
					calendars: [],
				});
			}
		});
	};

	getContentItems = (calendarId) => {
		if (!calendarId) return;

		this.setState({ loading: true });

		console.log("calendarId", calendarId);
		crudReadUnass(
			"contentcalendar_content",
			{
				field: "calendar_id",
				action: "==",
				value: calendarId,
			},
			(status, content) => {
				if (status === 200) {
					console.log("content", content);
					const transformedContent = content;

					this.setState({
						contentItems: transformedContent,
						loading: false,
					});
				} else {
					console.error("Error fetching content items:", status);
					this.setState({
						loading: false,
						contentItems: [],
					});
				}
			},
		);
	};

	refreshCalendars = () => {
		this.getcalendars();
		if (
			this.state.selectedContentCalendar &&
			this.state.selectedContentCalendar.id
		) {
			this.getContentItems(this.state.selectedContentCalendar.id);
		}
	};

	handleCalendarSelect = (calendar) => {
		console.log("calendar", calendar);
		this.setState(
			{
				selectedContentCalendar: calendar,
			},
			() => {
				if (calendar && calendar.id) {
					console.log("calendar", calendar);
					this.getContentItems(calendar.id);
				}
			},
		);
	};

	renderViewToggle = () => {
		const { viewMode } = this.state;
		return (
			<View style={styles.viewToggleContainer}>
				<TouchableOpacity
					style={[
						styles.toggleButton,
						viewMode === "calendar" && styles.toggleButtonActive,
					]}
					onPress={() =>
						this.setState({
							viewMode: "calendar",
							selectedPost: null,
						})
					}>
					<Text
						style={[
							styles.toggleButtonText,
							viewMode === "calendar" &&
								styles.toggleButtonTextActive,
						]}>
						Calendar View
					</Text>
				</TouchableOpacity>
				<TouchableOpacity
					style={[
						styles.toggleButton,
						viewMode === "instagram" && styles.toggleButtonActive,
					]}
					onPress={() =>
						this.setState({
							viewMode: "instagram",
							selectedPost: null,
						})
					}>
					<Text
						style={[
							styles.toggleButtonText,
							viewMode === "instagram" &&
								styles.toggleButtonTextActive,
						]}>
						Instagram Grid
					</Text>
				</TouchableOpacity>
				<TouchableOpacity
					style={[
						styles.toggleButton,
						viewMode === "tiktok" && styles.toggleButtonActive,
					]}
					onPress={() =>
						this.setState({
							viewMode: "tiktok",
							selectedPost: null,
						})
					}>
					<Text
						style={[
							styles.toggleButtonText,
							viewMode === "tiktok" &&
								styles.toggleButtonTextActive,
						]}>
						TikTok Grid
					</Text>
				</TouchableOpacity>
			</View>
		);
	};

	render() {
		let calendars = this.state.calendars.filter((d_) => {
			let filter = d_;

			// Filter by brand
			if (this.state.brand) {
				filter = filter && d_.client_id === this.state.brand.id;
			}

			// Filter by search
			if (
				typeof this.state.filterSearch !== "undefined" &&
				this.state.filterSearch !== ""
			) {
				let text_ = this.state.filterSearch;
				var outString = text_.replace(
					/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
					"",
				);

				let join_names = `${d_.title} ${d_.client_name}`;

				join_names = join_names.replace(
					/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
					"",
				);

				return join_names
					.toLowerCase()
					.includes(outString.toLowerCase());
			}

			return filter;
		});
		return (
			<View style={{ flex: 1, padding: 10 }}>
				<View
					style={{
						flex: 1,
						width: "100%",
						flexDirection: "row",
						marginBottom: 5,
					}}>
					<LeftSidebar
						showInput={this.state.showInput}
						filterSearch={this.state.filterSearch}
						loading={this.state.loading}
						user_data={this.state.user_data}
						addContentCalendar={this.state.addContentCalendar}
						calendars={calendars}
						selectedContentCalendar={
							this.state.selectedContentCalendar
						}
						brand={this.state.brand}
						onSearchChange={(text) => {
							this.setState({ filterSearch: text });
						}}
						onAddCalendar={() => {
							this.setState({ addContentCalendar: true });
						}}
						onCalendarSelect={this.handleCalendarSelect}
						onBrandSelect={(d) => this.setState({ brand: d })}
						style={{
							display:
								this.state.selectedContentCalendar !== ""
									? "none"
									: "flex",
						}}
					/>

					{this.state.selectedContentCalendar !== ""
						? this.calendarPage(this.state.selectedContentCalendar)
						: null}
				</View>
				{this.addNewContent()}
				{this.addContentCalendar()}
			</View>
		);
	}

	contentSection = (d) => {
		// Filter content items based on selected date
		const filteredContent = this.state.contentItems.filter((item) => {
			const itemDate = moment(item.publishDate).format("DD/MM/YYYY");
			const selectedDate = moment(this.state.selectedDate).format(
				"DD/MM/YYYY",
			);
			return itemDate === selectedDate;
		});

		return (
			<View style={{ flex: 1, marginTop: 20 }}>
				<View
					style={{
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
						marginBottom: 15,
						paddingHorizontal: 10,
					}}>
					<Text
						style={{
							fontSize: 18,
							fontWeight: "600",
							color: APP_COLOURS.BG2,
						}}>
						Content Items -{" "}
						{moment(this.state.selectedDate).format("DD MMMM YYYY")}
					</Text>
					<TouchableOpacity
						style={{
							paddingHorizontal: 15,
							paddingVertical: 8,
							borderRadius: 20,
							backgroundColor: APP_COLOURS.PRIMARY,
							flexDirection: "row",
							alignItems: "center",
						}}
						onPress={() => {
							this.setState({
								addNewContent: true,
							});
						}}>
						<Text
							style={{
								color: "#FFF",
								fontSize: 14,
								marginRight: 5,
							}}>
							+
						</Text>
						<Text
							style={{
								color: "#FFF",
								fontSize: 14,
							}}>
							Add Content
						</Text>
					</TouchableOpacity>
				</View>

				<View style={{ flex: 1 }}>
					{filteredContent.length === 0 ? (
						<View
							style={{
								padding: 20,
								alignItems: "center",
							}}>
							<Text
								style={{
									color: APP_COLOURS.BORDER,
									fontSize: 14,
									fontStyle: "italic",
								}}>
								No content items for{" "}
								{moment(this.state.selectedDate).format(
									"DD MMMM YYYY",
								)}
							</Text>
						</View>
					) : (
						filteredContent.map((item, index) => (
							<ContentCalendarItem
								key={item.id || index}
								data={item}
								{...this.state}
								calendarId={d.id}
								onUpdate={() => {
									this.getContentItems(d.id);
									this.refreshCalendars();
								}}
								onDelete={() => {
									this.getContentItems(d.id);
									this.refreshCalendars();
								}}
							/>
						))
					)}
				</View>
			</View>
		);
	};

	contentCalendarItems = (d) => {
		let size = 16;
		return (
			<TouchableOpacity
				style={{}}
				onPress={async () => {
					await this.setState({
						selectedContentCalendar: d,
					});

					this.handleCalendarSelect(d);

					console.log("SLECTED=======", d);
				}}>
				<View
					style={{
						marginBottom: 5,
						padding: 10,
						backgroundColor:
							this.state.selectedContentCalendar.id === d.id
								? APP_COLOURS.DESELECTEDCOLOR
								: APP_COLOURS.WHITE_TRANSPARENT,
						flexDirection: "row",
						alignItems: "center",
						borderRadius: 8,
						overflow: "hidden",
					}}>
					<View
						style={{
							flex: 1,
							flexDirection: "row",
							// alignItems: 'center',
						}}>
						<View
							style={{
								flex: 1,
								height: "auto",
								maxWidth: 10,
								borderRadius: 2,
								// backgroundColor: TaskStatus(d.status),
								backgroundColor:
									this.state.selectedContentCalendar.id ===
									d.id
										? APP_COLOURS.BLUE
										: APP_COLOURS.WHITE_TRANSPARENT,
								marginRight: 15,
								justifyContent: "center",
								alignItems: "center",
							}}></View>
						{/* <View
				style={{
					justifyContent: "center",
					alignItems: "center",
					width: 110,
				}}>
				<View
					style={{
						// width: 150,
						justifyContent: "center",
						alignItems: "center",
						paddingRight: 2,
					}}>
					<Text
						allowFontScaling={false}
						style={{
							fontWeight: "700",
							fontSize: 14,
							// color: TaskStatus(d.status),
							// opacity: 0.8,
						}}>
						{moment(d.publishDate).fromNow()}
					</Text>
					<Text
						allowFontScaling={false}
						style={{
							fontWeight:
								this.state.selectedContentCalendar === d.id
									? "700"
									: "300",
							fontSize: 10,
							color: APP_COLOURS.BG2,
							// opacity: 0.8,
						}}>
						{typeof d.publishDate !== "undefined" &&
						d.publishDate !== ""
							? `${moment(d.publishDate).format(
									"DD/MM/YY HH:mm:ss",
							  )}`
							: ""}
					</Text>
				</View>
			</View> */}

						<View
							style={{
								justifyContent: "center",
								alignItems: "center",
								flexDirection: "row",
								padding: 10,
							}}>
							<View
								style={{
									width: 200,
								}}>
								<Text
									allowFontScaling={false}
									style={{
										fontWeight: "500",
										fontSize: 16,
										color: APP_COLOURS.BG2,
									}}>
									{d.client_name}
								</Text>
								{/* <Text
									allowFontScaling={false}
									style={{
										fontWeight: "300",
										fontSize: 14,
										color: APP_COLOURS.BG2,
									}}>
									{d.handle}
								</Text> */}
							</View>
						</View>

						<View
							style={{
								flex: 1,
								// justifyContent: "center",
								alignItems: "center",
								flexDirection: "row",
							}}>
							<Text
								allowFontScaling={false}
								style={{
									fontWeight:
										this.state.selectedContentCalendar ===
										d.id
											? "700"
											: "500",
									fontSize: 16,
									color: APP_COLOURS.BG2,
								}}>
								{d.title}
							</Text>
						</View>

						<View
							style={{
								width: 80,
								justifyContent: "center",
								alignItems: "center",
								borderLeftWidth: 1,
								paddingLeft: 5,
								borderLeftColor: "#E8E8E8",
							}}>
							<View
								style={{
									flexDirection: "row",
									width: "100%",
								}}>
								<View
									style={{
										width: 80,
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										backgroundColor: StatusColour(d.status),
									}}>
									<Text
										style={{
											fontSize: 10,
											fontWeight: "300",
										}}>
										{d.status}
									</Text>
								</View>
							</View>
							{/* <View
								style={{
									width: 80,
									marginVertical: 15,
									backgroundColor: "#FFF",
								}}>
								<ProgressBarStages
									progress={`${(d.stage / 7) * 100}%`}
									inProgress={true}
									color={TaskStatus(d.status)}
								/>
							</View> */}
						</View>
					</View>
				</View>
			</TouchableOpacity>
		);
	};

	calendarPage = (d) => {
		const { viewMode } = this.state;
		const contentList = Array.isArray(d.contentList) ? d.contentList : [];
		const objectives = Array.isArray(d.objectives) ? d.objectives : [];
		const focusThisMonth = Array.isArray(d.focusThisMonth)
			? d.focusThisMonth
			: [];
		const importantDates = Array.isArray(d.importantDates)
			? d.importantDates
			: [];

		return (
			<View style={{ flex: 1, flexDirection: "row" }}>
				<View
					style={{
						flex: 1,
						backgroundColor: APP_COLOURS.WHITE_TRANSPARENT99,
						padding: 5,
						borderRadius: 10,
						marginLeft: 5,
					}}>
					<View
						style={{
							padding: 10,
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "center",
						}}>
						<TouchableOpacity
							style={{
								borderRadius: 15,
								flexDirection: "row",
								paddingVertical: 10,
								alignItems: "center",
								paddingHorizontal: 15,
								borderWidth: 0.5,
								borderColor: APP_COLOURS.BG2,
								maxWidth: 150,
							}}
							onPress={() => {
								this.setState({
									selectedContentCalendar: "",
								});
							}}>
							<Text
								style={{
									color: APP_COLOURS.BG2,
									fontSize: 12,
								}}>
								Back
							</Text>
						</TouchableOpacity>

						<View style={{ flex: 1, padding: 10 }}>
							<Text
								style={{
									color: APP_COLOURS.BG2,
									fontSize: 19,
									fontWeight: "700",
								}}>
								{d.title}
							</Text>
						</View>
					</View>

					<View style={{ flex: 1, padding: 10 }}>
						<ScrollView showsVerticalScrollIndicator={false}>
							<View style={{ flexDirection: "row" }}>
								<OverviewSection
									data={{
										...d,
										contentList,
										objectives,
										focusThisMonth,
										importantDates,
									}}
									onUpdate={(updates) => {
										const updatedCalendar = {
											...this.state
												.selectedContentCalendar,
											...updates,
										};
										this.setState({
											selectedContentCalendar:
												updatedCalendar,
										});
										this.refreshCalendars();
									}}
									onDelete={() => {
										this.setState({
											selectedContentCalendar: "",
										});
										this.refreshCalendars();
									}}
									refreshCalendars={this.refreshCalendars}
								/>

								<View
									style={{
										flex: 1,
										borderRadius: 10,
										// backgroundColor:
										// 	APP_COLOURS.WHITE_TRANSPARENT99,
										marginLeft: 5,
										justifyContent: "center",
										alignItems: "center",
									}}>
									{this.renderViewToggle()}
									{viewMode === "calendar" ? (
										<View style={{ flex: 1, padding: 10 }}>
											<View style={{ width: 600 }}>
												<PostCalendar
													compWidth={600}
													startDate={(dd) => {
														console.log(
															"startDate",
															dd,
														);
														this.setState({
															selectedDate: dd,
														});
													}}
													endDate={(dd) => {
														console.log(dd);
													}}
													selectedDate={
														this.state
															.selectedDate ||
														moment(
															d.calendarDate,
															"DD/MM/YYYY",
														)
													}
													calendarDate={
														d.calendarDate
													}
													contentList={
														this.state.contentItems
													}
												/>
											</View>
										</View>
									) : viewMode === "instagram" ? (
										<InstagramGridView
											contentItems={
												this.state.contentItems
											}
											selectedContentCalendar={
												this.state
													.selectedContentCalendar
											}
										/>
									) : (
										<TikTokGridView
											contentItems={
												this.state.contentItems
											}
											selectedContentCalendar={
												this.state
													.selectedContentCalendar
											}
										/>
									)}
								</View>
							</View>
							<View style={{}}>{this.contentSection(d)}</View>
						</ScrollView>
					</View>
				</View>

				{this.loadingComp()}
			</View>
		);
	};

	loadingComp = () => {
		if (this.state.loading) {
			return (
				<Modal animationType='fade' transparent={true} visible={true}>
					<View
						style={{
							flex: 1,
							justifyContent: "flex-start",
							alignItems: "center",
							backgroundColor: "rgba(52, 52, 52, 0.6)",
						}}>
						<View
							style={{
								flex: 1,
								justifyContent: "center",
								alignItems: "center",
							}}>
							<View
								style={{
									// flex: 1,
									width: 300,
									height: 250,
									backgroundColor: "#FFF",
									justifyContent: "center",
									alignItems: "center",
									borderRadius: 15,
								}}>
								<Image
									source={debbie}
									style={{
										width: 70,
										height: 60,
										resizeMode: "contain",
									}}
								/>
								<Text
									style={{
										color: APP_COLOURS.BG2,
										fontSize: 15,
										marginLeft: 10,
										fontWeight: "600",
										fontFamily: "Gotham",
									}}>
									Loading...
								</Text>

								<ActivityIndicator color={APP_COLOURS.BG2} />
							</View>
						</View>
					</View>
				</Modal>
			);
		}
	};

	addNewContent = () => {
		if (this.state.addNewContent) {
			return (
				<AddContent
					{...this.state}
					selectedDate={moment(this.state.selectedDate, "DD/MM/YYYY")}
					selectedContentCalendarId={
						this.state.selectedContentCalendar.id
					}
					addEntry={(obj) => {
						this.setState({
							loading: true,
						});
						// console.log("obj", obj);
						// return;
						crudCreate(crudDocumentName, obj, () => {
							this.setState((prevState) => ({
								// projects: [...prevState.projects, newProject],
								addNewContent: false,
								loading: false,
							}));
							this.refreshCalendars();
							this.calendarPage(
								this.state.selectedContentCalendar,
							);
						});
					}}
					close={() =>
						this.setState({
							addNewContent: false,
							loading: false,
						})
					}
				/>
			);
		}
	};

	addContentCalendar = () => {
		if (this.state.addContentCalendar) {
			if (!this.state.brand) {
				return null;
			}
			return (
				<AddContentCalendar
					{...this.state}
					selectedProject={this.state.filterTab}
					selectedBrand={this.state.brand}
					addEntry={(obj) => {
						// Create new calendar with default structure
						const newCalendar = {
							...obj,
							id: uuid(),
							calendarDate: obj.start_date
								? moment(obj.start_date).format("DD/MM/YYYY")
								: moment().format("DD/MM/YYYY"),
							createdAt: moment().toISOString(),
							createdBy: this.state.user_data.id || "",
							description: obj.description || "",
							link: "",
							publishDate: moment().toISOString(),
							stage: 1,
							status: "Planning",

							// Default arrays
							objectives: [],
							focusThisMonth: [],
							importantDates: [],
							contentList: [],

							client_id: this.state.brand.id || "",
							client_name: this.state.brand.title || "",

							// Additional metadata
							messages: [],
							subtasks: [],
							assignment: [],
							calendarList: [],

							// Handle from brand's social channels
							handle:
								this.state.brand.socialChannels?.find(
									(channel) =>
										channel.platform === "Instagram",
								)?.handle || "",
						};

						crudCreate("contentcalendars", newCalendar, () => {
							this.refreshCalendars();
							this.setState({
								addContentCalendar: false,
							});
						});
					}}
					close={() =>
						this.setState({
							addContentCalendar: false,
						})
					}
				/>
			);
		}
	};
}
