import React, { useState } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	ScrollView,
	ActivityIndicator,
} from "react-native";
import { APP_COLOURS } from "../../APP_VARS";
import { crudCreate, crudUpdate } from "../../CRUDHELPER";

const SOCIAL_PLATFORMS = [
	{
		name: "Instagram",
		icon: "📸",
	},
	{
		name: "Facebook",
		icon: "👥",
	},
	{
		name: "Twitter",
		icon: "🐦",
	},
	{
		name: "LinkedIn",
		icon: "💼",
	},
	{
		name: "TikTok",
		icon: "🎵",
	},
];

const SocialMediaChannels = ({ brand, onUpdate }) => {
	const [isAdding, setIsAdding] = useState(false);
	const [loading, setLoading] = useState(false);
	const [newChannel, setNewChannel] = useState({
		platform: "",
		handle: "",
		url: "",
	});

	const handleAddChannel = async () => {
		if (!newChannel.platform || !newChannel.handle) return;

		setLoading(true);
		const updatedChannels = [
			...(brand.socialChannels || []),
			{ ...newChannel, id: Date.now().toString() },
		];

		try {
			await crudUpdate("brands", {
				id: brand.id,
				socialChannels: updatedChannels,
			});
			onUpdate();
			setIsAdding(false);
			setNewChannel({ platform: "", handle: "", url: "" });
		} catch (error) {
			console.error("Error adding social channel:", error);
		}
		setLoading(false);
	};

	const renderChannelList = () => {
		if (!brand.socialChannels || brand.socialChannels.length === 0) {
			return (
				<View
					style={{
						padding: 20,
						alignItems: "center",
					}}>
					<Text
						style={{
							color: APP_COLOURS.BG2,
							fontSize: 14,
							marginBottom: 10,
						}}>
						No social media channels added yet
					</Text>
				</View>
			);
		}

		return brand.socialChannels.map((channel) => (
			<View
				key={channel.id}
				style={{
					flexDirection: "row",
					alignItems: "center",
					padding: 10,
					backgroundColor: APP_COLOURS.WHITE_TRANSPARENT,
					borderRadius: 8,
					marginBottom: 5,
				}}>
				<Text style={{ fontSize: 20, marginRight: 10 }}>
					{
						SOCIAL_PLATFORMS.find(
							(p) => p.name === channel.platform,
						)?.icon
					}
				</Text>
				<Text
					style={{
						marginLeft: 10,
						color: APP_COLOURS.BG2,
						flex: 1,
						fontSize: 14,
					}}>
					{channel.handle}
				</Text>
			</View>
		));
	};

	const renderAddChannel = () => {
		if (!isAdding) return null;

		return (
			<View
				style={{
					padding: 10,
					backgroundColor: APP_COLOURS.WHITE_TRANSPARENT,
					borderRadius: 8,
					marginTop: 10,
				}}>
				<ScrollView horizontal showsHorizontalScrollIndicator={false}>
					{SOCIAL_PLATFORMS.map((platform) => (
						<TouchableOpacity
							key={platform.name}
							style={{
								padding: 10,
								backgroundColor:
									newChannel.platform === platform.name
										? APP_COLOURS.PRIMARY
										: APP_COLOURS.WHITE_TRANSPARENT,
								borderRadius: 8,
								marginRight: 10,
								flexDirection: "row",
								alignItems: "center",
							}}
							onPress={() =>
								setNewChannel({
									...newChannel,
									platform: platform.name,
								})
							}>
							<Text style={{ fontSize: 20, marginRight: 5 }}>
								{platform.icon}
							</Text>
							<Text
								style={{
									color:
										newChannel.platform === platform.name
											? "#FFF"
											: APP_COLOURS.BG2,
								}}>
								{platform.name}
							</Text>
						</TouchableOpacity>
					))}
				</ScrollView>

				<TextInput
					style={{
						marginTop: 10,
						padding: 10,
						backgroundColor: "#E8E8E8",
						borderRadius: 8,
						color: APP_COLOURS.BG2,
					}}
					placeholder='Enter handle (e.g. @brandname)'
					value={newChannel.handle}
					onChangeText={(text) =>
						setNewChannel({ ...newChannel, handle: text })
					}
				/>

				<TextInput
					style={{
						marginTop: 10,
						padding: 10,
						backgroundColor: "#E8E8E8",
						borderRadius: 8,
						color: APP_COLOURS.BG2,
					}}
					placeholder='Profile URL (optional)'
					value={newChannel.url}
					onChangeText={(text) =>
						setNewChannel({ ...newChannel, url: text })
					}
				/>

				<View
					style={{
						flexDirection: "row",
						justifyContent: "flex-end",
						marginTop: 10,
					}}>
					<TouchableOpacity
						style={{
							padding: 10,
							marginRight: 10,
						}}
						onPress={() => setIsAdding(false)}>
						<Text style={{ color: APP_COLOURS.BG2 }}>Cancel</Text>
					</TouchableOpacity>
					<TouchableOpacity
						style={{
							padding: 10,
							backgroundColor: APP_COLOURS.PRIMARY,
							borderRadius: 8,
						}}
						onPress={handleAddChannel}>
						{loading ? (
							<ActivityIndicator color='#FFF' />
						) : (
							<Text style={{ color: "#FFF" }}>Add Channel</Text>
						)}
					</TouchableOpacity>
				</View>
			</View>
		);
	};

	return (
		<View
			style={{
				padding: 10,
				borderRadius: 10,
				backgroundColor: "#FFF",
				marginVertical: 10,
			}}>
			<View
				style={{
					flexDirection: "row",
					justifyContent: "space-between",
					alignItems: "center",
					marginBottom: 10,
				}}>
				<Text
					style={{
						fontSize: 16,
						fontWeight: "600",
						color: APP_COLOURS.BG2,
					}}>
					Social Media Channels
				</Text>
				{!isAdding && (
					<TouchableOpacity
						style={{
							padding: 8,
							backgroundColor: APP_COLOURS.PRIMARY,
							borderRadius: 8,
						}}
						onPress={() => setIsAdding(true)}>
						<Text style={{ color: "#FFF" }}>Add Channel</Text>
					</TouchableOpacity>
				)}
			</View>

			{renderChannelList()}
			{renderAddChannel()}
		</View>
	);
};

export default SocialMediaChannels;
