import React, { Component } from "react";
import { View, Text, TouchableOpacity } from "react-native";
import { APP_COLOURS } from "../../APP_VARS";

const STRATEGY_TAGS = ["Awareness", "Consideration", "Conversion", "Promotion"];

export default class StrategyTagSelector extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedTags: props.existingTags || [],
		};
	}

	toggleTag = (tag) => {
		let selectedTags = [...this.state.selectedTags];
		const index = selectedTags.indexOf(tag);

		if (index > -1) {
			selectedTags.splice(index, 1);
		} else {
			selectedTags.push(tag);
		}

		this.setState({ selectedTags });

		// Call onAdd prop with the newly selected tag
		if (index === -1 && this.props.onAdd) {
			this.props.onAdd(tag);
		}
	};

	render() {
		return (
			<View style={{ padding: 10 }}>
				<Text
					style={{
						fontWeight: "400",
						fontFamily: "Gotham",
						fontSize: 12,
						color: APP_COLOURS.BG2,
						marginBottom: 10,
						position: "absolute",
						top: -2,
						left: 19,
						backgroundColor: "#FFF",
						padding: 4,
						borderRadius: 8,
						zIndex: 99,
					}}>
					Strategy Tags
				</Text>
				<View
					style={{
						flexDirection: "row",
						flexWrap: "wrap",
						borderWidth: 1,
						borderColor: APP_COLOURS.BORDER,
						borderRadius: 8,
						padding: 10,
						marginTop: 10,
					}}>
					{STRATEGY_TAGS.map((tag, index) => (
						<TouchableOpacity
							key={index}
							onPress={() => this.toggleTag(tag)}
							style={{
								backgroundColor:
									this.state.selectedTags.includes(tag)
										? APP_COLOURS.BG2
										: "#FFF",
								borderRadius: 20,
								paddingHorizontal: 15,
								paddingVertical: 8,
								margin: 5,
								borderWidth: 1,
								borderColor: APP_COLOURS.BG2,
							}}>
							<Text
								style={{
									color: this.state.selectedTags.includes(tag)
										? "#FFF"
										: APP_COLOURS.BG2,
									fontFamily: "Gotham",
									fontSize: 14,
								}}>
								{tag}
							</Text>
						</TouchableOpacity>
					))}
				</View>
			</View>
		);
	}
}
