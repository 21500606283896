import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	Image,
	TextInput,
	ScrollView,
	Keyboard,
	Modal,
	ActivityIndicator,
} from "react-native";
import { APP_COLOURS } from "../../APP_VARS";

export default class PostTypes extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filterSearch: "",
			postTypes: [
				// Facebook
				"Facebook - Banner",
				"Facebook - Post",
				"Facebook - Story",
				"Facebook - Live",
				"Facebook - Event",
				"Facebook - Poll",
				"Facebook - Group Post",
				"Facebook - Shop Post",

				// Instagram
				"Instagram - Single Post",
				"Instagram - Carousel",
				"Instagram - Story",
				"Instagram - Reel",
				"Instagram - Guide",
				"Instagram - Live",
				"Instagram - IGTV",
				"Instagram - 3 post feed",

				// LinkedIn
				"LinkedIn - Post",
				"LinkedIn - Article",
				"LinkedIn - Poll",
				"LinkedIn - Document",
				"LinkedIn - Event",
				"LinkedIn - Newsletter",

				// Twitter/X
				"Twitter - Tweet",
				"Twitter - Thread",
				"Twitter - Poll",
				"Twitter - Space",

				// TikTok
				"TikTok - Video",
				"TikTok - Story",
				"TikTok - Live",
				"TikTok - Photo",

				// YouTube
				"YouTube - Video",
				"YouTube - Short",
				"YouTube - Live",
				"YouTube - Community Post",
				"YouTube - Premiere",

				// Pinterest
				"Pinterest - Pin",
				"Pinterest - Story",
				"Pinterest - Idea Pin",
				"Pinterest - Shop Pin",

				// Snapchat
				"Snapchat - Snap",
				"Snapchat - Story",
				"Snapchat - Spotlight",
			],
		};
	}

	render() {
		let { postTypes } = this.state;
		return (
			<View style={{ padding: 10, width: "100%" }}>
				<View
					style={{
						flexDirection: "row",
						backgroundColor: APP_COLOURS.BLACK_TRANS,
						borderRadius: 10,
						padding: 10,
						marginBottom: 10,
					}}>
					<TextInput
						placeholder='Search post types...'
						placeholderTextColor={APP_COLOURS.BG2}
						value={this.state.filterSearch}
						onChangeText={(text) =>
							this.setState({ filterSearch: text })
						}
						style={{
							flex: 1,
							color: APP_COLOURS.BG2,
							fontSize: 14,
						}}
					/>
				</View>

				<ScrollView showsHorizontalScrollIndicator={false} horizontal>
					<View
						style={{
							flexDirection: "row",
						}}>
						{postTypes
							.filter((d_) => {
								let filter = d_;

								if (
									typeof this.state.filterSearch !==
										"undefined" &&
									this.state.filterSearch !== ""
								) {
									let text_ = this.state.filterSearch;
									var outString = text_.replace(
										/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
										"",
									);

									let join_names = `${d_}`;

									join_names = join_names.replace(
										/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
										"",
									);

									return join_names
										.toLowerCase()
										.includes(outString.toLowerCase());
								}

								return filter;
							})
							.map((d, i) => (
								<TouchableOpacity
									key={i}
									style={{}}
									onPress={() => {
										this.setState({
											filterTab: d,
										});

										this.props.selected(d);
									}}>
									<View
										style={{
											width: "auto",
											//   height: 50,
											justifyContent: "center",
											alignItems: "center",
											backgroundColor:
												this.state.filterTab === d
													? APP_COLOURS.BLUE
													: APP_COLOURS.BLACK_TRANS,
											padding: 10,
											borderRadius: 10,
											marginRight: 5,
										}}>
										<Text
											style={{
												fontSize: 12,
												color:
													this.state.filterTab === d
														? "#FFF"
														: APP_COLOURS.BG2,
												fontWeight: "500",
											}}>
											{d}
										</Text>
									</View>
								</TouchableOpacity>
							))}
					</View>
				</ScrollView>
			</View>
		);
	}
}
