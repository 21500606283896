import React, { useState, useEffect } from "react";
import { View, Text, StyleSheet, ScrollView } from "react-native";
import moment from "moment";
import { darkTheme } from "./theme";

export default function WorkLogModal({
	isVisible,
	onClose,
	workLogs,
	tasks,
	initialMode = "logs",
}) {
	const [selectedDateRange, setSelectedDateRange] = useState("today");
	const [showReportPreview, setShowReportPreview] = useState(false);
	const [mode, setMode] = useState(initialMode);

	useEffect(() => {
		setMode(initialMode);
		setShowReportPreview(isVisible && initialMode === "summary");
	}, [initialMode, isVisible]);

	if (!isVisible) return null;

	const formatDuration = (seconds) => {
		const hours = Math.floor(seconds / 3600);
		const minutes = Math.floor((seconds % 3600) / 60);
		return `${hours}h ${minutes}m`;
	};

	const getDateRangeStart = () => {
		switch (selectedDateRange) {
			case "today":
				return moment().startOf("day");
			case "week":
				return moment().startOf("week");
			case "month":
				return moment().startOf("month");
			default:
				return moment(0);
		}
	};

	const filterLogsByDate = (logs) => {
		const startDate = getDateRangeStart();
		return logs.filter((log) =>
			moment.unix(log.startTime).isSameOrAfter(startDate),
		);
	};

	const generateHTMLReport = () => {
		const reportDate = moment().format("MMMM D, YYYY");
		let totalDuration = 0;

		const taskLogs = Object.entries(workLogs).map(([taskId, logs]) => {
			const task = tasks.find((t) => t.id === taskId);
			const filteredLogs = filterLogsByDate(logs);
			const taskDuration = filteredLogs.reduce(
				(total, log) => total + (log.duration || 0),
				0,
			);
			totalDuration += taskDuration;

			return {
				task,
				logs: filteredLogs,
				duration: taskDuration,
			};
		});

		const html = `
			<!DOCTYPE html>
			<html>
			<head>
				<style>
					body { font-family: Arial, sans-serif; line-height: 1.6; color: #333; }
					.header { text-align: center; margin-bottom: 30px; }
					.task { margin-bottom: 20px; padding: 15px; background: #f5f5f5; border-radius: 5px; }
					.task-header { display: flex; justify-content: space-between; margin-bottom: 10px; }
					.task-title { font-weight: bold; color: #2196F3; }
					.task-duration { color: #666; }
					.log-entry { margin-left: 20px; padding: 5px 0; border-bottom: 1px solid #eee; }
					.summary { margin-top: 30px; padding-top: 20px; border-top: 2px solid #ddd; }
				</style>
			</head>
			<body>
				<div class="header">
					<h1>Work Log Report</h1>
					<p>Generated on ${reportDate}</p>
					<p>Period: ${selectedDateRange}</p>
				</div>
				${taskLogs
					.filter((taskLog) => taskLog.logs.length > 0)
					.map(
						({ task, logs, duration }) => `
					<div class="task">
						<div class="task-header">
							<span class="task-title">${task.title}</span>
							<span class="task-duration">Total: ${formatDuration(duration)}</span>
						</div>
						${logs
							.map(
								(log) => `
							<div class="log-entry">
								<div>${moment.unix(log.startTime).format("MMM D, h:mm A")} - 
									${log.endTime ? moment.unix(log.endTime).format("h:mm A") : "In Progress"}</div>
								<div>Duration: ${log.duration ? formatDuration(log.duration) : "Ongoing"}</div>
							</div>
						`,
							)
							.join("")}
					</div>
				`,
					)
					.join("")}
				<div class="summary">
					<h2>Summary</h2>
					<p>Total Time: ${formatDuration(totalDuration)}</p>
				</div>
			</body>
			</html>
		`;

		return html;
	};

	const handleExport = () => {
		const html = generateHTMLReport();
		const blob = new Blob([html], { type: "text/html" });
		const url = URL.createObjectURL(blob);
		const a = document.createElement("a");
		a.href = url;
		a.download = `worklog-${selectedDateRange}-${moment().format(
			"YYYY-MM-DD",
		)}.html`;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		URL.revokeObjectURL(url);
	};

	const handlePreview = () => {
		setShowReportPreview(true);
	};

	const ReportPreviewModal = () => {
		if (!showReportPreview) return null;

		const containerStyle = {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			backgroundColor: "rgba(0, 0, 0, 0.85)",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			zIndex: 1100,
		};

		const contentStyle = {
			backgroundColor: darkTheme.colors.surface,
			borderRadius: darkTheme.borderRadius.lg,
			width: "90%",
			height: "90vh",
			padding: darkTheme.spacing.lg,
			display: "flex",
			flexDirection: "column",
		};

		const headerStyle = {
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-between",
			alignItems: "center",
			marginBottom: darkTheme.spacing.md,
		};

		const titleStyle = {
			fontSize: 20,
			fontWeight: "bold",
			color: darkTheme.colors.text.primary,
		};

		const controlsStyle = {
			display: "flex",
			flexDirection: "row",
			gap: darkTheme.spacing.sm,
		};

		const buttonStyle = {
			padding: darkTheme.spacing.sm,
			borderRadius: darkTheme.borderRadius.sm,
			cursor: "pointer",
			color: darkTheme.colors.text.primary,
			fontWeight: "500",
		};

		const previewContainerStyle = {
			flex: 1,
			backgroundColor: "#ffffff",
			borderRadius: darkTheme.borderRadius.md,
			overflow: "hidden",
		};

		return (
			<div style={containerStyle}>
				<div style={contentStyle}>
					<div style={headerStyle}>
						<div style={titleStyle}>
							{mode === "summary"
								? "Daily Summary"
								: "Report Preview"}
						</div>
						<div style={controlsStyle}>
							{mode === "logs" && (
								<button
									onClick={handleExport}
									style={{
										...buttonStyle,
										backgroundColor:
											darkTheme.colors.primary,
									}}>
									Download
								</button>
							)}
							{mode === "summary" && (
								<button
									onClick={handleSendSummary}
									style={{
										...buttonStyle,
										backgroundColor:
											darkTheme.colors.primary,
									}}>
									Send Summary
								</button>
							)}
							<button
								onClick={onClose}
								style={{
									...buttonStyle,
									backgroundColor: darkTheme.colors.error,
								}}>
								Close
							</button>
						</div>
					</div>
					<div style={previewContainerStyle}>
						<div
							style={{
								width: "100%",
								height: "100%",
								overflow: "auto",
								padding: "20px",
							}}
							dangerouslySetInnerHTML={{
								__html: generateHTMLReport(),
							}}
						/>
					</div>
				</div>
			</div>
		);
	};

	const handleSendSummary = async () => {
		try {
			console.log("Sending summary...");
			onClose();
		} catch (error) {
			console.error("Error sending summary:", error);
		}
	};

	if (mode === "summary") {
		return <ReportPreviewModal />;
	}

	return (
		<View style={styles.modalOverlay}>
			<View style={styles.modalContent}>
				<View style={styles.modalHeader}>
					<Text style={styles.modalTitle}>Work Log</Text>
					<View style={styles.controls}>
						<View style={styles.dateRangeSelector}>
							{["today", "week", "month", "all"].map((range) => (
								<Text
									key={range}
									style={[
										styles.dateRangeOption,
										selectedDateRange === range &&
											styles.selectedRange,
									]}
									onClick={() => setSelectedDateRange(range)}>
									{range.charAt(0).toUpperCase() +
										range.slice(1)}
								</Text>
							))}
						</View>
						<View style={styles.buttonGroup}>
							<View
								onClick={handlePreview}
								style={[styles.button, styles.exportButton]}>
								<Text style={styles.buttonText}>
									Preview Report
								</Text>
							</View>
							<View
								onClick={onClose}
								style={[styles.button, styles.closeButton]}>
								<Text style={styles.buttonText}>Close</Text>
							</View>
						</View>
					</View>
				</View>
				<ScrollView style={styles.logList}>
					{Object.entries(workLogs).map(([taskId, logs]) => {
						const task = tasks.find((t) => t.id === taskId);
						const filteredLogs = filterLogsByDate(logs);
						if (!filteredLogs.length) return null;

						return (
							<View key={taskId} style={styles.taskSection}>
								<Text style={styles.taskTitle}>
									{task?.title}
								</Text>
								{filteredLogs.map((log) => (
									<View key={log.id} style={styles.logEntry}>
										<Text style={styles.logTime}>
											{moment
												.unix(log.startTime)
												.format("MMM D, h:mm A")}{" "}
											-{" "}
											{log.endTime
												? moment
														.unix(log.endTime)
														.format("h:mm A")
												: "In Progress"}
										</Text>
										<Text style={styles.logDuration}>
											{log.duration
												? formatDuration(log.duration)
												: "Ongoing"}
										</Text>
									</View>
								))}
							</View>
						);
					})}
				</ScrollView>
			</View>
			<ReportPreviewModal />
		</View>
	);
}

const styles = StyleSheet.create({
	modalOverlay: {
		position: "fixed",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: "rgba(0, 0, 0, 0.75)",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		zIndex: 1000,
	},
	modalContent: {
		backgroundColor: darkTheme.colors.surface,
		borderRadius: darkTheme.borderRadius.lg,
		width: "80%",
		maxWidth: 900,
		maxHeight: "90vh",
		padding: darkTheme.spacing.lg,
	},
	modalHeader: {
		marginBottom: darkTheme.spacing.lg,
	},
	modalTitle: {
		fontSize: 24,
		fontWeight: "bold",
		color: darkTheme.colors.text.primary,
		marginBottom: darkTheme.spacing.md,
	},
	controls: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		gap: darkTheme.spacing.md,
	},
	dateRangeSelector: {
		flexDirection: "row",
		gap: darkTheme.spacing.sm,
	},
	dateRangeOption: {
		padding: darkTheme.spacing.sm,
		color: darkTheme.colors.text.secondary,
		cursor: "pointer",
		borderRadius: darkTheme.borderRadius.sm,
	},
	selectedRange: {
		backgroundColor: darkTheme.colors.primary,
		color: darkTheme.colors.text.primary,
	},
	buttonGroup: {
		flexDirection: "row",
		gap: darkTheme.spacing.sm,
	},
	button: {
		padding: darkTheme.spacing.sm,
		borderRadius: darkTheme.borderRadius.sm,
		cursor: "pointer",
	},
	exportButton: {
		backgroundColor: darkTheme.colors.primary,
	},
	closeButton: {
		backgroundColor: darkTheme.colors.error,
	},
	buttonText: {
		color: darkTheme.colors.text.primary,
		fontWeight: "500",
	},
	logList: {
		flex: 1,
	},
	taskSection: {
		marginBottom: darkTheme.spacing.lg,
	},
	taskTitle: {
		fontSize: 18,
		fontWeight: "500",
		color: darkTheme.colors.text.primary,
		marginBottom: darkTheme.spacing.sm,
	},
	logEntry: {
		flexDirection: "row",
		justifyContent: "space-between",
		padding: darkTheme.spacing.sm,
		backgroundColor: darkTheme.colors.background,
		borderRadius: darkTheme.borderRadius.sm,
		marginBottom: darkTheme.spacing.xs,
	},
	logTime: {
		color: darkTheme.colors.text.primary,
	},
	logDuration: {
		color: darkTheme.colors.text.secondary,
	},
	previewModalOverlay: {
		position: "fixed",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: "rgba(0, 0, 0, 0.85)",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		zIndex: 1100, // Higher than the parent modal
	},
	previewModalContent: {
		backgroundColor: darkTheme.colors.surface,
		borderRadius: darkTheme.borderRadius.lg,
		width: "90%",
		height: "90vh",
		padding: darkTheme.spacing.lg,
		display: "flex",
		flexDirection: "column",
	},
	previewHeader: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: darkTheme.spacing.md,
	},
	previewTitle: {
		fontSize: 20,
		fontWeight: "bold",
		color: darkTheme.colors.text.primary,
	},
	previewControls: {
		flexDirection: "row",
		gap: darkTheme.spacing.sm,
	},
	previewContainer: {
		flex: 1,
		backgroundColor: "#ffffff",
		borderRadius: darkTheme.borderRadius.md,
		overflow: "hidden",
	},
	iframeContainer: {
		width: "100%",
		height: "100%",
		border: "none",
		overflow: "auto",
	},
});
