import React, { useState, useEffect } from "react";
import { View, Text, StyleSheet } from "react-native";
import moment from "moment";
import { darkTheme } from "./theme";

export default function TaskTimer({
	startTime,
	isRunning,
	onPause,
	onStop,
	onComplete,
	totalElapsedTime = 0,
}) {
	const [elapsedTime, setElapsedTime] = useState(totalElapsedTime);
	const [status, setStatus] = useState(isRunning ? "started" : "paused");

	useEffect(() => {
		let interval;
		const now = moment().unix();

		if (isRunning && startTime) {
			// Calculate initial elapsed time including any previous time
			const initialElapsed = totalElapsedTime + (now - startTime);
			setElapsedTime(initialElapsed);
			setStatus("started");

			// Set up interval for running timer
			interval = setInterval(() => {
				const currentTime = moment().unix();
				const newElapsed = totalElapsedTime + (currentTime - startTime);
				setElapsedTime(newElapsed);
			}, 1000);
		} else {
			// For stopped/paused tasks, show the total elapsed time
			setElapsedTime(totalElapsedTime);
			setStatus(isRunning ? "started" : "paused");
		}

		// Cleanup interval on unmount or when dependencies change
		return () => {
			if (interval) {
				clearInterval(interval);
			}
		};
	}, [isRunning, startTime, totalElapsedTime]);

	// Update status when isRunning changes
	useEffect(() => {
		setStatus(isRunning ? "started" : "paused");
	}, [isRunning]);

	const formatTime = (seconds) => {
		const duration = moment.duration(seconds, "seconds");
		const hours = Math.floor(duration.asHours());
		const minutes = duration.minutes();
		const secs = duration.seconds();

		return `${hours.toString().padStart(2, "0")}:${minutes
			.toString()
			.padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
	};

	const StartTimeDisplay = () => {
		if (!startTime) {
			return <Text style={styles.startedText}>Not started</Text>;
		}
		return (
			<Text style={styles.startedText}>
				Started: {moment.unix(startTime).format("h:mm A")}
			</Text>
		);
	};

	return (
		<View style={styles.container}>
			<Text style={styles.statusText}>
				Status: {status.charAt(0).toUpperCase() + status.slice(1)}
			</Text>
			<Text style={styles.timerText}>{formatTime(elapsedTime)}</Text>
			<StartTimeDisplay />

			<View style={styles.controlsContainer}>
				{isRunning ? (
					<>
						<View
							onClick={onPause}
							style={[styles.button, styles.pauseButton]}>
							<Text style={styles.buttonText}>Pause</Text>
						</View>
						<View
							onClick={onStop}
							style={[styles.button, styles.stopButton]}>
							<Text style={styles.buttonText}>Stop</Text>
						</View>
						<View
							onClick={onComplete}
							style={[styles.button, styles.completeButton]}>
							<Text style={styles.buttonText}>Complete</Text>
						</View>
					</>
				) : (
					<View
						onClick={onPause}
						style={[styles.button, styles.startButton]}>
						<Text style={styles.buttonText}>
							{startTime ? "Resume" : "Start"}
						</Text>
					</View>
				)}
			</View>
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		padding: darkTheme.spacing.lg,
		backgroundColor: darkTheme.colors.surface,
		borderRadius: darkTheme.borderRadius.lg,
		alignItems: "center",
		gap: darkTheme.spacing.md,
	},
	statusText: {
		fontSize: 16,
		color: darkTheme.colors.text.secondary,
		marginBottom: darkTheme.spacing.sm,
	},
	timerText: {
		fontSize: 32,
		fontWeight: "bold",
		color: darkTheme.colors.text.primary,
		fontFamily: "monospace",
	},
	startedText: {
		fontSize: 14,
		color: darkTheme.colors.text.secondary,
		marginBottom: darkTheme.spacing.md,
	},
	controlsContainer: {
		flexDirection: "row",
		gap: darkTheme.spacing.md,
		justifyContent: "center",
		flexWrap: "wrap",
	},
	button: {
		padding: darkTheme.spacing.sm,
		paddingHorizontal: darkTheme.spacing.lg,
		borderRadius: darkTheme.borderRadius.md,
		cursor: "pointer",
		minWidth: 100,
		alignItems: "center",
	},
	buttonText: {
		color: darkTheme.colors.text.primary,
		fontWeight: "500",
	},
	startButton: {
		backgroundColor: darkTheme.colors.primary,
	},
	pauseButton: {
		backgroundColor: darkTheme.colors.warning,
	},
	stopButton: {
		backgroundColor: darkTheme.colors.error,
	},
	completeButton: {
		backgroundColor: darkTheme.colors.success,
	},
});
