import React, { useState, useEffect, useRef } from "react";
import {
	View,
	Text,
	TextInput,
	StyleSheet,
	ScrollView,
	Pressable,
	Image,
	ActivityIndicator,
} from "react-native-web";
import { PhotoIcon, CalendarIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import NDPTable from "./NDPTable";
import { api } from "../../Api";

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB in bytes

const NDPDetail = ({ ndp, onUpdateNDP }) => {
	const [localNDP, setLocalNDP] = useState(ndp);
	const [hasChanges, setHasChanges] = useState(false);
	const [isProcessingImage, setIsProcessingImage] = useState(false);
	const [uploadError, setUploadError] = useState(null);
	const [users, setUsers] = useState([]);
	const [currentUser, setCurrentUser] = useState(null);
	const [isUserModalOpen, setIsUserModalOpen] = useState(false);
	const [selectedSection, setSelectedSection] = useState(null);
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [searchQuery, setSearchQuery] = useState("");
	const fileInputRef = useRef(null);

	useEffect(() => {
		setLocalNDP(ndp);
		setHasChanges(false);
		setUploadError(null);
	}, [ndp]);

	useEffect(() => {
		const fetchUsers = async () => {
			try {
				console.log("Fetching users...`${api}/users`", `${api}/users`);
				const response = await axios.get(`${api}/users`);
				console.log("Users response:", response.data); // Debug log
				// Ensure we're setting an array of users
				const usersArray = Array.isArray(response.data)
					? response.data
					: response.data.users
					? response.data.users
					: response.data.data
					? response.data.data
					: [];
				setUsers(usersArray);
				// Set current user from localStorage
				const userData = JSON.parse(localStorage.getItem("user_data"));
				setCurrentUser(userData);
			} catch (error) {
				console.error("Error fetching users:", error);
				setUsers([]); // Set empty array on error
			}
		};
		fetchUsers();
	}, []);

	const handleInputChange = (field, value) => {
		setLocalNDP((prev) => ({
			...prev,
			[field]: value,
		}));
		setHasChanges(true);
	};

	const formatDateForInput = (dateString) => {
		if (!dateString) return "";
		try {
			const date = new Date(dateString);
			return date.toISOString().split("T")[0];
		} catch (error) {
			return "";
		}
	};

	const renderInput = (field) => {
		const isDateField =
			field.key === "sttDate" || field.key === "inStoreDate";

		if (isDateField) {
			return (
				<View style={styles.dateInputContainer}>
					<input
						type='date'
						value={formatDateForInput(localNDP[field.key])}
						onChange={(e) =>
							handleInputChange(field.key, e.target.value)
						}
						className='date-input'
						style={styles.dateInput}
					/>
					<CalendarIcon style={styles.calendarIcon} />
				</View>
			);
		}

		return (
			<TextInput
				style={styles.input}
				value={localNDP[field.key] || ""}
				onChange={(e) => handleInputChange(field.key, e.target.value)}
				placeholder={field.label}
			/>
		);
	};

	const handleSave = async () => {
		try {
			const user_data = JSON.parse(localStorage.getItem("user_data"));
			if (!user_data || !user_data.accessToken) {
				setUploadError("Authentication error: No valid token found");
				return;
			}

			let dataToSend;
			if (localNDP.image) {
				if (localNDP.image.startsWith("data:image")) {
					// For base64 images, send directly in JSON
					dataToSend = {
						...localNDP,
						image: localNDP.image,
						title: localNDP.productName,
						description: `Image for ${localNDP.productName}`,
					};
				} else if (localNDP.imageFile) {
					// For file uploads, use FormData
					const formData = new FormData();
					formData.append("image", localNDP.imageFile);
					formData.append("title", localNDP.productName);
					formData.append(
						"description",
						`Image for ${localNDP.productName}`,
					);

					// Add other NDP data
					const ndpData = { ...localNDP };
					delete ndpData.image;
					delete ndpData.imageFile;
					formData.append("data", JSON.stringify(ndpData));

					dataToSend = formData;
				} else {
					// If image is a URL, just send the regular update
					dataToSend = { ...localNDP };
				}
			} else {
				dataToSend = { ...localNDP };
			}

			// Call the update function with the appropriate data
			const response = await onUpdateNDP(dataToSend);

			// Update local state with the new image URL if provided
			if (response?.data?.imageUrl) {
				setLocalNDP((prev) => ({
					...prev,
					imageUrl: response.data.imageUrl,
					image: null, // Clear the temporary image data
				}));
			}

			setHasChanges(false);
			setUploadError(null);
		} catch (error) {
			console.error("Error saving NDP:", error);
			if (error.response) {
				setUploadError(
					`Server error: ${
						error.response.data.message || error.response.statusText
					}`,
				);
			} else if (error.request) {
				setUploadError(
					"No response from server. Please check your connection.",
				);
			} else {
				setUploadError(`Error: ${error.message}`);
			}
		}
	};

	const handleImageClick = () => {
		fileInputRef.current?.click();
	};

	const handleImageChange = async (event) => {
		const file = event.target.files?.[0];
		if (!file) return;

		try {
			setIsProcessingImage(true);
			setUploadError(null);

			// Validate file size (5MB limit)
			if (file.size > MAX_FILE_SIZE) {
				throw new Error("Image size exceeds 5MB limit");
			}

			// Validate file type
			const validTypes = ["image/jpeg", "image/png", "image/gif"];
			if (!validTypes.includes(file.type)) {
				throw new Error(
					"Invalid file type. Please use JPEG, PNG, or GIF",
				);
			}

			// Store the file object for FormData upload
			handleInputChange("imageFile", file);

			// Create preview
			const reader = new FileReader();
			reader.onloadend = () => {
				handleInputChange("image", reader.result);
			};
			reader.onerror = () => {
				throw new Error("Failed to read file");
			};
			reader.readAsDataURL(file);
		} catch (error) {
			console.error("Error processing image:", error);
			setUploadError(error.message);
		} finally {
			setIsProcessingImage(false);
		}
	};

	const handleAccessChange = (sectionId, selectedUsers) => {
		setLocalNDP((prev) => ({
			...prev,
			tableAccess: {
				...prev.tableAccess,
				[sectionId]: selectedUsers,
			},
		}));
		setHasChanges(true);
	};

	const canViewTable = (sectionId) => {
		if (!currentUser || !localNDP.tableAccess) return true; // Default to visible if no access control set
		const allowedUsers = localNDP.tableAccess[sectionId] || [];
		return (
			allowedUsers.length === 0 || allowedUsers.includes(currentUser.uid)
		);
	};

	const openUserModal = (sectionId) => {
		setSelectedSection(sectionId);
		setSelectedUsers(localNDP.tableAccess?.[sectionId] || []);
		setIsUserModalOpen(true);
	};

	const closeUserModal = () => {
		setIsUserModalOpen(false);
		setSelectedSection(null);
		setSelectedUsers([]);
	};

	const handleUserSelection = (userId) => {
		setSelectedUsers((prev) => {
			const isSelected = prev.includes(userId);
			if (isSelected) {
				return prev.filter((id) => id !== userId);
			} else {
				return [...prev, userId];
			}
		});
	};

	const getUserDisplayName = (user) => {
		if (user.first_name && user.last_name) {
			return `${user.first_name} ${user.last_name}`;
		}
		return user.username || "Unnamed User";
	};

	const getPositionDisplay = (user) => {
		const position = user.position || '';
		const organisation = user.organisation || '';
		
		if (position && organisation) {
			return `${position} • ${organisation}`;
		} else if (position) {
			return position;
		} else if (organisation) {
			return organisation;
		}
		return '';
	};

	const getUserById = (userId) => {
		return users.find((user) => user._id === userId || user.id === userId);
	};

	const getAccessDisplay = (sectionId) => {
		const allowedUsers = localNDP.tableAccess?.[sectionId] || [];
		if (allowedUsers.length === 0) return "Everyone";
		
		const userList = allowedUsers
			.map((userId) => {
				const user = getUserById(userId);
				return user ? getUserDisplayName(user) : null;
			})
			.filter(Boolean);
		
		return `${userList.length} ${userList.length === 1 ? 'user' : 'users'}`;
	};

	const renderSelectedUserAvatars = (sectionId) => {
		const allowedUsers = localNDP.tableAccess?.[sectionId] || [];
		if (allowedUsers.length === 0) return null;

		const selectedUsersList = allowedUsers
			.map((userId) => getUserById(userId))
			.filter(Boolean)
			.slice(0, 3); // Show max 3 avatars

		return (
			<View style={styles.avatarGroup}>
				{selectedUsersList.map((user, index) => (
					<View 
						key={user._id} 
						style={[
							styles.avatarContainer,
							index > 0 && { marginLeft: -8 }
						]}
					>
						<Image
							source={getAvatarSource(user.avatar || 1)}
							style={styles.smallAvatar}
						/>
					</View>
				))}
				{allowedUsers.length > 3 && (
					<View style={[styles.avatarContainer, { marginLeft: -8 }]}>
						<View style={styles.moreAvatars}>
							<Text style={styles.moreAvatarsText}>
								+{allowedUsers.length - 3}
							</Text>
						</View>
					</View>
				)}
			</View>
		);
	};

	const saveUserAccess = () => {
        if (!localNDP || !localNDP.id) {
            console.error("Error: NDP or NDP ID is missing", { localNDP });
            return;
        }

        const updatedNDP = {
            ...localNDP,
            tableAccess: {
                ...localNDP.tableAccess,
                [selectedSection]: selectedUsers
            }
        };
        setLocalNDP(updatedNDP);
        
        console.log("Saving user access:", {
            ndpId: localNDP.id,
            section: selectedSection,
            users: selectedUsers
        });

        // Save to backend using axios.put with ndp.id
        axios.put(`${api}/crud/ndp/${localNDP.id}`, updatedNDP)
            .then(() => {
                setIsUserModalOpen(false);
                setSelectedUsers([]);
                setSelectedSection(null);
                onUpdateNDP(updatedNDP); // Update parent component
            })
            .catch((error) => {
                console.error("Error saving user access:", error.response || error);
            });
    };

	const getFilteredUsers = () => {
		return users.filter((user) => {
			const searchLower = searchQuery.toLowerCase();
			const fullName = getUserDisplayName(user).toLowerCase();
			const username = (user.username || '').toLowerCase();
			const position = (user.position || '').toLowerCase();
			const organisation = (user.organisation || '').toLowerCase();
			
			return fullName.includes(searchLower) ||
				   username.includes(searchLower) ||
				   position.includes(searchLower) ||
				   organisation.includes(searchLower);
		});
	};

	const getAvatarSource = (avatarNumber) => {
		try {
			return require(`../../../assets/images/Avatars/${avatarNumber}.png`);
		} catch {
			return require("../../../assets/images/Avatars/1.png"); // Default avatar
		}
	};

	const headerFields = [
		{ key: "productName", label: "PRODUCT NAME" },
		{ key: "objective", label: "OBJECTIVE" },
		{ key: "sttDate", label: "STT DATE" },
		{ key: "inStoreDate", label: "IN-STORE DATE" },
		{ key: "totalUnits", label: "TOTAL UNITS" },
		{ key: "testers", label: "TESTERS" },
		{ key: "samples", label: "SAMPLES" },
		{ key: "artwork", label: "ARTWORK" },
	];

	const tableSections = [
		{
			title: "PR / PRESS DROPS",
			id: "pr",
		},
		{
			title: "MALL & STORE SUPPORT",
			id: "mall",
		},
		{
			title: "ON-LINE/ PAID MEDIA ADVERTISING",
			id: "online",
		},
		{
			title: "INFLUENCERS",
			id: "influencers",
		},
		{
			title: "DIRECT MARKETING",
			id: "direct",
		},
		{
			title: "COMMUNITY MANAGEMENT",
			id: "community",
		},
	];

	if (!ndp) {
		return (
			<View style={styles.emptyState}>
				<Text style={styles.emptyStateText}>
					Select an NDP from the list to view details
				</Text>
			</View>
		);
	}

	return (
		<ScrollView style={styles.container}>
			<View style={styles.header}>
				<View style={styles.headerTop}>
					<View>
						<Text style={styles.breadcrumb}>
							NDP Launch Plan /{" "}
							{localNDP.productName || "Untitled NDP"}
						</Text>
						<Text style={styles.title}>
							{localNDP.productName || "Untitled NDP"}
						</Text>
						<Text style={styles.subtitle}>
							Launch Date: {localNDP.inStoreDate || "Not set"}
						</Text>
					</View>
					{hasChanges && (
						<Pressable
							style={({ pressed }) => ({
								...styles.saveButton,
								opacity: pressed ? 0.8 : 1,
							})}
							onPress={handleSave}>
							<Text style={styles.saveButtonText}>
								Save Changes
							</Text>
						</Pressable>
					)}
				</View>
				<View style={styles.headerContent}>
					<View style={styles.headerFields}>
						<View style={styles.fieldsGrid}>
							{headerFields.map((field) => (
								<View key={field.key} style={styles.fieldGroup}>
									<Text style={styles.label}>
										{field.label}
									</Text>
									{renderInput(field)}
								</View>
							))}
						</View>
					</View>
					<View style={styles.imageSection}>
						<Pressable
							style={({ pressed }) => [
								styles.uploadButton,
								pressed && styles.buttonPressed,
							]}
							onPress={() => fileInputRef.current?.click()}
						>
							<PhotoIcon style={{ width: 24, height: 24, color: '#ffffff' }} />
							<Text style={styles.uploadButtonText}>
								{localNDP.imageUrl ? "Change Image" : "Upload Image"}
							</Text>
						</Pressable>
						{isProcessingImage ? (
							<View style={styles.imagePlaceholder}>
								<ActivityIndicator
									size='large'
									color='#dc2626'
								/>
								<Text style={styles.uploadText}>
									Processing image...
								</Text>
							</View>
						) : localNDP.imageUrl || localNDP.image ? (
							<Image
								source={{
									uri:
										localNDP.imageUrl || localNDP.image,
								}}
								style={styles.image}
								resizeMode='contain'
							/>
						) : (
							<View style={styles.imagePlaceholder}>
								<PhotoIcon style={styles.uploadIcon} />
								<Text style={styles.uploadText}>
									Click to upload product image
								</Text>
								<Text style={styles.uploadSubtext}>
									Maximum size: 5MB
								</Text>
							</View>
						)}
						{uploadError && (
							<Text style={styles.errorText}>{uploadError}</Text>
						)}
						<input
							type='file'
							ref={fileInputRef}
							style={{ display: "none" }}
							accept='image/*'
							onChange={handleImageChange}
						/>
					</View>
				</View>
			</View>

			<View style={styles.tables}>
				{tableSections.map(
					(section) =>
						canViewTable(section.id) && (
							<View key={section.id} style={styles.tableSection}>
								<View style={styles.tableTitleRow}>
									<Text style={styles.tableTitle}>
										{section.title}
									</Text>
									<View style={styles.accessControl}>
										{renderSelectedUserAvatars(section.id)}
										<Pressable
											style={styles.addButton}
											onPress={() => openUserModal(section.id)}>
											<Text style={styles.addButtonText}>+</Text>
										</Pressable>
									</View>
								</View>
								<NDPTable
									data={localNDP[section.id] || []}
									onUpdate={(newData) =>
										handleInputChange(section.id, newData)
									}
								/>
							</View>
						)
				)}
			</View>

			{isUserModalOpen && (
				<View style={styles.modalOverlay}>
					<View style={styles.modal}>
						<View style={styles.modalHeader}>
							<Text style={styles.modalTitle}>Manage Access</Text>
							<Pressable onPress={closeUserModal}>
								<Text style={styles.closeButton}>×</Text>
							</Pressable>
						</View>
						<View style={styles.searchContainer}>
							<MagnifyingGlassIcon style={styles.searchIcon} />
							<TextInput
								style={styles.searchInput}
								placeholder="Search users..."
								value={searchQuery}
								onChange={(e) => setSearchQuery(e.target.value)}
							/>
						</View>
						<ScrollView style={styles.userList}>
							{getFilteredUsers().map((user) => (
								<Pressable
									key={user._id}
									style={[
										styles.userItem,
										selectedUsers.includes(user._id) &&
										styles.userItemSelected,
									]}
									onPress={() => handleUserSelection(user._id)}
								>
									<View style={styles.userItemContent}>
										<Image
											source={getAvatarSource(user.avatar || 1)}
											style={styles.avatar}
										/>
										<View style={styles.userInfo}>
											<Text style={styles.userName}>
												{getUserDisplayName(user)}
											</Text>
											<Text style={styles.userDetails}>
												{getPositionDisplay(user)}
											</Text>
											<Text style={styles.userEmail}>
												{user.username || ''}
											</Text>
										</View>
									</View>
								</Pressable>
							))}
						</ScrollView>
						<View style={styles.modalFooter}>
							<Pressable
								style={styles.cancelButton}
								onPress={closeUserModal}>
								<Text style={styles.cancelButtonText}>Cancel</Text>
							</Pressable>
							<Pressable
								style={styles.saveButton}
								onPress={saveUserAccess}>
								<Text style={styles.saveButtonText}>Save</Text>
							</Pressable>
						</View>
					</View>
				</View>
			)}
		</ScrollView>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: "#ffffff",
	},
	emptyState: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: "#ffffff",
		padding: 24,
	},
	emptyStateText: {
		fontSize: 16,
		color: "#6b7280",
		textAlign: "center",
	},
	header: {
		padding: 16,
		borderBottomWidth: 1,
		borderBottomColor: "#e5e7eb",
		backgroundColor: "#f9fafb",
	},
	headerTop: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "flex-start",
		marginBottom: 16,
	},
	headerContent: {
		flexDirection: "row",
		gap: 16,
	},
	breadcrumb: {
		fontSize: 12,
		color: "#6b7280",
		marginBottom: 4,
	},
	title: {
		fontSize: 20,
		fontWeight: "600",
		color: "#111827",
		marginBottom: 2,
	},
	subtitle: {
		fontSize: 13,
		color: "#4b5563",
	},
	saveButton: {
		backgroundColor: "#dc2626",
		paddingHorizontal: 16,
		paddingVertical: 8,
		borderRadius: 6,
	},
	saveButtonText: {
		color: "#ffffff",
		fontSize: 14,
		fontWeight: "500",
	},
	headerFields: {
		flex: 1,
		backgroundColor: "#ffffff",
		borderRadius: 6,
		padding: 16,
		shadowColor: "#000",
		shadowOffset: { width: 0, height: 1 },
		shadowOpacity: 0.05,
		shadowRadius: 2,
	},
	imageSection: {
		width: window.innerWidth / 3,
		backgroundColor: "#ffffff",
		borderRadius: 6,
		padding: 16,
		shadowColor: "#000",
		shadowOffset: { width: 0, height: 1 },
		shadowOpacity: 0.05,
		shadowRadius: 2,
	},
	uploadButton: {
		backgroundColor: "#f3f4f6",
		paddingHorizontal: 16,
		paddingVertical: 8,
		borderRadius: 6,
	},
	buttonPressed: {
		backgroundColor: "#e5e7eb",
	},
	image: {
		width: "100%",
		height: "100%",
	},
	imagePlaceholder: {
		width: "100%",
		height: "100%",
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: "#f9fafb",
	},
	uploadIcon: {
		width: 24,
		height: 24,
		color: "#6b7280",
		marginBottom: 8,
	},
	uploadText: {
		fontSize: 13,
		color: "#6b7280",
		textAlign: "center",
		marginBottom: 4,
	},
	uploadSubtext: {
		fontSize: 11,
		color: "#9ca3af",
		textAlign: "center",
	},
	uploadButtonText: {
		color: "#6b7280",
		fontSize: 14,
		marginLeft: 8,
	},
	fieldsGrid: {},
	fieldGroup: {
		flexDirection: "row",
		alignItems: "center",
	},
	label: {
		width: 100,
		fontSize: 12,
		fontWeight: "500",
		color: "#374151",
		marginRight: 6,
	},
	input: {
		flex: 1,
		borderWidth: 1,
		borderColor: "#e5e7eb",
		borderRadius: 4,
		padding: 4,
		fontSize: 13,
		backgroundColor: "#ffffff",
		minHeight: 28,
	},
	tables: {
		padding: 1,
		gap: 16,
	},
	tableSection: {
		marginBottom: 16,
		backgroundColor: "#ffffff",
		borderRadius: 6,
		padding: 16,
		shadowColor: "#000",
		shadowOffset: { width: 0, height: 1 },
		shadowOpacity: 0.05,
		shadowRadius: 2,
	},
	tableTitleRow: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: 12,
	},
	tableTitle: {
		fontSize: 14,
		fontWeight: "600",
		color: "#111827",
		marginBottom: 12,
	},
	accessControl: {
		flexDirection: "row",
		alignItems: "center",
	},
	avatarGroup: {
		flexDirection: 'row',
		alignItems: 'center',
		marginRight: 8,
	},
	avatarContainer: {
		position: 'relative',
	},
	smallAvatar: {
		width: 24,
		height: 24,
		borderRadius: 12,
		borderWidth: 2,
		borderColor: '#ffffff',
	},
	moreAvatars: {
		width: 24,
		height: 24,
		borderRadius: 12,
		backgroundColor: '#f3f4f6',
		justifyContent: 'center',
		alignItems: 'center',
		borderWidth: 2,
		borderColor: '#ffffff',
	},
	moreAvatarsText: {
		fontSize: 10,
		fontWeight: '600',
		color: '#6b7280',
	},
	addButton: {
		backgroundColor: "#f3f4f6",
		width: 24,
		height: 24,
		borderRadius: 12,
		justifyContent: "center",
		alignItems: "center",
	},
	addButtonText: {
		fontSize: 16,
		color: "#6b7280",
		fontWeight: "600",
		marginTop: -2,
	},
	modalOverlay: {
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: "rgba(0, 0, 0, 0.5)",
		justifyContent: "center",
		alignItems: "center",
	},
	modal: {
		width: "90%",
		maxWidth: 500,
		backgroundColor: "#ffffff",
		borderRadius: 8,
		padding: 20,
		maxHeight: "80%",
	},
	modalHeader: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: 16,
	},
	modalTitle: {
		fontSize: 18,
		fontWeight: "600",
		color: "#111827",
	},
	closeButton: {
		fontSize: 24,
		color: "#6b7280",
		padding: 4,
	},
	userList: {
		maxHeight: 300,
	},
	userItem: {
		padding: 12,
		borderRadius: 6,
		marginBottom: 8,
		backgroundColor: "#f3f4f6",
	},
	userItemSelected: {
		backgroundColor: "#fee2e2",
		borderColor: "#dc2626",
		borderWidth: 1,
	},
	userName: {
		fontSize: 14,
		fontWeight: "600",
		color: "#111827",
		marginBottom: 2,
	},
	userDetails: {
		fontSize: 12,
		color: "#4b5563",
		marginBottom: 2,
	},
	userEmail: {
		fontSize: 12,
		color: "#6b7280",
	},
	modalFooter: {
		flexDirection: "row",
		justifyContent: "flex-end",
		gap: 12,
		marginTop: 20,
	},
	cancelButton: {
		paddingHorizontal: 16,
		paddingVertical: 8,
		borderRadius: 6,
		backgroundColor: "#f3f4f6",
	},
	cancelButtonText: {
		color: "#374151",
		fontSize: 14,
		fontWeight: "500",
	},
	saveButton: {
		backgroundColor: "#dc2626",
		paddingHorizontal: 16,
		paddingVertical: 8,
		borderRadius: 6,
	},
	saveButtonText: {
		color: "#ffffff",
		fontSize: 14,
		fontWeight: "500",
	},
	errorText: {
		color: "#dc2626",
		fontSize: 12,
		marginTop: 8,
		textAlign: "center",
	},
	dateInputContainer: {
		flex: 1,
		position: "relative",
		flexDirection: "row",
		alignItems: "center",
	},
	dateInput: {
		flex: 1,
		borderWidth: 1,
		borderColor: "#e5e7eb",
		borderRadius: 4,
		padding: 6,
		paddingRight: 30, // Make room for the calendar icon
		fontSize: 13,
		backgroundColor: "#ffffff",
		minHeight: 32,
		appearance: "none", // Remove default calendar icon
		color: "#111827",
	},
	calendarIcon: {
		position: "absolute",
		right: 8,
		width: 16,
		height: 16,
		color: "#6b7280",
		pointerEvents: "none",
	},
	searchContainer: {
		flexDirection: "row",
		alignItems: "center",
		padding: 8,
		backgroundColor: "#f3f4f6",
		borderRadius: 6,
		marginBottom: 16,
	},
	searchIcon: {
		width: 20,
		height: 20,
		color: "#6b7280",
		marginRight: 8,
	},
	searchInput: {
		flex: 1,
		fontSize: 14,
		color: "#111827",
		padding: 4,
	},
	userItemContent: {
		flexDirection: "row",
		alignItems: "center",
	},
	avatar: {
		width: 40,
		height: 40,
		borderRadius: 20,
		marginRight: 12,
	},
	userInfo: {
		flex: 1,
	},
	avatarGroup: {
		flexDirection: 'row',
		alignItems: 'center',
		marginRight: 8,
	},
	avatarContainer: {
		position: 'relative',
	},
	smallAvatar: {
		width: 24,
		height: 24,
		borderRadius: 12,
		borderWidth: 2,
		borderColor: '#ffffff',
	},
	avatarStatus: {
		position: 'absolute',
		bottom: -1,
		right: -1,
		backgroundColor: '#ffffff',
		borderRadius: 6,
		padding: 2,
	},
	statusDot: {
		width: 6,
		height: 6,
		borderRadius: 3,
	},
	moreAvatars: {
		width: 24,
		height: 24,
		borderRadius: 12,
		backgroundColor: '#f3f4f6',
		justifyContent: 'center',
		alignItems: 'center',
		borderWidth: 2,
		borderColor: '#ffffff',
	},
	moreAvatarsText: {
		fontSize: 10,
		fontWeight: '600',
		color: '#6b7280',
	},
	accessText: {
		fontSize: 12,
		color: '#6b7280',
	},
	titleSection: {
		flex: 1,
		marginRight: 16,
	},
	accessInfo: {
		flexDirection: 'row',
		alignItems: 'center',
		marginTop: 4,
	},
});

// Add global styles for the date input
const styleSheet = document.createElement("style");
styleSheet.textContent = `
    .date-input::-webkit-calendar-picker-indicator {
        opacity: 0;
        width: 24px;
        height: 24px;
        cursor: pointer;
    }
`;
document.head.appendChild(styleSheet);

export default NDPDetail;
