export const darkTheme = {
	colors: {
		background: "#1E1E1E",
		surface: "#2D2D2D",
		primary: "#4CAF50",
		error: "#FF5252",
		warning: "#FFC107",
		text: {
			primary: "#FFFFFF",
			secondary: "#B3B3B3",
			disabled: "#666666",
		},
		border: "#404040",
		hover: "#3D3D3D",
	},
	spacing: {
		xs: 4,
		sm: 8,
		md: 16,
		lg: 24,
		xl: 32,
	},
	borderRadius: {
		sm: 4,
		md: 8,
		lg: 12,
	},
};
