import React from "react";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	ActivityIndicator,
	ScrollView,
} from "react-native";
import Lottie from "react-lottie";
import AutoHeightImage from "../AutoHeightImageComp";
import { APP_COLOURS } from "../../APP_VARS";
import BrandTabs from "../BrandTabs";
import * as animationData from "../../../assets/lottie/team.json";
import SocialMediaChannels from "./SocialMediaChannels";
import { crudRead } from "../../CRUDHELPER";

const styles = {
	container: {
		width: 500,
		paddingBottom: 60,
		backgroundColor: "#FFF",
		padding: 5,
		borderRadius: 10,
	},
	header: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	searchContainer: {
		width: "100%",
		flexDirection: "row",
		alignItems: "center",
		height: 45,
		paddingLeft: 5,
	},
	searchInput: {
		flex: 1,
		minWidth: 200,
		backgroundColor: APP_COLOURS.BLACK_TRANS,
		borderRadius: 13,
	},
	inputStyle: {
		fontWeight: "400",
		fontFamily: "Gotham",
		width: "100%",
		paddingLeft: 15,
		padding: 10,
		fontSize: 15,
		borderRadius: 13,
		marginRight: 15,
		color: APP_COLOURS.BG2,
		backgroundColor: "#E8E8E8",
		fontWeight: "500",
	},
	brandTabsContainer: {
		padding: 10,
		borderRadius: 10,
		backgroundColor: APP_COLOURS.BG2,
		marginVertical: 10,
	},
};

const LeftSidebar = ({
	showInput,
	filterSearch,
	loading,
	user_data,
	addContentCalendar,
	calendars,
	selectedContentCalendar,
	onSearchChange,
	onAddCalendar,
	onCalendarSelect,
	onBrandSelect,
	brand,
}) => {
	return (
		<View style={styles.container}>
			<View style={styles.header}>
				<View style={{ padding: 10 }}>
					<TouchableOpacity
						style={{
							flexDirection: "row",
							alignItems: "center",
						}}>
						<AutoHeightImage
							width={20}
							source={require("../../../assets/images/menu.png")}
						/>
						<Text
							adjustsFontSizeToFit
							style={{
								fontWeight: "800",
								fontFamily: "Gotham",
								color: APP_COLOURS.BG2,
								fontSize: 13,
								marginLeft: 4,
							}}>
							tetrice
						</Text>
					</TouchableOpacity>
					<Text
						style={{
							color: APP_COLOURS.BG2,
							fontSize: 28,
							fontWeight: "800",
							fontFamily: "Gotham",
						}}>
						Content Calendar
					</Text>
				</View>
				<View>
					<Lottie
						options={{
							loop: true,
							autoplay: true,
							animationData: animationData,
							rendererSettings: {
								preserveAspectRatio: "xMidYMid slice",
							},
						}}
						height={150}
						width={200}
					/>
				</View>
			</View>

			<View style={styles.brandTabsContainer}>
				<Text
					style={{
						fontSize: 10,
						color: "#FFF",
						fontWeight: "400",
						fontFamily: "Gotham",
						padding: 0,
						marginBottom: 3,
					}}>
					Select brand
				</Text>
				<ScrollView horizontal showsHorizontalScrollIndicator={false}>
					<BrandTabs brand={brand} selectedSubPage={onBrandSelect} />
				</ScrollView>
			</View>

			{brand && (
				<SocialMediaChannels
					brand={brand}
					onUpdate={() => {
						// Refresh brand data
						if (brand.id) {
							crudRead(
								"brands",
								brand.id,
								(status, updatedBrand) => {
									if (status === 200) {
										onBrandSelect(updatedBrand);
									}
								},
							);
						}
					}}
				/>
			)}

			<View style={styles.searchContainer}>
				<View style={styles.searchInput}>
					{showInput && (
						<TextInput
							style={styles.inputStyle}
							placeholderTextColor={"#797979"}
							autoCapitalize='sentences'
							clearButtonMode='while-editing'
							autoCompleteType='off'
							editable={true}
							autoCorrect={true}
							autoFocus
							keyboardType='default'
							textContentType='none'
							placeholder={` Search `}
							defaultValue={filterSearch}
							onChangeText={onSearchChange}
						/>
					)}
				</View>
				<TouchableOpacity
					style={{ marginRight: 10, marginLeft: 10 }}
					onPress={() => onSearchChange("")}>
					<AutoHeightImage
						width={19}
						source={require("../../../assets/images/searchIcon.png")}
					/>
				</TouchableOpacity>
				{loading && <ActivityIndicator color={APP_COLOURS.PRIMARY} />}
				{user_data.username === "wesley@tetrice.co.za" && (
					<TouchableOpacity
						style={{
							marginLeft: 15,
							borderRadius: 15,
							flexDirection: "row",
							paddingVertical: 10,
							alignItems: "center",
							paddingHorizontal: 15,
							borderWidth: 0.5,
							borderColor: brand ? APP_COLOURS.BG2 : "#ccc",
							opacity: brand ? 1 : 0.5,
						}}
						disabled={!brand}
						onPress={onAddCalendar}>
						<Text
							style={{
								color: brand ? APP_COLOURS.BG2 : "#ccc",
								fontSize: 14,
								marginRight: 10,
							}}>
							+
						</Text>
						<Text
							style={{
								color: brand ? APP_COLOURS.BG2 : "#ccc",
								fontSize: 12,
							}}>
							New Calendar
						</Text>
					</TouchableOpacity>
				)}
			</View>
			{!brand && (
				<Text
					style={{
						color: APP_COLOURS.PRIMARY,
						fontSize: 12,
						textAlign: "center",
						marginTop: 10,
						fontStyle: "italic",
					}}>
					Please select a brand to create a new calendar
				</Text>
			)}

			<ScrollView style={{ flex: 1, marginTop: 10 }}>
				{loading ? (
					<View
						style={{
							flex: 1,
							justifyContent: "center",
							alignItems: "center",
							padding: 20,
						}}>
						<ActivityIndicator
							size='large'
							color={APP_COLOURS.PRIMARY}
						/>
						<Text
							style={{
								marginTop: 10,
								color: APP_COLOURS.BG2,
								fontSize: 14,
							}}>
							Loading calendars...
						</Text>
					</View>
				) : calendars.length === 0 ? (
					<View
						style={{
							flex: 1,
							justifyContent: "center",
							alignItems: "center",
							padding: 20,
						}}>
						<Text style={{ color: APP_COLOURS.BG2, fontSize: 14 }}>
							No calendars found
						</Text>
					</View>
				) : (
					calendars.map((calendar) => (
						<TouchableOpacity
							key={calendar.id}
							style={{
								marginBottom: 5,
								padding: 10,
								backgroundColor:
									selectedContentCalendar.id === calendar.id
										? APP_COLOURS.DESELECTEDCOLOR
										: APP_COLOURS.WHITE_TRANSPARENT,
								flexDirection: "row",
								alignItems: "center",
								borderRadius: 8,
								overflow: "hidden",
							}}
							onPress={() => onCalendarSelect(calendar)}>
							<View
								style={{
									flex: 1,
									flexDirection: "row",
									alignItems: "center",
								}}>
								<View
									style={{
										flex: 1,
										height: "100%",
										maxWidth: 10,
										borderRadius: 2,
										backgroundColor:
											selectedContentCalendar.id ===
											calendar.id
												? APP_COLOURS.BLUE
												: APP_COLOURS.WHITE_TRANSPARENT,
										marginRight: 15,
									}}
								/>
								<View style={{ flex: 1 }}>
									<Text
										style={{
											fontWeight: "500",
											fontSize: 16,
											color: APP_COLOURS.BG2,
										}}>
										{calendar.title}
									</Text>
									<Text
										style={{
											fontWeight: "300",
											fontSize: 14,
											color: APP_COLOURS.BG2,
										}}>
										{calendar.client_name}
									</Text>
								</View>
								<View
									style={{
										width: 80,
										justifyContent: "center",
										alignItems: "center",
										borderLeftWidth: 1,
										paddingLeft: 5,
										borderLeftColor: "#E8E8E8",
									}}>
									<View
										style={{
											paddingHorizontal: 10,
											paddingVertical: 5,
											borderRadius: 12,
											backgroundColor:
												APP_COLOURS.WHITE_TRANSPARENT,
										}}>
										<Text
											style={{
												fontSize: 12,
												color: APP_COLOURS.BG2,
											}}>
											{calendar.status}
										</Text>
									</View>
								</View>
							</View>
						</TouchableOpacity>
					))
				)}
			</ScrollView>
		</View>
	);
};

export default LeftSidebar;
