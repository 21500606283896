import React, { Component } from "react";
import { View, Text, StyleSheet, ActivityIndicator } from "react-native";
import { crudRead, crudCreate, crudUpdate, crudDelete } from "../../CRUDHELPER";
import moment from "moment";
import TaskTimer from "./TaskTimer";
// import TaskList from "./TaskList";
// import SessionHistory from "./SessionHistory";
// import WorkLogList from "./WorkLogList";
import { darkTheme } from "./theme";
import "./TimeBoxer.css";
import TaskTable from "./TaskTable";
// import TaskAnalytics from "./TaskAnalytics";
// import TimeSensitiveTasks from "./TimeSensitiveTasks";
import WorkLogModal from "./WorkLogModal";
import TaskDetailsModal from "./TaskDetailsModal";
import WorkLogList from "./WorkLogList";

export default class TimeBoxerIndex extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tasks: [],
			activeTask: null,
			timerRunning: false,
			startTime: null,
			completedSessions: [],
			estimatedDurations: {},
			isLoading: true,
			error: null,
			workLogs: {},
			isWorkLogModalVisible: false,
			selectedTask: null,
			workLogModalMode: "logs",
		};
	}

	componentDidMount() {
		this.fetchTasks().then(() => {
			this.fetchAllWorkLogs().then(() => {
				this.restoreActiveTask();
			});
		});
	}

	fetchTasks = async () => {
		try {
			this.setState({ isLoading: true, error: null });

			await new Promise((resolve, reject) => {
				crudRead("projects_tasks", "", (status, data) => {
					if (status === 200) {
						// Set default duration estimates
						const defaultEstimations = {};
						data.forEach((task) => {
							defaultEstimations[task.id] = 30; // Default 30 minutes
						});

						this.setState({
							tasks: data,
							estimatedDurations: defaultEstimations,
							isLoading: false,
							error: null,
						});
						resolve(data);
					} else {
						reject(new Error(`Failed to fetch tasks: ${status}`));
					}
				});
			});
		} catch (error) {
			console.error("Error fetching tasks:", error);
			this.setState({
				error: "Failed to fetch tasks",
				isLoading: false,
			});
		}
	};

	fetchAllWorkLogs = async () => {
		try {
			console.log("Fetching work logs...");
			await new Promise((resolve, reject) => {
				const dateFilter = moment().format("YYYYMMDD");
				console.log("Today start timestamp:", dateFilter);

				crudRead(
					"projects_tasks_worklog",
					{
						field: "dateFilter",
						action: "==",
						value: dateFilter,
					},
					(status, worklogData) => {
						console.log("Work logs fetch status:", status);
						console.log("Raw work logs data:", worklogData);

						if (status === 200) {
							// Group work logs by task ID
							const groupedLogs = worklogData.reduce(
								(acc, log) => {
									// Ensure log has all required fields
									const processedLog = {
										...log,
										id: log._id || log.id,
										startTime:
											log.startTime || moment().unix(),
										status: log.status || "unknown",
										taskId: log.taskId,
									};

									if (!acc[processedLog.taskId]) {
										acc[processedLog.taskId] = [];
									}
									acc[processedLog.taskId].push(processedLog);
									return acc;
								},
								{},
							);

							console.log("Processed grouped logs:", groupedLogs);

							// Find the latest task by comparing timestamps
							let latestTaskId = null;
							let latestTimestamp = 0;
							let latestStatus = null;

							// Iterate through all task logs to find the most recent one
							Object.entries(groupedLogs).forEach(
								([taskId, logs]) => {
									if (!logs || !logs.length) return;

									// Find the latest log for this task
									const taskLatestLog = logs.reduce(
										(latest, current) => {
											return !latest ||
												current.startTime >
													latest.startTime
												? current
												: latest;
										},
										null,
									);

									// Update if this is the most recent overall
									if (
										taskLatestLog &&
										taskLatestLog.startTime >
											latestTimestamp
									) {
										latestTimestamp =
											taskLatestLog.startTime;
										latestTaskId = taskId;
										latestStatus = taskLatestLog.status;
									}
								},
							);

							console.log(
								"Latest task:",
								latestTaskId,
								"Status:",
								latestStatus,
							);

							this.setState(
								{
									workLogs: groupedLogs,
									activeTask: latestTaskId,
									timerRunning: latestStatus === "started",
									startTime:
										latestStatus === "started"
											? latestTimestamp
											: null,
									error: null,
								},
								() => {
									console.log(
										"State updated with work logs:",
										this.state.workLogs,
									);
								},
							);
							resolve(groupedLogs);
						} else {
							const error = new Error(
								`Failed to fetch work logs: ${status}`,
							);
							console.error(error);
							reject(error);
						}
					},
				);
			});
		} catch (error) {
			console.error("Error in fetchAllWorkLogs:", error);
			this.setState({
				error: "Failed to fetch work logs: " + error.message,
				workLogs: {},
			});
		}
	};

	restoreActiveTask = () => {
		const { workLogs, tasks } = this.state;
		let mostRecentActiveTask = null;
		let mostRecentStartTime = 0;

		// Find the most recent active task by checking work logs
		Object.entries(workLogs).forEach(([taskId, logs]) => {
			if (!logs || !logs.length) return;

			// Get the most recent log for this task
			const latestLog = logs.reduce((latest, current) => {
				return !latest || current.startTime > latest.startTime
					? current
					: latest;
			}, null);

			// Only consider logs from today
			const isToday = moment
				.unix(latestLog.startTime)
				.isSame(moment(), "day");
			if (!isToday) return;

			// Check if this is the most recent active task
			if (latestLog && latestLog.startTime > mostRecentStartTime) {
				const task = tasks.find((t) => t.id === taskId);
				if (task) {
					mostRecentStartTime = latestLog.startTime;
					mostRecentActiveTask = {
						taskId,
						status: latestLog.status,
						startTime: latestLog.startTime,
						endTime: latestLog.endTime,
					};
				}
			}
		});

		if (mostRecentActiveTask) {
			// Only set as active if the task was "started" and hasn't been stopped/completed
			const isRunning =
				mostRecentActiveTask.status === "started" &&
				!mostRecentActiveTask.endTime;

			this.setState({
				activeTask: isRunning ? mostRecentActiveTask.taskId : null,
				timerRunning: isRunning,
				startTime: isRunning ? mostRecentActiveTask.startTime : null,
			});
		} else {
			this.setState({
				activeTask: null,
				timerRunning: false,
				startTime: null,
			});
		}
	};

	handleTimerAction = async (taskId, action) => {
		const currentTime = moment().unix();
		const { activeTask } = this.state;

		let dateFilter = moment().format("YYYYMMDD");

		try {
			// If starting a new task and there's an active task, create a stop log for it first
			if (action === "start" && activeTask && activeTask !== taskId) {
				await new Promise((resolve, reject) => {
					crudCreate(
						"projects_tasks_worklog",
						{
							taskId: activeTask,
							startTime: currentTime,
							status: "stopped",
							dateFilter: dateFilter,
							assignment_ids: [this.props.user_data?.id],
						},
						(status, worklogData) => {
							if (status === 200) {
								this.setState((prevState) => ({
									workLogs: {
										...prevState.workLogs,
										[activeTask]: [
											...(prevState.workLogs[
												activeTask
											] || []),
											{
												id: worklogData.id,
												startTime: currentTime,
												status: "stopped",
											},
										],
									},
								}));
								resolve(worklogData);
							} else {
								reject(
									new Error(
										`Failed to create stop log: ${status}`,
									),
								);
							}
						},
					);
				});
			}

			// Create new log entry for the current action
			await new Promise((resolve, reject) => {
				crudCreate(
					"projects_tasks_worklog",
					{
						taskId,
						startTime: currentTime,
						status:
							action === "start"
								? "started"
								: action === "pause"
								? "paused"
								: action === "stop"
								? "stopped"
								: action === "complete"
								? "completed"
								: action,
						dateFilter: dateFilter,
						assignment_ids: [this.props.user_data?.id],
					},
					(status, worklogData) => {
						if (status === 200) {
							this.setState((prevState) => ({
								activeTask: action === "start" ? taskId : null,
								timerRunning: action === "start",
								startTime:
									action === "start" ? currentTime : null,
								workLogs: {
									...prevState.workLogs,
									[taskId]: [
										...(prevState.workLogs[taskId] || []),
										{
											id: worklogData.id,
											startTime: currentTime,
											status: worklogData.status,
										},
									],
								},
							}));
							resolve(worklogData);
						} else {
							reject(
								new Error(
									`Failed to create work log: ${status}`,
								),
							);
						}
					},
				);
			});

			// Update task status if needed
			if (action === "start") {
				await this.handleStatusChange(taskId, "in-progress");
			} else if (action === "complete") {
				await this.handleStatusChange(taskId, "completed");
			}
		} catch (error) {
			console.error("Error handling timer action:", error);
			this.setState({
				error: `Failed to ${action} timer. Please try again.`,
			});
		}
	};

	handleStatusChange = (taskId, newStatus) => {
		// If trying to archive a non-completed task, prevent it
		if (newStatus === "archived") {
			const task = this.state.tasks.find((t) => t.id === taskId);
			if (!task || task.status !== "completed") {
				this.setState({
					error: "Only completed tasks can be archived",
				});
				return;
			}
		}

		crudUpdate(
			"projects_tasks",
			{
				id: taskId,
				status: newStatus,
				archived_at: newStatus === "archived" ? moment().unix() : null,
				archived_by:
					newStatus === "archived" ? this.props.user?.id : null,
			},
			async (status) => {
				if (status === 200) {
					// If task is being archived, handle the archiving process
					if (newStatus === "archived") {
						await this.handleArchiveProcess(taskId);
						return;
					}

					this.setState((prevState) => {
						const updatedTasks = prevState.tasks.map((task) =>
							task.id === taskId
								? { ...task, status: newStatus }
								: task,
						);

						// Find the updated task to sync with selectedTask
						const updatedTask = updatedTasks.find(
							(t) => t.id === taskId,
						);

						return {
							tasks: updatedTasks,
							// Update selectedTask if it's the task being modified
							selectedTask:
								prevState.selectedTask?.id === taskId
									? updatedTask
									: prevState.selectedTask,
							error: null,
						};
					});

					// If task is completed, end any active timer
					if (
						newStatus === "completed" &&
						this.state.activeTask === taskId
					) {
						this.startTask(taskId, "end");
					}
				} else {
					this.setState({
						error: "Failed to update task status",
					});
				}
			},
		);
	};

	pauseTask = () => {
		try {
			const { activeTask, startTime, workLogs } = this.state;
			const endTime = moment().unix();
			let dateFilter = moment().format("YYYYMMDD");
			// Find the active work log entry
			const taskWorkLogs = workLogs[activeTask] || [];
			const activeWorkLog = taskWorkLogs[taskWorkLogs.length - 1];

			if (activeWorkLog) {
				// Update work log entry with end time
				crudCreate(
					"projects_tasks_worklog",
					{
						id: activeWorkLog.id,
						endTime: endTime,
						duration: endTime - activeWorkLog.startTime,
						status: "completed",
						dateFilter: dateFilter,
						assignment_ids: [this.props.user_data?.id],
					},
					(status) => {
						if (status === 200) {
							this.setState((prevState) => {
								const updatedWorkLogs = {
									...prevState.workLogs,
								};
								const taskLogs = [
									...(updatedWorkLogs[activeTask] || []),
								];
								taskLogs[taskLogs.length - 1] = {
									...taskLogs[taskLogs.length - 1],
									endTime,
									duration: endTime - activeWorkLog.startTime,
									status: "completed",
								};
								updatedWorkLogs[activeTask] = taskLogs;

								return {
									workLogs: updatedWorkLogs,
									completedSessions: [
										...prevState.completedSessions,
										{
											taskId: activeTask,
											startTime: activeWorkLog.startTime,
											endTime,
											duration:
												endTime -
												activeWorkLog.startTime,
										},
									],
									timerRunning: false,
									error: null,
								};
							});
						} else {
							console.error("Failed to update work log entry");
							this.setState({
								error: "Failed to log task completion. Please try again.",
							});
						}
					},
				);
			}

			// Update task in database
			crudCreate(
				"projects_tasks_sessions",
				{
					taskId: activeTask,
					startTime,
					endTime,
					duration: endTime - startTime,
				},
				(status) => {
					if (status !== 200) {
						console.error("Failed to log task session");
						this.setState({
							error: "Failed to save session. Please try again.",
						});
					}
				},
			);
		} catch (error) {
			console.error("Error pausing task:", error);
			this.setState({
				error: "Failed to pause task. Please try again.",
			});
		}
	};

	handleArchiveProcess = async (taskId) => {
		try {
			const taskToArchive = this.state.tasks.find((t) => t.id === taskId);
			if (!taskToArchive) return;

			// First create archive record
			await new Promise((resolve, reject) => {
				crudCreate(
					"archived_tasks",
					{
						...taskToArchive,
						archived_at: moment().unix(),
						archived_by: this.props.user?.id || "unknown",
						original_task_id: taskId,
					},
					(status, archivedData) => {
						if (status === 200) {
							resolve(archivedData);
						} else {
							reject(
								new Error(`Failed to archive task: ${status}`),
							);
						}
					},
				);
			});

			console.log("taskToArchive", taskToArchive);

			// Then delete from projects_tasks using crudDelete
			await new Promise((resolve, reject) => {
				crudDelete("projects_tasks", taskToArchive, (status) => {
					if (status === 200) {
						resolve();
					} else {
						reject(new Error(`Failed to delete task: ${status}`));
					}
				});
			});

			// Update local state
			this.setState((prevState) => ({
				tasks: prevState.tasks.filter((t) => t.id !== taskId),
				selectedTask: null, // Close task details modal
				error: null,
				workLogs: {
					...prevState.workLogs,
					[taskId]: undefined, // Remove work logs for archived task
				},
			}));
		} catch (error) {
			console.error("Error archiving task:", error);
			this.setState({
				error: "Failed to archive task. Please try again.",
			});
		}
	};

	toggleWorkLogModal = () => {
		this.setState((prevState) => ({
			isWorkLogModalVisible: !prevState.isWorkLogModalVisible,
		}));
	};

	handleTaskClick = (task) => {
		this.setState({ selectedTask: task });
	};

	handleCloseTaskDetails = () => {
		this.setState({ selectedTask: null });
	};

	handleAddWorkLogComment = async (logId, updatedLog) => {
		try {
			await new Promise((resolve, reject) => {
				crudUpdate(
					"projects_tasks_worklog",
					{
						id: logId,
						comment: updatedLog.comment,
						commented_at: moment().unix(),
						commented_by: this.props.user?.id,
					},
					async (status) => {
						if (status === 200) {
							// Fetch updated logs to ensure we have the latest data
							await this.fetchAllWorkLogs();
							resolve();
						} else {
							reject(
								new Error(`Failed to add comment: ${status}`),
							);
						}
					},
				);
			});
		} catch (error) {
			console.error("Error adding comment:", error);
			this.setState({
				error: "Failed to add comment. Please try again.",
			});
		}
	};

	handleSendDailySummary = () => {
		this.setState({
			isWorkLogModalVisible: true,
			workLogModalMode: "summary",
		});
	};

	handleCloseWorkLogModal = () => {
		this.setState({
			isWorkLogModalVisible: false,
			workLogModalMode: "logs", // Reset mode when closing
		});
	};

	render() {
		const {
			tasks,
			activeTask,
			timerRunning,
			estimatedDurations,
			completedSessions,
			startTime,
			isLoading,
			error,
			workLogs,
			isWorkLogModalVisible,
			selectedTask,
			workLogModalMode,
		} = this.state;

		if (isLoading) {
			return (
				<View style={styles.centerContainer}>
					<ActivityIndicator
						style={styles.activityIndicator}
						size={40}
						color={darkTheme.colors.primary}
					/>
					<Text style={styles.loadingText}>Loading tasks...</Text>
				</View>
			);
		}

		if (error) {
			return (
				<View style={styles.centerContainer}>
					<Text style={styles.errorText}>{error}</Text>
					<View onClick={this.fetchTasks} style={styles.retryButton}>
						<Text style={styles.buttonText}>Retry</Text>
					</View>
				</View>
			);
		}

		const activeTaskData = tasks.find((t) => t.id === activeTask);

		return (
			<View style={styles.container}>
				<View style={styles.header}>
					<Text style={styles.headerTitle}>Time Boxer</Text>
				</View>

				<View style={styles.mainContent}>
					{error && <Text style={styles.errorBanner}>{error}</Text>}

					{/* Main Layout */}
					<View style={styles.contentLayout}>
						{/* Left Side - Task Table */}
						<View style={styles.leftPanel}>
							<TaskTable
								tasks={tasks}
								estimatedDurations={estimatedDurations}
								onStartTask={(taskId) =>
									this.handleTimerAction(taskId, "start")
								}
								activeTaskId={activeTask}
								workLogs={workLogs}
								onStatusChange={this.handleStatusChange}
								onTaskClick={this.handleTaskClick}
								onArchive={this.handleArchiveProcess}
							/>
						</View>

						{/* Right Side - Active Task & Details */}
						<View style={styles.rightPanel}>
							{activeTask ? (
								<View style={styles.activeTaskContainer}>
									<Text style={styles.activeTaskTitle}>
										{activeTaskData?.title}
									</Text>
									<TaskTimer
										startTime={startTime}
										isRunning={timerRunning}
										onPause={() =>
											this.handleTimerAction(
												activeTask,
												timerRunning
													? "pause"
													: "start",
											)
										}
										onStop={() =>
											this.handleTimerAction(
												activeTask,
												"stop",
											)
										}
										onComplete={() =>
											this.handleTimerAction(
												activeTask,
												"complete",
											)
										}
										totalElapsedTime={
											workLogs[activeTask]?.reduce(
												(total, log) => {
													if (log.duration) {
														return (
															total + log.duration
														);
													}
													if (
														!log.endTime &&
														log.status === "started"
													) {
														return (
															total +
															(moment().unix() -
																log.startTime)
														);
													}
													return total;
												},
												0,
											) || 0
										}
									/>
								</View>
							) : (
								<View style={styles.noActiveTaskContainer}>
									<Text style={styles.noActiveTaskText}>
										No active task. Select a task to start
										working.
									</Text>
								</View>
							)}

							{/* Work Log List */}
							<WorkLogList
								workLogs={Object.entries(workLogs).reduce(
									(acc, [taskId, logs]) => {
										const task = tasks.find(
											(t) => t.id === taskId,
										);
										if (!task || !logs) return acc;

										// Add task information to each log and ensure all required fields
										const logsWithTask = logs.map(
											(log) => ({
												...log,
												task,
												taskId,
												id: log._id || log.id,
												startTime:
													log.startTime ||
													moment().unix(),
												status: log.status || "unknown",
											}),
										);

										return [...acc, ...logsWithTask];
									},
									[],
								)}
								tasks={tasks}
								activeTask={activeTaskData}
								onAddComment={this.handleAddWorkLogComment}
								onSendDailySummary={this.handleSendDailySummary}
							/>

							{selectedTask && (
								<View style={styles.taskDetailsContainer}>
									{/* Task details content */}
								</View>
							)}
						</View>
					</View>
				</View>

				<WorkLogModal
					isVisible={isWorkLogModalVisible}
					onClose={this.handleCloseWorkLogModal}
					workLogs={workLogs}
					tasks={tasks}
					initialMode={workLogModalMode}
				/>

				<TaskDetailsModal
					isVisible={!!selectedTask}
					onClose={this.handleCloseTaskDetails}
					task={selectedTask}
					workLogs={workLogs}
					estimatedDuration={
						selectedTask ? estimatedDurations[selectedTask.id] : 0
					}
					onTimerAction={this.handleTimerAction}
					activeTaskId={activeTask}
					timerRunning={timerRunning}
					onStatusChange={this.handleStatusChange}
				/>
			</View>
		);
	}
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: darkTheme.colors.background,
	},
	header: {
		padding: darkTheme.spacing.md,
		backgroundColor: darkTheme.colors.surface,
		borderBottomWidth: 1,
		borderBottomColor: darkTheme.colors.border,
	},
	headerTitle: {
		fontSize: 24,
		fontWeight: "bold",
		color: darkTheme.colors.text.primary,
	},
	mainContent: {
		flex: 1,
		padding: darkTheme.spacing.md,
	},

	centerContainer: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		padding: darkTheme.spacing.xl,
		backgroundColor: darkTheme.colors.background,
	},
	loadingText: {
		marginTop: darkTheme.spacing.md,
		color: darkTheme.colors.text.secondary,
	},
	errorText: {
		color: darkTheme.colors.error,
		textAlign: "center",
		marginBottom: darkTheme.spacing.md,
	},
	errorBanner: {
		backgroundColor: `${darkTheme.colors.error}22`,
		color: darkTheme.colors.error,
		padding: darkTheme.spacing.md,
		borderRadius: darkTheme.borderRadius.md,
		marginBottom: darkTheme.spacing.md,
		textAlign: "center",
	},
	activeTaskContainer: {
		backgroundColor: darkTheme.colors.surface,
		padding: darkTheme.spacing.lg,
		borderRadius: darkTheme.borderRadius.lg,
		marginBottom: darkTheme.spacing.lg,
		borderWidth: 1,
		borderColor: darkTheme.colors.border,
	},
	activeTaskTitle: {
		fontSize: 18,
		fontWeight: "bold",
		color: darkTheme.colors.text.primary,
		marginBottom: darkTheme.spacing.md,
	},
	buttonContainer: {
		flexDirection: "row",
		justifyContent: "center",
		gap: darkTheme.spacing.md,
		marginTop: darkTheme.spacing.md,
	},
	activityIndicator: {
		marginBottom: darkTheme.spacing.md,
	},
	button: {
		padding: darkTheme.spacing.sm,
		borderRadius: darkTheme.borderRadius.md,
		minWidth: 120,
		alignItems: "center",
		justifyContent: "center",
		cursor: "pointer",
	},
	pauseButton: {
		backgroundColor: darkTheme.colors.error,
	},
	resumeButton: {
		backgroundColor: darkTheme.colors.primary,
	},
	buttonText: {
		color: darkTheme.colors.text.primary,
		fontWeight: "500",
	},
	retryButton: {
		backgroundColor: darkTheme.colors.primary,
		padding: darkTheme.spacing.sm,
		paddingHorizontal: darkTheme.spacing.lg,
		borderRadius: darkTheme.borderRadius.md,
		marginTop: darkTheme.spacing.md,
		cursor: "pointer",
	},
	historyContainer: {
		display: "flex",
		flexDirection: "row",
		gap: darkTheme.spacing.lg,
	},
	analyticsContainer: {
		flexDirection: "row",
		gap: darkTheme.spacing.lg,
		marginBottom: darkTheme.spacing.lg,
	},
	viewWorkLogButton: {
		backgroundColor: darkTheme.colors.primary,
		padding: darkTheme.spacing.md,
		borderRadius: darkTheme.borderRadius.md,
		cursor: "pointer",
	},
	contentLayout: {
		flex: 1,
		flexDirection: "row",
		gap: darkTheme.spacing.lg,
	},
	leftPanel: {
		flex: 2,
	},
	rightPanel: {
		flex: 1,
	},
	noActiveTaskContainer: {
		padding: darkTheme.spacing.xl,
		backgroundColor: darkTheme.colors.surface,
		borderRadius: darkTheme.borderRadius.lg,
		alignItems: "center",
		justifyContent: "center",
	},
	noActiveTaskText: {
		color: darkTheme.colors.text.secondary,
		textAlign: "center",
	},
	taskDetailsContainer: {
		// Add your task details styles here
	},
});
