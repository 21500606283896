import React, { Component } from "react";
import {
	View,
	Text,
	TextInput,
	ActivityIndicator,
	ScrollView,
	TouchableOpacity,
	Image,
	Modal,
} from "react-native";
import {
	APP_COLOURS,
	Avatars,
	RETAILERS,
	StatusColour,
	driveImg,
} from "../../APP_VARS";
import AutoHeightImage from "../AutoHeightImageComp";

import moment from "moment";
import {
	createCampaignsPerBrand,
	crudCreate,
	crudDelete,
	crudReadUnass,
	crudUpdate,
	deleteCampaignsPerBrand,
	fetchCampaignsPerBrand,
	sendCampaignForApproval,
	sendDiscordMessage,
} from "../../CRUDHELPER";
import AddCampaign from "./AddCampaign";
// import TopTabs from "../TopTabs";
import SmallerTabs from "../TopTabs/SmallerTabs";
import MediumTabs from "../TopTabs/MediumTabs";
import StatusItemListings from "../DailyPlanner/StatusItemListings";
import StatusPerformanceMedia from "./StatusPerformanceMedia";
import CampaignPreview from "./CampaignPreview";
import CampaignLaunchChecklist from "./CampaignLaunchChecklist";
import ListingItem from "./ListingItem";
import axios from "axios";

function isOdd(num) {
	return num % 2;
}

function currencyFormat(num) {
	return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

const sendDiscordNotification = async (
	message,
	username = "tetrice",
	avatar_url = "",
) => {
	const webhookUrl =
		"https://discord.com/api/webhooks/1191734945278869555/Xy3r8fIe6cj_fqFPgOvq-CTacA693Y8XJFzi5Q5OYIvOPvwAroZrTgSUw3TtDyB5zMCs";

	try {
		const response = await axios.post(webhookUrl, {
			content: message,
			username: username,
			avatar_url: avatar_url,
		});

		return {
			success: true,
			message: "Discord notification sent successfully",
		};
	} catch (error) {
		console.error("Error sending Discord notification:", error);
		throw {
			success: false,
			message: "Failed to send Discord notification",
			error: error.message,
		};
	}
};

export default class Campaigns extends Component {
	constructor(props) {
		super(props);
		this.state = {
			driveImg: driveImg,
			campaigns: [],

			user_data: "",

			updateListing: false,

			selectedRetailer: "",

			selectedTab: "Strategy",

			selectedCampaign: "",

			showCampaign: false,

			emailSent: false,

			approvedCampaign: null,

			selectedTab: "",
		};
	}

	componentDidMount() {
		this.fetchCampaigns(this.props.selectedBrand.id);
		console.log(this.props.selectedBrand);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.selectedBrand !== this.props.selectedBrand) {
			this.fetchCampaigns(this.props.selectedBrand.id);
		}
	}

	fetchCampaigns = () => {
		this.setState({
			loading: true,
			campaigns: [],
		});

		fetchCampaignsPerBrand(
			{
				brand_id: this.props.selectedBrand.id,
			},
			async (s, d) => {
				console.log("campaigns", d, s);
				if (s === 200) {
					await this.setState({
						campaigns: d,
						loading: false,
					});
				} else {
					this.setState({
						loading: false,
					});
				}
			},
		);
	};

	refreshCampaigns = () => {
		this.fetchCampaigns(this.props.selectedBrand.id);
	};

	handleCampaignApproval = (campaign) => {
		this.setState({ approvedCampaign: campaign });
	};

	handleCampaignLaunch = () => {
		this.setState({ approvedCampaign: null });
		this.fetchCampaigns();
	};

	render() {
		return (
			<View
				style={{
					flex: 1,
					padding: 5,
					backgroundColor: "#EFF5FC",
					// paddingTop: 10,
				}}>
				<View
					style={{
						width: "100%",
						paddingBottom: 2,
						flexDirection: "row",
					}}>
					<ScrollView horizontal>
						<View
							style={{
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "space-between",
							}}>
							<MediumTabs
								selectedSubPage={(d) => {
									this.setState({
										selectedTab: d,
									});
									console.log(d);
								}}
								tabs={[
									"All",
									"Strategy",
									"Awaiting Budget Approval",
									"Approved",
									"Awaiting PO",
									"Campaign Booked & Approved",
									"Chaselist",
									"Awaiting Chaselist Approval",
									"Active Campaigns",
									"Completed Campaigns",
								]}
							/>
						</View>
					</ScrollView>
				</View>
				<View
					style={{
						flex: 1,
					}}>
					{this.state.selectedTab === "Strategy" ? (
						<View style={{ padding: 10 }}>
							<Text
								style={{
									color: "#000",
									fontSize: 16,
									fontWeight: "600",
								}}>
								{this.props.selectedBrand.title} strategy
								formulation phase
							</Text>
							<Text
								style={{
									color: "#000",
									fontSize: 12,
								}}>
								Once a campaign is created it can be sent to
								brand for budget approval
							</Text>
							<View
								style={{
									// flex: 1,

									flexDirection: "row",
									alignItems: "center",
									// height: 45,
									marginRight: 10,
								}}>
								<TouchableOpacity
									style={{
										marginRight: 10,
										marginLeft: 10,
									}}
									onPress={() => {
										this.setState({
											showInput: !this.state.showInput,
										});
									}}>
									<AutoHeightImage
										width={19}
										source={require("../../../assets/images/searchIcon.png")}
									/>
								</TouchableOpacity>
								{this.state.loading ? (
									<ActivityIndicator
										color={APP_COLOURS.PRIMARY}
									/>
								) : null}
								<View
									style={{
										// height: "auto",
										// width: "100%",
										flex: 1,
										minWidth: 200,
										// maxWidth: 400,
										backgroundColor:
											APP_COLOURS.BLACK_TRANS,
										borderRadius: 13,
									}}>
									{this.state.showInput ? (
										<TextInput
											style={{
												fontWeight: "400",
												fontFamily: "Gotham",

												width: "100%",
												paddingLeft: 15,
												padding: 10,
												fontSize: 12,
												borderRadius: 13,
												marginRight: 15,
												color: APP_COLOURS.BG2,
												// backgroundColor:
												// 	APP_COLOURS.WHITE_TRANSPARENT4,
												backgroundColor: "#E8E8E8",
												fontWeight: "500",
											}}
											placeholderTextColor={"#797979"}
											autoCapitalize='sentences'
											clearButtonMode='while-editing'
											autoCompleteType='off'
											editable={true}
											autoCorrect={true}
											autoFocus
											keyboardType='default'
											textContentType='none'
											placeholder={` Search `}
											// defaultValue={this.state.user_data.email}
											defaultValue={
												this.state.filterSearch
											}
											onChangeText={(text) => {
												console.log(text);
												this.setState({
													filterSearch: text,
												});
											}}
										/>
									) : null}
								</View>

								<TouchableOpacity
									style={{
										// marginRight: 15,
										marginLeft: 15,
										backgroundColor: APP_COLOURS.BG2,
										borderRadius: 15,
										flexDirection: "row",
										paddingVertical: 10,
										alignItems: "center",
										paddingHorizontal: 15,
									}}
									onPress={async () => {
										this.setState({
											showCampaign:
												!this.state.showCampaign,
											selectedCampaign: "",
										});
									}}>
									<Text
										style={{
											color: "#FFF",
											fontSize: 14,
											marginRight: 10,
										}}>
										+
									</Text>
									<Text
										style={{
											color: "#FFF",
											fontSize: 12,
										}}>
										New
									</Text>
								</TouchableOpacity>
							</View>
						</View>
					) : null}
					<ScrollView>
						{/* <Text
							style={{
								color: "#000",
								fontSize: 12,
							}}>
							{this.props.selectedBrand.title}{" "}
							{this.props.selectedBrand.client_id}
						</Text> */}

						{this.state.campaigns
							.sort((a, b) => {
								return (
									moment(a.due_date).unix() -
									moment(b.due_date).unix()
								);
							})
							.filter((df) => {
								if (
									this.state.selectedTab === "" ||
									this.state.selectedTab === "All"
								) {
									return df;
								} else if (
									df.status === this.state.selectedTab
								) {
									return df;
								}
							})
							.filter((d_) => {
								let filter = d_;

								if (
									typeof this.state.filterSearch !==
										"undefined" &&
									this.state.filterSearch !== ""
								) {
									let text_ = this.state.filterSearch;
									var outString = text_.replace(
										/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
										"",
									);

									let join_names = `${d_.title} ${d_.barcode} ${d_.retailer}`;

									join_names = join_names.replace(
										/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
										"",
									);

									return join_names
										.toLowerCase()
										.includes(outString.toLowerCase());
								}

								return filter;
							})
							.map((item) => (
								<ListingItem
									key={item._id}
									item={item}
									onCampaignSelect={(campaign) => {
										this.setState({
											showOverview: true,
											selectedCampaign: campaign,
										});
									}}
									onGoogleDriveClick={(url) => {
										window.open(url, "_blank");
									}}
								/>
							))}
					</ScrollView>
				</View>

				{this.state.showCampaign ? (
					<AddCampaign
						selectedBrand={this.props.selectedBrand}
						selectedCampaign={this.state.selectedCampaign}
						addEntry={(obj) => {
							createCampaignsPerBrand(obj, async () => {
								try {
									const notificationMessage =
										`🎉 New Campaign Created\n\n` +
										`Brand: ${this.props.selectedBrand.title}\n` +
										`Campaign: ${obj.title}\n` +
										`Retailer: ${obj.retailer}\n` +
										`Due Date: ${moment(
											obj.due_date,
										).format("DD MMM YYYY")}\n` +
										`Status: Strategy`;

									await sendDiscordNotification(
										notificationMessage,
									);
								} catch (discordError) {
									console.error(
										"Failed to send Discord notification:",
										discordError,
									);
									// Continue with the rest of the process even if Discord notification fails
								}

								this.setState({
									showCampaign: false,
								});
								this.fetchCampaigns();
							});
						}}
						deletCampaign={(obj) => {
							deleteCampaignsPerBrand(
								{
									_id: this.state.selectedCampaign._id,
								},
								async () => {
									this.setState({
										showCampaign: false,
									});
									this.fetchCampaigns();
								},
							);
						}}
						close={() =>
							this.setState({
								showCampaign: false,
								selectedCampaign: "",
							})
						}
					/>
				) : null}

				{this.state.showOverview ? (
					<CampaignPreview
						selectedCampaign={this.state.selectedCampaign}
						selectedTab={this.state.selectedTab}
						selectedBrand={this.props.selectedBrand}
						deletCampaign={(obj) => {
							deleteCampaignsPerBrand(
								{
									_id: this.state.selectedCampaign._id,
								},
								async () => {
									this.setState({
										showCampaign: false,
										showOverview: false,
									});
									this.fetchCampaigns();
								},
							);
						}}
						close={() =>
							this.setState({
								showOverview: false,
								selectedCampaign: "",
							})
						}
						sendForApproval={(obj) => {
							sendCampaignForApproval(obj, async () => {
								this.setState({
									showCampaign: false,
									showOverview: false,
									emailSent: true,
								});

								this.fetchCampaigns();
							});
						}}
						refreshCampaigns={this.refreshCampaigns}
					/>
				) : null}

				{this.state.emailSent ? (
					<Modal
						animationType='fade'
						transparent={true}
						visible={true}>
						<View
							style={{
								width: this.props.screenWidth,
								height: this.props.screenHeight,
								backgroundColor: "rgba(52, 52, 52, 0.6)",
							}}>
							<TouchableOpacity
								activeOpacity={1}
								style={{
									width: this.props.screenWidth,
									height: this.props.screenHeight,
									backgroundColor: "rgba(52, 52, 52, 0.6)",
									zIndex: 10,
									position: "absolute",
									top: 0,
									justifyContent: "center",
									alignItems: "center",
								}}
								onPress={() => {
									this.setState({
										emailSent: false,
									});
								}}>
								<View
									style={{
										borderRadius: 15,
										overflow: "hidden",
										alignItems: "center",
										borderRadius: 20,
										overflow: "hidden",
										width: 250,
										height: 250,
										backgroundColor: "#3FA5FA",
										justifyContent: "center",
										alignItems: "center",
									}}>
									<AutoHeightImage
										width={80}
										source={require("../../../assets/images/emailSent1.png")}
										style={{}}
									/>
									<Text
										style={{
											marginTop: 20,
											color: "#FFF",
											fontSize: 25,
											fontWeight: "700",
										}}>
										Email Sent
									</Text>
								</View>
							</TouchableOpacity>
						</View>
					</Modal>
				) : null}

				{this.state.approvedCampaign && (
					<CampaignLaunchChecklist
						campaign={this.state.approvedCampaign}
						onLaunch={this.handleCampaignLaunch}
					/>
				)}
			</View>
		);
	}
}
