import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	Image,
	Modal,
	TextInput,
	ActivityIndicator,
} from "react-native";
import moment from "moment";
import { APP_COLOURS } from "../../APP_VARS";
import { Avatars, StatusColour, TaskStatus } from "../../APP_VARS";
import StatusItem from "../DailyPlanner/StatusItem";
import Lottie from "react-lottie";
import {
	createSurveyApproval,
	crudCreate,
	crudDelete,
	crudGetUsers,
	crudRead,
	crudReadAll,
	crudUpdate,
	sendDiscordMessage,
} from "../../CRUDHELPER";
import "./Editor/custome.css";
import Fade from "react-reveal/Fade";
import * as animationData from "./RatingJson.json";

import starFill from "./starFill.png";
import star from "./star.png";

import AutoHeightImage from "../AutoHeightImageComp";
import FeedBackSent from "./FeedBackSent";
import SendingFeedback from "./SendingFeedback";
import SurveyItem from "./Survey";

const WhitePaper = ({ data }) => {
	const [survey, setSurvey] = useState(null);
	const [feedback, setFeedback] = useState("");
	const [starRating, setstarRating] = useState(null);

	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false);
	const [beginSurvey, setbeginSurvey] = useState(false);

	const [sent, setSent] = useState(false);
	const [sending, setSending] = useState(false);

	if (loading) return <div>Loading...</div>;
	if (error) return <div>Error: {error}</div>;

	if (sending) return <SendingFeedback />;
	if (sent) return <FeedBackSent />;

	let maxWidth = 800;
	let screenWidth = window.innerWidth;

	screenWidth = screenWidth > maxWidth ? maxWidth : screenWidth;

	return (
		<View
			style={{
				flex: 1,
				backgroundColor: data.bgColor,
				width: "100%",
				alignItems: "center",
			}}>
			<View
				style={{
					flex: 1,
					backgroundColor: data.bgColor,
					width: screenWidth,
					overflow: "hidden",
				}}>
				<View
					style={{
						position: "absolute",

						width: screenWidth * 1.2,
						height: screenWidth * 0.8,

						overflow: "hidden",
					}}>
					{typeof data.image !== "undefined" &&
					data.image !== null ? (
						<Image
							source={data.image}
							style={{
								resizeMode: "cover",
								width: screenWidth * 1.2,
								height: screenWidth * 0.8,
							}}
						/>
					) : (
						<Image
							source={require("./ARTICLEIMAGE.png")}
							style={{
								resizeMode: "cover",
								width: screenWidth * 1.2,
								height: screenWidth * 0.8,
							}}
						/>
					)}

					<View
						style={{
							position: "absolute",
							bottom: 0,
							width: screenWidth * 1.2,
							left: -20,
						}}>
						<Image
							source={require("./fade.png")}
							style={{
								width: screenWidth * 1.2,
								height: 200,
								resizeMode: "strech",
							}}
						/>
					</View>
				</View>
				<View
					style={{
						position: "absolute",
						right: 20,
						top: 20,
					}}>
					<Image
						source={require("./skrum_menu.png")}
						style={{
							width: 100,
							height: 100,
							resizeMode: "cover",
						}}
					/>
				</View>
				<ScrollView showsVerticalScrollIndicator={false}>
					<View
						style={{
							flex: 1,
							paddingTop: screenWidth * 0.8,
							opacity: 0.99,
							overflow: "hidden",
						}}>
						<View
							style={{
								position: "absolute",
								top: 0,
								width: screenWidth * 1.2,
								height: screenWidth * 0.8,

								overflow: "hidden",
							}}>
							{/* <Image
							source={data.image}
							style={{
								resizeMode: "cover",
								width: screenWidth * 1.2,
								height: screenWidth * 0.8,
							}}
						/> */}
							{/* <Lottie
										options={{
											loop: true,
											autoplay: true,
											animationData: animationData,
											rendererSettings: {
												preserveAspectRatio:
													"xMidYMid slice",
											},
										}}
										height={250}
										width={350}
									/> */}

							<View
								style={{
									position: "absolute",
									bottom: 0,
									width: screenWidth * 1.2,
									left: -20,
								}}>
								<Image
									source={require("./fade.png")}
									style={{
										width: screenWidth * 1.2,
										height: 200,
										resizeMode: "strech",
									}}
								/>
							</View>
						</View>

						<View
							style={{
								padding: 20,
								// paddingLeft: 20,
								backgroundColor: data.bgColor,
								// paddingLeft: 40,
							}}>
							<View
								style={{
									top: -180,
								}}>
								<View
									style={{
										width: "100%",
										height: "auto",
										padding: 7,
										alignItems: "center",
										borderRadius: 10,
										minHeight: 70,

										flexDirection: "row",
										borderColor: "#C5C5C5",
									}}>
									<Image
										source={
											Avatars[
												parseInt(data.writtenBy.avatar)
											].pp
										}
										style={{
											width: 30,
											height: 30,
											borderRadius: 25,
											resizeMode: "contain",
											backgroundColor: "#FFF",
											borderWidth: 1,
											borderColor: "#C5C5C5",
											// shadowColor: "#ECF4FF",
											// shadowOffset: {
											// 	width: 0,
											// 	height: 8,
											// },
											// shadowOpacity: 0.44,
											// shadowRadius: 10.32,

											// elevation: 16,
										}}
									/>
									<View
										style={{
											padding: 5,
											borderRadius: 10,
											alignItems: "center",
											flexDirection: "column",
											flex: 1,
											alignItems: "flex-start",
										}}>
										<Text
											style={{
												fontFamily: "Gotham",
												fontSize: 12,
												fontWeight: "600",
												color: APP_COLOURS.WHITE,
											}}>
											{data.writtenBy.first_name}{" "}
											{data.writtenBy.last_name}
										</Text>
									</View>
								</View>
								<Text
									style={{
										fontFamily: "Gotham",
										fontSize: 9,
										marginBottom: 10,
										color:
											data.mode === "Dark"
												? APP_COLOURS.TEXTCOLOR
												: APP_COLOURS.BG3,
										fontWeight: "300",
									}}>
									{moment(
										data.dateCreated,
										"DD/MM/YYYY",
									).fromNow()}
								</Text>
								<View
									style={{
										width: "100%",
									}}>
									<View style={{}}>
										<Text
											style={{
												fontFamily: "Gotham",
												fontSize: 23,
												color:
													data.mode === "Dark"
														? APP_COLOURS.TEXTCOLOR
														: APP_COLOURS.BG3,
												fontWeight: "500",
											}}>
											{data.title}
										</Text>
									</View>
								</View>

								<Text
									style={{
										fontSize: 16,
										color:
											data.mode === "Dark"
												? APP_COLOURS.TEXTCOLOR
												: APP_COLOURS.BG3,
										fontFamily: "Gotham",
										fontWeight: "300",
										// marginTop: 10,
										// textAlign: "justify",
									}}>
									<div
										className='ql-whitepaper'
										dangerouslySetInnerHTML={{
											__html: data.html,
										}}
									/>
								</Text>

								<Text
									style={{
										fontSize: 16,
										color:
											data.mode === "Dark"
												? APP_COLOURS.TEXTCOLOR
												: APP_COLOURS.BG3,
										fontFamily: "Gotham",
										fontWeight: "300",
										marginTop: 20,
									}}>
									Best regards,
								</Text>

								<Text
									style={{
										fontSize: 16,
										color:
											data.mode === "Dark"
												? APP_COLOURS.TEXTCOLOR
												: APP_COLOURS.BG3,
										fontFamily: "Gotham",
										fontWeight: "500",
										marginTop: 10,
									}}>
									{data.writtenBy.first_name}
									<Text
										style={{
											fontFamily: "Gotham",
											fontWeight: "800",
										}}>
										{" "}
										{data.writtenBy.last_name}
									</Text>
								</Text>

								{/* <Text
								style={{
									fontSize: 13,
									color: "#FFF",
									fontFamily: "Gotham",
									fontWeight: "500",
									marginTop: 10,
								}}>
								team{" "}
								<Text
									style={{
										fontSize: 15,
										color: "#FFF",
										fontFamily: "Gotham",
										fontWeight: "800",
									}}>
									tetrice
								</Text>
							</Text> */}

								<View
									style={{
										width: "100%",
										marginTop: 100,
										justifyContent: "center",
									}}>
									<View
										style={{
											// width: 250,
											width: "100%",
											flexDirection: "row",
											marginBottom: 20,
											justifyContent: "center",
										}}>
										{[1, 2, 3, 4, 5, 6, 7].map(
											(priority) => (
												<TouchableOpacity
													key={priority}
													style={{
														marginRight: 5,
													}}
													onPress={async () => {
														setstarRating(priority);
													}}>
													{starRating >= priority ? (
														<AutoHeightImage
															width={35}
															style={{
																marginRight: 5,
															}}
															source={starFill}
														/>
													) : (
														<AutoHeightImage
															width={35}
															style={{
																marginRight: 5,
																opacity: 0.3,
															}}
															source={star}
														/>
													)}
												</TouchableOpacity>
											),
										)}
									</View>

									<View style={{}}>
										<Text
											style={{
												fontWeight: "400",
												fontFamily: "Avenir Next",
												fontSize: 12,
												color: "#0098FD",
												marginBottom: 4,
												position: "absolute",
												top: -12,

												zIndex: 99,
												padding: 4,
												left: 15,
												borderRadius: 20,
												backgroundColor: data.bgColor,
											}}>
											Feedback
										</Text>

										<TextInput
											style={{
												fontSize: 16,
												color: "#FFF",
												fontWeight: "400",
												fontFamily: "Avenir Next",
												width: "100%",
												paddingLeft: 10,
												padding: 20,
												borderWidth: 1,
												borderColor: "#0098FD",
												borderRadius: 20,
												borderWidth: 1,
												minHeight: 30,
											}}
											placeholderTextColor={"#797979"}
											autoCapitalize='sentences'
											multiline
											numberOfLines={5}
											clearButtonMode='while-editing'
											// autoCompleteType="off"
											value={feedback}
											placeholder={`Type something`}
											onChangeText={(text) => {
												setFeedback(text);
											}}
										/>
									</View>
								</View>

								<TouchableOpacity
									style={{
										borderRadius: 10,
										justifyContent: "center",
										alignItems: "center",
										marginVertical: 25,
										flexDirection: "row",
										overflow: "hidden",
										marginTop: 30,
										opacity: feedback !== "" ? 1 : 0.2,
									}}
									onPress={() => {
										// setbeginSurvey(true);
										let user_data = JSON.parse(
											localStorage.getItem("user_data"),
										);
										sendDiscordMessage(
											`${user_data.first_name} ${user_data.last_name}`,
											`[Notice Feedback]:
- ${data.title} 
- ${starRating}/7
- ${feedback}
`,
											`${window.location.origin}${
												Avatars[
													parseInt(user_data.avatar)
												].pp
											}`,
											"0QPZYrY6zpAnJXqX9gQl",
										);
										setSending(true);
										setFeedback("");
										// alert("Thank you! Feedback sent");

										setTimeout(() => {
											setSending(false);
											setSent(true);
										}, 1500);
										// 						setTimeout(() => {
										// 	this.setState({
										// 		loading: false,
										// 		authenticating: false,
										// 	});
										// }, 1000);
									}}>
									<Image
										source={require("./button.svg").default}
										style={{
											width: "101%",
											height: 45,
											resizeMode: "cover",
										}}
									/>

									<Text
										style={{
											fontSize: 16,
											// marginTop: 12,
											fontWeight: "600",
											color: "#FFF",
											position: "absolute",
										}}>
										Send
									</Text>
								</TouchableOpacity>
							</View>
						</View>
					</View>
				</ScrollView>
				<View
					style={{
						position: "absolute",
						bottom: 0,
						width: screenWidth * 1.2,
						left: -20,
					}}>
					<Image
						source={require("./fade.png")}
						style={{
							width: screenWidth * 1.2,
							height: 100,
							resizeMode: "strech",
						}}
					/>
				</View>
			</View>
		</View>
	);
};

export default WhitePaper;
