import React, { useState } from "react";
import {
	View,
	Text,
	Modal,
	TouchableOpacity,
	Image,
	ActivityIndicator,
	ScrollView,
} from "react-native";
import { APP_COLOURS, RETAILERS } from "../../APP_VARS";

export default function ImageScaling({ visible, onClose }) {
	const [selectedImage, setSelectedImage] = useState(null);
	const [selectedRetailers, setSelectedRetailers] = useState([
		RETAILERS[0].title,
	]);
	const [processing, setProcessing] = useState(false);
	const [processedImages, setProcessedImages] = useState([]);
	const [error, setError] = useState(null);

	const handleImageSelect = (event) => {
		const file = event.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.onloadend = () => {
				const base64String = reader.result.split(",")[1];
				setSelectedImage({
					base64: base64String,
					file: file,
				});
			};
			reader.readAsDataURL(file);
		}
	};

	const toggleRetailer = (retailerTitle) => {
		setSelectedRetailers((prev) => {
			if (prev.includes(retailerTitle)) {
				return prev.filter((title) => title !== retailerTitle);
			} else {
				return [...prev, retailerTitle];
			}
		});
	};

	const processImage = async () => {
		if (!selectedImage || selectedRetailers.length === 0) return;

		setProcessing(true);
		setError(null);

		try {
			const selectedRetailersData = RETAILERS.filter((r) =>
				selectedRetailers.includes(r.title),
			);

			const response = await fetch(
				"https://workflow.tetrice.co.za/webhook-test/5ed4bac1-e417-4bc2-8044-ad00e8add128",
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						image: selectedImage.base64,
						retailers: selectedRetailersData.map((r) => ({
							retailer: r.title,
							specs: r.images,
						})),
					}),
				},
			);

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}

			const data = await response.json();

			if (data.error) {
				throw new Error(data.error);
			}

			setProcessedImages(data.images || []);
		} catch (error) {
			console.error("Error processing images:", error);
			setError(
				error.message || "Failed to process images. Please try again.",
			);
		} finally {
			setProcessing(false);
		}
	};

	return (
		<Modal animationType='fade' transparent={true} visible={visible}>
			<View
				style={{
					flex: 1,
					justifyContent: "center",
					alignItems: "center",
					backgroundColor: "rgba(52, 52, 52, 0.6)",
				}}>
				<View
					style={{
						backgroundColor: "#FFF",
						borderRadius: 10,
						padding: 20,
						width: "80%",
						maxWidth: 600,
						maxHeight: "90%",
					}}>
					<View
						style={{
							flexDirection: "row",
							justifyContent: "space-between",
							marginBottom: 20,
						}}>
						<Text
							style={{
								fontSize: 20,
								fontWeight: "600",
								color: APP_COLOURS.BG2,
							}}>
							Image Scaling
						</Text>
						<TouchableOpacity onPress={onClose}>
							<Text style={{ fontSize: 20 }}>×</Text>
						</TouchableOpacity>
					</View>

					<View style={{ marginBottom: 20 }}>
						<Text style={{ marginBottom: 10 }}>
							Select Retailers:
						</Text>
						<ScrollView style={{ maxHeight: 200 }}>
							{RETAILERS.map((retailer) => (
								<TouchableOpacity
									key={retailer.title}
									style={{
										flexDirection: "row",
										alignItems: "center",
										padding: 10,
										borderRadius: 5,
										backgroundColor:
											selectedRetailers.includes(
												retailer.title,
											)
												? APP_COLOURS.BG2 + "20"
												: "transparent",
										marginBottom: 5,
									}}
									onPress={() =>
										toggleRetailer(retailer.title)
									}>
									<View
										style={{
											width: 20,
											height: 20,
											borderRadius: 4,
											borderWidth: 2,
											borderColor: APP_COLOURS.BG2,
											marginRight: 10,
											justifyContent: "center",
											alignItems: "center",
										}}>
										{selectedRetailers.includes(
											retailer.title,
										) && (
											<View
												style={{
													width: 12,
													height: 12,
													backgroundColor:
														APP_COLOURS.BG2,
													borderRadius: 2,
												}}
											/>
										)}
									</View>
									<Text>{retailer.title}</Text>
								</TouchableOpacity>
							))}
						</ScrollView>
					</View>

					<View style={{ marginBottom: 20 }}>
						<Text style={{ marginBottom: 10 }}>Upload Image:</Text>
						<input
							type='file'
							accept='image/*'
							onChange={handleImageSelect}
							style={{ marginBottom: 10 }}
						/>
						{selectedImage && (
							<Image
								source={{ uri: selectedImage.base64 }}
								style={{
									width: 200,
									height: 200,
									resizeMode: "contain",
								}}
							/>
						)}
					</View>

					{error && (
						<View
							style={{
								backgroundColor: APP_COLOURS.RED_TRANS,
								padding: 10,
								borderRadius: 5,
								marginBottom: 15,
							}}>
							<Text style={{ color: APP_COLOURS.RED2 }}>
								{error}
							</Text>
						</View>
					)}

					<TouchableOpacity
						style={{
							backgroundColor: APP_COLOURS.BG2,
							padding: 15,
							borderRadius: 25,
							alignItems: "center",
							opacity:
								selectedImage &&
								selectedRetailers.length > 0 &&
								!processing
									? 1
									: 0.5,
						}}
						onPress={processImage}
						disabled={
							!selectedImage ||
							processing ||
							selectedRetailers.length === 0
						}>
						{processing ? (
							<View
								style={{
									flexDirection: "row",
									alignItems: "center",
								}}>
								<ActivityIndicator color='#FFF' />
								<Text style={{ color: "#FFF", marginLeft: 10 }}>
									Processing...
								</Text>
							</View>
						) : (
							<Text style={{ color: "#FFF" }}>
								Process Image
								{selectedRetailers.length > 0
									? ` (${selectedRetailers.length} retailers)`
									: ""}
							</Text>
						)}
					</TouchableOpacity>

					{processedImages.length > 0 && (
						<ScrollView style={{ marginTop: 20, maxHeight: 300 }}>
							<Text style={{ marginBottom: 10 }}>
								Processed Images:
							</Text>
							{processedImages.map((img, index) => (
								<View
									key={index}
									style={{
										marginBottom: 15,
										padding: 10,
										borderRadius: 5,
										backgroundColor: APP_COLOURS.BG2 + "10",
									}}>
									<Text
										style={{
											fontWeight: "500",
											marginBottom: 5,
										}}>
										{img.retailer} - {img.title}
									</Text>
									<Image
										source={{ uri: img.url }}
										style={{
											width: 100,
											height: 100,
											resizeMode: "contain",
											marginBottom: 5,
										}}
									/>
									<TouchableOpacity
										style={{
											backgroundColor: APP_COLOURS.BG2,
											padding: 5,
											borderRadius: 5,
											alignItems: "center",
										}}
										onPress={() => {
											const link =
												document.createElement("a");
											link.href = img.url;
											link.download = `${img.retailer}_${img.title}.jpg`;
											link.click();
										}}>
										<Text style={{ color: "#FFF" }}>
											Download
										</Text>
									</TouchableOpacity>
								</View>
							))}
						</ScrollView>
					)}
				</View>
			</View>
		</Modal>
	);
}
