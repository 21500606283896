import React, { useState } from "react";
import { View, Text, StyleSheet, TextInput, ScrollView } from "react-native";
import moment from "moment";
import { darkTheme } from "./theme";

const formatDuration = (seconds) => {
	if (!seconds) return "Ongoing";
	const duration = moment.duration(seconds, "seconds");
	const hours = Math.floor(duration.asHours());
	const minutes = duration.minutes();
	return hours > 0 ? `${hours}h ${minutes}m` : `${minutes}m`;
};

const getStatusColor = (status) => {
	switch (status.toLowerCase()) {
		case "started":
			return darkTheme.colors.primary;
		case "paused":
			return darkTheme.colors.warning;
		case "completed":
			return darkTheme.colors.success;
		case "stopped":
			return darkTheme.colors.error;
		default:
			return darkTheme.colors.text.secondary;
	}
};

export default function WorkLogList({
	workLogs,
	tasks,
	activeTask,
	onAddComment,
	onSendDailySummary,
}) {
	const [commentInputs, setCommentInputs] = useState({});

	const handleCommentChange = (logId, value) => {
		setCommentInputs((prev) => ({ ...prev, [logId]: value }));
	};

	const handleCommentSubmit = (logId, currentLog) => {
		if (commentInputs[logId]?.trim()) {
			const newComment = {
				text: commentInputs[logId].trim(),
				timestamp: moment().unix(),
				user_id: activeTask?.user_id,
			};

			// Ensure currentLog.comment is always an array
			const currentComments = Array.isArray(currentLog.comment)
				? currentLog.comment
				: currentLog.comment
				? [currentLog.comment]
				: [];

			// Create updated log with new comment added to array
			const updatedLog = {
				...currentLog,
				comment: [...currentComments, newComment],
			};

			onAddComment(logId, updatedLog);
			setCommentInputs((prev) => ({ ...prev, [logId]: "" }));
		}
	};

	// Filter and sort today's logs
	const todaysLogs = workLogs
		.filter((log) => {
			if (!log || !log.startTime) return false;
			return moment.unix(log.startTime).isSame(moment(), "day");
		})
		.sort((a, b) => b.startTime - a.startTime);

	// Calculate total time
	const totalTimeToday = todaysLogs.reduce((total, log) => {
		if (log.duration) return total + log.duration;
		if (!log.endTime && log.status === "started") {
			return total + (moment().unix() - log.startTime);
		}
		return total;
	}, 0);

	return (
		<View style={styles.container}>
			<View style={styles.header}>
				<View>
					<Text style={styles.title}>Today's Activity</Text>
					<Text style={styles.subtitle}>
						Total time: {formatDuration(totalTimeToday)}
					</Text>
				</View>
				<View onClick={onSendDailySummary} style={styles.summaryButton}>
					<Text style={styles.buttonText}>Send Summary</Text>
				</View>
			</View>

			<View style={styles.logList}>
				<ScrollView>
					{todaysLogs.length === 0 ? (
						<Text style={styles.emptyText}>
							No activity recorded today
						</Text>
					) : (
						todaysLogs.map((log, index) => (
							<View
								key={`${log.id}-${index}`}
								style={styles.logItem}>
								<View style={styles.logHeader}>
									<Text style={styles.logTime}>
										{moment
											.unix(log.startTime)
											.format("h:mm A")}
									</Text>
									<Text style={styles.taskTitle}>
										{log.task?.title || "Unknown Task"}
									</Text>
									<Text
										style={[
											styles.status,
											{
												color: getStatusColor(
													log.status,
												),
											},
										]}>
										{log.status.toUpperCase()}
									</Text>
								</View>

								{log.duration && (
									<Text style={styles.duration}>
										Duration: {formatDuration(log.duration)}
									</Text>
								)}

								{/* Comments Section */}
								{Array.isArray(log.comment) &&
									log.comment.length > 0 && (
										<View style={styles.notes}>
											{log.comment.map(
												(comment, commentIndex) => (
													<View
														key={`${commentIndex}-${comment.timestamp}`}
														style={
															styles.commentItem
														}>
														<Text
															style={
																styles.noteText
															}>
															{comment.text}
														</Text>
														<Text
															style={
																styles.commentTime
															}>
															{moment
																.unix(
																	comment.timestamp,
																)
																.format(
																	"h:mm A",
																)}
														</Text>
													</View>
												),
											)}
										</View>
									)}

								{/* Comment Input */}
								<View style={styles.commentInput}>
									<TextInput
										style={styles.input}
										placeholder='Add note...'
										placeholderTextColor={
											darkTheme.colors.text.secondary
										}
										value={commentInputs[log.id] || ""}
										onChangeText={(value) =>
											handleCommentChange(log.id, value)
										}
										onSubmitEditing={() =>
											handleCommentSubmit(log.id, log)
										}
									/>
									<View
										onClick={() =>
											handleCommentSubmit(log.id, log)
										}
										style={[
											styles.addButton,
											{
												opacity: commentInputs[
													log.id
												]?.trim()
													? 1
													: 0.5,
											},
										]}>
										<Text style={styles.buttonText}>
											Add
										</Text>
									</View>
								</View>
							</View>
						))
					)}
				</ScrollView>
			</View>
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: darkTheme.colors.surface,
		borderRadius: 8,
	},
	header: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		padding: 12,
		borderBottomWidth: 1,
		borderBottomColor: darkTheme.colors.border,
	},
	title: {
		fontSize: 16,
		fontWeight: "600",
		color: darkTheme.colors.text.primary,
	},
	subtitle: {
		fontSize: 12,
		color: darkTheme.colors.text.secondary,
		marginTop: 2,
	},
	summaryButton: {
		backgroundColor: darkTheme.colors.primary,
		padding: "6px 12px",
		borderRadius: 4,
		cursor: "pointer",
	},
	buttonText: {
		color: darkTheme.colors.text.primary,
		fontSize: 12,
		fontWeight: "500",
	},
	logList: {
		flex: 1,
	},
	logItem: {
		padding: 12,
		borderBottomWidth: 1,
		borderBottomColor: darkTheme.colors.border,
		paddingRight: 20,
	},
	logHeader: {
		flexDirection: "row",
		alignItems: "center",
		gap: 8,
	},
	logTime: {
		fontSize: 12,
		color: darkTheme.colors.text.secondary,
		minWidth: 70,
	},
	taskTitle: {
		flex: 1,
		fontSize: 13,
		fontWeight: "500",
		color: darkTheme.colors.text.primary,
	},
	status: {
		fontSize: 11,
		fontWeight: "600",
	},
	duration: {
		fontSize: 11,
		color: darkTheme.colors.text.secondary,
		marginTop: 4,
	},
	notes: {
		marginTop: 8,
		padding: 8,
		backgroundColor: `${darkTheme.colors.primary}11`,
		borderRadius: 4,
		gap: 6,
		paddingLeft: 80,
	},
	commentItem: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		gap: 8,
	},
	noteText: {
		flex: 1,
		fontSize: 12,
		color: darkTheme.colors.text.primary,
	},
	commentTime: {
		fontSize: 10,
		color: darkTheme.colors.text.secondary,
	},
	commentInput: {
		flexDirection: "row",
		gap: 8,
		marginTop: 8,
	},
	input: {
		flex: 1,
		backgroundColor: darkTheme.colors.background,
		padding: "4px 8px",
		borderRadius: 4,
		fontSize: 12,
		color: darkTheme.colors.text.primary,
		border: `1px solid ${darkTheme.colors.border}`,
	},
	addButton: {
		backgroundColor: darkTheme.colors.secondary,
		padding: "4px 12px",
		borderRadius: 4,
		cursor: "pointer",
	},
	emptyText: {
		padding: 16,
		textAlign: "center",
		color: darkTheme.colors.text.secondary,
		fontSize: 13,
	},
});
