import React, { Component } from "react";
import { useNavigate } from "react-router-dom";
import {
	View,
	Text,
	Dimensions,
	Image,
	TouchableOpacity,
	ScrollView,
	Modal,
} from "react-native";
import moment from "moment";

import Fade from "react-reveal/Fade";
import Font from "react-font";
import { APP_COLOURS } from "../APP_VARS";
import female from "./../../assets/images/female.png";
import traqQ from "./../../assets/images/logo512.png";
import close from "./../../assets/images/close.png";
// import logoHalf from "./../../assets/images/logoHalf.svg";
import logoHalf from "./../../assets/images/menu.png";
import logoFull from "./../../assets/images/logoTetrice.png";

import Profile from "./Profile";
import OrganisationAcount from "./OrganisationAccount/OrganisationAcount";
import TopTabs from "./TopTabs";

// import TechSupport from "./TechSupport/index";
import techSupportIcon from "./TechSupport/techSupport.svg";

import BrandCrud from "./CrudBrand";
// import MallsCrud from "./CrudMalls";
// import RetailerCrud from "./CrudRetailer";
import BrandTabs from "./BrandTabs";
import ProjectManager from "./DailyPlanner/ProjectManager";
import { Avatars } from "../APP_VARS";
import OrganisationServices from "./ServicesOverview/OrganisationServices";
import TeamOverview from "./TeamOverview";
import Usernamanement from "./UserManagement/usermanagement";
import ContentCalendar from "./ContentCalendar";
import SentimentTracker from "./SentimentTracker";
import GrowthTracker from "./GrowthTracker";
import TetriceCalendar from "./CalendarTetrice";
import ActivityLog from "./ActivityLog";
import StatusModal from "./StatusModal";
import { Timer } from "./TImerCompnent";
import AttendanceRecord from "./AttendanceRecord";
import ProposalReport from "./PresentationReport/ProposalReport";
import MerchandisingIndex from "./Merchandising";
import AutoHeightImage from "./AutoHeightImageComp";
import { playAudio } from "./Notifications";
import CommunityManager from "./CommunityManager";
import TechSupportComponent from "./TechSupport";
import { sendDiscordMessage } from "../CRUDHELPER";
import WhitePaper from "./WhitePapers/WhitePaper";
import PreviewUsers from "./UserManagement/PreviewUsers";
import TetriceCalendarMobile from "./CalendarTetrice/TetriceCalendarMobile";
import NotificationList from "./Notifications/NotificationList";
import AutoRefresh from "./AutoRefresh";
import Lottie from "react-lottie";
import AssetsLibrary from "./AssetsLibrary";
import ProjectRouter from "./DailyPlanner/ProjectRouter";
import ProstaffSignups from "./ProstaffDashboard";
import TetriceHome from "./tetriceHome";
// import ProposalApp from "./Proposals/AllProposals";
import PresentationApp from "./PresentationApp/AllPresentations";

import * as animationData2 from "../../assets/lottie/Animation-Ai.json";
import CreativeRequests from "./CreativeRequests";
import FlashImage from "./FlashImage";
import PerformanceMediaIndex from "./PerformanceMedia";
import NpdLaunchTracker from "./NDPLaunchPlan/index";

import TimeBoxerIndex from "./TimeBoxer/TimeBoxerIndex";

const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").height;

class index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user_data: "",
			email: "",
			accessToken: "",
			refresh_token: "",
			currentModal: "",
			screenWidth: screenWidth,
			screenHeight: screenHeight,
			authenticating: true,
			validated: true,
			hover: true,
			currentPage: "",
			selectedBrand: "",
			selectedBrandTab: {
				id: "",
				title: "All",
			},
			pages: [
				{
					title: "tetrice",
					page: "tetriceHome",
					img: require("./../../assets/images/icons/gantt_icon.png"),
					permission: ["tetrice"],
				},
				{
					title: "Projects",
					page: "projects",
					img: require("./../../assets/images/icons/gantt_icon.png"),
					permission: [],
				},
				{
					title: "Time Box",
					page: "timebox",
					img: require("./../../assets/images/icons/todoList.png"),
					permission: ["tetrice", "DIGITAL"],
				},
				{
					title: "Creative Requests",
					page: "creativerequests",
					img: require("./../../assets/images/icons/survey.png"),
					permission: ["tetrice"],
				},
				{
					title: "Content Calendar",
					page: "contentcalendar",
					img: require("./../../assets/images/icons/todoList.png"),
					permission: ["tetrice", "DIGITAL"],
				},
				{
					title: "ProStaff - Personas",
					page: "influencersProstaff",
					img: require("./../../assets/images/icons/todoList.png"),
					permission: ["tetrice", "DIGITAL", "Elvee Consulting"],
				},
				{
					title: "NPD Tracker",
					page: "NpdLaunchTracker",
					img: require("./../../assets/images/icons/todoList.png"),
					permission: ["tetrice", "DIGITAL", "Elvee Consulting"],
				},
				// {
				// 	title: "Proposals",
				// 	page: "proposal",
				// 	img: require("./../../assets/images/icons/todoList.png"),
				// 	permission: ["tetrice"],
				// },
				{
					title: "Presentations",
					page: "presentation",
					img: require("./../../assets/images/icons/DevTracker.png"),
					permission: ["tetrice"],
				},

				{
					title: "Performance Media",
					page: "performancemedia",
					img: require("./../../assets/images/icons/survey.png"),
					permission: ["tetrice"],
				},

				{
					title: "Assets Library",
					page: "assetslibrary",
					img: require("./../../assets/images/icons/todoList.png"),
					permission: ["tetrice"],
				},
				{
					title: "Services",
					page: "services",
					img: require("./../../assets/images/icons/appFeatures.png"),
					permission: ["wesley@tetrice.co.za"],
				},
				// {
				// 	title: "Planner",
				// 	page: "planner",
				// 	img: require("./../../assets/images/icons/gantt_icon.png"),
				// },
				{
					title: "Merchandising",
					page: "merchandising",
					img: require("./../../assets/images/icons/survey.png"),
					permission: ["MERCHANDISING", "DIGITAL", "tetrice"],
				},
				{
					title: "User management",
					page: "usermanagement",
					img: require("./../../assets/images/icons/MeetingNotes.png"),
					permission: [
						"wesley@tetrice.co.za",
						"aaron.roche@tetrice.co.za",
					],
				},
				// {
				// 	title: "NPD Tracker [INTERNAL]",
				// 	page: "npdtracker",
				// 	img: require("./../../assets/images/icons/MeetingNotes.png"),
				// },
				// {
				// 	title: "NPD Tracker [EXTERNAL]",
				// 	page: "npdtracker2",
				// 	img: require("./../../assets/images/icons/MeetingNotes.png"),
				// },
				{
					title: "Sentiment tracker",
					page: "sentracker",
					img: require("./../../assets/images/icons/todoList.png"),
					permission: ["wesley@tetrice.co.za"],
				},
				{
					title: "Growth tracker",
					page: "smtracker",
					img: require("./../../assets/images/icons/todoList.png"),
					permission: ["wesley@tetrice.co.za"],
				},
				{
					title: "Calendar",
					page: "tetriceCalendar",
					img: require("./../../assets/images/icons/MeetingNotes.png"),
					permission: ["tetrice"],
				},

				{
					title: "Logs",
					page: "activityLog",
					img: require("./../../assets/images/icons/todoList.png"),
					permission: ["wesley@tetrice.co.za"],
				},
				{
					title: "Reports",
					page: "reports",
					img: require("./../../assets/images/icons/MeetingNotes.png"),
					permission: ["wesley@tetrice.co.za"],
				},

				{
					title: "Community Manager",
					page: "CommunityManager",
					img: require("./../../assets/images/icons/CommunityManagement.png"),
					permission: ["wesley@tetrice.co.za"],
				},
				{
					title: "TechSupport",
					page: "TechSupport",
					img: require("./../../assets/images/icons/DevTracker.png"),
					permission: ["wesley@tetrice.co.za"],
				},
			],

			loadingDeeplink: false,
		};
	}

	componentDidMount() {
		this.validateSession();
		window.addEventListener("resize", this.resize.bind(this));
		this.resize();
		setTimeout(() => {
			this.setState({
				currentPage: this.props.page,
				hover: this.props.page !== "presentation",
			});
		}, 300);
		console.log(this.props.project_id);

		if (this.props.project_id) {
			this.setState({
				loadingDeeplink: true,
			});
		}
	}

	validateSession = async () => {
		let user_data = await localStorage.getItem("user_data");
		let email = await localStorage.getItem("email");
		let accessToken = await localStorage.getItem("accessToken");
		let refresh_token = await localStorage.getItem("refresh_token");

		if (accessToken !== null && accessToken !== "") {
			await this.setState({
				user_data: JSON.parse(user_data),
				email,
				accessToken,
				refresh_token,
			});
			setTimeout(() => {
				this.setState({
					loading: false,
					authenticating: false,
				});
			}, 1000);
		} else {
			console.log("accessToken");
			this.props.navigate("/");
			window.location.href = "/";
			localStorage.clear();
			localStorage.clear();
		}
	};

	componentDidUpdate() {}

	resize() {
		this.setState({
			screenHeight: Dimensions.get("window").height,
			screenWidth: Dimensions.get("window").width,
		});
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resize.bind(this));
	}

	logOut = async () => {
		await localStorage.clear();
		await localStorage.clear();
		this.props.navigate("/");
		// return <Navigate to='/' />;
	};

	render() {
		let w = this.state.screenWidth;
		let h = this.state.screenHeight;
		let minWidth = 900;
		// let align = 'right'
		if (!this.state.authenticating) {
			return (
				<View
					style={{
						overflow: "hidden",
						// backgroundColor: APP_COLOURS.BG2,
						// backgroundColor: "#F3F4F6",
						// backgroundColor: "#E0E3EB",
						backgroundColor: APP_COLOURS.BG3,
						position: "sticky",
						width: w,
						// minWidth: minWidth,
						height: this.state.screenHeight,
					}}>
					<View
						style={{
							flex: 1,
							marginLeft: "auto",
							marginRight: "auto",
							flexDirection: "row",
							height: this.state.screenHeight - 45,
							width: this.state.screenWidth,
							// minWidth: minWidth,
							// padding: 12,
							// paddingBottom: 5,
							// paddingTop: 1,
						}}>
						<View
							// onMouseEnter={() => this.setState({ hover: true })}
							// onMouseLeave={() => this.setState({ hover: false })}
							style={{
								// height: this.state.screenHeight - 45,
								width: this.state.hover ? 220 : "auto",
								alignItems: "left",
								padding: 10,
								// borderRadius: 20,

								// backgroundColor: APP_COLOURS.BG,
								backgroundColor: APP_COLOURS.BG2,
								// marginRight: 10,
								borderTopRightRadius: 20,
							}}>
							<ScrollView
								showsVerticalScrollIndicator={false}
								contentContainerStyle={{}}>
								<TouchableOpacity
									onPress={() =>
										this.setState({
											hover: !this.state.hover,
										})
									}>
									<View
										style={{
											height: 90,
											// width: 170,
											// flex: 1,
											// justifyContent: "center",
											// alignItems: "center",
											justifyContent: "flex-start",
											paddingTop: 5,
											marginBottom: 20,
										}}>
										<View
											style={{
												flexDirection: "row",
												alignItems: "center",
											}}>
											<Image
												source={require("../../assets/images/icons/skrum_menu.png")}
												style={{
													width: 30,
													height: 30,
													resizeMode: "contain",
													// marginRight: 3,
													display: this.state.hover
														? "flex"
														: "none",
												}}
											/>
											<AutoHeightImage
												width={
													this.state.hover ? 110 : 50
												}
												source={require("../../assets/images/skrum_logo.png")}
											/>
										</View>

										{/* {this.state.hover ? (
											<View
												style={{
													flexDirection: "row",
													alignItems: "center",
												}}>
												<Image
													source={logoHalf}
													style={{
														width: 45,
														height: 45,
														resizeMode: "contain",
														marginRight: 10,
													}}
												/>
												<Text
													style={{
														fontSize: 22,
														color: APP_COLOURS.WHITE,
													}}>
													<Font
														weight={800}
														family='Poppins'
														color={
															APP_COLOURS.WHITE
														}>
														tetrice
													</Font>
												</Text>
											</View>
										) : (
											<View
												style={{
													width: "100%",
													alignItems: "center",
													justifyContent: "center",
												}}>
												<Image
													source={logoHalf}
													style={{
														width: 45,
														height: 45,
														resizeMode: "contain",
													}}
												/>
											</View>
										)} */}
									</View>
								</TouchableOpacity>

								{/* <View
									style={{
										height: 70,
										// justifyContent: "center",
										// alignItems: "center",
										paddingTop: 0,
										marginBottom: 10,
										paddingLeft: 12,
									}}>
									{this.state.hover ? (
										<Image
											source={require("./../../assets/images/revlon.png")}
											style={{
												width: 90,
												height: 50,
												resizeMode: "contain",
											}}
										/>
									) : (
										<Image
											source={require("./../../assets/images/revlon.png")}
											style={{
												width: 30,
												height: 50,
												resizeMode: "contain",
											}}
										/>
									)}
								</View> */}

								<View
									style={{
										alignItems: "center",
										justifyContent: "center",
										width: "100%",
										// display:  'flex' : 'none',
									}}>
									<TouchableOpacity
										style={{
											width: "100%",
											height: "auto",
											padding: 7,
											alignItems: "center",
											borderRadius: 10,
											minHeight: 70,
											marginBottom: 10,
											flexDirection: "row",
											borderColor: "#C5C5C5",
										}}
										onPress={() => {
											// this.logOut();
											this.setState({
												currentPage: "profile",
											});
										}}>
										{typeof this.state.user_data?.avatar !==
										"undefined" ? (
											<Image
												source={
													Avatars[
														parseInt(
															this.state.user_data
																.avatar,
														)
													].pp
												}
												style={{
													width: 30,
													height: 30,
													borderRadius: 25,
													resizeMode: "contain",
													backgroundColor: "#FFF",
													borderWidth: 0.3,
													borderColor: "#C5C5C5",
													shadowColor: "#ECF4FF",
													shadowOffset: {
														width: 0,
														height: 8,
													},
													shadowOpacity: 0.44,
													shadowRadius: 10.32,

													elevation: 16,
												}}
											/>
										) : null}

										<View
											style={{
												padding: 5,
												borderRadius: 10,
												alignItems: "center",
												flexDirection: "column",
												flex: 1,
												// alignItems: 'flex-end',
												alignItems: "flex-start",
												display: this.state.hover
													? "flex"
													: "none",
											}}>
											<Text
												style={{
													fontFamily: "Gotham",
													fontSize: 12,
													fontWeight: "600",
													color: APP_COLOURS.WHITE,
												}}>
												{
													this.state.user_data
														.first_name
												}{" "}
												{this.state.user_data.last_name}
											</Text>
											<Text
												style={{
													fontFamily: "Gotham",
													fontSize: 10,
													opacity: 0.8,
													color: APP_COLOURS.WHITE,
												}}>
												{this.state.user_data.username}
											</Text>
										</View>
									</TouchableOpacity>
								</View>

								{/* <View
									style={{
										alignItems: "center",
										justifyContent: "center",
										width: "100%",
										// display:  'flex' : 'none',
									}}>
									<NotificationList
										collapse={this.state.hover}
									/>
								</View> */}

								<TouchableOpacity
									style={{
										width: "100%",
										height: "auto",
										padding: 7,
										alignItems: "center",
										justifyContent: "center",
										borderRadius: 10,
										minHeight: 45,
										marginBottom: 10,
										flexDirection: "row",
										backgroundColor: APP_COLOURS.TRANSHALO,
									}}
									onPress={() => {
										window.location =
											"https://reporting.tetrice.co.za";
									}}>
									<View
										style={{
											width: 30,
											height: 30,
											alignItems: "center",
											justifyContent: "center",
											paddingLeft: 7,
										}}>
										<Image
											source={require("./../../assets/images/skrum_menu.png")}
											style={{
												width: 28,
												height: 28,
												resizeMode: "contain",
											}}
										/>
									</View>

									<View
										style={{
											flex: 1,
											padding: 5,
											display: this.state.hover
												? "flex"
												: "none",
											marginLeft: 9,
										}}>
										<Text
											style={{
												fontFamily: "Gotham",
												fontSize: 12,
												color: APP_COLOURS.TEXTCOLOR,
												fontWeight: "900",
											}}>
											Digital Tracker
										</Text>
									</View>
								</TouchableOpacity>

								{this.state.pages
									.filter((m) => {
										if (m.permission.length > 0) {
											if (
												m.permission.includes(
													this.state.user_data
														.username,
												)
											) {
												return m;
											} else if (
												m.permission.includes(
													this.state.user_data
														.permission,
												)
											) {
												return m;
											} else if (
												m.permission.includes(
													this.state.user_data
														.organisation,
												)
											) {
												return m;
											} else {
												return;
											}
										} else {
											return m;
										}
									})
									.map((d, i) => (
										<View
											key={i}
											style={{
												alignItems: "center",
												justifyContent: "center",
												width: "100%",
											}}>
											<TouchableOpacity
												style={{
													width: "100%",
													height: "auto",
													padding: 7,
													alignItems: "center",
													justifyContent: "center",
													borderRadius: 10,
													minHeight: 45,
													marginBottom: 10,
													flexDirection: "row",
													// borderWidth:
													// 	this.state.currentPage == d.page
													// 		? 0.3
													// 		: 0,
													// borderColor: "#C5C5C5",
													backgroundColor:
														this.state
															.currentPage ==
														d.page
															? "rgba(13, 109, 251, 0.7)"
															: "transparent",
												}}
												onPress={() => {
													// this.setState({
													// 	currentPage: d.page,
													// });
													window.location.href = `/home/${d.page}`;
												}}>
												<View
													style={{
														flex: 1,
														padding: 5,
														display: this.state
															.hover
															? "flex"
															: "none",
														marginLeft: 9,
													}}>
													<Text
														style={{
															fontFamily:
																"Avenir",
															fontSize: 14,
															color: APP_COLOURS.TEXTCOLOR,
															fontWeight:
																this.state
																	.currentPage ==
																d.page
																	? "700"
																	: "300",
														}}>
														{d.title}
													</Text>
												</View>
												<View
													style={{
														width: 30,
														height: 30,
														alignItems: "center",
														justifyContent:
															"center",
													}}>
													<Image
														source={d.img}
														style={{
															width: 28,
															height: 28,
															resizeMode:
																"contain",
															// shadowColor: "#000",
															// shadowOffset: {
															// 	width: 0,
															// 	height: 4,
															// },
															// shadowOpacity: 0.23,
															// shadowRadius: 4.65,
															// elevation: 8,
														}}
													/>
												</View>
											</TouchableOpacity>
										</View>
									))}
							</ScrollView>

							<View
								style={{
									alignItems: "center",
									justifyContent: "center",
									width: "100%",
								}}>
								<TouchableOpacity
									style={{
										width: "100%",
										height: "auto",
										padding: 7,
										alignItems: "center",
										justifyContent: "center",
										borderRadius: 10,
										minHeight: 45,
										marginBottom: 10,
										flexDirection: "row",
										backgroundColor: APP_COLOURS.TRANSHALO,
									}}
									onPress={() =>
										this.setState({
											currentPage: "settings",
										})
									}>
									<View
										style={{
											width: 30,
											height: 30,
											alignItems: "center",
											justifyContent: "center",
											paddingLeft: 7,
										}}>
										<Image
											source={require("./../../assets/images/icons/settings.png")}
											style={{
												width: 28,
												height: 28,
												resizeMode: "contain",
											}}
										/>
									</View>

									<View
										style={{
											flex: 1,
											padding: 5,
											display: this.state.hover
												? "flex"
												: "none",
											marginLeft: 9,
										}}>
										<Text
											style={{
												fontFamily: "Gotham",
												fontSize: 12,
												color: APP_COLOURS.TEXTCOLOR,
												fontWeight: "300",
											}}>
											Settings
										</Text>
									</View>
								</TouchableOpacity>
								<TouchableOpacity
									style={{
										width: "100%",
										height: "auto",
										padding: 7,
										alignItems: "center",
										justifyContent: "center",
										borderRadius: 10,
										minHeight: 45,
										marginBottom: 10,
										flexDirection: "row",
										backgroundColor: APP_COLOURS.TRANSHALO,
									}}
									onPress={() =>
										this.setState({
											currentPage: "teamtetrice",
										})
									}>
									<View
										style={{
											width: 30,
											height: 30,
											alignItems: "center",
											justifyContent: "center",
											paddingLeft: 7,
										}}>
										<Image
											source={require("./../../assets/images/menu.png")}
											style={{
												width: 28,
												height: 28,
												resizeMode: "contain",
											}}
										/>
									</View>

									<View
										style={{
											flex: 1,
											padding: 5,
											display: this.state.hover
												? "flex"
												: "none",
											marginLeft: 9,
										}}>
										<Text
											style={{
												fontFamily: "Gotham",
												fontSize: 12,
												color: APP_COLOURS.TEXTCOLOR,
												fontWeight: "900",
											}}>
											tetrice
										</Text>
									</View>
								</TouchableOpacity>

								{/* <TouchableOpacity
									style={{
										width: "100%",
										height: "auto",
										padding: 7,
										alignItems: "center",
										justifyContent: "center",
										borderRadius: 10,
										minHeight: 45,
										marginBottom: 10,
										flexDirection: "row",
										backgroundColor: APP_COLOURS.TRANSHALO,
										// this.state.currentPage == "organisationaccount"
										// 	? APP_COLOURS.TRANSHALO
										// 	: "transparent",
									}}
									onPress={() =>
										this.setState({
											currentPage: "organisationaccount",
										})
									}>
									<View
										style={{
											width: 30,
											height: 30,
											alignItems: "center",
											justifyContent: "center",
											paddingLeft: 7,
										}}>
										<Image
											source={require("./../../assets/images/icons/accounts.png")}
											style={{
												width: 28,
												height: 28,
												resizeMode: "contain",
											}}
										/>
									</View>

									<View
										style={{
											flex: 1,
											padding: 5,
											display: this.state.hover
												? "flex"
												: "none",
											marginLeft: 9,
										}}>
										<Text
											style={{
												fontFamily: "Gotham",
												fontSize: 12,
												color: APP_COLOURS.TEXTCOLOR,
												fontWeight: "300",
											}}>
											Account
										</Text>
									</View>
								</TouchableOpacity> */}

								<Timer
									onTimeout={async () => {
										let lastUpdated =
											await localStorage.getItem(
												"lastUpdated",
											);
										console.log(lastUpdated);

										if (
											typeof lastUpdated ===
												"undefined" ||
											lastUpdated === null ||
											moment(lastUpdated)
												.add(60, "minutes")
												.isBefore(moment())
										) {
											await this.setState({
												currentModal: "",
											});
											await this.setState({
												currentModal: "StatusModal",
											});

											setTimeout(() => {
												// playAudio(8);
											}, 500);

											await console.log("timer");
										}
									}}
								/>

								<TouchableOpacity
									style={{
										width: "100%",
										height: "auto",
										padding: 7,
										alignItems: "center",
										justifyContent: "center",
										borderRadius: 10,
										minHeight: 45,
										marginBottom: 10,
										flexDirection: "row",
										backgroundColor: APP_COLOURS.TRANSHALO,
										// this.state.currentPage == "organisationaccount"
										// 	? APP_COLOURS.TRANSHALO
										// 	: "transparent",
									}}
									onPress={() => this.logOut()}>
									<View
										style={{
											width: 30,
											height: 30,
											alignItems: "center",
											justifyContent: "center",
										}}>
										<Image
											source={require("./../../assets/images/logout.png")}
											style={{
												width: 23,
												height: 23,
												resizeMode: "contain",
											}}
										/>
									</View>

									<View
										style={{
											flex: 1,
											padding: 5,
											display: this.state.hover
												? "flex"
												: "none",
											marginLeft: 9,
										}}>
										<Text
											style={{
												fontFamily: "Gotham",
												fontSize: 12,
												color: APP_COLOURS.TEXTCOLOR,
												fontWeight: "300",
											}}>
											Logout
										</Text>
									</View>
								</TouchableOpacity>
							</View>
						</View>

						<View
							style={{
								flex: 1,
							}}>
							{/* {this.state.hover ? this.tetriceHome() : null} */}

							{this.state.currentPage === "profile" ? (
								<Profile logOut={this.logOut.bind(this)} />
							) : (
								<View />
							)}

							{this.state.currentPage === "projects" ? (
								<View
									style={{
										flex: 1,
										// paddingRight: 150,
									}}>
									{/* <BrandTabs
										{...this.state}
										selectedSubPage={(d) =>
											this.setState({
												selectedBrand: d,
											})
										}
									/> */}
									{/* <TopTabs
										{...this.state}
										screenHeight={this.state.screenHeight}
										screenWidth={this.state.screenWidth}
										selectedSubPage={(d) =>
											this.setState({
												subPage: d,
											})
										}
										tabs={["REVLON"]}
									/> */}

									{/* <ProjectManager {...this.state} /> */}

									<ProjectRouter parentData={this.state} />

									{/* <ProductManagement
										{...this.state}
										selectedBrand={this.state.selectedBrand}
										screenHeight={this.state.screenHeight}
										screenWidth={this.state.screenWidth}
										logOut={this.logOut.bind(this)}
									/> */}
								</View>
							) : (
								<View />
							)}

							{this.state.currentPage === "creativerequests" ? (
								<View
									style={{
										flex: 1,
									}}>
									<CreativeRequests
										{...this.state}
										logOut={this.logOut.bind(this)}
									/>
								</View>
							) : (
								<View />
							)}

							{this.state.currentPage === "services" ? (
								<View
									style={{
										flex: 1,
									}}>
									<OrganisationServices
										{...this.state}
										logOut={this.logOut.bind(this)}
									/>
								</View>
							) : (
								<View />
							)}

							{this.state.currentPage === "teamtetrice" ? (
								<View
									style={{
										flex: 1,
									}}>
									<TeamOverview
										{...this.state}
										logOut={this.logOut.bind(this)}
									/>
								</View>
							) : (
								<View />
							)}

							{this.state.currentPage === "settings" ? (
								<View
									style={{
										flex: 1,
									}}>
									{/* <BrandTabs
										{...this.state}
										selectedSubPage={(d) =>
											this.setState({
												selectedBrand: d,
											})
										}
									/> */}
									{/* <TopTabs
										{...this.state}
										selectedSubPage={(d) =>
											this.setState({
												subPage: d,
											})
										}
										tabs={["REVLON"]}
									/> */}

									<BrandCrud
										{...this.state}
										logOut={this.logOut.bind(this)}
									/>
								</View>
							) : (
								<View />
							)}

							{this.state.currentPage === "sentracker" ? (
								<SentimentTracker
									{...this.state}
									screenHeight={this.state.screenHeight}
									screenWidth={this.state.screenWidth}
									logOut={this.logOut.bind(this)}
								/>
							) : (
								<View />
							)}

							{this.state.currentPage === "performancemedia" ? (
								<PerformanceMediaIndex
									{...this.state}
									screenHeight={this.state.screenHeight}
									screenWidth={this.state.screenWidth}
									logOut={this.logOut.bind(this)}
									user_data={this.state.user_data}
								/>
							) : (
								<View />
							)}

							{this.state.currentPage === "assetslibrary" ? (
								<AssetsLibrary
									{...this.state}
									screenHeight={this.state.screenHeight}
									screenWidth={this.state.screenWidth}
									logOut={this.logOut.bind(this)}
									user_data={this.state.user_data}
								/>
							) : (
								<View />
							)}

							{this.state.currentPage === "contentcalendar" ? (
								<ContentCalendar
									{...this.state}
									screenHeight={this.state.screenHeight}
									screenWidth={this.state.screenWidth}
									logOut={this.logOut.bind(this)}
									user_data={this.state.user_data}
								/>
							) : (
								<View />
							)}

							{this.state.currentPage ===
							"influencersProstaff" ? (
								<ProstaffSignups
									{...this.state}
									screenHeight={this.state.screenHeight}
									screenWidth={this.state.screenWidth}
									logOut={this.logOut.bind(this)}
									user_data={this.state.user_data}
								/>
							) : (
								<View />
							)}

							{this.state.currentPage === "presentation" ? (
								<PresentationApp
									{...this.state}
									{...this.props}
									screenHeight={this.state.screenHeight}
									screenWidth={this.state.screenWidth}
									logOut={this.logOut.bind(this)}
									user_data={this.state.user_data}
								/>
							) : (
								<View />
							)}

							{/* {this.state.currentPage === "proposal" ? (
								<ProposalApp
									{...this.state}
									screenHeight={this.state.screenHeight}
									screenWidth={this.state.screenWidth}
									logOut={this.logOut.bind(this)}
									user_data={this.state.user_data}
								/>
							) : (
								<View />
							)} */}

							{/* {console.log(this.state.user_data)} */}
							{this.state.currentPage === "usermanagement" ? (
								<Usernamanement
									{...this.state}
									screenHeight={this.state.screenHeight}
									screenWidth={this.state.screenWidth}
									logOut={this.logOut.bind(this)}
								/>
							) : (
								<View />
							)}

							{this.state.currentPage === "merchandising" ? (
								<MerchandisingIndex
									{...this.state}
									screenHeight={this.state.screenHeight}
									screenWidth={this.state.screenWidth}
									logOut={this.logOut.bind(this)}
								/>
							) : (
								<View />
							)}

							{this.state.currentPage ===
							"organisationaccount" ? (
								<OrganisationAcount
									{...this.state}
									screenHeight={this.state.screenHeight}
									screenWidth={this.state.screenWidth}
									logOut={this.logOut.bind(this)}
								/>
							) : (
								<View />
							)}

							{this.state.currentPage === "NpdLaunchTracker" ? (
								<NpdLaunchTracker
									{...this.state}
									screenHeight={this.state.screenHeight}
									screenWidth={this.state.screenWidth}
									logOut={this.logOut.bind(this)}
								/>
							) : (
								<View />
							)}

							{this.state.currentPage === "smtracker" ? (
								<GrowthTracker
									{...this.state}
									screenHeight={this.state.screenHeight}
									screenWidth={this.state.screenWidth}
									logOut={this.logOut.bind(this)}
								/>
							) : (
								<View />
							)}

							{this.state.currentPage === "tetriceCalendar" ? (
								<TetriceCalendar
									{...this.state}
									screenHeight={this.state.screenHeight}
									screenWidth={this.state.screenWidth}
									logOut={this.logOut.bind(this)}
								/>
							) : (
								<View />
							)}

							{this.state.currentPage === "tetriceHome" ? (
								<TetriceHome
									{...this.state}
									screenHeight={this.state.screenHeight}
									screenWidth={this.state.screenWidth}
									logOut={this.logOut.bind(this)}
								/>
							) : (
								<View />
							)}

							{this.state.currentPage === "activityLog" ? (
								<View
									style={{
										flex: 1,
										padding: 10,
									}}>
									<View
										style={{
											padding: 10,
										}}>
										<Text
											style={{
												color: APP_COLOURS.BG2,
												fontSize: 28,
												fontWeight: "800",
												fontFamily: "Gotham",
												// marginTop: 20,
												// marginTop: 6,
											}}>
											All activity logs
										</Text>
									</View>
									<ActivityLog
										projectID={""}
										{...this.state}
										screenHeight={this.state.screenHeight}
										screenWidth={this.state.screenWidth}
										logOut={this.logOut.bind(this)}
									/>
									<AttendanceRecord
										{...this.state}
										screenHeight={this.state.screenHeight}
										screenWidth={this.state.screenWidth}
										logOut={this.logOut.bind(this)}
									/>
								</View>
							) : (
								<View />
							)}

							{this.state.currentPage === "reports" ? (
								<ProposalReport
									{...this.state}
									screenHeight={this.state.screenHeight}
									screenWidth={this.state.screenWidth}
									logOut={this.logOut.bind(this)}
								/>
							) : (
								<View />
							)}

							{this.state.currentPage === "CommunityManager" ? (
								<View
									style={{
										flex: 1,
									}}>
									<CommunityManager
										{...this.state}
										logOut={this.logOut.bind(this)}
									/>
								</View>
							) : (
								<View />
							)}

							{this.state.currentPage === "TechSupport" ? (
								<View
									style={{
										flex: 1,
									}}>
									<TechSupportComponent
										{...this.state}
										logOut={this.logOut.bind(this)}
									/>
								</View>
							) : (
								<View />
							)}

							{this.state.currentPage === "timebox" ? (
								<TimeBoxerIndex
									{...this.state}
									logOut={this.logOut.bind(this)}
								/>
							) : (
								<View />
							)}
						</View>
					</View>

					{/* {this.techSupportModal()} */}

					<AutoRefresh />

					{this.state.currentModal === "StatusModal" ? (
						<StatusModal
							screenWidth={w}
							screenHeight={h}
							user_data={this.state.user_data}
							accepted={(b, s) => {
								this.setState({
									statusUpdated: b,
									status: s,
									currentModal: "",
								});
								// this.onLoadProcess();
							}}
							avatarComp={null}
						/>
					) : null}
					{this.state.user_data.username ===
					"aaron.roche@tetrice.co.za" ? (
						<FlashImage />
					) : null}
				</View>
			);
		} else {
			return (
				<div
					style={{
						flex: 1,
						backgroundColor: APP_COLOURS.BG2,
						width: w,
						height: h,
						justifyContent: "center",
						alignItems: "center",
					}}>
					<View
						style={{
							width: w,
							height: h,
							justifyContent: "center",
							alignItems: "center",
							padding: 10,
						}}>
						<View
							style={{
								justifyContent: "center",
								alignItems: "center",
							}}>
							<View
								style={{
									flexDirection: "row",
									alignItems: "center",
								}}>
								{/* <View
									style={{
										flexDirection: "row",
									}}>
									<Text
										style={{
											fontSize: 45,
											color: APP_COLOURS.WHITE,
										}}>
										<Font
											weight={800}
											family='Poppins'
											color={APP_COLOURS.WHITE}>
											tetrice
										</Font>
									</Text>
								</View>
								<View
									style={{
										borderRightWidth: 1,
										borderRightColor: "#E5E7F5",
										marginHorizontal: 10,
										width: 1,
										height: "100%",
									}}
								/> */}
								<View
									style={{
										flexDirection: "row",
										alignItems: "center",
									}}>
									<AutoHeightImage
										width={200}
										source={require("../../assets/images/skrum_logo.png")}
									/>
								</View>
							</View>
						</View>
					</View>
				</div>
			);
		}
	}

	techSupportModal = () => {
		let sw = this.state.screenWidth;
		let sh = this.state.screenHeight;
		sw = sw > 600 ? 600 : sw - 30;
		sh = sh > 800 ? 800 : sh - 60;
		let align = "right";
		return (
			<>
				{!this.state.viewTechSupport ? (
					<View
						onMouseEnter={() => this.setState({ tshover: true })}
						onMouseLeave={() => this.setState({ tshover: false })}
						style={{
							position: "absolute",
							zIndex: 99,
							bottom: this.state.currentPage === "" ? 18 : 3,
							[align]: 14,
							// alignItems: "flex-end",
							justifyContent: "center",
							paddingRight: 15,
							shadowColor: "#000",
							shadowOffset: { width: 0, height: 8 },
							shadowOpacity: 0.44,
							shadowRadius: 9.32,
							elevation: 3,
							borderRadius: 40,
							borderTopRightRadius: 15,
							// backgroundColor: "transparent",
							backgroundColor: APP_COLOURS.BACKGROUND,
						}}>
						<TouchableOpacity
							onPress={() =>
								this.setState({
									viewTechSupport: true,
								})
							}>
							{/* <Image
								source={techSupportIcon}
								style={{
									width:
										this.state.currentPage === "" ||
										this.state.tshover
											? 90
											: 45,
									height:
										this.state.currentPage === "" ||
										this.state.tshover
											? 90
											: 45,
									resizeMode: "contain",
									// shadowColor: "#000",
									// shadowOffset: { width: 0, height: 8 },
									// shadowOpacity: 0.44,
									// shadowRadius: 9.32,
									// elevation: 3,
								}}
							/> */}
							<View
								style={{
									flexDirection: "row",
									alignItems: "center",
									paddingLeft: 10,
								}}>
								<Lottie
									options={{
										loop: true,
										autoplay: true,
										animationData: animationData2,
										rendererSettings: {
											preserveAspectRatio:
												"xMidYMid slice",
										},
									}}
									height={65}
									width={65}
								/>
								<View>
									<Text
										style={{
											// color: APP_COLOURS.TEXTCOLOR,
											color: "#FFF",
											fontSize: 26,
											fontWeight: "200",
											fontFamily: "Gotham",
										}}>
										Jarvis
									</Text>
									<Text
										style={{
											color: APP_COLOURS.TEXTCOLOR,
											fontSize: 9,
											fontWeight: "300",
											fontFamily: "Gotham",
										}}>
										powered by Ai
									</Text>
								</View>
							</View>
						</TouchableOpacity>
					</View>
				) : (
					<View
						style={{
							width: this.state.screenWidth,
							height: this.state.screenHeight,
							zIndex: 999,
							position: "absolute",
							left: 0,
							top: 0,
						}}>
						<TouchableOpacity
							style={{
								width: this.state.screenWidth,
								height: this.state.screenHeight,
							}}
							onPress={() =>
								this.setState({
									viewTechSupport: false,
								})
							}>
							<View
								style={{
									// flex: 1,
									justifyContent: "center",
									alignItems: "center",
									backgroundColor: "rgba(52, 52, 52, 0.5)",
									width: this.state.screenWidth,
									height: this.state.screenHeight,
								}}></View>
						</TouchableOpacity>

						<View
							style={{
								width: sw,
								height: sh,
								position: "absolute",
								bottom: 60,
								[align]: 18,
								alignItems: "center",
								justifyContent: "center",
								position: "absolute",
								// backgroundColor: "#864AE0",
								backgroundColor: APP_COLOURS.BACKGROUND,
								borderRadius: 18,
								shadowColor: "#000",
								shadowOffset: { width: 0, height: 8 },
								shadowOpacity: 0.44,
								shadowRadius: 9.32,
								elevation: 10,
							}}>
							<View
								style={{
									position: "absolute",
									zIndex: 99,
									top: -15,
									left: -15,
									marginLeft: "auto",
									marginRight: "auto",
									borderRadius: 20,
									backgroundColor: "#FFF",
								}}>
								<TouchableOpacity
									onPress={() =>
										this.setState({
											viewTechSupport: false,
											tshover: false,
										})
									}>
									<Image
										source={close}
										style={{
											width: 30,
											height: 30,
											resizeMode: "contain",
										}}
									/>
								</TouchableOpacity>
							</View>

							<View
								style={{
									flexDirection: "row",
									alignItems: "center",
									paddingLeft: 10,
								}}>
								<Lottie
									options={{
										loop: true,
										autoplay: true,
										animationData: animationData2,
										rendererSettings: {
											preserveAspectRatio:
												"xMidYMid slice",
										},
									}}
									height={65}
									width={65}
								/>
								<View>
									<Text
										style={{
											// color: APP_COLOURS.TEXTCOLOR,
											color: "#FFF",
											fontSize: 26,
											fontWeight: "200",
											fontFamily: "Gotham",
										}}>
										Jarvis
									</Text>
									<Text
										style={{
											color: APP_COLOURS.TEXTCOLOR,
											fontSize: 9,
											fontWeight: "300",
											fontFamily: "Gotham",
										}}>
										powered by Ai
									</Text>
								</View>
							</View>

							<TechSupportComponent
								screenHeight={sh}
								screenWidth={sw}
								user_data={this.state.user_data}
								logOut={this.logOut.bind(this)}
							/>
						</View>
					</View>
				)}
			</>
		);
	};

	tetriceHome = () => {
		if (this.state.user_data.permission === "tetrice") {
			return (
				<View
					style={{
						flex: 1,
						marginLeft: "auto",
						marginRight: "auto",
						flexDirection: "row",
						height: this.state.screenHeight - 45,
						width: this.state.screenWidth,
					}}>
					<ScrollView
						showsVerticalScrollIndicator={false}
						contentContainerStyle={{}}>
						<View
							// onMouseEnter={() => this.setState({ hover: true })}
							// onMouseLeave={() => this.setState({ hover: false })}
							style={{
								// height: this.state.screenHeight - 45,
								flex: 1,
								zIndex: 999,
								position: "absolute",

								width: "100%",
								alignItems: "left",
								padding: 10,
								// borderRadius: 20,

								backgroundColor: APP_COLOURS.BG2,
							}}>
							<View style={{ flex: 1 }}>
								<View
									style={{
										alignItems: "center",
										justifyContent: "center",
										width: "100%",
									}}>
									<View style={{ width: "100%" }}>
										<View
											style={{
												marginVertical: 20,
											}}>
											<Text
												style={{
													fontFamily: "Gotham",
													fontSize: 15,
													color: APP_COLOURS.TEXTCOLOR,
													fontWeight: "300",
													opacity: 0.5,
													paddingLeft: 20,
												}}>
												Notice board
											</Text>
										</View>

										{[
											{
												id: "1234",
												title: "Mandatory Policy Change",
												dateCreated: "04/01/2024",
												text: `
										I trust this letter finds each of you all well.
										As our organization continues to evolve and adapt to new challenges, we have recently undergone significant restructuring and experienced an increase in workflow. These changes are vital to our ongoing success and necessitates a corresponding adjustment in our operational approach.
										
										To foster a more cohesive and efficient work environment, we have decided to implement a mandatory in-office work schedule for all staff members. Effective immediately, our standard working hours will be from 8:00 AM to 5:00 PM, Monday through Friday. This decision was made with careful consideration, recognizing the value of direct collaboration and communication among team members. Working side by side will enhance our ability to monitor performance, streamline our workflow, and strengthen our team dynamics.
										
										In addition to this change, we are also introducing a mandatory protocol regarding the use of our Project Management Dashboard. It is now required for all employees to begin their day by logging into the dashboard, continuously manage and update tasks throughout the day, and conclude their day with a final update. This tool is essential for tracking our collective progress, managing resources effectively, and ensuring transparency across all projects.
										
										Please understand that adherence to this system is crucial. Failure to update the Project Management Dashboard consistently will be viewed as a performance issue and may result in formal performance review actions. It is important to recognize that consistent use of the dashboard is not only about individual accountability but also about contributing to the overall efficiency and success of our team.
										
										We appreciate your understanding and cooperation as we implement these changes. Our goal is to create an environment that maximizes productivity and fosters a strong, collaborative team culture. We are confident that these adjustments will contribute significantly to our shared goals and the overall success of our organization.
										
										Should you have any questions or concerns regarding these new policies, please feel free to reach out to your manager. We are here to support you through this transition and ensure a smooth adaptation to these new practices.

										Thank you for your continued dedication and hard work. Together, we will continue to achieve great things.
`,
												image: "https://cdn.discordapp.com/attachments/1107650389060374551/1192524811168137296/kalel0568_social_media_and_content_creation_team_illustration_p_cd6d0998-5462-4722-aea7-80e8e135afd2.png?ex=65a96447&is=6596ef47&hm=d027ca70d49ec5b3ecf005626261af3cdbf9d372496ac7bbbc1a3b79384d4a03&",
												writtenBy: {
													first_name: "Wesley",
													last_name: "Cheia",
													avatar: 47,
												},
												bgColor: "#090D13",
												mode: "Dark",
											},
											{
												id: "ewfsefv",
												title: "Welcoming 2024 - A Year of Opportunities and Growth!",
												dateCreated: "01/01/2024",
												text: `
										Happy New Year! As we step into 2024, I want to extend my warmest compliments of the season to each one of you. It's a time for new beginnings, and I am thrilled to embark on this journey with a team as dedicated and talented as ours.

Reflecting on the past year, we've experienced significant growth as a company. This expansion has not only been a testament to our collective efforts but has also introduced unique challenges that we've navigated together with resilience and adaptability.

One of the most notable changes has been the restructuring of our teams, new clients onboarded and the integration of numerous new projects and functions within the business. These changes are pivotal in shaping our path forward and demonstrate our capability to evolve and thrive in a dynamic environment.

With growth comes responsibility - Please check for Urgent & pending tasks on the project management dashboard. The dashboard is now available for mobile browsers: https://skrum.tetrice.co.za.
There are some limited functionalities but we will continue to make improvements. The iOS and Android apps will be distributed later in the year as our dev teams are inundated with other projects to complete. 

As some of you may already know, Early in December Wouter was in a horrific accident and has been hospitalised. He is in stable condition but will require allot of your prayers and well wishes. If any of you wish to know his status, please don't hesitate to reach out. 

Thank you for your ongoing dedication and hard work. Here's to a prosperous and fulfilling year ahead!`,
												image: "https://cdn.discordapp.com/attachments/1107650389060374551/1192544793503486152/kalel0568_social_media_and_content_creation_team_celebrating_ne_dfe9891c-7e84-47b4-ab5d-5bb4ffef4aed.png?ex=65a976e3&is=659701e3&hm=80343a4d548b8df688c1d44c160cb17743d2a53878ab160ac8a4696a950300e7&",

												writtenBy: {
													first_name: "Wesley",
													last_name: "Cheia",
													avatar: 47,
												},
												bgColor: "#090D13",
												mode: "Dark",
											},
										].map((d) => (
											<TouchableOpacity
												key={d.id}
												style={{
													width: "100%",
													borderRadius: 20,
													marginBottom: 10,
													// flexDirection: "row",
													backgroundColor: d.bgColor,
													overflow: "hidden",
													// minHeight: 170,
													height: 160,
												}}
												onPress={() => {
													this.setState({
														showWhitePaper: d,
													});

													sendDiscordMessage(
														`${this.state.user_data.first_name} ${this.state.user_data.last_name}`,
														`[Notice Opened]:
- ${d.title}
`,
														`${
															window.location
																.origin
														}${
															Avatars[
																this.state
																	.user_data
																	.avatar
															].pp
														}`,
														"0QPZYrY6zpAnJXqX9gQl",
													);
												}}>
												<View
													style={{
														flex: 1,
														// padding: 7,
														alignItems: "center",
														justifyContent:
															"center",
													}}>
													<View
														style={{
															position:
																"absolute",
															right: -50,
															opacity: 0.5,
														}}>
														<Image
															source={d.image}
															style={{
																width:
																	window.innerWidth *
																	0.7,
																height: 220,
																resizeMode:
																	"cover",
															}}
														/>
													</View>

													<View
														style={{
															position:
																"absolute",
															right: 20,
														}}>
														<Image
															source={require("./WhitePapers/skrum_menu.png")}
															style={{
																width: 100,
																height: 100,
																resizeMode:
																	"cover",
															}}
														/>
													</View>
													<View
														style={{
															flex: 1,
															width: "100%",
															padding: 7,
														}}>
														<View
															style={{
																width: "100%",
																height: "auto",
																padding: 7,
																alignItems:
																	"center",
																borderRadius: 10,
																// minHeight: 70,
																// marginBottom: 10,
																flexDirection:
																	"row",
																borderColor:
																	"#C5C5C5",
																opacity: 0.9,
															}}>
															<Image
																source={
																	Avatars[
																		parseInt(
																			d
																				.writtenBy
																				.avatar,
																		)
																	].pp
																}
																style={{
																	width: 30,
																	height: 30,
																	borderRadius: 25,
																	resizeMode:
																		"contain",
																	backgroundColor:
																		"#FFF",
																	borderWidth: 0.3,
																	borderColor:
																		"#C5C5C5",
																	shadowColor:
																		"#ECF4FF",
																	shadowOffset:
																		{
																			width: 0,
																			height: 8,
																		},
																	shadowOpacity: 0.44,
																	shadowRadius: 10.32,

																	elevation: 16,
																}}
															/>
															<View
																style={{
																	padding: 5,
																	borderRadius: 10,
																	alignItems:
																		"center",
																	flexDirection:
																		"column",
																	flex: 1,
																	alignItems:
																		"flex-start",
																}}>
																<Text
																	style={{
																		fontFamily:
																			"Gotham",
																		fontSize: 12,
																		fontWeight:
																			"600",
																		color: APP_COLOURS.WHITE,
																	}}>
																	{
																		d
																			.writtenBy
																			.first_name
																	}{" "}
																	{
																		d
																			.writtenBy
																			.last_name
																	}
																</Text>
															</View>
														</View>
														<View
															style={{
																flex: 1,
																padding: 5,
																marginLeft: 9,
															}}>
															<Text
																style={{
																	fontFamily:
																		"Gotham",
																	fontSize: 16,
																	color:
																		d.mode ===
																		"Dark"
																			? APP_COLOURS.TEXTCOLOR
																			: APP_COLOURS.BG3,
																	fontWeight:
																		"300",
																	width: "80%",
																	flexWrap:
																		"wrap",
																}}>
																{d.title}
															</Text>

															<Text
																style={{
																	fontFamily:
																		"Gotham",
																	fontSize: 9,
																	color:
																		d.mode ===
																		"Dark"
																			? APP_COLOURS.TEXTCOLOR
																			: APP_COLOURS.BG3,
																	fontWeight:
																		"300",
																}}>
																{moment(
																	d.dateCreated,
																	"DD/MM/YYYY",
																).fromNow()}
															</Text>
															<Text
																style={{
																	fontFamily:
																		"Gotham",
																	fontSize: 11,
																	color:
																		d.mode ===
																		"Dark"
																			? APP_COLOURS.TEXTCOLOR
																			: APP_COLOURS.BG3,
																	fontWeight:
																		"300",
																	maxHeight: 60,
																	width: "70%",
																	flexWrap:
																		"wrap",
																}}>
																{d.text.slice(
																	0,
																	300,
																)}
															</Text>
															<View
																style={{
																	position:
																		"absolute",
																	right: 0,
																	bottom: 0,
																}}>
																<Image
																	source={require("./WhitePapers/fade.png")}
																	style={{
																		width: window.innerWidth,
																		height: 100,
																		resizeMode:
																			"cover",
																	}}
																/>
															</View>
														</View>

														{/* <View
												style={{
													flex: 1,
													padding: 5,
													display: this
														.state.hover
														? "flex"
														: "none",
													marginLeft: 9,
												}}>
												<Text
													style={{
														fontFamily:
															"Gotham",
														fontSize: 12,
														color:
															d.mode ===
															"Dark"
																? APP_COLOURS.TEXTCOLOR
																: APP_COLOURS.BG3,
														fontWeight:
															"900",
													}}>
													tetrice
												</Text>
											</View> */}
													</View>
												</View>
												<View
													style={{
														position: "absolute",
														right: -20,
														bottom: -2,
													}}>
													<Image
														source={require("./WhitePapers/fade.png")}
														style={{
															width: window.innerWidth,
															height: 100,
															resizeMode: "cover",
														}}
													/>
												</View>
											</TouchableOpacity>
										))}
									</View>
									{this.state.showWhitePaper !== "" ? (
										<Modal
											animationType='fade'
											transparent={true}
											visible={true}>
											<View
												style={{
													backgroundColor:
														APP_COLOURS.BG2,
													flex: 1,
												}}>
												<View
													style={{
														width: "100%",

														padding: 10,
														borderTopLeftRadius: 20,
														backgroundColor: "#000",
													}}>
													<TouchableOpacity
														onPress={() =>
															this.setState({
																showWhitePaper:
																	"",
															})
														}>
														<Image
															source={require("../../assets/images/close.png")}
															style={{
																width: 25,
																height: 25,
																resizeMode:
																	"contain",
															}}
														/>
													</TouchableOpacity>
												</View>
												<WhitePaper
													data={
														this.state
															.showWhitePaper
													}
												/>
											</View>
										</Modal>
									) : null}

									<PreviewUsers />
								</View>

								<TetriceCalendarMobile
									{...this.state}
									screenHeight={this.state.screenHeight}
									screenWidth={this.state.screenWidth}
									logOut={this.logOut.bind(this)}
								/>
							</View>
						</View>
					</ScrollView>
				</View>
			);
		} else {
			return (
				<View
					style={{
						padding: 10,
						paddingTop: 200,
					}}>
					<View
						style={{
							marginVertical: 20,
							alignItems: "center",
							justifyContent: "center",
							width: "100%",

							padding: 10,
						}}>
						<Text
							style={{
								fontFamily: "Gotham",
								fontSize: 18,
								color: "#FFF",
								fontWeight: "500",
								marginVertical: 20,
							}}>
							Welcome to SKrum
						</Text>
						<Text
							style={{
								fontFamily: "Gotham",
								fontSize: 15,
								color: "#FFF",
								fontWeight: "300",
								// maxWidth: 250,
								textAlign: "center",
							}}>
							Our very own custom build project management
							software.
						</Text>
						<Text
							style={{
								fontFamily: "Gotham",
								fontSize: 15,
								color: APP_COLOURS.ORANGE,
								fontWeight: "300",
								// maxWidth: 250,
								textAlign: "center",
								marginVertical: 20,
							}}>
							Your currently logged in as a Client.
						</Text>
						<Text
							style={{
								fontFamily: "Gotham",
								fontSize: 15,
								color: APP_COLOURS.ORANGE,
								fontWeight: "300",
								// maxWidth: 250,
								textAlign: "center",
								marginVertical: 20,
							}}>
							To view the projects assigned to you, press the
							projects tab.
						</Text>
					</View>

					{/* <TeamOverview
						{...this.state}
						logOut={this.logOut.bind(this)}
					/> */}
				</View>
			);
		}
	};
}

export const withNavigation = (Component: Component) => {
	return (props) => <Component {...props} navigate={useNavigate()} />;
};

export default withNavigation(index);
