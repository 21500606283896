import React, { Component } from "react";
import { useNavigate } from "react-router-dom";
import {
	View,
	Text,
	Dimensions,
	Image,
	TouchableOpacity,
	ScrollView,
	Modal,
} from "react-native";
import moment from "moment";
import Fade from "react-reveal/Fade";
import Font from "react-font";
import { APP_COLOURS, StatusColour } from "../APP_VARS";
import female from "./../../assets/images/female.png";
import traqQ from "./../../assets/images/logo512.png";
import close from "./../../assets/images/close.png";
// import logoHalf from "./../../assets/images/logoHalf.svg";
import logoHalf from "./../../assets/images/menu.png";
import logoFull from "./../../assets/images/logoTetrice.png";

import Profile from "./Profile";
import OrganisationAcount from "./OrganisationAccount/OrganisationAcount";
import TopTabs from "./TopTabs";

// import TechSupport from "./TechSupport/index";
import techSupportIcon from "./TechSupport/techSupport.svg";

import BrandCrud from "./CrudBrand";
// import MallsCrud from "./CrudMalls";
// import RetailerCrud from "./CrudRetailer";
import BrandTabs from "./BrandTabs";
import ProjectManager from "./DailyPlanner/ProjectManagerMobile";
import { Avatars } from "../APP_VARS";
import OrganisationServices from "./ServicesOverview/OrganisationServices";
import TeamOverview from "./TeamOverview";
import Usernamanement from "./UserManagement/usermanagement";
import ContentCalendar from "./ContentCalendar";
import SentimentTracker from "./SentimentTracker";
import GrowthTracker from "./GrowthTracker";
import TetriceCalendar from "./CalendarTetrice";
import ActivityLog from "./ActivityLog";
import StatusModal from "./StatusModal";
import { Timer } from "./TImerCompnent";
import AttendanceRecord from "./AttendanceRecord";
import ProposalReport from "./PresentationReport/ProposalReport";
import MerchandisingIndex from "./Merchandising";
import TetriceCalendarMobile from "./CalendarTetrice/TetriceCalendarMobile";
import AutoHeightImage from "./AutoHeightImageComp";
import WhitePaper from "./WhitePapers/WhitePaper";
import PreviewUsers from "./UserManagement/PreviewUsers";
import { sendDiscordMessage } from "../CRUDHELPER";
import NoticeBoardComp from "./NoticeBoardComp";

const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").height;

class index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user_data: "",
			email: "",
			accessToken: "",
			refresh_token: "",
			currentModal: "",
			screenWidth: screenWidth,
			screenHeight: screenHeight,
			authenticating: true,
			validated: true,
			hover: true,
			currentPage: "",
			selectedBrand: "",
			selectedBrandTab: {
				id: "",
				title: "All",
			},
			pages: [
				{
					title: "Projects",
					page: "projects",
					img: require("./../../assets/images/icons/gantt_icon.png"),
					permission: [],
				},

				// {
				// 	title: "User management",
				// 	page: "usermanagement",
				// 	img: require("./../../assets/images/icons/MeetingNotes.png"),
				// 	permission: ["wesley@tetrice.co.za"],
				// },

				// {
				// 	title: "Sentiment tracker",
				// 	page: "sentracker",
				// 	img: require("./../../assets/images/icons/todoList.png"),
				// 	permission: [],
				// },
				// {
				// 	title: "Growth tracker",
				// 	page: "smtracker",
				// 	img: require("./../../assets/images/icons/todoList.png"),
				// 	permission: [],
				// },
				// {
				// 	title: "Calendar",
				// 	page: "tetriceCalendar",
				// 	img: require("./../../assets/images/icons/MeetingNotes.png"),
				// 	permission: [],
				// },
			],

			showWhitePaper: "",
		};
	}

	componentDidMount() {
		this.validateSession();
		window.addEventListener("resize", this.resize.bind(this));
		this.resize();
	}

	validateSession = async () => {
		let user_data = await localStorage.getItem("user_data");
		let email = await localStorage.getItem("email");
		let accessToken = await localStorage.getItem("accessToken");
		let refresh_token = await localStorage.getItem("refresh_token");

		if (accessToken !== null && accessToken !== "") {
			await this.setState({
				user_data: JSON.parse(user_data),
				email,
				accessToken,
				refresh_token,
			});
			setTimeout(() => {
				this.setState({
					loading: false,
					authenticating: false,
				});
			}, 1000);
		} else {
			this.props.navigate("/");
			window.location.href = "/";
			localStorage.clear();
		}
	};

	componentDidUpdate() {}

	resize() {
		this.setState({
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
		});
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resize.bind(this));
	}

	logOut = async () => {
		await localStorage.clear();
		await localStorage.clear();
		this.props.navigate("/");
		// return <Navigate to='/' />;
	};

	render() {
		let w = this.state.screenWidth;
		let h = this.state.screenHeight;
		let minWidth = 900;
		// let align = 'right'
		if (!this.state.authenticating) {
			return (
				<View
					style={{
						// overflow: "hidden",
						// // backgroundColor: "#E0E3EB",
						// backgroundColor: APP_COLOURS.BG2,
						// position: "sticky",
						// top: 0,
						// width: w,
						// // minWidth: minWidth,
						// height: h,
						// // paddingBottom: 15,

						flex: 1,
						backgroundColor: APP_COLOURS.BG2,
						height: "100%",
						overflow: "hidden",
					}}>
					<View
						style={{
							flexDirection: "row",
							backgroundColor: APP_COLOURS.BG2,
							justifyContent: "space-between",
							padding: 5,
							alignItems: "center",
						}}>
						<TouchableOpacity
							onPress={() =>
								this.setState({
									hover: !this.state.hover,
									currentPage: "",
								})
							}>
							<View
								style={{
									// height: 90,
									justifyContent: "flex-start",
									paddingTop: 5,
									paddingLeft: 10,
								}}>
								<View
									style={{
										flexDirection: "row",
										alignItems: "center",
									}}>
									<Image
										source={require("../../assets/images/icons/skrum_menu.png")}
										style={{
											width: 30,
											height: 30,
											resizeMode: "contain",
											marginRight: 3,
										}}
									/>
									<Image
										source={require("../../assets/images/skrum_logo.png")}
										style={{
											width: 110,
											height: 35,
											resizeMode: "contain",
											marginRight: 7,
										}}
									/>
								</View>
							</View>
						</TouchableOpacity>
						<View
							style={{
								display:
									this.state.currentPage === "profile"
										? "none"
										: "flex",
							}}>
							<TouchableOpacity
								style={{
									width: "100%",
									height: "auto",
									padding: 7,
									alignItems: "center",
									borderRadius: 10,
									// minHeight: 70,
									// marginBottom: 10,
									flexDirection: "row",
								}}
								onPress={() => {
									// this.logOut();
									this.setState({
										currentPage: "profile",
										hover: false,
									});
								}}>
								<Image
									source={
										Avatars[
											parseInt(
												this.state.user_data.avatar,
											)
										].pp
									}
									style={{
										width: 34,
										height: 34,
										borderRadius: 25,
										resizeMode: "contain",
										backgroundColor: "#FFF",
										borderColor: StatusColour(
											this.state.user_data.status,
										),
										// borderColor: "#C5C5C5",
										borderWidth: 2,
										// shadowColor: StatusColour(
										// 	this.state.user_data.status,
										// ),
										// shadowOffset: {
										// 	width: 0,
										// 	height: 8,
										// },
										// shadowOpacity: 0.44,
										// shadowRadius: 10.32,
										// elevation: 16,
									}}
								/>
								<View
									style={{
										// padding: 5,
										marginLeft: 5,
										borderRadius: 10,
										alignItems: "center",
										flexDirection: "column",
										flex: 1,
										// alignItems: 'flex-end',
										alignItems: "flex-start",
										display: this.state.hover
											? "flex"
											: "none",
									}}>
									<Text
										style={{
											fontFamily: "Gotham",
											fontSize: 12,
											fontWeight: "600",
											color: APP_COLOURS.WHITE,
										}}>
										{this.state.user_data.first_name}{" "}
										{this.state.user_data.last_name}
									</Text>
									<Text
										style={{
											fontFamily: "Gotham",
											fontSize: 10,
											opacity: 0.8,
											color: APP_COLOURS.WHITE,
										}}>
										{this.state.user_data.username}
									</Text>
								</View>
							</TouchableOpacity>
						</View>
					</View>
					{this.state.hover ? this.tetriceHome() : null}

					<Timer
						onTimeout={async () => {
							let lastUpdated = await localStorage.getItem(
								"lastUpdated",
							);
							console.log(lastUpdated);
							console.log(
								moment(lastUpdated)
									.add(30, "minutes")
									.isBefore(moment()),
							);
							if (
								typeof lastUpdated === "undefined" ||
								lastUpdated === null ||
								moment(lastUpdated)
									.add(15, "minutes")
									.isBefore(moment())
							) {
								await this.setState({
									currentModal: "",
								});
								await this.setState({
									currentModal: "StatusModal",
								});
								await console.log("timer");
							}
						}}
					/>

					<View
						style={{
							flex: 1,
							display: !this.state.hover ? "flex" : "none",
						}}>
						{this.state.currentPage === "profile" ? (
							<Profile logOut={this.logOut.bind(this)} />
						) : (
							<View />
						)}

						{this.state.currentPage === "projects" ? (
							<View
								style={{
									flex: 1,
								}}>
								{/* <BrandTabs
										{...this.state}
										selectedSubPage={(d) =>
											this.setState({
												selectedBrand: d,
											})
										}
									/> */}
								{/* <TopTabs
										{...this.state}
										screenHeight={this.state.screenHeight}
										screenWidth={this.state.screenWidth}
										selectedSubPage={(d) =>
											this.setState({
												subPage: d,
											})
										}
										tabs={["REVLON"]}
									/> */}

								<ProjectManager {...this.state} />

								{/* <ProductManagement
										{...this.state}
										selectedBrand={this.state.selectedBrand}
										screenHeight={this.state.screenHeight}
										screenWidth={this.state.screenWidth}
										logOut={this.logOut.bind(this)}
									/> */}
							</View>
						) : (
							<View />
						)}

						{this.state.currentPage === "services" ? (
							<View
								style={{
									flex: 1,
								}}>
								<OrganisationServices
									{...this.state}
									logOut={this.logOut.bind(this)}
								/>
							</View>
						) : (
							<View />
						)}

						{this.state.currentPage === "teamtetrice" ? (
							<View
								style={{
									flex: 1,
								}}>
								<TeamOverview
									{...this.state}
									logOut={this.logOut.bind(this)}
								/>
							</View>
						) : (
							<View />
						)}

						{this.state.currentPage === "settings" ? (
							<View
								style={{
									flex: 1,
								}}>
								{/* <BrandTabs
										{...this.state}
										selectedSubPage={(d) =>
											this.setState({
												selectedBrand: d,
											})
										}
									/> */}
								{/* <TopTabs
										{...this.state}
										selectedSubPage={(d) =>
											this.setState({
												subPage: d,
											})
										}
										tabs={["REVLON"]}
									/> */}

								<BrandCrud
									{...this.state}
									logOut={this.logOut.bind(this)}
								/>
							</View>
						) : (
							<View />
						)}

						{this.state.currentPage === "sentracker" ? (
							<SentimentTracker
								{...this.state}
								screenHeight={this.state.screenHeight}
								screenWidth={this.state.screenWidth}
								logOut={this.logOut.bind(this)}
							/>
						) : (
							<View />
						)}

						{this.state.currentPage === "contentcalendar" ? (
							<ContentCalendar
								{...this.state}
								screenHeight={this.state.screenHeight}
								screenWidth={this.state.screenWidth}
								logOut={this.logOut.bind(this)}
								user_data={this.state.user_data}
							/>
						) : (
							<View />
						)}
						{/* {console.log(this.state.user_data)} */}
						{this.state.currentPage === "usermanagement" ? (
							<Usernamanement
								{...this.state}
								screenHeight={this.state.screenHeight}
								screenWidth={this.state.screenWidth}
								logOut={this.logOut.bind(this)}
							/>
						) : (
							<View />
						)}

						{this.state.currentPage === "merchandising" ? (
							<MerchandisingIndex
								{...this.state}
								screenHeight={this.state.screenHeight}
								screenWidth={this.state.screenWidth}
								logOut={this.logOut.bind(this)}
							/>
						) : (
							<View />
						)}

						{this.state.currentPage === "organisationaccount" ? (
							<OrganisationAcount
								{...this.state}
								screenHeight={this.state.screenHeight}
								screenWidth={this.state.screenWidth}
								logOut={this.logOut.bind(this)}
							/>
						) : (
							<View />
						)}

						{this.state.currentPage === "smtracker" ? (
							<GrowthTracker
								{...this.state}
								screenHeight={this.state.screenHeight}
								screenWidth={this.state.screenWidth}
								logOut={this.logOut.bind(this)}
							/>
						) : (
							<View />
						)}

						{this.state.currentPage === "tetriceCalendar" ? (
							<TetriceCalendar
								{...this.state}
								screenHeight={this.state.screenHeight}
								screenWidth={this.state.screenWidth}
								logOut={this.logOut.bind(this)}
							/>
						) : (
							<View />
						)}

						{this.state.currentPage === "activityLog" ? (
							<View
								style={{
									flex: 1,
									padding: 10,
								}}>
								<View
									style={{
										padding: 10,
									}}>
									<Text
										style={{
											color: APP_COLOURS.BG2,
											fontSize: 28,
											fontWeight: "800",
											fontFamily: "Gotham",
											// marginTop: 20,
											// marginTop: 6,
										}}>
										All activity logs
									</Text>
								</View>
								<ActivityLog
									projectID={""}
									{...this.state}
									screenHeight={this.state.screenHeight}
									screenWidth={this.state.screenWidth}
									logOut={this.logOut.bind(this)}
								/>
								<AttendanceRecord
									{...this.state}
									screenHeight={this.state.screenHeight}
									screenWidth={this.state.screenWidth}
									logOut={this.logOut.bind(this)}
								/>
							</View>
						) : (
							<View />
						)}

						{this.state.currentPage === "reports" ? (
							<ProposalReport
								{...this.state}
								screenHeight={this.state.screenHeight}
								screenWidth={this.state.screenWidth}
								logOut={this.logOut.bind(this)}
							/>
						) : (
							<View />
						)}

						{this.state.currentPage === "" ? (
							<div
								style={{
									flex: 1,
									backgroundColor: APP_COLOURS.BG2,
									width: w,
									height: h,
									justifyContent: "center",
									alignItems: "center",
								}}>
								<View
									style={{
										width: w,
										height: h,
										justifyContent: "center",
										alignItems: "center",
										padding: 10,
										paddingBottom: 50,
									}}>
									<View
										style={{
											flexDirection: "row",
											alignItems: "center",
										}}>
										<Image
											source={require("../../assets/images/icons/skrum_menu.png")}
											style={{
												width: 30,
												height: 30,
												resizeMode: "contain",
												// marginRight: 3,
											}}
										/>
										<Image
											source={require("../../assets/images/skrum_logo.png")}
											style={{
												width: 95,
												height: 50,
												resizeMode: "contain",
											}}
										/>
									</View>
								</View>
							</div>
						) : null}
					</View>

					{this.techSupportModal()}

					{this.state.currentModal === "StatusModal" ? (
						<StatusModal
							screenWidth={w}
							screenHeight={h}
							user_data={this.state.user_data}
							accepted={(b, s) => {
								let user_data = JSON.parse(
									localStorage.getItem("user_data"),
								);
								this.setState({
									statusUpdated: b,
									status: s,
									user_data: user_data,
								});
								// this.onLoadProcess();
								setTimeout(() => {
									this.setState({
										currentModal: "",
									});
								}, 300);
							}}
							avatarComp={null}
						/>
					) : null}
				</View>
			);
		} else {
			return (
				<div
					style={{
						flex: 1,
						backgroundColor: APP_COLOURS.BG2,
						width: w,
						height: h,
						justifyContent: "center",
						alignItems: "center",
					}}>
					<View
						style={{
							width: w,
							height: h,
							justifyContent: "center",
							alignItems: "center",
							padding: 10,
							paddingBottom: 50,
						}}>
						<View
							style={{
								flexDirection: "row",
								alignItems: "center",
							}}>
							<Image
								source={require("../../assets/images/icons/skrum_menu.png")}
								style={{
									width: 30,
									height: 30,
									resizeMode: "contain",
									// marginRight: 3,
								}}
							/>
							<Image
								source={require("../../assets/images/skrum_logo.png")}
								style={{
									width: 95,
									height: 50,
									resizeMode: "contain",
								}}
							/>
						</View>
					</View>
				</div>
			);
		}
	}

	techSupportModal = () => {
		let sw = this.state.screenWidth;
		let sh = this.state.screenHeight;
		sw = sw > 600 ? 600 : sw - 30;
		sh = sh > 800 ? 800 : sh - 60;
		let align = "right";
		return (
			<>
				{!this.state.viewTechSupport ? (
					<View
						onMouseEnter={() => this.setState({ tshover: true })}
						onMouseLeave={() => this.setState({ tshover: false })}
						style={{
							position: "absolute",
							zIndex: 99,
							bottom: this.state.currentPage === "" ? 18 : 3,
							[align]: 14,
							// alignItems: "flex-end",
							justifyContent: "center",
							// paddingRight: 15,
							// shadowColor: "#000",
							// shadowOffset: { width: 0, height: 8 },
							// shadowOpacity: 0.44,
							// shadowRadius: 9.32,
							// elevation: 3,
							// borderRadius: 40,
							// borderTopRightRadius: 15,
							// backgroundColor: "transparent",
						}}>
						{/* <TouchableOpacity
							onPress={() =>
								this.setState({
									viewTechSupport: true,
								})
							}>
							<Image
								source={techSupportIcon}
								style={{
									width:
										this.state.currentPage === "" ||
										this.state.tshover
											? 90
											: 45,
									height:
										this.state.currentPage === "" ||
										this.state.tshover
											? 90
											: 45,
									resizeMode: "contain",
									// shadowColor: "#000",
									// shadowOffset: { width: 0, height: 8 },
									// shadowOpacity: 0.44,
									// shadowRadius: 9.32,
									// elevation: 3,
								}}
							/>
						</TouchableOpacity> */}
					</View>
				) : (
					<View
						style={{
							width: this.state.screenWidth,
							height: this.state.screenHeight,
							zIndex: 999,
							position: "absolute",
							left: 0,
							top: 0,
						}}>
						<TouchableOpacity
							style={{
								width: this.state.screenWidth,
								height: this.state.screenHeight,
							}}
							onPress={() =>
								this.setState({
									viewTechSupport: false,
								})
							}>
							<View
								style={{
									// flex: 1,
									justifyContent: "center",
									alignItems: "center",
									backgroundColor: "rgba(52, 52, 52, 0.5)",
									width: this.state.screenWidth,
									height: this.state.screenHeight,
								}}></View>
						</TouchableOpacity>

						<View
							style={{
								width: sw,
								height: sh,
								position: "absolute",
								bottom: 60,
								[align]: 18,
								alignItems: "center",
								justifyContent: "center",
								position: "absolute",
								// backgroundColor: "#864AE0",
								backgroundColor: APP_COLOURS.BACKGROUND,
								borderRadius: 18,
								shadowColor: "#000",
								shadowOffset: { width: 0, height: 8 },
								shadowOpacity: 0.44,
								shadowRadius: 9.32,
								elevation: 10,
							}}>
							<View
								style={{
									position: "absolute",
									zIndex: 99,
									top: -15,
									left: -15,
									marginLeft: "auto",
									marginRight: "auto",
									borderRadius: 20,
									backgroundColor: "#FFF",
								}}>
								<TouchableOpacity
									onPress={() =>
										this.setState({
											viewTechSupport: false,
											tshover: false,
										})
									}>
									<Image
										source={close}
										style={{
											width: 30,
											height: 30,
											resizeMode: "contain",
										}}
									/>
								</TouchableOpacity>
							</View>

							{/* <TechSupport
								screenHeight={sh}
								screenWidth={sw}
								user_data={this.state.user_data}
								logOut={this.logOut.bind(this)}
							/> */}
						</View>
					</View>
				)}
			</>
		);
	};

	tetriceHome = () => {
		if (this.state.user_data.permission === "tetrice") {
			return (
				<View
					style={{
						flex: 1,
						marginLeft: "auto",
						marginRight: "auto",
						flexDirection: "row",
						height: this.state.screenHeight - 45,
						width: this.state.screenWidth,
					}}>
					<ScrollView
						showsVerticalScrollIndicator={false}
						contentContainerStyle={{}}>
						<View
							// onMouseEnter={() => this.setState({ hover: true })}
							// onMouseLeave={() => this.setState({ hover: false })}
							style={{
								// height: this.state.screenHeight - 45,
								flex: 1,
								zIndex: 999,
								position: "absolute",

								width: "100%",
								alignItems: "left",
								padding: 10,
								// borderRadius: 20,

								backgroundColor: APP_COLOURS.BG2,
							}}>
							<View style={{ flex: 1, paddingBottom: 50 }}>
								<View
									style={{
										alignItems: "center",
										justifyContent: "center",
										width: "100%",
									}}>
									{/* <TouchableOpacity
						style={{
							width: "100%",
							height: "auto",
							padding: 7,
							alignItems: "center",
							justifyContent: "center",
							borderRadius: 10,
							minHeight: 45,
							marginBottom: 10,
							flexDirection: "row",
							backgroundColor: APP_COLOURS.TRANSHALO,
						}}
						onPress={() =>
							this.setState({
								currentPage: "settings",
							})
						}>
						<View
							style={{
								width: 30,
								height: 30,
								alignItems: "center",
								justifyContent: "center",
								paddingLeft: 7,
							}}>
							<Image
								source={require("./../../assets/images/icons/settings.png")}
								style={{
									width: 28,
									height: 28,
									resizeMode: "contain",
								}}
							/>
						</View>

						<View
							style={{
								flex: 1,
								padding: 5,
								display: this.state.hover
									? "flex"
									: "none",
								marginLeft: 9,
							}}>
							<Text
								style={{
									fontFamily: "Gotham",
									fontSize: 12,
									color: APP_COLOURS.TEXTCOLOR,
									fontWeight: "300",
								}}>
								Settings
							</Text>
						</View>
					</TouchableOpacity> */}
									{/* <TouchableOpacity
								style={{
									width: "100%",
									height: "auto",
									padding: 7,
									alignItems: "center",
									justifyContent: "center",
									borderRadius: 10,
									minHeight: 45,
									marginBottom: 10,
									flexDirection: "row",
									backgroundColor:
										APP_COLOURS.TRANSHALO,
								}}
								onPress={() =>
									this.setState({
										currentPage: "teamtetrice",
										hover: false,
									})
								}>
								<View
									style={{
										width: 30,
										height: 30,
										alignItems: "center",
										justifyContent: "center",
										paddingLeft: 7,
									}}>
									<Image
										source={require("./../../assets/images/menu.png")}
										style={{
											width: 28,
											height: 28,
											resizeMode: "contain",
										}}
									/>
								</View>

								<View
									style={{
										flex: 1,
										padding: 5,
										display: this.state.hover
											? "flex"
											: "none",
										marginLeft: 9,
									}}>
									<Text
										style={{
											fontFamily: "Gotham",
											fontSize: 12,
											color: APP_COLOURS.TEXTCOLOR,
											fontWeight: "900",
										}}>
										tetrice
									</Text>
								</View>
							</TouchableOpacity> */}
									<NoticeBoardComp
										user_data={this.state.user_data}
									/>

									<PreviewUsers />
								</View>

								{this.state.pages
									.filter((m) => {
										if (m.permission.length > 0) {
											if (
												m.permission.includes(
													this.state.user_data
														.username,
												)
											) {
												return m;
											} else {
												return;
											}
										} else {
											return m;
										}
									})
									.map((d, i) => (
										<View
											key={i}
											style={{
												alignItems: "center",
												justifyContent: "center",
												width: "100%",
												marginTop: 10,
											}}>
											<TouchableOpacity
												style={{
													width: "100%",
													height: "auto",
													padding: 7,
													alignItems: "center",
													justifyContent: "center",
													borderRadius: 10,
													minHeight: 45,
													marginBottom: 10,
													// flexDirection: "row",
													// borderWidth:
													// 	this.state.currentPage == d.page
													// 		? 0.3
													// 		: 0,
													// borderColor: "#C5C5C5",
													backgroundColor:
														this.state
															.currentPage ==
														d.page
															? "rgba(13, 109, 251, 0.7)"
															: APP_COLOURS.BG3,
												}}
												onPress={() =>
													this.setState({
														currentPage: d.page,
														hover: false,
													})
												}>
												<View
													style={{
														width: 30,
														height: 30,
														alignItems: "center",
														justifyContent:
															"center",
													}}>
													<Image
														source={d.img}
														style={{
															width: 28,
															height: 28,
															resizeMode:
																"contain",
															// shadowColor: "#000",
															// shadowOffset: {
															// 	width: 0,
															// 	height: 4,
															// },
															// shadowOpacity: 0.23,
															// shadowRadius: 4.65,
															// elevation: 8,
														}}
													/>
												</View>

												<View
													style={{
														flex: 1,
														padding: 5,
														display: this.state
															.hover
															? "flex"
															: "none",
														marginLeft: 9,
													}}>
													<Text
														style={{
															fontFamily:
																"Avenir",
															fontSize: 14,
															color: APP_COLOURS.TEXTCOLOR,
															fontWeight:
																this.state
																	.currentPage ==
																d.page
																	? "700"
																	: "300",
														}}>
														{d.title}
													</Text>
												</View>
											</TouchableOpacity>
										</View>
									))}

								{/* <TetriceCalendarMobile
									{...this.state}
									screenHeight={this.state.screenHeight}
									screenWidth={this.state.screenWidth}
									logOut={this.logOut.bind(this)}
								/> */}
							</View>
						</View>
					</ScrollView>
				</View>
			);
		} else {
			return (
				<View
					style={{
						padding: 10,
					}}>
					<View
						style={{
							marginVertical: 20,
							alignItems: "center",
							justifyContent: "center",
							width: "100%",

							padding: 10,
						}}>
						<Text
							style={{
								fontFamily: "Gotham",
								fontSize: 18,
								color: "#FFF",
								fontWeight: "500",
								marginVertical: 20,
							}}>
							Welcome to SKrum
						</Text>
						<Text
							style={{
								fontFamily: "Gotham",
								fontSize: 15,
								color: "#FFF",
								fontWeight: "300",
								// maxWidth: 250,
								textAlign: "center",
							}}>
							Our very own custom build project management
							software.
						</Text>
						<Text
							style={{
								fontFamily: "Gotham",
								fontSize: 15,
								color: APP_COLOURS.ORANGE,
								fontWeight: "300",
								// maxWidth: 250,
								textAlign: "center",
								marginVertical: 20,
							}}>
							Your currently logged in as a Client.
						</Text>
						<Text
							style={{
								fontFamily: "Gotham",
								fontSize: 15,
								color: APP_COLOURS.ORANGE,
								fontWeight: "300",
								// maxWidth: 250,
								textAlign: "center",
								marginVertical: 20,
							}}>
							To view the projects assigned to you, press the
							projects tab.
						</Text>
					</View>

					{this.state.pages
						.filter((m) => {
							if (m.permission.length > 0) {
								if (
									m.permission.includes(
										this.state.user_data.username,
									)
								) {
									return m;
								} else {
									return;
								}
							} else {
								return m;
							}
						})
						.map((d, i) => (
							<View
								key={i}
								style={{
									alignItems: "center",
									justifyContent: "center",
									width: "100%",
								}}>
								<TouchableOpacity
									style={{
										width: "100%",
										height: "auto",
										padding: 7,
										alignItems: "center",
										justifyContent: "center",
										borderRadius: 10,
										minHeight: 45,
										marginBottom: 10,
										// flexDirection: "row",
										// borderWidth:
										// 	this.state.currentPage == d.page
										// 		? 0.3
										// 		: 0,
										// borderColor: "#C5C5C5",
										backgroundColor:
											this.state.currentPage == d.page
												? "rgba(13, 109, 251, 0.7)"
												: APP_COLOURS.BG3,
									}}
									onPress={() =>
										this.setState({
											currentPage: d.page,
											hover: false,
										})
									}>
									<View
										style={{
											width: 30,
											height: 30,
											alignItems: "center",
											justifyContent: "center",
										}}>
										<Image
											source={d.img}
											style={{
												width: 28,
												height: 28,
												resizeMode: "contain",
												// shadowColor: "#000",
												// shadowOffset: {
												// 	width: 0,
												// 	height: 4,
												// },
												// shadowOpacity: 0.23,
												// shadowRadius: 4.65,
												// elevation: 8,
											}}
										/>
									</View>

									<View
										style={{
											flex: 1,
											padding: 5,
											display: this.state.hover
												? "flex"
												: "none",
											marginLeft: 9,
										}}>
										<Text
											style={{
												fontFamily: "Avenir",
												fontSize: 14,
												color: APP_COLOURS.TEXTCOLOR,
												fontWeight:
													this.state.currentPage ==
													d.page
														? "700"
														: "300",
											}}>
											{d.title}
										</Text>
									</View>
								</TouchableOpacity>
							</View>
						))}

					{/* <TeamOverview
						{...this.state}
						logOut={this.logOut.bind(this)}
					/> */}
				</View>
			);
		}
	};
}

export const withNavigation = (Component: Component) => {
	return (props) => <Component {...props} navigate={useNavigate()} />;
};

export default withNavigation(index);
