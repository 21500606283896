import React, { useState } from "react";
import { View, Text, Image, ScrollView, TouchableOpacity } from "react-native";
import NoticeBoardComp from "./NoticeBoardComp";
import PreviewUsers from "./UserManagement/PreviewUsers";
import { APP_COLOURS, Avatars, StatusColour } from "../APP_VARS";
// import CompanyCard from "./components/CompanyCard";
import ProfilePage from "./Profile";

const TetriceHome = ({ user_data, screenWidth, screenHeight }) => {
	const [selectedCard, setSelectedCard] = useState(null);

	const logOut = () => {
		localStorage.clear();
		window.location.href = "/";
	};

	const cards = [
		{
			id: "projects",
			title: "Projects Overview",
			description: "View and manage all ongoing projects",
			icon: require("../../assets/images/icons/gantt_icon.png"),
			component: () => (
				<View style={{ padding: 20 }}>
					<Text style={{ color: APP_COLOURS.WHITE, fontSize: 18 }}>
						Projects Overview Content
					</Text>
					{/* Add your projects component here */}
				</View>
			),
		},
		{
			id: "calendar",
			title: "Team Calendar",
			description: "Schedule and view team events",
			icon: require("../../assets/images/icons/MeetingNotes.png"),
			component: () => (
				<View style={{ padding: 20 }}>
					<Text style={{ color: APP_COLOURS.WHITE, fontSize: 18 }}>
						Team Calendar Content
					</Text>
					{/* Add your calendar component here */}
				</View>
			),
		},
		{
			id: "resources",
			title: "Resources",
			description: "Access company documents and resources",
			icon: require("../../assets/images/icons/todoList.png"),
			component: () => (
				<View style={{ padding: 20 }}>
					<Text style={{ color: APP_COLOURS.WHITE, fontSize: 18 }}>
						Resources Content
					</Text>
					{/* Add your resources component here */}
				</View>
			),
		},
	];

	return (
		<View
			style={{
				flex: 1,
				flexDirection: "row",
				// backgroundColor: APP_COLOURS.BG2,
				height: screenHeight,
			}}>
			{/* Left Profile Section */}

			{/* Middle Content Section */}
			<ScrollView
				style={{ flex: 1 }}
				contentContainerStyle={{ padding: 20 }}>
				<Text
					style={{
						fontFamily: "Gotham",
						fontSize: 24,
						fontWeight: "600",
						color: APP_COLOURS.WHITE,
						marginBottom: 20,
					}}>
					Company Dashboard
				</Text>

				<View
					style={{ flexDirection: "row", flexWrap: "wrap", gap: 20 }}>
					<View style={{ flex: 1, minWidth: 300 }}>
						<NoticeBoardComp user_data={user_data} />
					</View>

					<View style={{ flex: 1, minWidth: 300 }}>
						<Text
							style={{
								fontFamily: "Gotham",
								fontSize: 20,
								fontWeight: "600",
								color: APP_COLOURS.WHITE,
								marginBottom: 20,
							}}>
							Online
						</Text>
						<PreviewUsers />
					</View>
				</View>

				<View style={{ marginTop: 20 }}>
					{/* {cards.map((card) => (
						<CompanyCard
							key={card.id}
							title={card.title}
							description={card.description}
							icon={card.icon}
							isSelected={selectedCard?.id === card.id}
							onPress={() => setSelectedCard(card)}
						/>
					))} */}
				</View>
			</ScrollView>

			<View
				style={{
					width: 500,
					backgroundColor: APP_COLOURS.BG2,
					padding: 10,
				}}>
				<ProfilePage logOut={logOut} />
			</View>

			{/* Right Panel */}
			{selectedCard && (
				<View
					style={{
						width: 300,
						backgroundColor: APP_COLOURS.BG3,
						borderLeftWidth: 1,
						borderLeftColor: "rgba(255,255,255,0.1)",
					}}>
					{selectedCard.component()}
				</View>
			)}
		</View>
	);
};

export default TetriceHome;
