import React, { Component } from "react";
import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	TextInput,
} from "react-native";
import { APP_COLOURS } from "../../APP_VARS";

export default class TagSelector extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedTags: [], // Array to hold selected tags
			searchQuery: "", // Add search query state
		};
	}

	toggleTag = (tag) => {
		let selectedTags = [...this.state.selectedTags];
		if (selectedTags.includes(tag)) {
			selectedTags = selectedTags.filter((t) => t !== tag); // Remove tag if already selected
		} else {
			selectedTags.push(tag); // Add tag if not selected
			// Call onAdd prop when adding a new tag
			if (this.props.onAdd) {
				this.props.onAdd(tag);
			}
		}
		this.setState({ selectedTags });
	};

	// Add search filter method
	filterTags = (hashtags) => {
		const { searchQuery } = this.state;
		if (!searchQuery) return hashtags;
		return hashtags.filter((tag) =>
			tag.toLowerCase().includes(searchQuery.toLowerCase()),
		);
	};

	renderHashtagButtons() {
		const hashtags = [
			`@edgarsbeauty`,
			`@woolworths_sa`,
			`@superbalist`,
			`@truworthsfashion`,
			`@clicks_sa`,
			`@dischem.beauty`,
			`@dischem_pharmacies`,
			`@friendsofbash`,
			`@wooliesbeauty`,
		];

		const filteredTags = this.filterTags(hashtags);

		return filteredTags.map((d, i) => (
			<TouchableOpacity
				key={i}
				style={{}}
				onPress={() => this.toggleTag(d)}>
				<View
					style={{
						width: "auto",
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: this.state.selectedTags.includes(d)
							? APP_COLOURS.BLUE
							: APP_COLOURS.BLACK_TRANS,
						padding: 10,
						borderRadius: 10,
						marginRight: 5,
					}}>
					<Text
						style={{
							fontSize: 12,
							color: this.state.selectedTags.includes(d)
								? "#FFF"
								: APP_COLOURS.BG2,
							fontWeight: "500",
						}}>
						{d}
					</Text>
				</View>
			</TouchableOpacity>
		));
	}

	render() {
		return (
			<View
				style={{
					borderWidth: 1,
					borderColor: "#000",
					borderRadius: 12,
					padding: 10,
				}}>
				<Text
					style={{
						fontWeight: "400",
						fontFamily: "Gotham",

						fontSize: 12,
						color: APP_COLOURS.BG2,
						marginBottom: 4,
						position: "absolute",
						top: -12,
						left: 9,
						backgroundColor: "#FFF",
						padding: 4,
						paddingVertical: 1,
						borderRadius: 8,
						zIndex: 99,
					}}>
					Tags / mentions
				</Text>

				{/* Add search input */}
				<TextInput
					style={{
						borderWidth: 1,
						borderColor: APP_COLOURS.BLACK_TRANS,
						borderRadius: 8,
						padding: 8,
						marginBottom: 10,
						fontSize: 12,
					}}
					placeholder='Search tags...'
					value={this.state.searchQuery}
					onChangeText={(text) =>
						this.setState({ searchQuery: text })
					}
				/>

				<ScrollView horizontal showsHorizontalScrollIndicator={false}>
					{this.renderHashtagButtons()}
				</ScrollView>
			</View>
		);
	}
}
