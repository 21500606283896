import React, { Component } from "react";
import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	Image,
	TextInput,
	LayoutAnimation,
	Keyboard,
	Alert,
	Modal,
	Animated,
	ActivityIndicator,
	ScrollViewBase,
} from "react-native";
import moment from "moment";

import {
	APP_COLOURS,
	CustomLayoutSpring,
	Avatars,
	AppIcons,
	TaskStatus,
	StatusColour,
	PROJECTSTATUS,
	// SocialIcon,
} from "../../APP_VARS";
// import ProgressBarStages from "./ProgressBar";
// import AutoHeightImage from "../AutoHeightImageComp";
import {
	crudDelete,
	crudUpdate,
	crudCreateWithBase64,
	crudDeleteCalendarImage,
} from "../../CRUDHELPER";
import TimeSelector from "./TimeSelector";
import TagSelector from "./TagSelector";
import HashTagSelector from "./HashTagSelector";
import StrategyTagSelector from "./StrategyTagSelector";
import ImageUploader from "./ImageUploader";

function isOdd(num) {
	return num % 2;
}

export default class ContentCalendarItem extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedImage: null,
			showImageModal: false,
			isEditing: false,
			editedCopy: props.data.copy || "",
			editedDate: props.data.publishDate || new Date(),
			editedHashtags: [...(props.data.hashtags || [])],
			editedTags: [...(props.data.tags || [])],
			showTimeSelector: false,
			showHashtagSelector: false,
			showTagSelector: false,
			tempYear: moment(props.data.publishDate).format("YYYY"),
			tempMonth: moment(props.data.publishDate).format("MM"),
			tempDay: moment(props.data.publishDate).format("DD"),
			editedStrategyTags: [...(props.data.strategyTags || [])],
			showStrategyTagSelector: false,
			showImageUploader: false,
			assets: props.data.assets || [],
		};
	}

	handleDelete = async () => {
		if (
			window.confirm(
				"Are you sure you want to delete this content item? This action cannot be undone.",
			)
		) {
			const { assets } = this.state;

			// First delete all associated images if they exist
			if (assets && assets.length > 0) {
				try {
					// Create an array of promises for deleting each image
					const deletePromises = assets.map((asset) => {
						return new Promise((resolve, reject) => {
							crudDeleteCalendarImage(
								{
									calendar_id: this.props.data.calendar_id,
									id: asset.id,
									imageData: asset.imageData,
									fileType: asset.fileType,
									fileName: asset.fileName,
									document: asset,
								},
								(status) => {
									if (status === 200) {
										resolve();
									} else {
										reject(
											`Failed to delete image ${asset.fileName}`,
										);
									}
								},
							);
						});
					});

					// Wait for all images to be deleted
					await Promise.all(deletePromises);

					// After all images are deleted, delete the content item
					crudDelete(
						"contentcalendar_content",
						{ id: this.props.data.id },
						(status) => {
							if (status === 200) {
								if (this.props.onDelete) {
									this.props.onDelete();
								}
							} else {
								console.error("Error deleting content item");
							}
						},
					);
				} catch (error) {
					console.error("Error deleting images:", error);
					alert("Failed to delete some images. Please try again.");
				}
			} else {
				// If no images, just delete the content item
				crudDelete(
					"contentcalendar_content",
					{ id: this.props.data.id },
					(status) => {
						if (status === 200) {
							if (this.props.onDelete) {
								this.props.onDelete();
							}
						} else {
							console.error("Error deleting content item");
						}
					},
				);
			}
		}
	};

	handleImageClick = (asset) => {
		this.setState({
			selectedImage: asset,
			showImageModal: true,
		});
	};

	closeImageModal = () => {
		this.setState({
			selectedImage: null,
			showImageModal: false,
		});
	};

	handleSaveChanges = () => {
		const { tempYear, tempMonth, tempDay, editedDate } = this.state;

		const newDate = moment(editedDate);
		if (tempYear && tempMonth && tempDay) {
			newDate.year(tempYear);
			newDate.month(parseInt(tempMonth) - 1);
			newDate.date(tempDay);
		}

		if (!newDate.isValid()) {
			alert("Please enter a valid date");
			return;
		}

		const updates = {
			id: this.props.data.id,
			copy: this.state.editedCopy,
			date: newDate.toDate(),
			publishDate: newDate.toDate(),
			hashtags: this.state.editedHashtags,
			tags: this.state.editedTags,
			strategyTags: this.state.editedStrategyTags,
		};

		crudUpdate("contentcalendar_content", updates, (status) => {
			if (status === 200) {
				this.setState({
					isEditing: false,
					showTimeSelector: false,
					showHashtagSelector: false,
					showTagSelector: false,
					showStrategyTagSelector: false,
					editedDate: newDate.toDate(),
				});
				if (this.props.onUpdate) {
					this.props.onUpdate();
				}
			} else {
				console.error("Error updating content item");
			}
		});
	};

	handleAddHashtag = (hashtag) => {
		this.setState((prevState) => ({
			editedHashtags: [...prevState.editedHashtags, hashtag],
		}));
	};

	handleRemoveHashtag = (index) => {
		this.setState((prevState) => ({
			editedHashtags: prevState.editedHashtags.filter(
				(_, i) => i !== index,
			),
		}));
	};

	handleAddTag = (tag) => {
		this.setState((prevState) => ({
			editedTags: [...prevState.editedTags, tag],
		}));
	};

	handleRemoveTag = (index) => {
		this.setState((prevState) => ({
			editedTags: prevState.editedTags.filter((_, i) => i !== index),
		}));
	};

	handleAddStrategyTag = (tag) => {
		this.setState((prevState) => ({
			editedStrategyTags: [...prevState.editedStrategyTags, tag],
		}));
	};

	handleRemoveStrategyTag = (index) => {
		this.setState((prevState) => ({
			editedStrategyTags: prevState.editedStrategyTags.filter(
				(_, i) => i !== index,
			),
		}));
	};

	handleImageUpload = async (assetsToUpload) => {
		const assets = [...(this.state.assets || [])];

		const uploadPromises = assetsToUpload.map((a) => {
			return new Promise((resolve, reject) => {
				let obj = {
					calendar_id: this.props.data.calendar_id,
					document: a,
				};

				crudCreateWithBase64(
					"contentCalendarImages",
					obj,
					(status_, data) => {
						if (status_ === 200) {
							console.log(data);
							assets.push(data);
							resolve();
						} else {
							reject("Upload failed");
						}
					},
				);
			});
		});

		try {
			await Promise.all(uploadPromises);

			// Update the content item with new assets
			const updates = {
				id: this.props.data.id,
				assets: assets,
			};

			crudUpdate("contentcalendar_content", updates, (status) => {
				if (status === 200) {
					this.setState({ assets });
					if (this.props.onUpdate) {
						this.props.onUpdate();
					}
				} else {
					console.error(
						"Error updating content item with new assets",
					);
				}
			});
		} catch (error) {
			console.error("Error uploading images:", error);
		}
	};

	handleDeleteImage = (asset, index) => {
		if (window.confirm("Are you sure you want to delete this image?")) {
			crudDeleteCalendarImage(
				{
					calendar_id: this.props.data.calendar_id,
					id: asset.id,
					imageData: asset.imageData,
					fileType: asset.fileType,
					fileName: asset.fileName,
					document: asset,
				},
				async (status) => {
					if (status === 200) {
						// Remove the asset from the local state
						const newAssets = [...this.state.assets];
						newAssets.splice(index, 1);

						// Update the content item with the new assets array
						const updates = {
							id: this.props.data.id,
							assets: newAssets,
						};

						crudUpdate(
							"contentcalendar_content",
							updates,
							(updateStatus) => {
								if (updateStatus === 200) {
									this.setState({ assets: newAssets });
									if (this.props.onUpdate) {
										this.props.onUpdate();
									}
								} else {
									console.error(
										"Error updating content item after image deletion",
									);
								}
							},
						);
					} else {
						console.error("Error deleting image");
					}
				},
			);
		}
	};

	renderMediaSection = () => {
		const { assets } = this.state;

		return (
			<View style={styles.leftSection}>
				<Text style={styles.label}>Media Assets</Text>
				{(!assets || assets.length === 0) && (
					<View style={styles.uploadContainer}>
						<ImageUploader
							assets={(assets) => this.handleImageUpload(assets)}
						/>
					</View>
				)}
				<ScrollView showsVerticalScrollIndicator={false}>
					{assets &&
						assets.map((asset, index) => (
							<View key={index} style={styles.assetContainer}>
								{asset.fileType?.startsWith("image/") ? (
									<View>
										<TouchableOpacity
											onPress={() =>
												this.handleImageClick(asset)
											}>
											<Image
												source={{
													uri: asset.imageData?.url,
													cache: "force-cache",
												}}
												style={styles.assetImage}
											/>
											<Text
												style={styles.assetName}
												numberOfLines={1}>
												{asset.fileName}
											</Text>
										</TouchableOpacity>
										<TouchableOpacity
											style={styles.deleteImageButton}
											onPress={() =>
												this.handleDeleteImage(
													asset,
													index,
												)
											}>
											<Text
												style={
													styles.deleteImageButtonText
												}>
												Delete
											</Text>
										</TouchableOpacity>
									</View>
								) : (
									<View style={styles.fileAsset}>
										<Text
											style={styles.fileAssetText}
											numberOfLines={2}>
											{asset.fileName}
										</Text>
									</View>
								)}
							</View>
						))}
				</ScrollView>
				{assets && assets.length > 0 && (
					<TouchableOpacity
						style={styles.addMoreButton}
						onPress={() =>
							this.setState({ showImageUploader: true })
						}>
						<Text style={styles.addMoreButtonText}>
							Add More Images
						</Text>
					</TouchableOpacity>
				)}
				{this.state.showImageUploader && (
					<View style={styles.uploadContainer}>
						<ImageUploader
							assets={(assets) => {
								this.handleImageUpload(assets);
								this.setState({ showImageUploader: false });
							}}
						/>
					</View>
				)}
			</View>
		);
	};

	renderCopySection = () => {
		const { isEditing, editedCopy } = this.state;
		const { data: d } = this.props;

		return (
			<View style={styles.copySection}>
				<View style={styles.sectionHeader}>
					<Text style={styles.label}>Copy</Text>
					<TouchableOpacity
						style={styles.editButton}
						onPress={() =>
							this.setState({ isEditing: !isEditing })
						}>
						<Text style={styles.editButtonText}>
							{isEditing ? "Cancel" : "Edit"}
						</Text>
					</TouchableOpacity>
				</View>

				{isEditing ? (
					<TextInput
						style={styles.copyInput}
						multiline
						value={editedCopy}
						onChangeText={(text) =>
							this.setState({ editedCopy: text })
						}
					/>
				) : (
					<Text style={styles.copyText}>
						<Text style={styles.handle}>{d.handle} </Text>
						{d.copy}
					</Text>
				)}
			</View>
		);
	};

	renderDateSection = () => {
		const { showTimeSelector, editedDate, tempYear, tempMonth, tempDay } =
			this.state;

		return (
			<View style={styles.scheduleInfo}>
				<View style={styles.sectionHeader}>
					<Text style={styles.label}>Scheduled For</Text>
					<TouchableOpacity
						style={styles.editButton}
						onPress={() =>
							this.setState({
								showTimeSelector: !showTimeSelector,
								tempYear: moment(editedDate).format("YYYY"),
								tempMonth: moment(editedDate).format("MM"),
								tempDay: moment(editedDate).format("DD"),
							})
						}>
						<Text style={styles.editButtonText}>
							{showTimeSelector ? "Cancel" : "Edit"}
						</Text>
					</TouchableOpacity>
				</View>

				{showTimeSelector ? (
					<View style={styles.dateTimeSelector}>
						<View style={styles.datePickerContainer}>
							<Text style={styles.dateTimeLabel}>Date:</Text>
							<View style={styles.dateInputGroup}>
								<TextInput
									style={styles.dateInputPart}
									value={tempYear}
									onChangeText={(text) => {
										const year = text.replace(
											/[^0-9]/g,
											"",
										);
										if (year.length <= 4) {
											this.setState({ tempYear: year });
										}
									}}
									keyboardType='numeric'
									maxLength={4}
									placeholder='YYYY'
								/>
								<Text style={styles.dateInputSeparator}>-</Text>
								<TextInput
									style={styles.dateInputPart}
									value={tempMonth}
									onChangeText={(text) => {
										const month = text.replace(
											/[^0-9]/g,
											"",
										);
										if (
											month === "" ||
											(parseInt(month) >= 0 &&
												parseInt(month) <= 12)
										) {
											this.setState({ tempMonth: month });
										}
									}}
									keyboardType='numeric'
									maxLength={2}
									placeholder='MM'
								/>
								<Text style={styles.dateInputSeparator}>-</Text>
								<TextInput
									style={styles.dateInputPart}
									value={tempDay}
									onChangeText={(text) => {
										const day = text.replace(/[^0-9]/g, "");
										if (
											day === "" ||
											(parseInt(day) >= 0 &&
												parseInt(day) <= 31)
										) {
											this.setState({ tempDay: day });
										}
									}}
									keyboardType='numeric'
									maxLength={2}
									placeholder='DD'
								/>
							</View>
						</View>
						<TimeSelector
							selectedDate={editedDate}
							onDateSelect={(date) => {
								this.setState({
									editedDate: date,
								});
							}}
						/>
					</View>
				) : (
					<View>
						<Text style={styles.text}>
							{moment(editedDate).format("MMM DD, YYYY")}
						</Text>
						<Text style={[styles.text, { opacity: 0.7 }]}>
							{moment(editedDate).format("hh:mm A")}
						</Text>
					</View>
				)}
			</View>
		);
	};

	renderStrategyTagsSection = () => {
		const { showStrategyTagSelector, editedStrategyTags } = this.state;

		return (
			<View style={styles.strategyTagsContainer}>
				<View style={styles.sectionHeader}>
					<Text style={styles.label}>Strategy Tags</Text>
					<TouchableOpacity
						style={styles.editButton}
						onPress={() =>
							this.setState({
								showStrategyTagSelector:
									!showStrategyTagSelector,
							})
						}>
						<Text style={styles.editButtonText}>
							{showStrategyTagSelector ? "Done" : "Edit"}
						</Text>
					</TouchableOpacity>
				</View>

				{showStrategyTagSelector && (
					<StrategyTagSelector
						onAdd={this.handleAddStrategyTag}
						existingTags={editedStrategyTags}
					/>
				)}

				<View style={styles.tagsList}>
					{editedStrategyTags.map((tag, index) => (
						<TouchableOpacity
							key={index}
							style={styles.strategyTagContainer}
							onPress={() =>
								showStrategyTagSelector &&
								this.handleRemoveStrategyTag(index)
							}>
							<Text style={styles.strategyTag}>{tag}</Text>
							{showStrategyTagSelector && (
								<Text style={styles.removeTag}>×</Text>
							)}
						</TouchableOpacity>
					))}
				</View>
			</View>
		);
	};

	renderTagsSection = () => {
		const {
			showHashtagSelector,
			showTagSelector,
			editedHashtags,
			editedTags,
		} = this.state;

		return (
			<View style={styles.tagsSection}>
				<View style={styles.hashtagsContainer}>
					<View style={styles.sectionHeader}>
						<Text style={styles.label}>Hashtags</Text>
						<TouchableOpacity
							style={styles.editButton}
							onPress={() =>
								this.setState({
									showHashtagSelector: !showHashtagSelector,
								})
							}>
							<Text style={styles.editButtonText}>
								{showHashtagSelector ? "Done" : "Edit"}
							</Text>
						</TouchableOpacity>
					</View>

					{showHashtagSelector && (
						<HashTagSelector
							onAdd={this.handleAddHashtag}
							existingTags={editedHashtags}
						/>
					)}

					<View style={styles.tagsList}>
						{editedHashtags.map((tag, index) => (
							<TouchableOpacity
								key={index}
								style={styles.hashtagContainer}
								onPress={() =>
									showHashtagSelector &&
									this.handleRemoveHashtag(index)
								}>
								<Text style={styles.hashtag}>{tag}</Text>
								{showHashtagSelector && (
									<Text style={styles.removeTag}>×</Text>
								)}
							</TouchableOpacity>
						))}
					</View>
				</View>

				<View style={styles.tagsContainer}>
					<View style={styles.sectionHeader}>
						<Text style={styles.label}>Tags</Text>
						<TouchableOpacity
							style={styles.editButton}
							onPress={() =>
								this.setState({
									showTagSelector: !showTagSelector,
								})
							}>
							<Text style={styles.editButtonText}>
								{showTagSelector ? "Done" : "Edit"}
							</Text>
						</TouchableOpacity>
					</View>

					{showTagSelector && (
						<TagSelector
							onAdd={this.handleAddTag}
							existingTags={editedTags}
						/>
					)}

					<View style={styles.tagsList}>
						{editedTags.map((tag, index) => (
							<TouchableOpacity
								key={index}
								style={styles.tagContainer}
								onPress={() =>
									showTagSelector &&
									this.handleRemoveTag(index)
								}>
								<Text style={styles.tag}>{tag}</Text>
								{showTagSelector && (
									<Text style={styles.removeTag}>×</Text>
								)}
							</TouchableOpacity>
						))}
					</View>
				</View>
				{this.renderStrategyTagsSection()}
			</View>
		);
	};

	render() {
		const { data: d } = this.props;
		const postSize = 180;

		return (
			<View style={styles.container}>
				{this.renderMediaSection()}

				{/* Right Section - Content Details */}
				<View style={styles.rightSection}>
					{/* Header */}
					<View style={styles.header}>
						<View style={styles.titleSection}>
							<Text style={styles.title}>{d.title}</Text>
							<Text style={styles.date}>
								{moment(d.date).format("MMMM DD, YYYY")}
							</Text>
						</View>
						<View style={styles.statusBadge}>
							<Text style={styles.statusText}>
								{d.stage
									? `Stage ${d.stage}: ${d.status}`
									: "Planning"}
							</Text>
						</View>
					</View>

					{/* Channel Info */}
					<View style={styles.contentDetails}>
						<View style={styles.channelInfo}>
							<Text style={styles.label}>Channel</Text>
							<Text style={styles.channelName}>{d.channel}</Text>
							<Text style={[styles.text, { opacity: 0.7 }]}>
								{d.handle}
							</Text>
						</View>

						{this.renderDateSection()}
					</View>

					{this.renderCopySection()}
					{this.renderTagsSection()}

					{/* Save Changes Button */}
					{(this.state.isEditing ||
						this.state.showTimeSelector ||
						this.state.showHashtagSelector ||
						this.state.showTagSelector ||
						this.state.showStrategyTagSelector) && (
						<TouchableOpacity
							style={styles.saveButton}
							onPress={this.handleSaveChanges}>
							<Text style={styles.saveButtonText}>
								Save Changes
							</Text>
						</TouchableOpacity>
					)}

					{/* Actions */}
					<View style={styles.actionsSection}>
						<TouchableOpacity
							style={styles.deleteButton}
							onPress={this.handleDelete}>
							<Text style={styles.deleteButtonText}>Delete</Text>
						</TouchableOpacity>
					</View>
				</View>

				{/* Image Modal */}
				<Modal
					visible={this.state.showImageModal}
					transparent={true}
					onRequestClose={this.closeImageModal}>
					<View style={styles.modalContainer}>
						<TouchableOpacity
							style={styles.modalClose}
							onPress={this.closeImageModal}>
							<Text style={styles.modalCloseText}>×</Text>
						</TouchableOpacity>
						{this.state.selectedImage && (
							<Image
								source={{
									uri: this.state.selectedImage.imageData
										?.url,
									cache: "force-cache",
								}}
								style={styles.modalImage}
							/>
						)}
					</View>
				</Modal>
			</View>
		);
	}
}

const styles = {
	container: {
		backgroundColor: "#FFFFFF",
		borderRadius: 12,
		marginBottom: 20,
		shadowColor: "#000",
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.1,
		shadowRadius: 4,
		elevation: 3,
		flexDirection: "row",
	},
	leftSection: {
		width: 300,
		padding: 20,
		borderRightWidth: 1,
		borderRightColor: "#E8E8E8",
	},
	rightSection: {
		flex: 1,
		padding: 20,
	},
	header: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: 15,
	},
	titleSection: {
		flex: 1,
	},
	title: {
		fontSize: 20,
		fontWeight: "600",
		color: APP_COLOURS.BG2,
		marginBottom: 4,
	},
	date: {
		fontSize: 14,
		color: APP_COLOURS.BG2,
		opacity: 0.7,
	},
	statusBadge: {
		backgroundColor: APP_COLOURS.PRIMARY,
		paddingHorizontal: 12,
		paddingVertical: 6,
		borderRadius: 20,
	},
	statusText: {
		color: "#FFFFFF",
		fontSize: 12,
		fontWeight: "500",
	},
	contentDetails: {
		flexDirection: "row",
		marginBottom: 20,
	},
	channelInfo: {
		flex: 1,
		marginRight: 20,
	},
	scheduleInfo: {
		flex: 1,
	},
	label: {
		fontSize: 12,
		fontWeight: "600",
		color: APP_COLOURS.BG2,
		opacity: 0.6,
		marginBottom: 8,
		textTransform: "uppercase",
	},
	channelName: {
		fontSize: 16,
		fontWeight: "500",
		color: APP_COLOURS.BG2,
		marginBottom: 4,
	},
	text: {
		fontSize: 14,
		color: APP_COLOURS.BG2,
		marginBottom: 2,
	},
	assetContainer: {
		marginBottom: 12,
		position: "relative",
	},
	assetImage: {
		width: 260,
		height: 260,
		borderRadius: 8,
		backgroundColor: APP_COLOURS.BORDER,
	},
	assetName: {
		fontSize: 12,
		color: APP_COLOURS.BG2,
		opacity: 0.7,
		marginTop: 4,
	},
	fileAsset: {
		width: 260,
		height: 100,
		backgroundColor: APP_COLOURS.BORDER,
		borderRadius: 8,
		justifyContent: "center",
		alignItems: "center",
		padding: 10,
	},
	fileAssetText: {
		color: APP_COLOURS.BG2,
		fontSize: 12,
		textAlign: "center",
	},
	copySection: {
		marginBottom: 20,
	},
	copyText: {
		fontSize: 14,
		color: APP_COLOURS.BG2,
		lineHeight: 20,
		backgroundColor: "#F8F8F8",
		padding: 12,
		borderRadius: 8,
	},
	handle: {
		fontWeight: "600",
	},
	tagsSection: {
		flexDirection: "row",
		marginBottom: 20,
	},
	hashtagsContainer: {
		flex: 1,
		marginRight: 20,
		backgroundColor: APP_COLOURS.WHITE_TRANSPARENT,
		padding: 10,
		borderRadius: 10,
	},
	tagsContainer: {
		flex: 1,
		backgroundColor: APP_COLOURS.WHITE_TRANSPARENT,
		padding: 10,
		borderRadius: 10,
	},
	tagsList: {
		flexDirection: "row",
		flexWrap: "wrap",
	},
	hashtag: {
		fontSize: 13,
		color: APP_COLOURS.BLUE,
		backgroundColor: "#F0F7FF",
		paddingHorizontal: 10,
		paddingVertical: 4,
		borderRadius: 12,
		marginRight: 8,
		marginBottom: 8,
	},
	tag: {
		fontSize: 13,
		color: APP_COLOURS.BG2,
		backgroundColor: "#F0F0F0",
		paddingHorizontal: 10,
		paddingVertical: 4,
		borderRadius: 12,
		marginRight: 8,
		marginBottom: 8,
	},
	actionsSection: {
		flexDirection: "row",
		justifyContent: "flex-end",
		marginTop: 20,
	},
	deleteButton: {
		backgroundColor: APP_COLOURS.RED,
		paddingHorizontal: 16,
		paddingVertical: 8,
		borderRadius: 20,
	},
	deleteButtonText: {
		color: "#FFFFFF",
		fontSize: 14,
		fontWeight: "500",
	},
	modalContainer: {
		flex: 1,
		backgroundColor: "rgba(0, 0, 0, 0.9)",
		justifyContent: "center",
		alignItems: "center",
	},
	modalClose: {
		position: "absolute",
		top: 40,
		right: 40,
		zIndex: 1,
	},
	modalCloseText: {
		color: "#FFFFFF",
		fontSize: 30,
		fontWeight: "600",
	},
	modalImage: {
		width: "90%",
		height: "80%",
		resizeMode: "contain",
	},
	sectionHeader: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: 8,
	},
	editButton: {
		paddingHorizontal: 12,
		paddingVertical: 4,
		borderRadius: 12,
		backgroundColor: APP_COLOURS.PRIMARY,
	},
	editButtonText: {
		color: "#FFFFFF",
		fontSize: 12,
		fontWeight: "500",
	},
	copyInput: {
		fontSize: 14,
		color: APP_COLOURS.BG2,
		lineHeight: 20,
		backgroundColor: "#F8F8F8",
		padding: 12,
		borderRadius: 8,
		minHeight: 100,
		textAlignVertical: "top",
	},
	hashtagContainer: {
		flexDirection: "row",
		alignItems: "center",
		backgroundColor: "#F0F7FF",
		paddingHorizontal: 10,
		paddingVertical: 4,
		borderRadius: 12,
		marginRight: 8,
		marginBottom: 8,
	},
	tagContainer: {
		flexDirection: "row",
		alignItems: "center",
		backgroundColor: "#F0F0F0",
		paddingHorizontal: 10,
		paddingVertical: 4,
		borderRadius: 12,
		marginRight: 8,
		marginBottom: 8,
	},
	removeTag: {
		marginLeft: 4,
		color: APP_COLOURS.RED,
		fontSize: 16,
		fontWeight: "600",
	},
	saveButton: {
		backgroundColor: APP_COLOURS.GREEN,
		paddingHorizontal: 16,
		paddingVertical: 8,
		borderRadius: 20,
		alignSelf: "flex-end",
		marginTop: 20,
	},
	saveButtonText: {
		color: "#FFFFFF",
		fontSize: 14,
		fontWeight: "500",
	},
	dateTimeSelector: {
		backgroundColor: "#F8F8F8",
		padding: 12,
		borderRadius: 8,
		marginBottom: 10,
	},
	datePickerContainer: {
		flexDirection: "row",
		alignItems: "center",
		marginBottom: 10,
		padding: 8,
		backgroundColor: "#FFFFFF",
		borderRadius: 6,
	},
	dateTimeLabel: {
		fontSize: 14,
		fontWeight: "500",
		color: APP_COLOURS.BG2,
		marginRight: 10,
	},
	dateInputGroup: {
		flexDirection: "row",
		alignItems: "center",
		flex: 1,
	},
	dateInputPart: {
		width: 60,
		padding: 6,
		borderWidth: 1,
		borderColor: "#E8E8E8",
		borderRadius: 4,
		textAlign: "center",
		fontSize: 14,
		color: APP_COLOURS.BG2,
	},
	dateInputSeparator: {
		marginHorizontal: 5,
		fontSize: 14,
		color: APP_COLOURS.BG2,
	},
	strategyTagsContainer: {
		flex: 1,
		marginLeft: 20,
		backgroundColor: APP_COLOURS.WHITE_TRANSPARENT,
		padding: 10,
		borderRadius: 10,
	},
	strategyTagContainer: {
		flexDirection: "row",
		alignItems: "center",
		backgroundColor: "#FFE8E0",
		paddingHorizontal: 10,
		paddingVertical: 4,
		borderRadius: 12,
		marginRight: 8,
		marginBottom: 8,
	},
	strategyTag: {
		fontSize: 13,
		color: APP_COLOURS.PRIMARY,
	},
	uploadContainer: {
		marginBottom: 20,
		padding: 10,
		borderRadius: 8,
		borderWidth: 1,
		borderColor: APP_COLOURS.BORDER,
		backgroundColor: "#F8F8F8",
	},
	addMoreButton: {
		backgroundColor: APP_COLOURS.PRIMARY,
		padding: 10,
		borderRadius: 8,
		alignItems: "center",
		marginTop: 10,
	},
	addMoreButtonText: {
		color: "#FFFFFF",
		fontSize: 14,
		fontWeight: "500",
	},
	deleteImageButton: {
		position: "absolute",
		top: 8,
		right: 8,
		backgroundColor: "rgba(255, 59, 48, 0.8)",
		paddingHorizontal: 8,
		paddingVertical: 4,
		borderRadius: 4,
	},
	deleteImageButtonText: {
		color: "#FFFFFF",
		fontSize: 12,
		fontWeight: "500",
	},
};
