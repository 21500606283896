import React, { useState } from "react";
import { View, Text, StyleSheet, TextInput, ScrollView } from "react-native";
import moment from "moment";
import { darkTheme } from "./theme";
import { PROJECTSTATUS } from "../../APP_VARS";

// Helper function to get status color
const getStatusColor = (status) => {
	const statusConfig = PROJECTSTATUS.find(
		(s) => s.status.toLowerCase() === status.toLowerCase(),
	);
	return statusConfig?.color || darkTheme.colors.text.disabled;
};

// Add helper function for date formatting and checking
const formatDueDate = (dueDate) => {
	if (!dueDate) return "No due date";

	const date = moment(dueDate);
	if (!date.isValid()) return "Invalid date";

	const now = moment();
	const days = date.diff(now, "days");

	let formattedDate = date.format("MMM D, YYYY h:mm A");

	// Add relative time indicators
	if (days < 0) {
		formattedDate += " (Overdue)";
	} else if (days === 0) {
		formattedDate += " (Today)";
	} else if (days === 1) {
		formattedDate += " (Tomorrow)";
	} else if (days < 7) {
		formattedDate += ` (${days} days)`;
	}

	return formattedDate;
};

const renderActionButtons = (task, isActive, onStartTask, onTaskClick) => {
	return (
		<View style={styles.actionButtons}>
			{!isActive && (
				<View
					style={[styles.button, styles.startButton]}
					onClick={(e) => {
						e.stopPropagation();
						onStartTask(task.id);
					}}>
					<Text style={styles.buttonText}>Start</Text>
				</View>
			)}
		</View>
	);
};

export default function TaskTable({
	tasks,
	estimatedDurations,
	onStartTask,
	activeTaskId,
	workLogs,
	onStatusChange,
	onTaskClick,
	onArchive,
}) {
	const [searchQuery, setSearchQuery] = useState("");
	const [sortConfig, setSortConfig] = useState({
		key: "due_date",
		direction: "asc",
	});

	const handleSort = (key) => {
		setSortConfig((prevConfig) => ({
			key,
			direction:
				prevConfig.key === key && prevConfig.direction === "asc"
					? "desc"
					: "asc",
		}));
	};

	const filteredAndSortedTasks = tasks
		.filter((task) => {
			// console.log("Task:", task);
			return (
				task.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
				task.status.toLowerCase().includes(searchQuery.toLowerCase())
			);
		})
		.sort((a, b) => {
			const direction = sortConfig.direction === "asc" ? 1 : -1;
			switch (sortConfig.key) {
				case "due_date":
					if (!a.createdAt && !b.createdAt) return 0;
					if (!a.createdAt) return 1;
					if (!b.createdAt) return -1;

					const dateA = moment(a.createdAt);
					const dateB = moment(b.createdAt);

					if (!dateA.isValid() && !dateB.isValid()) return 0;
					if (!dateA.isValid()) return 1;
					if (!dateB.isValid()) return -1;

					return direction * dateA.diff(dateB);
				case "status":
					return direction * a.status.localeCompare(b.status);
				case "title":
					return direction * a.title.localeCompare(b.title);
				default:
					return 0;
			}
		});

	const getSortIcon = (key) => {
		if (sortConfig.key !== key) return "↕";
		return sortConfig.direction === "asc" ? "↑" : "↓";
	};

	const handleArchive = (e, taskId) => {
		e.stopPropagation();
		if (
			window.confirm(
				"Are you sure you want to archive this task? This action cannot be undone.",
			)
		) {
			onStatusChange(taskId, "archived");
		}
	};

	return (
		<View style={styles.container}>
			<TextInput
				style={styles.searchInput}
				placeholder='Search tasks...'
				placeholderTextColor={darkTheme.colors.text.secondary}
				value={searchQuery}
				onChange={(e) => setSearchQuery(e.target.value)}
			/>
			<View style={styles.table}>
				<View style={styles.tableHeader}>
					<View
						onClick={() => handleSort("title")}
						style={styles.headerCell}>
						<Text style={styles.headerText}>
							Title {getSortIcon("title")}
						</Text>
					</View>
					<View
						onClick={() => handleSort("status")}
						style={styles.headerCell}>
						<Text style={styles.headerText}>
							Status {getSortIcon("status")}
						</Text>
					</View>
					<View
						onClick={() => handleSort("due_date")}
						style={styles.headerCell}>
						<Text style={styles.headerText}>
							Created Date {getSortIcon("due_date")}
						</Text>
					</View>
					<View style={styles.headerCell}>
						<Text style={styles.headerText}>Actions</Text>
					</View>
				</View>
				<ScrollView>
					{filteredAndSortedTasks.map((task) => (
						<View
							key={task.id}
							style={[
								styles.tableRow,
								task.id === activeTaskId && styles.activeRow,
							]}
							onClick={() => onTaskClick(task)}>
							<View style={styles.cellmain}>
								<Text style={styles.cellText}>
									{task.title}
								</Text>
							</View>
							<View style={styles.cell}>
								<Text
									style={[
										styles.statusBadge,
										{
											backgroundColor: getStatusColor(
												task.status,
											),
										},
									]}>
									{task.status.toUpperCase()}
								</Text>
							</View>
							<View style={styles.cell}>
								<Text
									style={[
										styles.cellText,
										task.createdAt &&
											moment(task.createdAt).isBefore(
												moment(),
											) &&
											styles.overdue,
									]}>
									{task.createdAt
										? moment(task.createdAt).format(
												"MMM D, YYYY h:mm A",
										  )
										: "No date"}
								</Text>
							</View>
							<View style={styles.cell}>
								{renderActionButtons(
									task,
									task.id === activeTaskId,
									onStartTask,
									onTaskClick,
								)}
							</View>
						</View>
					))}
				</ScrollView>
			</View>
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: darkTheme.colors.surface,
		borderRadius: darkTheme.borderRadius.lg,
		padding: darkTheme.spacing.lg,
	},
	searchInput: {
		backgroundColor: darkTheme.colors.background,
		color: darkTheme.colors.text.primary,
		padding: darkTheme.spacing.md,
		borderRadius: darkTheme.borderRadius.md,
		marginBottom: darkTheme.spacing.lg,
		border: `1px solid ${darkTheme.colors.border}`,
	},
	table: {
		flex: 1,
	},
	tableHeader: {
		flexDirection: "row",
		borderBottom: `2px solid ${darkTheme.colors.border}`,
		paddingBottom: darkTheme.spacing.sm,
		marginBottom: darkTheme.spacing.sm,
	},
	headerCell: {
		flex: 1,
		cursor: "pointer",
		padding: darkTheme.spacing.sm,
	},
	headerText: {
		color: darkTheme.colors.text.primary,
		fontWeight: "600",
	},
	tableRow: {
		flexDirection: "row",
		borderBottom: `1px solid ${darkTheme.colors.border}`,
		padding: darkTheme.spacing.sm,
		cursor: "pointer",
		"&:hover": {
			backgroundColor: `${darkTheme.colors.primary}11`,
		},
	},
	activeRow: {
		backgroundColor: `${darkTheme.colors.primary}22`,
	},
	cellmain: {
		flex: 3,
		padding: darkTheme.spacing.sm,
		justifyContent: "center",
	},
	cell: {
		flex: 1,
		padding: darkTheme.spacing.sm,
		justifyContent: "center",
	},
	cellText: {
		color: darkTheme.colors.text.primary,
	},
	statusBadge: {
		padding: darkTheme.spacing.xs,
		borderRadius: darkTheme.borderRadius.sm,
		color: "#000",
		fontSize: 12,
		textAlign: "center",
		maxWidth: 100,
		fontWeight: "500",
	},
	startButton: {
		backgroundColor: darkTheme.colors.primary,
		padding: darkTheme.spacing.sm,
		borderRadius: darkTheme.borderRadius.sm,
		alignItems: "center",
		cursor: "pointer",
		minWidth: 80,
	},
	pauseButton: {
		backgroundColor: darkTheme.colors.warning,
		padding: darkTheme.spacing.sm,
		borderRadius: darkTheme.borderRadius.sm,
		alignItems: "center",
		cursor: "pointer",
	},
	buttonText: {
		color: darkTheme.colors.text.primary,
		fontWeight: "500",
	},
	overdue: {
		color: darkTheme.colors.error,
	},
	actionButtons: {
		flexDirection: "row",
		gap: darkTheme.spacing.sm,
		justifyContent: "flex-end",
	},
	archiveButton: {
		backgroundColor: darkTheme.colors.text.disabled,
		padding: darkTheme.spacing.sm,
		borderRadius: darkTheme.borderRadius.sm,
		alignItems: "center",
		cursor: "pointer",
	},
	detailsButton: {
		backgroundColor: darkTheme.colors.secondary,
		padding: darkTheme.spacing.sm,
		borderRadius: darkTheme.borderRadius.sm,
		alignItems: "center",
		cursor: "pointer",
		minWidth: 80,
	},
});
