import React, { useState, useEffect } from "react";
import { View, Text, StyleSheet, ScrollView, Image } from "react-native";
import moment from "moment";
import { darkTheme } from "./theme";
import TaskTimer from "./TaskTimer";
import { crudRead } from "../../CRUDHELPER";
import HTMLRenderer from "./HTMLRenderer";
import TaskStatusSelector from "./TaskStatusSelector";

export default function TaskDetailsModal({
	isVisible,
	onClose,
	task,
	workLogs,
	estimatedDuration,
	onTimerAction,
	activeTaskId,
	timerRunning,
	onStatusChange,
	onDelete,
}) {
	const [project, setProject] = useState(null);
	const [isLoadingProject, setIsLoadingProject] = useState(false);
	const [projectError, setProjectError] = useState(null);

	useEffect(() => {
		if (task?.project_id) {
			fetchProject(task.project_id);
		}
	}, [task?.project_id]);

	const fetchProject = async (projectId) => {
		setIsLoadingProject(true);
		setProjectError(null);

		try {
			await new Promise((resolve, reject) => {
				crudRead(
					"projects",
					{
						field: "id",
						action: "==",
						value: task?.project_id,
					},
					(status, projectData) => {
						if (status === 200) {
							console.log(projectData[0]);
							setProject(projectData[0]);
							resolve(projectData[0]);
						} else {
							reject(
								new Error(`Failed to fetch project: ${status}`),
							);
						}
					},
				);
			});
		} catch (error) {
			console.error("Error fetching project:", error);
			setProjectError("Failed to load project details");
		} finally {
			setIsLoadingProject(false);
		}
	};

	if (!isVisible || !task) return null;

	const getTaskStartTime = () => {
		if (!workLogs || !workLogs[task.id]) return null;
		const taskLogs = workLogs[task.id];
		const activeLog = taskLogs.find((log) => log.status === "started");
		return activeLog?.startTime || null;
	};

	const formatDuration = (seconds) => {
		const hours = Math.floor(seconds / 3600);
		const minutes = Math.floor((seconds % 3600) / 60);
		return `${hours}h ${minutes}m`;
	};

	const getTotalTimeSpent = () => {
		if (!workLogs[task.id]) return 0;
		return workLogs[task.id].reduce((total, log) => {
			return total + (log.duration || 0);
		}, 0);
	};

	const isActiveTask = task?.id === activeTaskId;

	const renderTimerControls = () => {
		if (!isActiveTask) {
			return (
				<View
					onClick={() => onTimerAction(task.id, "start")}
					style={[styles.actionButton, styles.startButton]}>
					<Text style={styles.buttonText}>Start Task</Text>
				</View>
			);
		}

		return (
			<View style={styles.taskActions}>
				{timerRunning ? (
					<>
						<View
							onClick={() => onTimerAction(task.id, "pause")}
							style={[styles.actionButton, styles.pauseButton]}>
							<Text style={styles.buttonText}>Pause</Text>
						</View>
						<View
							onClick={() => onTimerAction(task.id, "stop")}
							style={[styles.actionButton, styles.endButton]}>
							<Text style={styles.buttonText}>Stop</Text>
						</View>
						<View
							onClick={() => onTimerAction(task.id, "complete")}
							style={[
								styles.actionButton,
								styles.completeButton,
							]}>
							<Text style={styles.buttonText}>Complete</Text>
						</View>
					</>
				) : (
					<View
						onClick={() => onTimerAction(task.id, "start")}
						style={[styles.actionButton, styles.startButton]}>
						<Text style={styles.buttonText}>Resume</Text>
					</View>
				)}
			</View>
		);
	};

	return (
		<View style={styles.modalOverlay}>
			<View style={styles.modalContent}>
				{/* Header */}
				<View
					style={[
						styles.header,
						{
							backgroundColor:
								task.status === "completed"
									? darkTheme.colors.primary
									: darkTheme.colors.background,
						},
					]}>
					<View style={styles.headerContent}>
						<View style={styles.titleContainer}>
							<View onClick={onClose} style={styles.closeButton}>
								<Text style={styles.closeIcon}>×</Text>
							</View>
							<Text style={styles.title}>{task.title}</Text>
						</View>
						{project && (
							<Text style={styles.projectInfo}>
								Project: {project.title}
								{project.brand && ` - ${project.brand.title}`}
							</Text>
						)}
						<Text style={styles.status}>
							Status:{" "}
							{task.status
								? task.status.toUpperCase()
								: "PENDING"}{" "}
							{task.id}
						</Text>
					</View>
				</View>

				{/* Split Content */}
				<View style={styles.splitContent}>
					{/* Left Side - Task Details */}
					<View style={styles.leftPanel}>
						<ScrollView style={styles.scrollContent}>
							{/* Status Section */}
							<View style={styles.section}>
								<TaskStatusSelector
									currentStatus={task.status}
									onStatusChange={(newStatus) =>
										onStatusChange(task.id, newStatus)
									}
								/>
							</View>

							{/* Timer Section */}
							<View style={styles.section}>
								<Text style={styles.sectionTitle}>
									Time Tracking
								</Text>
								<View style={styles.timeTrackingContainer}>
									{/* Time Stats */}
									<View style={styles.timeStats}>
										<View style={styles.timeStatItem}>
											<Text style={styles.timeStatLabel}>
												Estimated
											</Text>
											<Text style={styles.timeStatValue}>
												{estimatedDuration} minutes
											</Text>
										</View>
										<View style={styles.timeStatItem}>
											<Text style={styles.timeStatLabel}>
												Total Spent
											</Text>
											<Text style={styles.timeStatValue}>
												{formatDuration(
													getTotalTimeSpent(),
												)}
											</Text>
										</View>
										{isActiveTask && (
											<View style={styles.timeStatItem}>
												<Text
													style={
														styles.timeStatLabel
													}>
													Current Session
												</Text>
												<TaskTimer
													startTime={getTaskStartTime()}
													isRunning={timerRunning}
													totalElapsedTime={getTotalTimeSpent()}
													compact={true}
												/>
											</View>
										)}
									</View>
									{task.status !== "completed" &&
										task.status !== "archived" && (
											<View style={styles.timerControls}>
												{renderTimerControls()}
											</View>
										)}
								</View>
							</View>

							{/* Details Section */}
							<View style={styles.section}>
								<Text style={styles.sectionTitle}>
									Task Details
								</Text>
								<View style={styles.detailsGrid}>
									<View style={styles.detailItem}>
										<Text style={styles.detailLabel}>
											Priority
										</Text>
										<Text
											style={[
												styles.detailValue,
												{
													color: task.priority
														? task.priority ===
														  "high"
															? darkTheme.colors
																	.error
															: task.priority ===
															  "medium"
															? darkTheme.colors
																	.warning
															: darkTheme.colors
																	.primary
														: darkTheme.colors.text
																.secondary,
												},
											]}>
											{task.priority
												? task.priority.toUpperCase()
												: "NONE"}
										</Text>
									</View>
									{task.deadline && (
										<View style={styles.detailItem}>
											<Text style={styles.detailLabel}>
												Due Date
											</Text>
											<Text style={styles.detailValue}>
												{moment(task.deadline).format(
													"MMM D, YYYY",
												)}
											</Text>
										</View>
									)}
									{task.type && (
										<View style={styles.detailItem}>
											<Text style={styles.detailLabel}>
												Type
											</Text>
											<Text style={styles.detailValue}>
												{task.type}
											</Text>
										</View>
									)}
								</View>
								{task.description && (
									<View style={styles.description}>
										<Text style={styles.detailLabel}>
											Description
										</Text>
										<Text style={styles.descriptionText}>
											{task.description}
										</Text>
									</View>
								)}
							</View>

							{/* Work Log Section */}
							<View style={styles.section}>
								<Text style={styles.sectionTitle}>
									Work Log
								</Text>
								<View style={styles.workLogList}>
									{workLogs[task.id]
										?.sort(
											(a, b) => b.startTime - a.startTime,
										)
										.map((log) => (
											<View
												key={`${task.id}-${log.id}-${log.startTime}`}
												style={styles.logEntry}>
												<Text style={styles.logTime}>
													{moment
														.unix(log.startTime)
														.format(
															"MMM D, h:mm A",
														)}{" "}
													-{" "}
													{log.endTime
														? moment
																.unix(
																	log.endTime,
																)
																.format(
																	"h:mm A",
																)
														: "In Progress"}
												</Text>
												<Text
													style={styles.logDuration}>
													{log.duration
														? formatDuration(
																log.duration,
														  )
														: "Ongoing"}
												</Text>
											</View>
										))}
								</View>
							</View>
						</ScrollView>
					</View>

					{/* Right Side - Project Details */}
					<View style={styles.rightPanel}>
						<ScrollView style={styles.scrollContent}>
							{task.project_id ? (
								<View style={styles.section}>
									<Text style={styles.sectionTitle}>
										Project Details
									</Text>
									<View style={styles.projectContainer}>
										{isLoadingProject ? (
											<Text style={styles.loadingText}>
												Loading project details...
											</Text>
										) : projectError ? (
											<Text style={styles.errorText}>
												{projectError}
											</Text>
										) : project ? (
											<>
												<View
													style={
														styles.projectHeader
													}>
													<Text
														style={
															styles.projectTitle
														}>
														{project.title}
													</Text>
													{project.brand && (
														<Text
															style={
																styles.brandName
															}>
															{
																project.brand
																	.title
															}
														</Text>
													)}
												</View>
												{project.description && (
													<View
														style={
															styles.projectDescription
														}>
														<HTMLRenderer
															html={
																project.description
															}
														/>
													</View>
												)}
												{project.status && (
													<View
														style={
															styles.projectMeta
														}>
														<Text
															style={
																styles.metaLabel
															}>
															Status
														</Text>
														<Text
															style={
																styles.metaValue
															}>
															{project.status.toUpperCase()}
														</Text>
													</View>
												)}
												{project.deadline && (
													<View
														style={
															styles.projectMeta
														}>
														<Text
															style={
																styles.metaLabel
															}>
															Project Deadline
														</Text>
														<Text
															style={
																styles.metaValue
															}>
															{moment(
																project.deadline,
															).format(
																"MMM D, YYYY",
															)}
														</Text>
													</View>
												)}
											</>
										) : null}
									</View>
								</View>
							) : (
								<View style={styles.noProjectContainer}>
									<Text style={styles.noProjectText}>
										No project associated with this task
									</Text>
								</View>
							)}
						</ScrollView>
					</View>
				</View>

				{/* Footer Actions */}
				<View style={styles.footer}>
					<View style={styles.actionButtons}>
						{task.status === "completed" && (
							<View
								onClick={() =>
									onStatusChange(task.id, "archived")
								}
								style={[styles.button, styles.archiveButton]}>
								<Text style={styles.buttonText}>
									Archive Task
								</Text>
							</View>
						)}
					</View>
				</View>
			</View>
		</View>
	);
}

const styles = StyleSheet.create({
	modalOverlay: {
		position: "fixed",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: "rgba(0, 0, 0, 0.75)",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		zIndex: 1000,
	},
	modalContent: {
		backgroundColor: darkTheme.colors.surface,
		borderRadius: darkTheme.borderRadius.lg,
		width: "90%",
		maxWidth: 1200,
		maxHeight: "90vh",
		display: "flex",
		flexDirection: "column",
	},
	header: {
		borderTopLeftRadius: darkTheme.borderRadius.lg,
		borderTopRightRadius: darkTheme.borderRadius.lg,
		padding: darkTheme.spacing.lg,
	},
	headerContent: {
		gap: darkTheme.spacing.sm,
	},
	titleContainer: {
		flexDirection: "row",
		alignItems: "center",
		gap: darkTheme.spacing.md,
	},
	closeButton: {
		cursor: "pointer",
	},
	closeIcon: {
		fontSize: 24,
		color: darkTheme.colors.text.primary,
		fontWeight: "bold",
	},
	title: {
		fontSize: 24,
		fontWeight: "bold",
		color: darkTheme.colors.text.primary,
	},
	status: {
		fontSize: 14,
		color: darkTheme.colors.text.primary,
		opacity: 0.8,
	},
	content: {
		flex: 1,
		padding: darkTheme.spacing.lg,
	},
	section: {
		marginBottom: darkTheme.spacing.lg,
	},
	sectionTitle: {
		fontSize: 18,
		fontWeight: "600",
		color: darkTheme.colors.text.primary,
		marginBottom: darkTheme.spacing.md,
	},
	timerContainer: {
		backgroundColor: darkTheme.colors.background,
		padding: darkTheme.spacing.lg,
		borderRadius: darkTheme.borderRadius.md,
	},
	timeInfo: {
		marginTop: darkTheme.spacing.md,
		flexDirection: "row",
		justifyContent: "space-between",
	},
	timeInfoText: {
		color: darkTheme.colors.text.secondary,
	},
	detailsGrid: {
		flexDirection: "row",
		flexWrap: "wrap",
		gap: darkTheme.spacing.lg,
		marginBottom: darkTheme.spacing.lg,
	},
	detailItem: {
		flex: 1,
		minWidth: 200,
	},
	detailLabel: {
		fontSize: 12,
		color: darkTheme.colors.text.secondary,
		marginBottom: darkTheme.spacing.xs,
	},
	detailValue: {
		fontSize: 16,
		color: darkTheme.colors.text.primary,
	},
	description: {
		backgroundColor: darkTheme.colors.background,
		padding: darkTheme.spacing.md,
		borderRadius: darkTheme.borderRadius.md,
	},
	descriptionText: {
		color: darkTheme.colors.text.primary,
		lineHeight: 1.5,
	},
	workLogList: {
		gap: darkTheme.spacing.sm,
	},
	logEntry: {
		flexDirection: "row",
		justifyContent: "space-between",
		padding: darkTheme.spacing.sm,
		backgroundColor: darkTheme.colors.background,
		borderRadius: darkTheme.borderRadius.sm,
	},
	logTime: {
		color: darkTheme.colors.text.primary,
	},
	logDuration: {
		color: darkTheme.colors.text.secondary,
	},
	footer: {
		padding: darkTheme.spacing.lg,
		borderTop: `1px solid ${darkTheme.colors.border}`,
	},
	actionButtons: {
		flexDirection: "row",
		justifyContent: "flex-end",
		gap: darkTheme.spacing.md,
	},
	button: {
		padding: darkTheme.spacing.sm,
		paddingHorizontal: darkTheme.spacing.lg,
		borderRadius: darkTheme.borderRadius.md,
		cursor: "pointer",
	},
	startButton: {
		backgroundColor: darkTheme.colors.primary,
	},
	deleteButton: {
		backgroundColor: darkTheme.colors.error,
	},
	buttonText: {
		color: darkTheme.colors.text.primary,
		fontWeight: "500",
	},
	projectInfo: {
		fontSize: 14,
		color: darkTheme.colors.text.primary,
		opacity: 0.9,
		marginTop: darkTheme.spacing.xs,
	},
	projectContainer: {
		backgroundColor: darkTheme.colors.background,
		padding: darkTheme.spacing.lg,
		borderRadius: darkTheme.borderRadius.md,
		minHeight: "100%",
	},
	projectHeader: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: darkTheme.spacing.sm,
	},
	projectTitle: {
		fontSize: 16,
		fontWeight: "500",
		color: darkTheme.colors.text.primary,
	},
	brandName: {
		fontSize: 14,
		color: darkTheme.colors.text.secondary,
		backgroundColor: darkTheme.colors.surface,
		paddingHorizontal: darkTheme.spacing.sm,
		paddingVertical: darkTheme.spacing.xs,
		borderRadius: darkTheme.borderRadius.sm,
	},
	projectDescription: {
		marginTop: darkTheme.spacing.lg,
		padding: darkTheme.spacing.md,
		backgroundColor: darkTheme.colors.surface,
		borderRadius: darkTheme.borderRadius.md,
		"& .html-content": {
			color: darkTheme.colors.text.primary,
			fontSize: 14,
			lineHeight: 1.6,
			"& h1, & h2, & h3, & h4, & h5, & h6": {
				margin: `${darkTheme.spacing.md}px 0`,
				color: darkTheme.colors.text.primary,
			},
			"& p": {
				margin: `${darkTheme.spacing.sm}px 0`,
			},
			"& ul, & ol": {
				margin: `${darkTheme.spacing.sm}px 0`,
				paddingLeft: darkTheme.spacing.xl,
			},
			"& li": {
				margin: `${darkTheme.spacing.xs}px 0`,
			},
			"& img": {
				maxWidth: "100%",
				height: "auto",
				borderRadius: darkTheme.borderRadius.sm,
			},
		},
	},
	loadingText: {
		color: darkTheme.colors.text.secondary,
		textAlign: "center",
		padding: darkTheme.spacing.md,
	},
	errorText: {
		color: darkTheme.colors.error,
		textAlign: "center",
		padding: darkTheme.spacing.md,
	},
	splitContent: {
		flex: 1,
		flexDirection: "row",
		gap: darkTheme.spacing.lg,
		minHeight: "70vh",
	},
	leftPanel: {
		flex: 1,
		borderRight: `1px solid ${darkTheme.colors.border}`,
		overflow: "auto",
	},
	rightPanel: {
		flex: 1,
		overflow: "auto",
	},
	scrollContent: {
		padding: darkTheme.spacing.lg,
	},
	projectMeta: {
		marginTop: darkTheme.spacing.md,
		padding: darkTheme.spacing.md,
		backgroundColor: darkTheme.colors.surface,
		borderRadius: darkTheme.borderRadius.sm,
	},
	metaLabel: {
		fontSize: 12,
		color: darkTheme.colors.text.secondary,
		marginBottom: darkTheme.spacing.xs,
	},
	metaValue: {
		fontSize: 14,
		color: darkTheme.colors.text.primary,
	},
	noProjectContainer: {
		padding: darkTheme.spacing.xl,
		alignItems: "center",
		justifyContent: "center",
	},
	noProjectText: {
		color: darkTheme.colors.text.secondary,
		textAlign: "center",
	},
	":global(.html-content)": {
		color: darkTheme.colors.text.primary,
		"& h1, & h2, & h3, & h4, & h5, & h6": {
			color: darkTheme.colors.text.primary,
			marginBottom: darkTheme.spacing.sm,
		},
		"& p": {
			marginBottom: darkTheme.spacing.md,
			lineHeight: 1.6,
		},
		"& a": {
			color: darkTheme.colors.primary,
		},
		"& ul, & ol": {
			paddingLeft: darkTheme.spacing.lg,
			marginBottom: darkTheme.spacing.md,
		},
		"& li": {
			marginBottom: darkTheme.spacing.xs,
		},
	},
	timeTrackingContainer: {
		backgroundColor: darkTheme.colors.background,
		padding: darkTheme.spacing.lg,
		borderRadius: darkTheme.borderRadius.md,
		gap: darkTheme.spacing.lg,
	},
	timeStats: {
		flexDirection: "row",
		justifyContent: "space-between",
		gap: darkTheme.spacing.lg,
	},
	timeStatItem: {
		flex: 1,
		backgroundColor: darkTheme.colors.surface,
		padding: darkTheme.spacing.md,
		borderRadius: darkTheme.borderRadius.sm,
		alignItems: "center",
	},
	timeStatLabel: {
		fontSize: 12,
		color: darkTheme.colors.text.secondary,
		marginBottom: darkTheme.spacing.xs,
	},
	timeStatValue: {
		fontSize: 16,
		color: darkTheme.colors.text.primary,
		fontWeight: "500",
	},
	taskActions: {
		flexDirection: "row",
		justifyContent: "center",
		gap: darkTheme.spacing.md,
	},
	actionButton: {
		padding: darkTheme.spacing.sm,
		paddingHorizontal: darkTheme.spacing.xl,
		borderRadius: darkTheme.borderRadius.md,
		cursor: "pointer",
	},
	startButton: {
		backgroundColor: darkTheme.colors.primary,
	},
	pauseButton: {
		backgroundColor: darkTheme.colors.warning,
	},
	completeButton: {
		backgroundColor: darkTheme.colors.success || "#4CAF50",
	},
	endButton: {
		backgroundColor: darkTheme.colors.error,
	},
	archiveButton: {
		backgroundColor: darkTheme.colors.secondary || "#757575",
	},
	timerControls: {
		marginTop: darkTheme.spacing.md,
		padding: darkTheme.spacing.md,
		backgroundColor: darkTheme.colors.background,
		borderRadius: darkTheme.borderRadius.md,
		gap: darkTheme.spacing.md,
	},
});
