import React from "react";
import { View, Image, TouchableOpacity, Text, ScrollView } from "react-native";
import moment from "moment";
import { APP_COLOURS, screenWidth } from "../../APP_VARS";
import AutoHeightImage from "../../Authed/AutoHeightImageComp";

const InstagramGridView = ({ contentItems, selectedContentCalendar }) => {
	// Filter only Instagram feed posts
	const instagramPosts = contentItems
		.filter(
			(item) =>
				item.postType === "Instagram - Single Post" ||
				item.postType === "Instagram - Feed" ||
				item.postType === "Instagram - 3 post feed" ||
				item.postType === "Instagram - Post carousel",
		)
		.sort((a, b) => moment(b.publishDate).diff(moment(a.publishDate)));

	const phoneWidth = 380;
	const gridItemWidth = (phoneWidth - 90) / 3;

	const renderProfileStats = () => (
		<View style={{ flexDirection: "row", paddingVertical: 15 }}>
			<View style={styles.statContainer}>
				<Text style={styles.statNumber}>{instagramPosts.length}</Text>
				<Text style={styles.statLabel}>posts</Text>
			</View>
			<View style={styles.statContainer}>
				<Text style={styles.statNumber}>0</Text>
				<Text style={styles.statLabel}>followers</Text>
			</View>
			<View style={styles.statContainer}>
				<Text style={styles.statNumber}>0</Text>
				<Text style={styles.statLabel}>following</Text>
			</View>
		</View>
	);

	const renderProfileInfo = () => (
		<View style={{ paddingHorizontal: 15, paddingBottom: 15 }}>
			<View
				style={{
					flexDirection: "row",
					alignItems: "center",
					marginBottom: 10,
				}}>
				<View style={styles.profileImageContainer}>
					<Text style={styles.profileImagePlaceholder}>
						{selectedContentCalendar?.client_name?.[0]?.toUpperCase() ||
							"C"}
					</Text>
				</View>
				{renderProfileStats()}
			</View>
			<Text style={styles.username}>
				{selectedContentCalendar?.client_name || "Client Name"}
			</Text>
			<Text style={styles.handle}>
				{selectedContentCalendar?.handle || "handle"}
			</Text>
			<Text style={styles.bio}>
				{selectedContentCalendar?.description ||
					"No description available"}
			</Text>
		</View>
	);

	return (
		<View style={styles.container}>
			<View style={styles.phoneFrame}>
				<View style={styles.notch} />
				<View style={styles.instagramHeader}>
					<AutoHeightImage
						width={103}
						source={require("../../../assets/images/tetrice-revlon-main.png")}
					/>
				</View>
				<ScrollView style={{ flex: 1 }}>
					{renderProfileInfo()}
					<View style={styles.gridContainer}>
						{instagramPosts.map((post, index) => (
							<View
								key={post.id || index}
								style={[
									styles.gridItem,
									{
										width: gridItemWidth,
										height: gridItemWidth * 1.1,
									},
								]}>
								<TouchableOpacity style={styles.gridItemTouch}>
									{post.assets && post.assets.length > 0 ? (
										<Image
											source={{
												uri: post.assets[0].imageData
													?.url,
												cache: "force-cache",
											}}
											style={styles.gridImage}
										/>
									) : (
										<View
											style={styles.placeholderContainer}>
											<Text
												style={styles.placeholderText}>
												{moment(
													post.publishDate,
												).format("DD MMM")}
											</Text>
										</View>
									)}
								</TouchableOpacity>
							</View>
						))}
					</View>
				</ScrollView>
			</View>
		</View>
	);
};

const styles = {
	container: {
		flex: 1,
		alignItems: "center",
		paddingTop: 20,
	},
	phoneFrame: {
		width: 380,
		height: 700,
		backgroundColor: "#FFF",
		borderRadius: 40,
		borderWidth: 10,
		borderColor: "#000",
		overflow: "hidden",
		position: "relative",
	},
	notch: {
		position: "absolute",
		top: 0,
		width: 150,
		height: 25,
		backgroundColor: "#000",
		alignSelf: "center",
		borderBottomLeftRadius: 20,
		borderBottomRightRadius: 20,
		zIndex: 1,
	},
	instagramHeader: {
		height: 44,
		borderBottomWidth: 0.5,
		borderBottomColor: "#DBDBDB",
		justifyContent: "center",
		paddingHorizontal: 15,
	},
	profileImageContainer: {
		width: 86,
		height: 86,
		borderRadius: 43,
		backgroundColor: APP_COLOURS.PRIMARY,
		justifyContent: "center",
		alignItems: "center",
		marginRight: 15,
	},
	profileImagePlaceholder: {
		color: "#FFF",
		fontSize: 36,
		fontWeight: "bold",
	},
	statContainer: {
		flex: 1,
		alignItems: "center",
	},
	statNumber: {
		fontSize: 16,
		fontWeight: "600",
		color: "#262626",
	},
	statLabel: {
		fontSize: 13,
		color: "#262626",
	},
	username: {
		fontSize: 14,
		fontWeight: "600",
		color: "#262626",
		marginBottom: 2,
	},
	handle: {
		fontSize: 14,
		color: "#262626",
		marginBottom: 4,
	},
	bio: {
		fontSize: 14,
		color: "#262626",
		lineHeight: 20,
	},
	gridContainer: {
		flexDirection: "row",
		flexWrap: "wrap",
		padding: 15,
		borderTopWidth: 0.5,
		borderTopColor: "#DBDBDB",
	},
	gridItem: {
		padding: 1,
	},
	gridItemTouch: {
		flex: 1,
		backgroundColor: APP_COLOURS.BLACK_TRANS,
		borderRadius: 0,
		overflow: "hidden",
	},
	gridImage: {
		width: "100%",
		height: "100%",
		resizeMode: "cover",
	},
	placeholderContainer: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		padding: 5,
	},
	placeholderText: {
		color: APP_COLOURS.BG2,
		fontSize: 12,
		textAlign: "center",
	},
};

export default InstagramGridView;
