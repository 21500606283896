import React, { Component } from "react";
import { View, Text, TouchableOpacity, StyleSheet } from "react-native";
import moment from "moment";
import { APP_COLOURS } from "../../APP_VARS";

export default class TimeSelector extends Component {
	constructor(props) {
		super(props);
		const date = moment(props.selectedDate || new Date());
		this.state = {
			selectedTime: date.format("HH:mm"),
		};
	}

	generateTimeSlots = () => {
		const slots = [];
		for (let hour = 0; hour < 24; hour++) {
			for (let minute of [0, 30]) {
				// Creates slots every 30 minutes
				const timeString = `${hour.toString().padStart(2, "0")}:${minute
					.toString()
					.padStart(2, "0")}`;
				slots.push(timeString);
			}
		}
		return slots;
	};

	handleTimeSelect = (time) => {
		this.setState({ selectedTime: time });

		const [hours, minutes] = time.split(":");
		const date = moment(this.props.selectedDate);
		date.set({
			hours: parseInt(hours),
			minutes: parseInt(minutes),
		});

		if (this.props.onDateSelect) {
			this.props.onDateSelect(date.toDate());
		}
	};

	render() {
		return (
			<View style={styles.container}>
				<Text style={styles.label}>Time:</Text>
				<View style={styles.timeSlotContainer}>
					{this.generateTimeSlots().map((time) => (
						<TouchableOpacity
							key={time}
							style={[
								styles.timeSlot,
								this.state.selectedTime === time &&
									styles.selectedTimeSlot,
							]}
							onPress={() => this.handleTimeSelect(time)}>
							<Text
								style={[
									styles.timeSlotText,
									this.state.selectedTime === time &&
										styles.selectedTimeSlotText,
								]}>
								{time}
							</Text>
						</TouchableOpacity>
					))}
				</View>
			</View>
		);
	}
}

const styles = StyleSheet.create({
	container: {
		padding: 8,
		backgroundColor: "#FFFFFF",
		borderRadius: 6,
	},
	label: {
		fontSize: 14,
		fontWeight: "500",
		color: APP_COLOURS.BG2,
		marginBottom: 10,
	},
	timeSlotContainer: {
		flexDirection: "row",
		flexWrap: "wrap",
		gap: 8,
	},
	timeSlot: {
		padding: 8,
		borderWidth: 1,
		borderColor: "#E8E8E8",
		borderRadius: 4,
		minWidth: 70,
		alignItems: "center",
	},
	selectedTimeSlot: {
		backgroundColor: APP_COLOURS.BG2,
		borderColor: APP_COLOURS.BG2,
	},
	timeSlotText: {
		fontSize: 14,
		color: APP_COLOURS.BG2,
	},
	selectedTimeSlotText: {
		color: "#FFFFFF",
	},
});
