import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	Image,
	TextInput,
	ScrollView,
	Keyboard,
	Modal,
	ActivityIndicator,
} from "react-native";
import moment from "moment";
import Font from "react-font";
// import DatePicker from 'react-native-date-picker';
import { APP_COLOURS, screenHeight } from "../../APP_VARS";

import tick from "../../../assets/images/tick.png";
import starFill from "../../../assets/images/Table/starFill.png";
import star from "../../../assets/images/Table/star.png";
import FullCalendar from "./FullCalendar";
import PostTypes from "./PostTypes";
import DateSelectorSingle from "./DateSelectorSingle";
import TimeSelector from "./TimeSelector";
import ImageUploader from "./ImageUploader";
import TagSelector from "./TagSelector";
import HashTagSelector from "./HashTagSelector";
import StrategyTagSelector from "./StrategyTagSelector";
import { crudCreateWithBase64 } from "../../CRUDHELPER";
// import BrandTabs from "../BrandTabs";

function currencyFormat(num) {
	return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

function uuid() {
	return "xxxxxxxxx".replace(/[xy]/g, function (c) {
		var r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

function capitalizeName(name) {
	return name.replace(/\b(\w)/g, (s) => s.toUpperCase());
}

const BG = "#171B22";
const BG2 = "#20242B";
const FONT = "#F3F3F5";
const BORDER = "#383D43";

const BLUE = "#3F6EFF";
const WHITE = FONT;
const FONT_ALMOSTWHITE = "#D4D4D4";

const RED = "#FF5F5F";
const GREEN = "#3F6EFF";
const OPACITYGREY = "#2F2F39";

const FONT_DARK = "#141D31";
const BORDERBOTTOM = "#28282F";

class AutoHeightImage extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		return (
			<Image
				style={{
					width: this.props.width,
					height:
						typeof this.props.height !== "undefined"
							? this.props.height
							: this.props.width,
					resizeMode: "contain",
				}}
				source={this.props.source}
			/>
		);
	}
}

export default class AddContent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			calendar_id: this.props.selectedContentCalendarId,

			id: uuid(),
			title: "",
			// project: this.props.selectedProject,
			due_date: moment(this.props.selectedDate, "DD/MM/YYYY"),
			date: moment(this.props.selectedDate, "DD/MM/YYYY"),
			publishDate: moment(this.props.selectedDate, "DD/MM/YYYY"),

			assignment_ids: [this.props.user_data.id],
			assignment: [],

			copy: "",
			assets: [],
			assetsToUpload: [],
			strategyTags: [],
		};
		this._timeout = 1000;
	}

	componentDidMount() {
		console.log(this.props.selectedContentCalendarId);
		this.setKeyboardListener();
	}

	generateCalendar = () => {
		let startOfWeek = moment(this.state.due_date).startOf("week");

		console.log("startOfWeek", startOfWeek);

		let datesArray = [];

		let dLoop = Array.from(Array(7).keys());
		let doff = 0;
		for (const d of dLoop) {
			let date = moment(startOfWeek).add(doff, "days");
			doff = d + 1;
			datesArray.push({ date: date, event: true, meeting: true });
		}

		console.log(datesArray);
		// this.setState({
		//   eventCalendar: datesArray,
		// });
	};

	uploadContent = (assets) => {
		let obj = {
			calendar_id: "hZgzLwGl6oUx5YkOj4vi",

			approval: false,

			changeHistory: [],
			channel: "",
			client_id: "df63292e4",
			client_name: "Elizabeth Arden",

			description: "",
			handle: "@elizabetharden_sa",
			id: "hZgzLwGl6oUx5YkOj4vi",

			stage: 1,
			status: "Planning",
			...this.state,
			assets: assets,
		};

		delete obj.assetsToUpload;

		this.props.addEntry(obj);
		console.log(obj);
	};

	render() {
		let form_fields = [
			{
				id: 1,
				editable: true,
				state: "Description of content",
				title: "description",
				autoCapitalize: false,
				validation: "",
				multiline: false,
			},
			{
				id: 2,
				editable: true,
				state: "copy",
				title: "copy",
				autoCapitalize: false,
				validation: "",
				multiline: true,
			},
			// {
			// 	id: 3,
			// 	editable: true,
			// 	state: "total_budget",
			// 	title: "Project Budget",
			// 	autoCapitalize: true,
			// 	validation: "number",
			// },
		];
		return (
			<Modal animationType='fade' transparent={true} visible={true}>
				<View
					style={{
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "rgba(52, 52, 52, 0.6)",
					}}>
					<ScrollView
						showsVerticalScrollIndicator={false}
						ref={(ref) => (this.scrollViewRef = ref)}>
						<View
							style={{
								width: "100%",
								maxWidth: 500,
								// height: this.props.screenHeight / 2,
								height: "auto",
								// maxHeight: screenHeight - this.state.keyboardOffset,
								// flex: 1,
								// backgroundColor: BG,
								backgroundColor: "#FFF",
								borderRadius: 10,
								padding: 10,
								// paddingTop: 50,
								marginTop: 50,
							}}>
							<View
								style={{
									flexDirection: "row",
									alignItems: "center",
									// borderBottomWidth: 0.5,
									paddingBottom: 10,
									// borderBottomcolor: APP_COLOURS.BG2,
									marginBottom: 20,
								}}>
								<TouchableOpacity
									onPress={async () => {
										this.props.close();
									}}>
									<Image
										style={{
											width: 24,
											height: 24,
											resizeMode: "contain",
											marginRight: 10,
										}}
										source={require("../../../assets/images/close.png")}
									/>
								</TouchableOpacity>
								<View style={{}}>
									<Text
										style={{
											color: APP_COLOURS.BG2,
											fontSize: 20,
											marginLeft: 10,
											fontWeight: "600",
											fontFamily: "Gotham",
										}}>
										New content
									</Text>
								</View>
							</View>

							<PostTypes
								selected={(d) => {
									this.setState({
										postType: d,
									});
								}}
							/>
							<Text
								style={{
									color: APP_COLOURS.BG2,
									fontSize: 20,
									marginLeft: 10,
									fontWeight: "600",
									fontFamily: "Gotham",
								}}>
								{this.state.postType}
							</Text>

							{/* <View
								style={{
									marginBottom: 10,
									padding: 10,
									borderRadius: 10,
									backgroundColor: BG2,
									// backgroundColor: "#FFF",
									maxWidth: 350,
								}}>
								<Text
									style={{
										fontSize: 10,
										color: "#FFF",
										fontWeight: "400",
										fontFamily: "Gotham",
										// opacity: 0.5,
										padding: 0,
										marginBottom: 3,
									}}>
									Select brand
								</Text>
								<ScrollView
									horizontal
									showsHorizontalScrollIndicator={false}>
									<BrandTabs
										{...this.state}
										selectedSubPage={(d) =>
											this.setState({
												brand: d,
											})
										}
									/>
								</ScrollView>
							</View> */}

							{/* <View
								style={{
									marginBottom: 10,
									padding: 10,
									borderRadius: 10,
									// backgroundColor: BG2,
									backgroundColor: "#FFF",
								}}>
								<Text
									style={{
										fontSize: 10,
										color: APP_COLOURS.BG2,
										fontWeight: "400",
										fontFamily: "Gotham",
										// opacity: 0.5,
										padding: 0,
										marginBottom: 3,
									}}>
									Priority
								</Text>

								<View
									style={{
										width: 120,
										flexDirection: "row",
										justifyContent: "flex-start",
									}}>
									{[0, 1, 2, 3, 4, 5].map((priority, ii) => (
										<TouchableOpacity
											key={ii}
											key={priority}
											onPress={() =>
												this.setState({
													priority: priority,
												})
											}>
											{this.state.priority >= priority ? (
												<AutoHeightImage
													width={19}
													style={{
														marginRight: 3,
													}}
													source={starFill}
												/>
											) : (
												<AutoHeightImage
													width={19}
													style={{
														marginRight: 3,
														opacity: 0.5,
													}}
													source={star}
												/>
											)}
										</TouchableOpacity>
									))}
								</View>
							</View> */}

							{/* <View
								style={{
									marginTop: 20,
								}}>
								<Text
									style={{
										fontWeight: "400",
										fontFamily: "Gotham",
										fontSize: 12,
										color: BORDER,
										marginBottom: 4,
										position: "absolute",
										top: -12,
										left: 9,
										zIndex: 99,
										backgroundColor: BG,
										padding: 4,
										borderRadius: 8,
									}}>
									Task description
								</Text>

								<TextInput
									style={{
										fontSize: 16,
										color: APP_COLOURS.BG2,
										fontWeight: "400",
										fontFamily: "Gotham",
										width: "100%",
										paddingLeft: 10,
										padding: 13,
										// backgroundColor:
										// 	this.state.docID !== "" ? BLUE : BG2,
										color: APP_COLOURS.BG2,
										borderRadius: 7,
										borderColor: BORDER,
										borderWidth: 1,
									}}
									placeholderTextColor={FONT}
									autoCapitalize='sentences'
									// autoCorrect={false}
									clearButtonMode='while-editing'
									// autoCompleteType="off"
									placeholder={`Task title`}
									onChangeText={(text) =>
										this.setState({
											title: text,
										})
									}
								/>
							</View> */}

							{form_fields.map((d, i) => {
								let validated = `${d.state}_valid`;
								return (
									<View
										key={i}
										style={{
											width: "auto",

											flexDirection: "row",
											alignItems: "center",
											margin: 10,
											marginBottom: 16,
										}}>
										<Text
											style={{
												opacity: this.state.unEditable
													? 0.4
													: 1,
												fontFamily: "Gotham",
												fontSize: 12,
												color: APP_COLOURS.PLACEHOLDER,
												fontWeight: "500",
												marginBottom: 4,
												position: "absolute",
												top: -12,
												left: 9,
												backgroundColor: "#FFF",
												padding: 4,
												borderRadius: 8,
											}}>
											{d.title}
										</Text>
										<TextInput
											style={{
												width: "100%",
												opacity: this.state.unEditable
													? 0.4
													: 1,
												borderWidth: 1,
												borderColor: this.state[
													validated
												]
													? APP_COLOURS.GREEN
													: typeof this.state[
															d.state
													  ] !== "undefined"
													? "#000"
													: APP_COLOURS.RED,
												padding: 12,
												paddingLeft: 30,
												fontSize: 16,
												borderRadius: 8,
												backgroundColor:
													APP_COLOURS.WHITE,
												color: "#000",
												fontWeight: "500",
												fontFamily: "Gotham",
											}}
											placeholderTextColor={"#000"}
											// autoCapitalize="sentences"
											clearButtonMode='while-editing'
											autoCorrect={false}
											placeholder={d.title}
											multiline={d.multiline}
											numberOfLines={d.multiline ? 10 : 1}
											value={this.state[d.state]}
											onChangeText={async (text) => {
												if (d.autoCapitalize) {
													text = await capitalizeName(
														text,
													);
												}

												await this.setState({
													[d.state]: text,
												});
											}}
										/>

										{this.state.unEditable &&
										!d.editable ? (
											<View
												style={{
													position: "absolute",
													width: "100%",
													height: "100%",
													backgroundColor: `rgba(52, 52, 52, 0.3)`,
													flex: 1,
													justifyContent: "center",
													alignItems: "center",
													borderRadius: 8,
												}}>
												<Text
													style={{
														fontFamily: "Gotham",
														fontSize: 12,
														color: APP_COLOURS.WHITE,
														fontWeight: "700",
													}}>
													Uneditable field
												</Text>
											</View>
										) : null}

										{this.state[validated] ? (
											<Image
												source={tick}
												style={{
													width: 16,
													height: 16,
													resizeMode: "contain",
													position: "absolute",
													left: 9,
													top: 16,
												}}
											/>
										) : null}
									</View>
								);
							})}
							<TimeSelector
								selected={(d) => {
									this.setState({
										time: d,
									});
								}}
							/>
							<View
								style={{
									width: "100%",
									justifyContent: "center",
									alignItems: "center",
									padding: 10,
								}}>
								<View
									style={{
										width: "100%",
									}}>
									<Text
										style={{
											fontWeight: "400",
											fontFamily: "Gotham",

											fontSize: 12,
											color: APP_COLOURS.BG2,
											marginBottom: 4,
											position: "absolute",
											top: -12,
											left: 9,
											backgroundColor: "#FFF",
											padding: 4,
											borderRadius: 8,
											zIndex: 99,
										}}>
										Start date
									</Text>
									<View
										style={{
											borderRadius: 7,
											borderColor: BORDER,
											borderWidth: 1,
										}}>
										<TouchableOpacity
											onPress={() => {
												this.setState({
													dateAllocation: "date",
													showCalendar:
														!this.state
															.showCalendar,
												});
											}}>
											<View
												style={{
													width: "100%",
													padding: 13,
													// justifyContent: "center",
													// alignItems: "center",
													paddingLeft: 10,
												}}>
												<Text
													style={{
														color: APP_COLOURS.BG2,
														fontWeight: "600",
														fontSize: 16,
													}}>
													{moment(
														this.state.date,
													).format("DD/MM/YYYY")}
												</Text>
											</View>
										</TouchableOpacity>
									</View>
								</View>
								<View
									style={{
										width: 350,
										alignItems: "center",
										justifyContent: "center",
									}}>
									{this.state.showCalendar ? (
										<DateSelectorSingle
											compWidth={350}
											selectedDate={this.state.date}
											startDate={(date) => {
												console.log("date", date);
												this.setState({
													date: date,
													publishDate: date,
													showCalendar: false,
												});
											}}
										/>
									) : null}
								</View>
							</View>

							<View
								style={{
									width: "100%",
									// height: 300,
								}}>
								<View
									style={{
										flexDirection: "row",
										alignItems: "center",
										justifyContent: "center",
										margin: 10,
										marginBottom: 16,

										width: "auto",
										borderWidth: 1,
										borderColor: "#000",
										// padding: 2,
										overflow: "hidden",
										fontSize: 16,
										borderRadius: 12,
										backgroundColor: APP_COLOURS.WHITE,
										color: "#000",
										fontWeight: "200",
										fontFamily: "Gotham",
									}}>
									<ImageUploader
										assets={(assets) => {
											console.log(assets);
											this.setState({
												assetsToUpload: assets,
											});
										}}
									/>
								</View>
							</View>

							<View style={{}}>
								{this.state.assets.map((dd) => (
									<View
										style={{
											flexDirection: "row",
											alignItems: "center",
										}}>
										<Image
											style={{
												width: 50,
												height: 50,
												resizeMode: "contain",
												marginRight: 10,
											}}
											source={{
												uri: dd.imageData?.url,
											}}
										/>
										<Text style={{ margin: 2 }}>
											{dd.fileName}
										</Text>
									</View>
								))}
							</View>

							<HashTagSelector
								selected={(d) => {
									this.setState({
										hashtags: d,
									});
								}}
							/>

							<TagSelector
								selected={(d) => {
									this.setState({
										tags: d,
									});
								}}
							/>

							<StrategyTagSelector
								selected={(d) => {
									this.setState({
										strategyTags: d,
									});
								}}
							/>

							{/* <TouchableOpacity
									style={{
										width: this.state.addingBrand
											? "auto"
											: "100%",
										padding: 10,
										display:
											this.state.passcode !== ""
												? "flex"
												: "none",
									}}
									onPress={async () => {
										
									}}>
									<View
										style={{
											backgroundColor: APP_COLOURS.BG2,
											width: this.state.addingBrand
												? "auto"
												: "100%",
											padding: 13,
											height: 45,
											borderRadius: 25,
											justifyContent: "center",
											alignItems: "center",
										}}>
										{!this.state.addingBrand ? (
											<Text
												style={{
													alignItems: "center",
													justifyContent: "center",
													color: "#FFF",
													fontSize: 15,
												}}>
												<Font
													weight={700}
													family='Montserrat'>
													Upload assets
												</Font>
											</Text>
										) : (
											<ActivityIndicator color={"#FFF"} />
										)}
									</View>
								</TouchableOpacity> */}

							<TouchableOpacity
								style={{
									width: this.state.addingBrand
										? "auto"
										: "100%",
									padding: 10,
									display:
										this.state.passcode !== ""
											? "flex"
											: "none",
								}}
								onPress={async () => {
									// let { assetsToUpload, assets } = this.state;
									// console.log(assetsToUpload);

									// const upload = await assetsToUpload.map(
									// 	async (a) => {
									// 		let obj = {
									// 			calendar_id:
									// 				this.state.calendar_id,
									// 		};
									// 		obj.document = a;

									// 		await crudCreateWithBase64(
									// 			"contentCalendarImages",
									// 			obj,
									// 			(status_, data) => {
									// 				if (status_ === 200) {
									// 					console.log(data);
									// 					assets.push(data);
									// 				}
									// 			},
									// 		);
									// 	},
									// );

									// delete this.state.assetsToUpload;
									// // await this.setState({
									// // 	assets: assets,
									// // });
									// await this.uploadContent(assets);

									let { assetsToUpload, assets } = this.state;
									console.log(assetsToUpload);

									const uploadPromises = assetsToUpload.map(
										(a) => {
											return new Promise(
												(resolve, reject) => {
													let obj = {
														calendar_id:
															this.state
																.calendar_id,
														document: a,
													};

													crudCreateWithBase64(
														"contentCalendarImages",
														obj,
														(status_, data) => {
															if (
																status_ === 200
															) {
																console.log(
																	data,
																);
																assets.push(
																	data,
																);
																resolve(); // Resolve the promise when the upload is successful
															} else {
																reject(
																	"Upload failed",
																); // Reject the promise if the upload fails
															}
														},
													);
												},
											);
										},
									);

									try {
										// Wait for all upload operations to complete
										await Promise.all(uploadPromises);

										// Once all uploads are done, call uploadContent
										await this.uploadContent(assets);
									} catch (error) {
										console.error(
											"Error uploading images:",
											error,
										);
									}

									// Reset assetsToUpload to indicate that the upload is complete
									this.setState({ assetsToUpload: [] });
								}}>
								<View
									style={{
										backgroundColor: APP_COLOURS.BG2,
										width: this.state.addingBrand
											? "auto"
											: "100%",
										padding: 13,
										height: 45,
										borderRadius: 25,
										justifyContent: "center",
										alignItems: "center",
									}}>
									{!this.state.addingBrand ? (
										<Text
											style={{
												alignItems: "center",
												justifyContent: "center",
												color: "#FFF",
												fontSize: 15,
											}}>
											<Font
												weight={700}
												family='Montserrat'>
												Add content
											</Font>
										</Text>
									) : (
										<ActivityIndicator color={"#FFF"} />
									)}
								</View>
							</TouchableOpacity>
						</View>
					</ScrollView>
				</View>
			</Modal>
		);
	}

	setKeyboardListener = async () => {
		this.keyboardDidShowListener = Keyboard.addListener(
			"keyboardDidShow",
			this._keyboardDidShow,
		);
		this.keyboardDidHideListener = Keyboard.addListener(
			"keyboardDidHide",
			this._keyboardDidHide,
		);
	};

	_keyboardDidShow = (event) => {
		console.log(event.endCoordinates.height);
		if (this.state.keyboardOffset === 0) {
			this.setState({
				keyboardOffset: event.endCoordinates.height,
				// keyboardActive: true,
			});
		}
	};

	_keyboardDidHide = () => {
		// this.setState({
		//   keyboardActive: false,
		// });
		// LayoutAnimation.spring();
	};

	componentWillUnmount() {
		this.setState = (state, callback) => {
			return;
		};
		this.keyboardDidShowListener.remove();
		this.keyboardDidShowListener.remove();
	}
}
