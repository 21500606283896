import React from 'react';
import { View, Text, TextInput, StyleSheet, Pressable } from 'react-native-web';
import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline';

const NDPTable = ({ data, onUpdate }) => {
    const columns = [
        { key: 'glCode', label: 'GL CODES', width: 120 },
        { key: 'description', label: 'DESCRIPTION', width: 150 },
        { key: 'task', label: 'TASK', width: 200 },
        { key: 'status', label: 'STATUS', width: 120 },
        { key: 'implementationDate', label: 'IMPLEMENTATION DATE', width: 150 },
        { key: 'budget', label: 'BUDGET', width: 100 },
        { key: 'poStatus', label: 'PO STATUS', width: 100 },
        { key: 'actual', label: 'ACTUAL', width: 100 },
        { key: 'workingMedia', label: 'Working Media', width: 120 },
        { key: 'nonWorkingMedia', label: 'Non-Working', width: 120 },
    ];

    const handleInputChange = (index, field, value) => {
        const newData = [...data];
        newData[index] = {
            ...newData[index],
            [field]: value,
        };
        onUpdate(newData);
    };

    const addRow = () => {
        const newRow = columns.reduce((acc, col) => ({ ...acc, [col.key]: '' }), {});
        onUpdate([...data, newRow]);
    };

    const deleteRow = (index) => {
        const newData = data.filter((_, i) => i !== index);
        onUpdate(newData);
    };

    return (
        <View style={styles.container}>
            <View style={styles.headerRow}>
                {columns.map((column) => (
                    <View
                        key={column.key}
                        style={{
                            ...styles.headerCell,
                            width: column.width,
                        }}
                    >
                        <Text style={styles.headerText}>{column.label}</Text>
                    </View>
                ))}
                <View style={styles.actionCell}>
                    <Text style={styles.headerText}>Actions</Text>
                </View>
            </View>

            {data.map((row, rowIndex) => (
                <View key={rowIndex} style={styles.row}>
                    {columns.map((column) => (
                        <View
                            key={column.key}
                            style={{
                                ...styles.cell,
                                width: column.width,
                            }}
                        >
                            <TextInput
                                style={styles.input}
                                value={row[column.key] || ''}
                                onChange={(e) =>
                                    handleInputChange(rowIndex, column.key, e.target.value)
                                }
                                placeholder={column.label}
                            />
                        </View>
                    ))}
                    <View style={styles.actionCell}>
                        <Pressable
                            style={({ pressed }) => ({
                                ...styles.deleteButton,
                                ...(pressed && styles.buttonPressed),
                            })}
                            onPress={() => deleteRow(rowIndex)}
                        >
                            <TrashIcon style={{ width: 16, height: 16, color: '#ffffff' }} />
                        </Pressable>
                    </View>
                </View>
            ))}

            <Pressable
                style={({ pressed }) => ({
                    ...styles.addButton,
                    ...(pressed && styles.buttonPressed),
                })}
                onPress={addRow}
            >
                <PlusIcon style={{ width: 16, height: 16, color: '#ffffff' }} />
                <Text style={styles.addButtonText}>Add Row</Text>
            </Pressable>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        borderWidth: 1,
        borderColor: '#e5e7eb',
        borderRadius: 6,
        overflow: 'hidden',
    },
    headerRow: {
        flexDirection: 'row',
        backgroundColor: '#f3f4f6',
        borderBottomWidth: 1,
        borderBottomColor: '#e5e7eb',
    },
    headerCell: {
        padding: 12,
        borderRightWidth: 1,
        borderRightColor: '#e5e7eb',
    },
    headerText: {
        fontSize: 12,
        fontWeight: '600',
        color: '#374151',
    },
    row: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: '#e5e7eb',
    },
    cell: {
        padding: 8,
        borderRightWidth: 1,
        borderRightColor: '#e5e7eb',
    },
    input: {
        fontSize: 14,
        padding: 4,
        borderWidth: 1,
        borderColor: '#e5e7eb',
        borderRadius: 4,
    },
    actionCell: {
        width: 80,
        padding: 8,
        justifyContent: 'center',
        alignItems: 'center',
    },
    deleteButton: {
        padding: 6,
        borderRadius: 4,
        backgroundColor: '#ef4444',
    },
    buttonPressed: {
        opacity: 0.8,
    },
    addButton: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 12,
        backgroundColor: '#dc2626',
        margin: 12,
        borderRadius: 6,
    },
    addButtonText: {
        color: '#ffffff',
        fontSize: 14,
        fontWeight: '500',
    },
});

export default NDPTable;
