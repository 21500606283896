import React, { Component } from "react";
import { View, StyleSheet } from "react-native-web";
import NDPList from "./NDPList";
import NDPDetail from "./NDPDetail";
import BrandTabs from "../BrandTabs";
import {
	crudReadUnass,
	crudCreate,
	crudUpdate,
	crudRead,
} from "../../CRUDHELPER";

const COLLECTION = "tracker_ndp";

class NDPLaunchPlan extends Component {
	constructor(props) {
		super(props);
		this.state = {
			ndps: [],
			selectedNDP: null,
			loading: true,
			selectedBrand: {
				id: "",
				title: "All",
			},
			selectedSubPage: "all",
		};
	}

	componentDidMount() {
		this.fetchNDPs();
	}

	fetchNDPs = async () => {
		try {
			const where = this.state.selectedBrand?.id
				? {
						field: "brand",
						action: "==",
						value: this.state.selectedBrand.id,
				  }
				: "";

			await crudReadUnass(COLLECTION, where, (status, result) => {
				if (status === 200 && result?.status === "success") {
					this.setState({ ndps: result.data });
				} else if (status === 403) {
					console.error("Authentication error. Please log in again.");
				} else {
					console.error("Error fetching NDPs:", result);
				}
				this.setState({ loading: false });
			});
		} catch (error) {
			console.error("Error fetching NDPs:", error);
			this.setState({ loading: false });
		}
	};

	handleAddNDP = async () => {
		// Implementation commented out as per original code
	};

	handleUpdateNDP = async (updatedNDP) => {
		try {
			const id =
				updatedNDP instanceof FormData
					? JSON.parse(updatedNDP.get("data")).id
					: updatedNDP.id;

			if (!id) {
				throw new Error("No ID found in update data");
			}

			return new Promise((resolve, reject) => {
				crudUpdate(COLLECTION, updatedNDP, (status, result) => {
					if (status === 200 && result?.status === "success") {
						const updatedData = result.data;
						if (result.data.imageUrl) {
							updatedData.imageUrl = result.data.imageUrl;
							delete updatedData.image;
						}
						this.setState((prevState) => ({
							ndps: prevState.ndps.map((ndp) =>
								ndp.id === updatedData.id ? updatedData : ndp,
							),
							selectedNDP: updatedData,
						}));
						resolve(result);
					} else {
						const error = new Error(
							result?.message || "Update failed",
						);
						console.error("Error updating NDP:", error);
						reject(error);
					}
				});
			});
		} catch (error) {
			console.error("Error updating NDP:", error);
			throw error;
		}
	};

	render() {
		const { ndps, selectedNDP, loading, selectedBrand, selectedSubPage } =
			this.state;

		return (
			<View style={styles.container}>
				<View style={styles.content}>
					<BrandTabs
						selectedBrandTab={selectedBrand}
						selectedSubPage={(brand) => {
							this.setState(
								{
									selectedBrand: brand,
									selectedSubPage: brand.id
										? brand.title.toLowerCase()
										: "all",
								},
								() => {
									this.fetchNDPs();
								},
							);
						}}
					/>
					<View style={styles.listContainer}>
						<NDPList
							ndps={ndps}
							selectedNDP={selectedNDP}
							onSelectNDP={(ndp) =>
								this.setState({ selectedNDP: ndp })
							}
							onAddNDP={this.handleAddNDP}
							loading={loading}
						/>
						{selectedNDP && (
							<NDPDetail
								ndp={selectedNDP}
								onUpdateNDP={this.handleUpdateNDP}
							/>
						)}
					</View>
				</View>
			</View>
		);
	}
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		flexDirection: "column",
		backgroundColor: "#ffffff",
	},
	content: {
		flex: 1,
		flexDirection: "column",
	},
	listContainer: {
		flex: 1,
		flexDirection: "row",
	},
});

export default NDPLaunchPlan;
