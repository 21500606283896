import React, { Component } from "react";
import { View, Text, ScrollView, TouchableOpacity, Image } from "react-native";
import moment from "moment";
import Fade from "react-reveal/Fade";
import Font from "react-font";
import { APP_COLOURS } from "../../APP_VARS";

export default class TopTabsObj extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tabs: this.props.tabs,
			tab: this.props.tabs[0],
			hover: true,
		};
	}

	componentDidMount() {
		this.props.selectedSubPage(this.state.tab);
	}

	render() {
		return (
			<View>
				<View
					// onMouseEnter={() => this.setState({ hover: true })}
					// onMouseLeave={() => this.setState({ hover: false })}
					style={{
						flexDirection: "row",
					}}>
					{this.state.tabs.map((d, i) => (
						<TouchableOpacity
							style={{
								backgroundColor:
									this.props.selectedTab === d.code
										? APP_COLOURS.BLUE
										: APP_COLOURS.WHITE_TRANSPARENT9,
								borderWidth: 2,
								borderColor:
									this.props.selectedTab === d.code
										? APP_COLOURS.BLUE
										: APP_COLOURS.PLACEHOLDER,
								marginRight: 5,
								borderRadius: 10,
								padding: 10,
								// justifyContent: "center",
								alignItems: "center",
								minWidth: 150,
								flexDirection: "row",
								paddingTop: this.state.hover ? 50 : 10,
								marginLeft: 10,
							}}
							onPress={() => this.props.selectedSubPage(d)}>
							<Text
								style={{
									fontSize: 14,
									fontWeight: "900",
									// this.state.tab === d ? "900" : "300",
									color:
										this.props.selectedTab === d.code
											? "#FFF"
											: APP_COLOURS.BG2,
									marginRight: 15,
									fontFamily: "Gotham",
								}}>
								{d.title}
							</Text>
						</TouchableOpacity>
					))}
				</View>
			</View>
		);
	}
}
