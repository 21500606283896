import React, { useState, useEffect } from "react";
import { View, Text, TouchableOpacity, Image } from "react-native";
import moment from "moment";
import { APP_COLOURS } from "../../APP_VARS";
import AutoHeightImage from "../AutoHeightImageComp";

const PostCalendar = ({
	compWidth,
	startDate,
	endDate,
	selectedDate,
	contentList,
}) => {
	const [months, setMonths] = useState([]);

	useEffect(() => {
		// If no content, use current month
		if (!contentList || contentList.length === 0) {
			setMonths([moment()]);
			return;
		}

		// Get all unique months that have content
		const uniqueMonths = new Set();

		contentList.forEach((content) => {
			const contentDate = moment(content.publishDate);
			uniqueMonths.add(contentDate.format("YYYY-MM"));
		});

		// Convert to array of moment objects, sorted chronologically
		const monthsArray = Array.from(uniqueMonths)
			.map((monthStr) => moment(monthStr, "YYYY-MM"))
			.sort((a, b) => a.valueOf() - b.valueOf());

		setMonths(monthsArray);
	}, [contentList]);

	const renderMonth = (monthDate) => {
		const daysInMonth = monthDate.daysInMonth();
		const firstDayOfMonth = moment(monthDate).startOf("month").day();
		const days = [];

		// Add empty cells for days before the first day of the month
		for (let i = 0; i < firstDayOfMonth; i++) {
			days.push(<View key={`empty-${i}`} style={styles.emptyDay} />);
		}

		// Add days of the month
		for (let i = 1; i <= daysInMonth; i++) {
			const currentDate = moment(monthDate).date(i);
			const isSelected =
				selectedDate && currentDate.isSame(selectedDate, "day");

			// Find content for this day
			const dayContent = contentList.filter((content) =>
				moment(content.publishDate).isSame(currentDate, "day"),
			);

			// Get first content's first asset
			const firstContentImage =
				dayContent.length > 0 &&
				dayContent[0].assets &&
				dayContent[0].assets.length > 0
					? dayContent[0].assets[0].imageData.url
					: null;

			days.push(
				<TouchableOpacity
					key={i}
					style={[
						styles.day,
						isSelected && styles.selectedDay,
						dayContent.length > 0 &&
							!firstContentImage &&
							styles.missingImageDay,
						dayContent.length > 0 && styles.hasContentDay,
					]}
					onPress={() => startDate(currentDate)}>
					{firstContentImage ? (
						<View style={styles.imageContainer}>
							<Image
								source={{ uri: firstContentImage }}
								style={styles.contentImage}
								resizeMode='cover'
							/>
							<View style={styles.dayOverlay}>
								<Text
									style={[
										styles.dayText,
										styles.dayTextOverImage,
										isSelected && styles.selectedDayText,
									]}>
									{i}
								</Text>
							</View>
						</View>
					) : (
						<View style={styles.dayTextContainer}>
							<Text
								style={[
									styles.dayText,
									isSelected && styles.selectedDayText,
									dayContent.length > 0 &&
										styles.hasContentDayText,
								]}>
								{i}
							</Text>
						</View>
					)}
					{dayContent.length > 1 && (
						<View style={styles.contentDot}>
							<Text style={styles.contentCount}>
								{dayContent.length}
							</Text>
						</View>
					)}
				</TouchableOpacity>,
			);
		}

		return (
			<View
				key={monthDate.format("YYYY-MM")}
				style={styles.monthContainer}>
				<Text style={styles.monthTitle}>
					{monthDate.format("MMMM YYYY")}
				</Text>
				<View style={styles.weekDays}>
					{["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map(
						(day) => (
							<Text key={day} style={styles.weekDay}>
								{day}
							</Text>
						),
					)}
				</View>
				<View style={styles.daysGrid}>{days}</View>
			</View>
		);
	};

	return (
		<View style={styles.container}>
			{months.map((month) => renderMonth(month))}
		</View>
	);
};

const styles = {
	container: {
		flex: 1,
		gap: 20,
	},
	monthContainer: {
		marginBottom: 20,
		// backgroundColor: APP_COLOURS.WHITE_TRANSPARENT,
		borderRadius: 10,
		padding: 15,
	},

	monthTitle: {
		fontSize: 18,
		fontWeight: "600",
		color: APP_COLOURS.BG2,
		marginBottom: 15,
		textAlign: "center",
	},
	weekDays: {
		flexDirection: "row",
		justifyContent: "space-around",
		marginBottom: 10,
	},
	weekDay: {
		width: 40,
		textAlign: "center",
		color: APP_COLOURS.BG2,
		fontSize: 12,
		fontWeight: "500",
	},
	daysGrid: {
		flexDirection: "row",
		flexWrap: "wrap",
	},
	day: {
		width: "14.28%",
		// backgroundColor: APP_COLOURS.WHITE,
		// borderRadius: 20,
		// borderWidth: 1,
		// borderColor: APP_COLOURS.WHITE_TRANSPARENT99,
		aspectRatio: 1,
		// justifyContent: "center",
		// alignItems: "center",
		position: "relative",
		padding: 2,
	},
	dayTextContainer: {
		flex: 1,
		backgroundColor: APP_COLOURS.WHITE,
		borderRadius: 18,
		// borderWidth: 1,
		// borderColor: APP_COLOURS.WHITE_TRANSPARENT3,
		justifyContent: "center",
		alignItems: "center",
	},
	emptyDay: {
		width: "14.28%",
		aspectRatio: 1,
	},
	dayText: {
		color: APP_COLOURS.BG2,
		fontSize: 14,
		fontWeight: "600",
	},
	selectedDay: {
		backgroundColor: APP_COLOURS.PRIMARY,
		borderRadius: 20,
	},
	selectedDayText: {
		// color: "#FFF",
		fontWeight: "800",
	},
	hasContentDay: {
		backgroundColor: APP_COLOURS.WHITE_TRANSPARENT2,
		borderRadius: 20,
		overflow: "hidden",
	},
	hasContentDayText: {
		color: APP_COLOURS.BG2,
		fontWeight: "500",
	},
	contentDot: {
		position: "absolute",
		bottom: 5,
		backgroundColor: APP_COLOURS.PRIMARY,
		borderRadius: 10,
		width: 16,
		height: 16,
		justifyContent: "center",
		alignItems: "center",
	},
	contentCount: {
		color: "#FFF",
		fontSize: 10,
		fontWeight: "600",
	},
	imageContainer: {
		width: "100%",
		height: "100%",
		borderRadius: 20,
		overflow: "hidden",
		position: "relative",
	},
	contentImage: {
		width: "100%",
		height: "100%",
		resizeMode: "cover",
	},
	dayOverlay: {
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: "rgba(0,0,0,0.3)",
		justifyContent: "center",
		alignItems: "center",
	},
	dayTextOverImage: {
		color: "#FFF",
		fontWeight: "600",
		textShadowColor: "rgba(0,0,0,0.5)",
		textShadowOffset: { width: 1, height: 1 },
		textShadowRadius: 3,
	},
	missingImageDay: {
		backgroundColor: "#FFA500", // Orange background for days with content but no image
		borderRadius: 20,
	},
};

export default PostCalendar;
