import React, { useState } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	Modal,
	ActivityIndicator,
} from "react-native";
import moment from "moment";
import { APP_COLOURS } from "../../APP_VARS";
import TimeSelector from "./TimeSelector";

const AddContentCalendar = ({ selectedBrand, addEntry, close }) => {
	const [formData, setFormData] = useState({
		title: "",
		description: "",
		start_date: moment().format(),
		// Pre-fill with brand data
		client_name: selectedBrand?.title,
		client_id: selectedBrand?.id,
		handle:
			selectedBrand?.socialChannels?.find(
				(channel) => channel.platform === "Instagram",
			)?.handle || "",
	});
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState({});

	const handleSubmit = () => {
		// Validate form
		const newErrors = {};
		if (!formData.title) newErrors.title = "Title is required";
		if (!formData.start_date)
			newErrors.start_date = "Start date is required";

		if (Object.keys(newErrors).length > 0) {
			setErrors(newErrors);
			return;
		}

		setLoading(true);
		addEntry(formData);
	};

	return (
		<Modal animationType='fade' transparent={true} visible={true}>
			<View style={styles.modalContainer}>
				<View style={styles.modalContent}>
					<View style={styles.modalHeader}>
						<Text style={styles.modalTitle}>
							New Calendar for {selectedBrand?.title || ""}
						</Text>
						<TouchableOpacity
							style={styles.closeButton}
							onPress={close}>
							<Text style={styles.closeButtonText}>×</Text>
						</TouchableOpacity>
					</View>

					<View style={styles.formContainer}>
						<View style={styles.formGroup}>
							<Text style={styles.label}>Calendar Title*</Text>
							<TextInput
								style={[
									styles.input,
									errors.title && styles.inputError,
								]}
								placeholder='e.g. November 2023'
								value={formData.title}
								onChangeText={(text) =>
									setFormData({
										...formData,
										title: text,
									})
								}
							/>
							{errors.title && (
								<Text style={styles.errorText}>
									{errors.title}
								</Text>
							)}
						</View>

						<View style={styles.formGroup}>
							<Text style={styles.label}>Description</Text>
							<TextInput
								style={[styles.input, styles.textArea]}
								placeholder='Calendar description'
								value={formData.description}
								onChangeText={(text) =>
									setFormData({
										...formData,
										description: text,
									})
								}
								multiline
								numberOfLines={4}
							/>
						</View>

						{/* <View style={styles.formGroup}>
							<Text style={styles.label}>Start Date*</Text>
							<TimeSelector
								selectedDate={moment(formData.start_date)}
								onDateChange={(date) =>
									setFormData({
										...formData,
										start_date: date.format(),
									})
								}
								error={errors.start_date}
							/>
							{errors.start_date && (
								<Text style={styles.errorText}>
									{errors.start_date}
								</Text>
							)}
						</View> */}

						<View style={styles.socialChannelsContainer}>
							<Text style={styles.label}>Social Channels</Text>
							{selectedBrand.socialChannels?.map((channel) => (
								<Text
									key={channel.id}
									style={styles.channelText}>
									{channel.platform}: {channel.handle}
								</Text>
							))}
						</View>

						<View style={styles.buttonContainer}>
							<TouchableOpacity
								style={styles.cancelButton}
								onPress={close}>
								<Text style={styles.cancelButtonText}>
									Cancel
								</Text>
							</TouchableOpacity>
							<TouchableOpacity
								style={styles.submitButton}
								onPress={handleSubmit}
								disabled={loading}>
								{loading ? (
									<ActivityIndicator color='#FFF' />
								) : (
									<Text style={styles.submitButtonText}>
										Create Calendar
									</Text>
								)}
							</TouchableOpacity>
						</View>
					</View>
				</View>
			</View>
		</Modal>
	);
};

const styles = {
	modalContainer: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: "rgba(52, 52, 52, 0.6)",
	},
	modalContent: {
		width: "90%",
		maxWidth: 600,
		backgroundColor: "#FFF",
		borderRadius: 10,
		padding: 20,
	},
	modalHeader: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: 20,
	},
	modalTitle: {
		fontSize: 20,
		fontWeight: "600",
		color: APP_COLOURS.BG2,
	},
	closeButton: {
		padding: 10,
	},
	closeButtonText: {
		fontSize: 24,
		color: APP_COLOURS.BG2,
	},
	formContainer: {
		gap: 15,
	},
	formGroup: {
		marginBottom: 15,
	},
	label: {
		fontSize: 14,
		color: APP_COLOURS.BG2,
		marginBottom: 5,
		fontWeight: "500",
	},
	input: {
		borderWidth: 1,
		borderColor: "#E8E8E8",
		borderRadius: 8,
		padding: 10,
		fontSize: 14,
		color: APP_COLOURS.BG2,
		backgroundColor: "#F8F8F8",
	},
	textArea: {
		height: 100,
		textAlignVertical: "top",
	},
	inputError: {
		borderColor: APP_COLOURS.RED,
	},
	errorText: {
		color: APP_COLOURS.RED,
		fontSize: 12,
		marginTop: 5,
	},
	socialChannelsContainer: {
		marginTop: 10,
		padding: 15,
		backgroundColor: "#F8F8F8",
		borderRadius: 8,
	},
	channelText: {
		fontSize: 14,
		color: APP_COLOURS.BG2,
		marginTop: 5,
	},
	buttonContainer: {
		flexDirection: "row",
		justifyContent: "flex-end",
		marginTop: 20,
		gap: 10,
	},
	cancelButton: {
		padding: 10,
		borderRadius: 8,
		borderWidth: 1,
		borderColor: APP_COLOURS.BG2,
	},
	cancelButtonText: {
		color: APP_COLOURS.BG2,
		fontSize: 14,
	},
	submitButton: {
		padding: 10,
		borderRadius: 8,
		backgroundColor: APP_COLOURS.PRIMARY,
		minWidth: 120,
		alignItems: "center",
	},
	submitButtonText: {
		color: "#FFF",
		fontSize: 14,
		fontWeight: "500",
	},
};

export default AddContentCalendar;
