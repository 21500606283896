import React, { Component } from "react";
import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	Image,
	Modal,
	TextInput,
	ActivityIndicator,
} from "react-native";
import moment from "moment";
import { APP_COLOURS } from "../../APP_VARS";
import { Avatars, StatusColour, TaskStatus } from "../../APP_VARS";
// import StatusItem from "../DailyPlanner/StatusItem";
import tick from "./../../../assets/images/tick.png";
import Lottie from "react-lottie";
import Font from "react-font";
import close from "../../../assets/images/close.png";
import {
	crudCreate,
	crudDelete,
	crudGetUsers,
	crudRead,
	crudReadAll,
	crudUpdate,
} from "../../CRUDHELPER";
import Fade from "react-reveal/Fade";
import AutoHeightImage from "../AutoHeightImageComp";

import * as animationData from "../../../assets/lottie/team.json";
import OrganogramComponent from "./OrganogramComponent";

function uuidv4() {
	return "xxxxxxx".replace(/[xy]/g, function (c) {
		let r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

function capitalizeName(name) {
	return name.replace(/\b(\w)/g, (s) => s.toUpperCase());
}

export default class Usermanagement extends Component {
	constructor(props) {
		super(props);
		this.state = {
			users: [],
			selectedUser: "",
			user_data: {
				id: "",
				username: "",
			},
			addNewMember: false,
			formFields: [
				{
					id: 1,
					editable: true,
					state: "username",
					title: "Username",
					autoCapitalize: false,
					validation: "email",
				},
				{
					id: 2,
					editable: true,
					state: "first_name",
					title: "First name",
					autoCapitalize: true,
					validation: "name",
				},
				{
					id: 3,
					editable: true,
					state: "last_name",
					title: "Last name",
					autoCapitalize: true,
					validation: "name",
				},

				{
					id: 4,
					editable: true,
					state: "organisation",
					title: "organisation",
					autoCapitalize: false,
					validation: "none",
				},
				{
					id: 4,
					editable: true,
					state: "position",
					title: "position",
					autoCapitalize: true,
					validation: "none",
				},
			],
			currentTab: "users",
		};
	}

	componentDidMount() {
		this.validateSession();
		this.getusers();
	}
	validateEmail = (text) => {
		// console.log(text);
		let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		if (reg.test(text) === false) {
			// console.log("Email is Not Correct");
			this.setState({ emailValid: false });
			return false;
		} else {
			this.setState({ emailValid: true });
			// console.log("Email is Correct");
			return true;
		}
	};

	getusers = async () => {
		crudGetUsers((users) => {
			this.setState({
				users: users,
				loading: false,
			});
			console.log(users);
		});
	};

	validateSession = async () => {
		let user_data = await localStorage.getItem("user_data");
		let email = await localStorage.getItem("email");
		let accessToken = await localStorage.getItem("accessToken");
		let refresh_token = await localStorage.getItem("refresh_token");

		if (accessToken !== null && accessToken !== "") {
			await this.setState({
				user_data: JSON.parse(user_data),
				email,
				accessToken,
				refresh_token,
			});
			setTimeout(() => {
				this.setState({
					loading: false,
					authenticating: false,
				});
			}, 1000);
		} else {
			localStorage.clear();
			window.location.href = "/";
		}
	};

	render() {
		let users = this.state.users.filter((d_) => {
			let filter = d_;

			if (
				typeof this.state.filterSearch !== "undefined" &&
				this.state.filterSearch !== ""
			) {
				let text_ = this.state.filterSearch;
				var outString = text_.replace(
					/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
					"",
				);

				let join_names = `${JSON.stringify(d_)}`;

				join_names = join_names.replace(
					/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
					"",
				);

				return join_names
					.toLowerCase()
					.includes(outString.toLowerCase());
			}

			return filter;
		});
		return (
			<View
				style={{
					flex: 1,
					padding: 10,
				}}>
				<View
					style={{
						flex: 1,
						width: "100%",
						// maxWidth: 1000,
						flexDirection: "row",
						marginBottom: 5,
						// width: "60%",
					}}>
					<View
						style={{
							flex: 1,
							paddingBottom: 60,
							backgroundColor: "#FFF",
							padding: 5,
							borderRadius: 10,
							// alignItems: "center",
						}}>
						<View
							style={{
								flexDirection: "row",
								justifyContent: "space-between",
								alignItems: "center",
							}}>
							<View
								style={{
									padding: 10,
								}}>
								<TouchableOpacity
									style={{
										flexDirection: "row",
										alignItems: "center",
										// position: "absolute",
									}}
									onPress={() => {
										// this.props.close();
									}}>
									<AutoHeightImage
										width={20}
										source={require("../../../assets/images/menu.png")}
									/>
									<Text
										adjustsFontSizeToFit
										style={{
											fontWeight: "800",
											fontFamily: "Gotham",
											color: APP_COLOURS.BG2,
											fontSize: 13,
											marginLeft: 4,
										}}>
										tetrice
									</Text>
								</TouchableOpacity>
								<Text
									style={{
										color: APP_COLOURS.BG2,
										fontSize: 28,
										fontWeight: "800",
										fontFamily: "Gotham",
										// marginTop: 20,
										// marginTop: 6,
									}}>
									User management
								</Text>

								<Text
									style={{
										fontSize: 16,
										color: APP_COLOURS.BG2,
										fontWeight: "300",
										padding: 2,
										marginBottom: 20,
									}}>
									User profile management
								</Text>
							</View>
							<View
								style={
									{
										// position: "absolute",
										// right: 10,
										// top: 10,
									}
								}>
								<Lottie
									options={{
										loop: true,
										autoplay: true,
										animationData: animationData,
										rendererSettings: {
											preserveAspectRatio:
												"xMidYMid slice",
										},
									}}
									height={150}
									width={200}
								/>
							</View>
						</View>

						<View
							style={{
								// flex: 1,
								width: "100%",
								flexDirection: "row",
								alignItems: "center",
								height: 45,
								paddingLeft: 5,
							}}>
							<View
								style={{
									// height: "auto",
									// width: "100%",
									flex: 1,
									minWidth: 200,
									// maxWidth: 400,
									backgroundColor: APP_COLOURS.BLACK_TRANS,
									borderRadius: 13,
								}}>
								{/* {this.state.showInput ? ( */}
								<TextInput
									style={{
										fontWeight: "400",
										fontFamily: "Gotham",

										width: "100%",
										paddingLeft: 15,
										padding: 10,
										fontSize: 15,
										borderRadius: 13,
										marginRight: 15,
										color: APP_COLOURS.BG2,
										// backgroundColor:
										// 	APP_COLOURS.WHITE_TRANSPARENT4,
										backgroundColor: "#E8E8E8",
										fontWeight: "500",
									}}
									placeholderTextColor={"#797979"}
									autoCapitalize='sentences'
									clearButtonMode='while-editing'
									autoCompleteType='off'
									editable={true}
									autoCorrect={true}
									autoFocus
									keyboardType='default'
									textContentType='none'
									placeholder={` Search `}
									// defaultValue={this.state.user_data.email}
									defaultValue={this.state.filterSearch}
									onChangeText={(text) => {
										console.log(text);
										this.setState({
											filterSearch: text,
										});
									}}
								/>
								{/* ) : null} */}
							</View>
							<TouchableOpacity
								style={{
									marginRight: 10,
									marginLeft: 10,
								}}
								onPress={() =>
									this.setState({
										showInput: !this.state.showInput,
									})
								}>
								<AutoHeightImage
									width={19}
									source={require("../../../assets/images/searchIcon.png")}
								/>
							</TouchableOpacity>
							{this.state.loading ? (
								<ActivityIndicator
									color={APP_COLOURS.PRIMARY}
								/>
							) : null}
							{this.state.user_data.username ===
							"wesley@tetrice.co.za" ? (
								<TouchableOpacity
									style={{
										// marginRight: 15,
										marginLeft: 15,
										// backgroundColor: APP_COLOURS.BG2,
										borderRadius: 15,
										flexDirection: "row",
										paddingVertical: 10,
										alignItems: "center",
										paddingHorizontal: 15,
										borderWidth: 0.5,
										borderColor: APP_COLOURS.BG2,
									}}
									onPress={async () => {
										this.setState({
											addNewMember:
												!this.state.addNewMember,
										});
									}}>
									<Text
										style={{
											color: APP_COLOURS.BG2, // "#FFF",
											fontSize: 14,
											marginRight: 10,
										}}>
										+
									</Text>
									<Text
										style={{
											color: APP_COLOURS.BG2, // "#FFF",
											fontSize: 12,
										}}>
										New
									</Text>
								</TouchableOpacity>
							) : null}
						</View>

						<View
							style={{
								flex: 1,
							}}>
							<ScrollView showsVerticalScrollIndicator={false}>
								<View
									style={{
										flexDirection: "row",
										marginBottom: 20,
										borderBottomWidth: 1,
										borderBottomColor:
											APP_COLOURS.BLACK_TRANS,
									}}>
									<TouchableOpacity
										style={{
											padding: 10,
											borderBottomWidth: 2,
											borderBottomColor:
												this.state.currentTab ===
												"users"
													? APP_COLOURS.BLUE
													: "transparent",
										}}
										onPress={() =>
											this.setState({
												currentTab: "users",
											})
										}>
										<Text
											style={{
												color:
													this.state.currentTab ===
													"users"
														? APP_COLOURS.BLUE
														: APP_COLOURS.BLACK,
											}}>
											Users List
										</Text>
									</TouchableOpacity>

									<TouchableOpacity
										style={{
											padding: 10,
											borderBottomWidth: 2,
											borderBottomColor:
												this.state.currentTab ===
												"organogram"
													? APP_COLOURS.BLUE
													: "transparent",
										}}
										onPress={() =>
											this.setState({
												currentTab: "organogram",
											})
										}>
										<Text
											style={{
												color:
													this.state.currentTab ===
													"organogram"
														? APP_COLOURS.BLUE
														: APP_COLOURS.BLACK,
											}}>
											Organization Chart
										</Text>
									</TouchableOpacity>
								</View>

								{this.state.currentTab === "users" ? (
									<View style={{ flex: 1 }}>
										{users
											.filter(
												(d) =>
													d.organisation ===
													"tetrice",
											)
											.sort((a, b) => a.order > b.order)
											.map((d) => this.userListItem(d))}

										<Text
											style={{
												fontSize: 16,
												color: APP_COLOURS.BG2,
												fontWeight: "900",
												padding: 2,
												marginTop: 20,
											}}>
											Clients
										</Text>
										{users
											.filter(
												(d) =>
													d.organisation !==
													"tetrice",
											)
											.map((d) => this.userListItem(d))}
									</View>
								) : null}
							</ScrollView>
						</View>
					</View>
					<View
						style={{
							// flex: 2,
							width: 700,
						}}>
						{this.state.selectedUser !== ""
							? this.profileItem(this.state.selectedUser)
							: null}

						{/* {this.state.currentTab === "organogram" ? (
							<View style={{ flex: 1 }}>
								<OrganogramComponent users={users} />
							</View>
						) : null} */}

						{this.state.addNewMember ? this.addNewMember() : null}
					</View>
				</View>
			</View>
		);
	}

	userListItem = (d) => {
		let size = 40;
		return (
			<TouchableOpacity
				key={d.id}
				style={{}}
				onPress={() =>
					this.setState({
						selectedUser: d,
					})
				}>
				<View
					style={{
						width: "auto",
						// justifyContent: "space-between",
						backgroundColor:
							this.state.selectedUser.id === d.id
								? APP_COLOURS.BLUE
								: "transparent",
						padding: 8,
						borderRadius: 5,
						marginRight: 5,
						flexDirection: "row",
						// marginBottom: 1,
						alignItems: "center",
						borderTopColor: "#E8E8E8",
						borderTopWidth: 0.5,
					}}>
					<View
						style={{
							flexDirection: "row",
							justifyContent: "flex-start",
							alignItems: "center",

							width: size,
							height: size,
							// overflow: "hidden",
							borderRadius: size / 2,
							shadowColor: "#6494B7",
							shadowOffset: {
								width: 0,
								height: 4,
							},
							shadowOpacity: 0.23,
							shadowRadius: 4.65,
							elevation: 8,
						}}>
						{typeof d.avatar !== "undefined" ? (
							<Image
								// source={avatars[parseInt(ass.avatar)]}
								source={Avatars[parseInt(d.avatar)].pp}
								style={{
									width: size,
									height: size,
									borderRadius: size / 2,
									resizeMode: "contain",
									backgroundColor: "#FFF",
								}}
							/>
						) : null}
					</View>
					<View
						style={{
							flex: 1,
							paddingLeft: 5,
							// flexDirection: "row",
							// alignItems: "center",
						}}>
						<View
							style={{
								flex: 1,
								flexDirection: "row",
							}}>
							<Text
								style={{
									fontSize: 16,
									color:
										this.state.selectedUser.id === d.id
											? "#FFF"
											: APP_COLOURS.BG2,
									fontWeight: "500",
									opacity: 0.7,
								}}>
								{d.first_name}{" "}
							</Text>
							<Text
								style={{
									fontSize: 16,
									color:
										this.state.selectedUser.id === d.id
											? "#FFF"
											: APP_COLOURS.BG2,
									fontWeight: "500",
								}}>
								{d.last_name}
							</Text>
						</View>
						{/* <Text
							style={{
								fontSize: 15,
								color:
									this.state.selectedUser.id === d.id
										? "#FFF"
										: APP_COLOURS.BG2,
								fontWeight: "500",
							}}>
							{d.username}
						</Text> */}
						<Text
							style={{
								// maxWidth: 160,
								fontSize: 10,
								color:
									this.state.selectedUser.id === d.id
										? "#FFF"
										: APP_COLOURS.BG2,
								fontWeight: "500",
								// textAlign: "right",
							}}>
							{typeof d.position !== "undefined"
								? d.position
								: d.organisation}
						</Text>
					</View>
					{/* <View
						style={{
							justifyContent: "flex-end",
							alignItems: "flex-end",
						}}>
						
					</View> */}
				</View>
			</TouchableOpacity>
		);
	};

	profileItem = (d) => {
		let size = 55;
		return (
			<View
				style={{
					flex: 1,
					paddingLeft: 10,
				}}>
				<View
					style={{
						flex: 1,
						alignItems: "center",
						backgroundColor: APP_COLOURS.BG4,
						padding: 10,
						borderRadius: 15,
					}}>
					<View
						style={{
							flexDirection: "row",
							justifyContent: "flex-start",
							alignItems: "center",

							width: size,
							shadowColor: "#6494B7",
							shadowOffset: {
								width: 0,
								height: 4,
							},
							shadowOpacity: 0.23,
							shadowRadius: 4.65,
							elevation: 8,
						}}>
						{typeof d.avatar !== "undefined" ? (
							<Image
								// source={avatars[parseInt(ass.avatar)]}
								source={Avatars[parseInt(d.avatar)].pp}
								style={{
									width: size,
									height: size,
									borderRadius: size / 2,
									resizeMode: "contain",
									backgroundColor: "#FFF",
								}}
							/>
						) : null}
					</View>
					<View style={{ flexDirection: "row" }}>
						<Text
							style={{
								fontSize: 16,
								color: APP_COLOURS.TEXTCOLOR,
								fontWeight: "500",
								opacity: 0.7,
							}}>
							{d.first_name}{" "}
						</Text>
						<Text
							style={{
								fontSize: 16,
								color: APP_COLOURS.TEXTCOLOR,
								fontWeight: "500",
							}}>
							{d.last_name}
						</Text>
					</View>
					<Text
						style={{
							fontSize: 16,
							color: APP_COLOURS.TEXTCOLOR,
							fontWeight: "500",
						}}>
						{d.username}
					</Text>
				</View>
				<View
					style={{
						flex: 1,
						alignItems: "center",
						backgroundColor: APP_COLOURS.BG4,
						padding: 10,
						borderRadius: 15,
					}}></View>
			</View>
		);
	};

	addNewMember = () => {
		return (
			<Modal animationType='fade' transparent={true} visible={true}>
				<View
					style={{
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "rgba(52, 52, 52, 0.6)",
					}}>
					<View
						style={{
							width: "100%",
							maxWidth: 500,
							height: "auto",
							backgroundColor: "white",
							borderRadius: 20,
							justifyContent: "center",
							alignItems: "center",
						}}>
						<View
							style={{
								position: "absolute",
								zIndex: 99,
								top: 10,
								left: 10,
							}}>
							<TouchableOpacity
								onPress={() =>
									this.setState({
										addNewMember: false,
									})
								}>
								<Image
									source={close}
									style={{
										width: 20,
										height: 20,
										resizeMode: "contain",
									}}
								/>
							</TouchableOpacity>
						</View>
						<View
							style={{
								width: "100%",
								padding: 20,
								height: "auto",
							}}>
							<Text
								allowFontScaling={false}
								style={{
									fontSize: 17 + this.state.fontSize,
									fontFamily: "Gotham",
									textAlign: "center",
									fontWeight: "600",
									marginBottom: 20,
								}}>
								Add staff member
							</Text>
							{/* <Text
								allowFontScaling={false}
								style={{
									fontSize: 16 + this.state.fontSize,
									fontFamily: "Gotham",
									textAlign: "center",
									fontWeight: "400",
									marginBottom: 20,
									width: "100%",
								}}>
								Please note, correctly formatted CSV/Excel spreadsheets
								are neccessary for the assessment to be uploaded and
								displayed correctly.
							</Text> */}
							{this.state.formFields.map((d, i) => {
								let validated = `${d.state}_valid`;
								return (
									<View
										key={i}
										style={{
											width: "auto",
											marginBottom: 16,
											flexDirection: "row",
											alignItems: "center",
										}}>
										<Text
											style={{
												opacity: this.state.unEditable
													? 0.4
													: 1,
												fontFamily: "Gotham",
												fontSize: 12,
												color: APP_COLOURS.PLACEHOLDER,
												fontWeight: "500",
												marginBottom: 4,
												position: "absolute",
												top: -12,
												left: 9,
												backgroundColor: "#FFF",
												padding: 4,
												borderRadius: 8,
											}}>
											{d.title}
										</Text>
										<TextInput
											style={{
												width: "100%",
												opacity: this.state.unEditable
													? 0.4
													: 1,
												borderWidth: 1,
												borderColor: this.state[
													validated
												]
													? APP_COLOURS.GREEN
													: typeof this.state[
															d.state
													  ] !== "undefined"
													? "#000"
													: APP_COLOURS.RED,
												padding: 12,
												paddingLeft: 30,
												fontSize: 16,
												borderRadius: 8,
												backgroundColor:
													APP_COLOURS.WHITE,
												color: "#000",
												fontWeight: "200",
												fontFamily: "Gotham",
											}}
											placeholderTextColor={"#000"}
											autoCapitalize='words'
											clearButtonMode='while-editing'
											autoCompleteType='email'
											editable={d.editable}
											onFocus={() => {
												this.setState({
													unEditable: !d.editable,
												});
											}}
											onBlur={() => {
												this.setState({
													unEditable: false,
												});
											}}
											autoCorrect={false}
											keyboardType='email-address'
											textContentType='emailAddress'
											placeholder={d.title}
											// defaultValue={this.state.user_data.email}
											value={this.state[d.state]}
											onChangeText={async (text) => {
												let valid = false;

												if (d.autoCapitalize) {
													text = await capitalizeName(
														text,
													);
												}

												if (d.validation === "email") {
													valid =
														await this.validateEmail(
															text,
														);
												}

												await this.setState({
													[d.state]: text,
													[validated]: valid,
												});
											}}
										/>

										{this.state.unEditable &&
										!d.editable ? (
											<View
												style={{
													position: "absolute",
													width: "100%",
													height: "100%",
													backgroundColor: `rgba(52, 52, 52, 0.3)`,
													flex: 1,
													justifyContent: "center",
													alignItems: "center",
													borderRadius: 8,
												}}>
												<Text
													style={{
														fontFamily: "Gotham",
														fontSize: 12,
														color: APP_COLOURS.WHITE,
														fontWeight: "700",
													}}>
													Uneditable field
												</Text>
											</View>
										) : null}

										{this.state[validated] ? (
											<Image
												source={tick}
												style={{
													width: 16,
													height: 16,
													resizeMode: "contain",
													position: "absolute",
													left: 9,
													top: 16,
												}}
											/>
										) : null}
									</View>
								);
							})}
						</View>

						<TouchableOpacity
							style={{
								width: this.state.addingStaff ? "auto" : "100%",
								padding: 10,
							}}
							onPress={async () => {
								await this.addStaffMember();
							}}>
							<View
								style={{
									backgroundColor: APP_COLOURS.RED,
									width: this.state.addingStaff
										? "auto"
										: "100%",
									padding: 13,
									height: 45,
									borderRadius: 25,
									justifyContent: "center",
									alignItems: "center",
								}}>
								{!this.state.addingStaff ? (
									<Text
										style={{
											alignItems: "center",
											justifyContent: "center",
											color: "#FFF",
											fontSize: 15,
										}}>
										<Font weight={700} family='Montserrat'>
											Add staff member
										</Font>
									</Text>
								) : (
									<ActivityIndicator color={"#FFF"} />
								)}
							</View>
						</TouchableOpacity>
					</View>
				</View>
			</Modal>
		);
	};

	addStaffMember = async () => {
		this.setState({
			addingStaff: true,
			errorLogin: false,
		});

		let { first_name, last_name, username, organisation } = this.state;

		let date_created = moment().toISOString();

		let permission = "1";
		let obj = {
			first_name,
			last_name,
			username,
			permission,
			date_created,
			organisation,
		};

		// console.log(obj);
		// return;

		var requestOptions = {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Origin: "",
				dataType: "json",
				Authorization: `Bearer ${this.state.accessToken}`,
			},
			redirect: "follow",
			body: JSON.stringify(obj),
		};
		// console.log(requestOptions);
		// return;
		await fetch(
			"https://workflow.tetrice.co.za/webhook/6c275bec-7881-49ee-8044-b60d250433bf",
			requestOptions,
		)
			.then(async (response) => {
				console.log("response =====", response);
				if (response.status === 200) {
					this.getusers();
					this.setState({
						addNewMember: false,
					});
				} else {
					alert("error adding staff member");
					this.setState({
						addNewMember: false,
					});
				}
			})
			.catch((error) => {
				this.setState({
					addNewMember: false,
				});
			});
	};
}
