import React, { Component } from "react";
import { View, Text, TouchableOpacity, Modal } from "react-native";
import { APP_COLOURS, TaskStatus, PROJECTSTATUS } from "../../APP_VARS";
import { crudUpdate } from "../../CRUDHELPER";
import moment from "moment";
import { ScrollView } from "react-native-web";

export default class CalendarStatus extends Component {
	constructor(props) {
		super(props);
		this.state = {
			status: this.props.status || "Planning",
			showModalArea: false,
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.status !== this.props.status) {
			this.setState({
				status: this.props.status,
			});
		}
	}

	handleStatusChange = (newStatus) => {
		this.setState({
			status: newStatus,
			showModalArea: false,
		});

		const updates = {
			status: newStatus,
			updatedAt: moment().toISOString(),
		};

		// First update local state
		if (this.props.onUpdate) {
			this.props.onUpdate(updates);
		}

		// Then update database
		crudUpdate(
			"contentcalendars",
			{ status: newStatus, id: this.props.calendarId },
			(status_code) => {
				if (status_code !== 200) {
					console.error("Error updating status");
					// Revert to previous status if update fails
					this.setState({
						status: this.props.status,
					});
				}
			},
		);
	};

	render() {
		return (
			<View
				style={{
					width: 80,
					backgroundColor: TaskStatus(this.state.status),
					borderRadius: 4,
					overflow: "hidden",
				}}>
				<TouchableOpacity
					style={{
						flex: 1,
					}}
					onPress={() => {
						this.setState({
							showModalArea: !this.state.showModalArea,
						});
					}}>
					<View
						style={{
							backgroundColor: TaskStatus(this.state.status),
							padding: 5,
							flex: 1,
							alignItems: "center",
							justifyContent: "center",
						}}>
						<Text
							numberOfLines={1}
							adjustsFontSizeToFit
							style={{
								textAlign: "center",
								color:
									this.state.status === "copy" ||
									this.state.status === "Design"
										? "#FFF"
										: "#000",
								fontSize: 12,
							}}>
							{this.state.status}
						</Text>
					</View>
				</TouchableOpacity>

				{this.state.showModalArea && (
					<Modal
						animationType='fade'
						transparent={true}
						visible={true}>
						<View
							style={{
								width: window.innerWidth,
								height: window.innerHeight,
								backgroundColor: "rgba(0, 0, 0, 0.75)",
								justifyContent: "center",
								alignItems: "center",
							}}>
							<TouchableOpacity
								activeOpacity={1}
								style={{
									width: window.innerWidth,
									height: window.innerHeight,
									backgroundColor: "rgba(0, 0, 0, 0.75)",
									zIndex: 10,
									position: "absolute",
									top: 0,
								}}
								onPress={() => {
									this.setState({
										showModalArea: false,
									});
								}}
							/>

							<View
								style={{
									position: "absolute",
									width: 300,
									height: "auto",
									maxHeight: 350,
									backgroundColor: "#20242B",
									borderRadius: 15,
									padding: 10,
									zIndex: 999,
									shadowColor: "#000",
									shadowOffset: {
										width: 0,
										height: 4,
									},
									shadowOpacity: 0.23,
									shadowRadius: 4.65,
									elevation: 8,
								}}>
								<ScrollView>
									{PROJECTSTATUS.sort(
										(a, b) => a.type > b.type,
									).map((status, ii) => (
										<TouchableOpacity
											key={ii}
											onPress={() => {
												this.handleStatusChange(
													status.status,
												);
											}}
											style={{
												flexDirection: "row",
												justifyContent: "center",
												alignItems: "center",
												marginBottom: 2,
												backgroundColor: TaskStatus(
													status.status,
												),
												width: "100%",
												borderRadius: 10,
												marginRight: 4,
											}}>
											<View
												style={{
													padding: 10,
												}}>
												<Text
													numberOfLines={1}
													adjustsFontSizeToFit
													style={{
														fontFamily: "Gotham",
														fontSize: 15,
														fontWeight: "600",
														color:
															status.status ===
																"copy" ||
															status.status ===
																"Design"
																? "#FFF"
																: "#000",
													}}>
													{status.status}
												</Text>
											</View>
										</TouchableOpacity>
									))}
								</ScrollView>
							</View>
						</View>
					</Modal>
				)}
			</View>
		);
	}
}
