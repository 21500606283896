import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	Image,
	TextInput,
	ScrollView,
	Keyboard,
	Modal,
	ActivityIndicator,
} from "react-native";
import moment from "moment";
import Font from "react-font";
// import DatePicker from 'react-native-date-picker';
import { APP_COLOURS, screenHeight } from "../../APP_VARS";

import tick from "../../../assets/images/tick.png";
import starFill from "../../../assets/images/Table/starFill.png";
import star from "../../../assets/images/Table/star.png";

import BrandTabs from "../BrandTabs";
import TopTabs from "../TopTabs";
import TopTabsObj from "../TopTabs/TabPassObj";

function currencyFormat(num) {
	return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

function uuid() {
	return "xxxxxxxxx".replace(/[xy]/g, function (c) {
		var r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

function capitalizeName(name) {
	return name.replace(/\b(\w)/g, (s) => s.toUpperCase());
}

const BG = "#171B22";
const BG2 = "#20242B";
const FONT = "#F3F3F5";
const BORDER = "#383D43";

const BLUE = "#3F6EFF";
const WHITE = FONT;
const FONT_ALMOSTWHITE = "#D4D4D4";

const RED = "#FF5F5F";
const GREEN = "#3F6EFF";
const OPACITYGREY = "#2F2F39";

const FONT_DARK = "#141D31";
const BORDERBOTTOM = "#28282F";

class AutoHeightImage extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		return (
			<Image
				style={{
					width: this.props.width,
					height:
						typeof this.props.height !== "undefined"
							? this.props.height
							: this.props.width,
					resizeMode: "contain",
				}}
				source={this.props.source}
			/>
		);
	}
}

export default class AddService extends Component {
	constructor(props) {
		super(props);
		const selectedService = props.selectedService || {};

		this.state = {
			id: selectedService.id || uuid(),
			title: selectedService.title || "",
			description: selectedService.description || "",
			sla_id: selectedService.sla_id || "",
			total_cost: selectedService.total_cost?.amount || "",
			brand: selectedService.brand || null,
			service: selectedService.service || null,
			platform: selectedService.platform || null,

			// Add any other fields that need to be preserved
			status: selectedService.status || "Pending",
			assignment: selectedService.assignment || [],
			assignment_ids: selectedService.assignment_ids || [
				props.user_data.id,
			],

			// UI state
			addingBrand: false,
			showInput: false,
		};
		this._timeout = 1000;
	}

	componentDidMount() {
		this.setKeyboardListener();
	}

	componentDidUpdate(prevProps) {
		// If selectedService changes, update state
		if (
			prevProps.selectedService !== this.props.selectedService &&
			this.props.selectedService
		) {
			const selectedService = this.props.selectedService;
			this.setState({
				id: selectedService.id,
				title: selectedService.title,
				description: selectedService.description,
				sla_id: selectedService.sla_id,
				total_cost: selectedService.total_cost?.amount,
				brand: selectedService.brand,
				service: selectedService.service,
				platform: selectedService.platform,
				status: selectedService.status,
				assignment: selectedService.assignment,
				assignment_ids: selectedService.assignment_ids,
			});
		}
	}

	generateCalendar = () => {
		let startOfWeek = moment(this.state.due_date).startOf("week");

		console.log("startOfWeek", startOfWeek);

		let datesArray = [];

		let dLoop = Array.from(Array(7).keys());
		let doff = 0;
		for (const d of dLoop) {
			let date = moment(startOfWeek).add(doff, "days");
			doff = d + 1;
			datesArray.push({ date: date, event: true, meeting: true });
		}

		console.log(datesArray);
		// this.setState({
		//   eventCalendar: datesArray,
		// });
	};

	deleteService = () => {
		if (this.props.selectedService) {
			// Show confirmation dialog
			if (
				window.confirm("Are you sure you want to delete this service?")
			) {
				this.props.deleteEntry(this.props.selectedService);
			}
		}
	};

	render() {
		let form_fields = [
			{
				id: 2,
				editable: true,
				state: "title",
				title: "title",
				autoCapitalize: true,
				validation: "name",
			},
			{
				id: 3,
				editable: true,
				state: "total_budget",
				title: "Project Budget",
				autoCapitalize: true,
				validation: "number",
			},
		];
		return (
			<Modal animationType='fade' transparent={true} visible={true}>
				<View
					style={{
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "rgba(52, 52, 52, 0.6)",
					}}>
					<ScrollView
						showsVerticalScrollIndicator={false}
						ref={(ref) => (this.scrollViewRef = ref)}>
						<View
							style={{
								width: "100%",
								maxWidth: 600,
								// height: this.props.screenHeight / 2,
								height: "auto",
								// maxHeight: screenHeight - this.state.keyboardOffset,
								// flex: 1,
								// backgroundColor: BG,
								// backgroundColor: "#FFF",
								backgroundColor: BG2,
								borderRadius: 10,
								padding: 10,
								// paddingTop: 50,
								marginTop: 50,
							}}>
							<View
								style={{
									flexDirection: "row",
									alignItems: "center",
									// borderBottomWidth: 0.5,
									paddingBottom: 10,
									// borderBottomcolor: APP_COLOURS.BG2,
									marginBottom: 20,
								}}>
								<TouchableOpacity
									onPress={async () => {
										this.props.close();
									}}>
									<Image
										style={{
											width: 24,
											height: 24,
											resizeMode: "contain",
											marginRight: 10,
										}}
										source={require("../../../assets/images/close.png")}
									/>
								</TouchableOpacity>
								<View style={{}}>
									<Text
										style={{
											color: "#FFF",
											fontSize: 20,
											marginLeft: 10,
											fontWeight: "600",
											fontFamily: "Gotham",
										}}>
										{this.props.selectedService
											? "Edit SLA"
											: "New SLA"}
									</Text>
								</View>
							</View>

							<View
								style={{
									marginBottom: 10,
									padding: 10,
									borderRadius: 10,
									backgroundColor: BG2,
									// backgroundColor: "#FFF",
									width: "100%",
									display: this.props.selectedService
										? "none"
										: "flex",
								}}>
								<Text
									style={{
										fontSize: 10,
										color: "#FFF",
										fontWeight: "400",
										fontFamily: "Gotham",
										// opacity: 0.5,
										padding: 0,
										marginBottom: 3,
									}}>
									Select brand
								</Text>
								<ScrollView
									horizontal
									showsHorizontalScrollIndicator={false}>
									<BrandTabs
										tabs={[
											{ id: "revlon", title: "REVLON" },
											{
												id: "ea",
												title: "Elizabeth Arden",
											},
										]}
										selectedBrand={this.state.brand}
										selectedSubPage={(d) =>
											this.setState({
												brand: d,
											})
										}
									/>
								</ScrollView>
							</View>

							<View
								style={{
									marginBottom: 10,
									padding: 10,
									borderRadius: 10,
									backgroundColor: BG2,
									// backgroundColor: "#FFF",
									width: "100%",
								}}>
								<Text
									style={{
										fontSize: 10,
										color: "#FFF",
										fontWeight: "400",
										fontFamily: "Gotham",
										// opacity: 0.5,
										padding: 0,
										marginBottom: 3,
									}}>
									Select service
								</Text>
								<ScrollView
									horizontal
									showsHorizontalScrollIndicator={false}>
									<TopTabsObj
										{...this.state}
										selectedTab={this.state.service?.code}
										screenHeight={this.props.screenHeight}
										screenWidth={this.props.screenWidth}
										selectedSubPage={(d) =>
											this.setState({
												service: d,
											})
										}
										tabs={[
											{
												title: "SaaS",
												code: "SaaS",
											},

											{
												title: "SDaaS",
												code: "SDaaS",
											},
											{
												title: "Consulting Services",
												code: "CS",
											},
											{
												title: "Community Management",
												code: "CM",
											},
											{
												title: "Content Creation",
												code: "CC",
											},
											{
												title: "Tech Support",
												code: "TS",
											},
											{
												title: "Digital Merchandising",
												code: "DM",
											},
											{
												title: "Data solutions",
												code: "DS",
											},
											{
												title: "Managed Cloud",
												code: "MC",
											},
											{
												title: "CRM Software",
												code: "SW-CRM",
											},
											{
												title: "SEO Services",
												code: "MKT-SEO",
											},
											{
												title: "Email Marketing",
												code: "MKT-EM",
											},
											{
												title: "PPC Advertising",
												code: "MKT-PPC",
											},
											{
												title: "Paid Media Advertising",
												code: "MKT-PM",
											},
											{
												title: "Analytics Software",
												code: "SW-AN",
											},
											{
												title: "Affiliate Marketing",
												code: "MKT-AFF",
											},
											{
												title: "Content Management System",
												code: "SW-CMS",
											},
											{
												title: "Marketing Automation",
												code: "SW-MA",
											},
											{
												title: "Graphic Design Software",
												code: "SW-GD",
											},
											{
												title: "Video Editing Software",
												code: "SW-VE",
											},
											{
												title: "Content Scheduling",
												code: "SW-CS",
											},
											{
												title: "Customer Support Software",
												code: "SW-CSUP",
											},
											{
												title: "User Experience (UX) Tools",
												code: "SW-UX",
											},
											{
												title: "A/B Testing Tools",
												code: "SW-ABT",
											},
											{
												title: "Landing Page Builder",
												code: "SW-LPB",
											},
											{
												title: "Affiliate Management System",
												code: "SW-AMS",
											},
											{
												title: "Database Management",
												code: "SW-DB",
											},
											{
												title: "Influencer Collaborations",
												code: "MKT-IC",
											},
											{
												title: "Referral Marketing",
												code: "MKT-RM",
											},
											{
												title: "Virtual Reality Advertising",
												code: "MKT-VR",
											},
											{
												title: "Augmented Reality Marketing",
												code: "MKT-AR",
											},
											{
												title: "Chatbots and AI Customer Support",
												code: "SW-CB",
											},
											{
												title: "Voice Search Optimization",
												code: "MKT-VSO",
											},
											{
												title: "Interactive Content Creation",
												code: "MKT-ICC",
											},
											{
												title: "Podcast Advertising",
												code: "MKT-PA",
											},
											{
												title: "Mobile App Marketing",
												code: "MKT-MAM",
											},
											{
												title: "Retargeting Campaigns",
												code: "MKT-RET",
											},
											{
												title: "E-commerce Software",
												code: "SW-ECOMM",
											},
										]}
									/>
								</ScrollView>
							</View>

							<View
								style={{
									marginBottom: 10,
									padding: 10,
									borderRadius: 10,
									backgroundColor: BG2,
									width: "100%",
								}}>
								<Text
									style={{
										fontSize: 10,
										color: "#FFF",
										fontWeight: "400",
										fontFamily: "Gotham",
										// opacity: 0.5,
										padding: 0,
										marginBottom: 3,
									}}>
									Select platform
								</Text>
								<ScrollView
									horizontal
									showsHorizontalScrollIndicator={false}>
									<TopTabsObj
										{...this.state}
										selectedTab={this.state.platform?.code}
										screenHeight={this.props.screenHeight}
										screenWidth={this.props.screenWidth}
										selectedSubPage={(d) =>
											this.setState({
												platform: d,
											})
										}
										tabs={[
											{
												title: "Social Media Platforms",
												code: "SM",
											},
											// {
											// 	title: "LinkedIn",
											// 	code: "LI",
											// },
											// {
											// 	title: "YouTube",
											// 	code: "YT",
											// },

											// {
											// 	title: "Podcasting",
											// 	code: "PD",
											// },
											// {
											// 	title: "Webinars/Online Events",
											// 	code: "WEB-EV",
											// },

											// {
											// 	title: "Snapchat",
											// 	code: "SC",
											// },
											// {
											// 	title: "Pinterest",
											// 	code: "PT",
											// },

											// {
											// 	title: "Website",
											// 	code: "WW",
											// },
											// {
											// 	title: "TikTok",
											// 	code: "TT",
											// },
											// {
											// 	title: "Facebook",
											// 	code: "FB",
											// },
											// {
											// 	title: "Instagram",
											// 	code: "IN",
											// },
											// {
											// 	title: "Twitter/X",
											// 	code: "TX",
											// },
											// {
											// 	title: "Threads",
											// 	code: "TH",
											// },
											{
												title: "Ecommerce platforms",
												code: "DM",
											},
											// {
											// 	title: "Takealot",
											// 	code: "ECOMM-TA",
											// },
											// {
											// 	title: "Clicks",
											// 	code: "ECOMM-CL",
											// },
											// {
											// 	title: "Dischem",
											// 	code: "ECOMM-DC",
											// },
											// {
											// 	title: "Truworths",
											// 	code: "ECOMM-TW",
											// },
											// {
											// 	title: "Bash",
											// 	code: "ECOMM-BS",
											// },
											// {
											// 	title: "Superbalist",
											// 	code: "ECOMM-SB",
											// },
											// {
											// 	title: "Amazon",
											// 	code: "ECOMM-AZ",
											// },
											{
												title: "Cloud platforms",
												code: "CLOUD",
											},

											{
												title: "Google Ads",
												code: "ECOMM-ABC",
											},
											{
												title: "Google Cloud Platform",
												code: "CLOUD-GCP",
											},
											{
												title: "Amazong Web Services",
												code: "CLOUD-AWS",
											},
											{
												title: "Digital ocean",
												code: "CLOUD-DO",
											},
											{
												title: "TRAQ - Staff management",
												code: "TRAQ",
											},
											{
												title: "Secondment - Consultant",
												code: "CONSULTANT",
											},
										]}
									/>
								</ScrollView>
							</View>

							<View
								style={{
									padding: 5,
								}}>
								<Text
									style={{
										color: "#FFF",
										fontSize: 14,
										marginLeft: 10,
										fontWeight: "600",
										fontFamily: "Gotham",
										flexWrap: "wrap",
									}}>
									{this.state.brand?.title} -{" "}
									{this.state.service?.title}
									{" - "}
									{this.state.platform?.title}
								</Text>
								<Text
									style={{
										color: "#FFF",
										fontSize: 14,
										marginLeft: 10,
										fontWeight: "600",
										fontFamily: "Gotham",
										flexWrap: "wrap",
									}}>
									{this.state.brand?.title} -{" "}
									{this.state.service?.code}
									{" - "}
									{this.state.platform?.code}
								</Text>
							</View>

							<View
								style={{
									marginTop: 20,
								}}>
								<Text
									style={{
										fontWeight: "400",
										fontFamily: "Gotham",
										fontSize: 12,
										color: "#FFF",
										marginBottom: 4,
										position: "absolute",
										top: -12,
										left: 9,
										zIndex: 99,
										backgroundColor: BG2,
										padding: 4,
										borderRadius: 8,
									}}>
									SLA Reference number
								</Text>

								<TextInput
									style={{
										fontSize: 16,
										color: APP_COLOURS.BG2,
										fontWeight: "400",
										fontFamily: "Gotham",
										width: "100%",
										paddingLeft: 10,
										padding: 13,
										color: "#FFF",
										borderRadius: 7,
										borderColor: BORDER,
										borderWidth: 1,
									}}
									placeholderTextColor={FONT}
									autoCapitalize='sentences'
									// autoCorrect={false}
									clearButtonMode='while-editing'
									// autoCompleteType="off"
									placeholder={``}
									value={this.state.sla_id}
									onChangeText={(text) =>
										this.setState({
											sla_id: text,
										})
									}
								/>
							</View>

							<View
								style={{
									marginTop: 20,
								}}>
								<Text
									style={{
										fontWeight: "400",
										fontFamily: "Gotham",
										fontSize: 12,
										color: "#FFF",
										marginBottom: 4,
										position: "absolute",
										top: -12,
										left: 9,
										zIndex: 99,
										backgroundColor: BG2,
										padding: 4,
										borderRadius: 8,
									}}>
									Service description
								</Text>

								<TextInput
									style={{
										fontSize: 16,
										color: APP_COLOURS.BG2,
										fontWeight: "400",
										fontFamily: "Gotham",
										width: "100%",
										paddingLeft: 10,
										padding: 13,
										color: "#FFF",
										borderRadius: 7,
										borderColor: BORDER,
										borderWidth: 1,
									}}
									multiline
									numberOfLines={10}
									placeholderTextColor={FONT}
									autoCapitalize='sentences'
									// autoCorrect={false}
									clearButtonMode='while-editing'
									// autoCompleteType="off"
									placeholder={``}
									value={this.state.description}
									onChangeText={(text) =>
										this.setState({
											description: text,
										})
									}
								/>
							</View>

							<View
								style={{
									marginTop: 20,
								}}>
								<Text
									style={{
										fontWeight: "400",
										fontFamily: "Gotham",
										fontSize: 12,
										color: "#FFF",
										marginBottom: 4,
										position: "absolute",
										top: -12,
										left: 9,
										zIndex: 99,
										backgroundColor: BG2,
										padding: 4,
										borderRadius: 8,
									}}>
									Cost: ZAR
								</Text>

								<View
									style={{
										fontSize: 16,
										color: APP_COLOURS.BG2,
										fontWeight: "700",
										fontFamily: "Gotham",
										width: "100%",
										paddingLeft: 10,
										padding: 13,
										color: "#FFF",
										borderRadius: 7,
										borderColor: BORDER,
										borderWidth: 1,
										flexDirection: "row",
										alignItems: "center",
									}}>
									<Text
										style={{
											fontWeight: "700",
											fontFamily: "Gotham",
											fontSize: 16,
											color: "#FFF",
										}}>
										R
									</Text>
									<TextInput
										style={{
											fontSize: 16,
											color: APP_COLOURS.BG2,
											fontWeight: "700",
											fontFamily: "Gotham",
											flex: 1,
											color: "#FFF",
											padding: 4,
										}}
										placeholderTextColor={FONT}
										keyboardType='decimal-pad'
										autoCapitalize='sentences'
										clearButtonMode='while-editing'
										value={this.state.total_cost.toString()}
										onChangeText={(text) =>
											this.setState({
												total_cost: text,
											})
										}
										placeholder={``}
									/>
								</View>
							</View>

							{/* {form_fields.map((d, i) => {
								let validated = `${d.state}_valid`;
								return (
									<View
										key={i}
										style={{
											width: "auto",

											flexDirection: "row",
											alignItems: "center",
											margin: 10,
											marginBottom: 16,
										}}>
										<Text
											style={{
												opacity: this.state.unEditable
													? 0.4
													: 1,
												fontFamily: "Gotham",
												fontSize: 12,
												color: APP_COLOURS.PLACEHOLDER,
												fontWeight: "500",
												marginBottom: 4,
												position: "absolute",
												top: -12,
												left: 9,
												backgroundColor: "#FFF",
												padding: 4,
												borderRadius: 8,
											}}>
											{d.title}
										</Text>
										<TextInput
											style={{
												width: "100%",
												opacity: this.state.unEditable
													? 0.4
													: 1,
												borderWidth: 1,
												borderColor: this.state[
													validated
												]
													? APP_COLOURS.GREEN
													: typeof this.state[
															d.state
													  ] !== "undefined"
													? "#000"
													: APP_COLOURS.RED,
												padding: 12,
												paddingLeft: 30,
												fontSize: 16,
												borderRadius: 8,
												backgroundColor:
													APP_COLOURS.WHITE,
												color: "#000",
												fontWeight: "500",
												fontFamily: "Gotham",
											}}
											placeholderTextColor={"#000"}
											// autoCapitalize="sentences"
											clearButtonMode='while-editing'
											autoCorrect={false}
											placeholder={d.title}
											value={this.state[d.state]}
											onChangeText={async (text) => {
												if (d.autoCapitalize) {
													text = await capitalizeName(
														text,
													);
												}

												await this.setState({
													[d.state]: text,
												});
											}}
										/>

										{this.state.unEditable &&
										!d.editable ? (
											<View
												style={{
													position: "absolute",
													width: "100%",
													height: "100%",
													backgroundColor: `rgba(52, 52, 52, 0.3)`,
													flex: 1,
													justifyContent: "center",
													alignItems: "center",
													borderRadius: 8,
												}}>
												<Text
													style={{
														fontFamily: "Gotham",
														fontSize: 12,
														color: APP_COLOURS.WHITE,
														fontWeight: "700",
													}}>
													Uneditable field
												</Text>
											</View>
										) : null}

										{this.state[validated] ? (
											<Image
												source={tick}
												style={{
													width: 16,
													height: 16,
													resizeMode: "contain",
													position: "absolute",
													left: 9,
													top: 16,
												}}
											/>
										) : null}
									</View>
								);
							})} */}

							{/* <View
								style={{
									width: 350,
									// display: this.state.showCalendar
									// 	? "flex"
									// 	: "none",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<View
									style={{
										width: 330,
										flexDirection: "row",
									}}>
									<View
										style={{
											marginTop: 20,
											flex: 1,
											marginRight: 10,
										}}>
										<Text
											style={{
												fontWeight: "400",
												fontFamily: "Gotham",

												fontSize: 12,
												color: APP_COLOURS.BG2,
												marginBottom: 4,
												position: "absolute",
												top: -12,
												left: 9,
												backgroundColor: "#FFF",
												padding: 4,
												borderRadius: 8,
												zIndex: 99,
											}}>
											Start date
										</Text>
										<View
											style={{
												borderRadius: 7,
												borderColor: BORDER,
												borderWidth: 1,
											}}>
											<TouchableOpacity
												onPress={() => {
													this.setState({
														dateAllocation:
															"start_date",
														showCalendar:
															!this.state
																.showCalendar,
													});
												}}>
												<View
													style={{
														width: "100%",
														padding: 13,
														// justifyContent: "center",
														// alignItems: "center",
														paddingLeft: 10,
													}}>
													<Text
														style={{
															color: APP_COLOURS.BG2,
															fontWeight: "600",
															fontSize: 16,
														}}>
														{moment(
															this.state
																.start_date,
														).format("DD/MM/YYYY")}
													</Text>
												</View>
											</TouchableOpacity>
										</View>
									</View>
									<View
										style={{
											marginTop: 20,
											flex: 1,
										}}>
										<Text
											style={{
												fontWeight: "400",
												fontFamily: "Gotham",

												fontSize: 12,
												color: APP_COLOURS.BG2,
												marginBottom: 4,
												position: "absolute",
												top: -12,
												left: 9,
												backgroundColor: "#FFF",
												padding: 4,
												borderRadius: 8,
												zIndex: 99,
											}}>
											Due date
										</Text>
										<View
											style={{
												borderRadius: 7,
												borderColor: BORDER,
												borderWidth: 1,
											}}>
											<TouchableOpacity
												onPress={() => {
													this.setState({
														dateAllocation:
															"due_date",
													});
												}}>
												<View
													style={{
														width: "100%",
														padding: 13,
														// justifyContent: "center",
														// alignItems: "center",
														paddingLeft: 10,
													}}>
													<Text
														style={{
															color: APP_COLOURS.BG2,
															fontWeight: "600",
															fontSize: 16,
														}}>
														{moment(
															this.state.due_date,
														).format("DD/MM/YYYY")}
													</Text>
												</View>
											</TouchableOpacity>
										</View>
									</View>
								</View>
							</View> */}

							<TouchableOpacity
								style={{
									width: this.state.addingBrand
										? "auto"
										: "100%",
									padding: 10,
								}}
								onPress={async () => {
									let brand = this.state.brand;
									let service = this.state.service;
									let platform = this.state.platform;
									let description = this.state.description;
									let sla_id = this.state.sla_id;
									let serviceCode = `${service.code}${platform.code}`;
									let total_cost = this.state.total_cost;

									if (this.props.selectedService) {
										// Create an object only with changed fields
										let changedFields = {};

										if (
											description !==
											this.props.selectedService
												.description
										) {
											changedFields.description =
												description;
										}

										if (
											total_cost !==
											this.props.selectedService
												.total_cost?.amount
										) {
											changedFields.total_cost = {
												amount: total_cost,
												currency: "ZAR",
											};
										}

										if (
											brand !==
											this.props.selectedService.brand
										) {
											changedFields.brand = brand;
										}

										if (
											service !==
											this.props.selectedService.service
										) {
											changedFields.service = service;
										}

										if (
											platform !==
											this.props.selectedService.platform
										) {
											changedFields.platform = platform;
										}

										if (
											sla_id !==
											this.props.selectedService.sla_id
										) {
											changedFields.sla_id = sla_id;
										}

										// Only update if there are changes
										if (
											Object.keys(changedFields).length >
											0
										) {
											this.props.updateEntry({
												...changedFields,
												id: this.props.selectedService
													.id,
												referenceId:
													this.props.selectedService
														.referenceId,
											});
										}
									} else {
										let start_date = moment(
											"01/11/2023",
											"DD/MM/YYYY",
										);

										let referenceId = `${
											brand.title
										}-${moment(
											start_date,
										).unix()}-${serviceCode}`;

										let total_cost = this.state.total_cost;

										let obj = {
											referenceId: referenceId,
											sla_id: sla_id,
											title: `${service.title} - ${brand.title} - ${platform.title} `,

											brand: brand,
											service: service,
											platform: platform,
											serviceCode: serviceCode,

											recurring: "Monthly",
											total_cost: {
												amount: total_cost,
												currency: "ZAR",
											},
											description: description,

											end_date: "",
											start_date: start_date,
											status: "Pending",
											assignment: [],
											assignment_ids: [
												this.props.user_data.id,
											],
										};

										// delete obj.keyboardOffset;
										this.props.addEntry(obj);
									}
								}}>
								<View
									style={{
										backgroundColor: APP_COLOURS.BG2,
										width: this.state.addingBrand
											? "auto"
											: "100%",
										padding: 13,
										height: 45,
										borderRadius: 25,
										justifyContent: "center",
										alignItems: "center",
									}}>
									{!this.state.addingBrand ? (
										<Text
											style={{
												alignItems: "center",
												justifyContent: "center",
												color: "#FFF",
												fontSize: 15,
											}}>
											<Font
												weight={700}
												family='Montserrat'>
												{this.props.selectedService
													? "Update service"
													: "Add service"}
											</Font>
										</Text>
									) : (
										<ActivityIndicator color={"#FFF"} />
									)}
								</View>
							</TouchableOpacity>

							{this.props.selectedService && (
								<TouchableOpacity
									style={{ marginTop: 10 }}
									onPress={() => {
										this.deleteService();
									}}>
									<View
										style={{
											backgroundColor: APP_COLOURS.RED,
											width: "100%",
											padding: 13,
											height: 45,
											borderRadius: 25,
											justifyContent: "center",
											alignItems: "center",
										}}>
										<Text
											style={{
												alignItems: "center",
												justifyContent: "center",
												color: "#FFF",
												fontSize: 15,
											}}>
											<Font
												weight={700}
												family='Montserrat'>
												Delete service
											</Font>
										</Text>
									</View>
								</TouchableOpacity>
							)}
						</View>
					</ScrollView>
				</View>
			</Modal>
		);
	}

	setKeyboardListener = async () => {
		this.keyboardDidShowListener = Keyboard.addListener(
			"keyboardDidShow",
			this._keyboardDidShow,
		);
		this.keyboardDidHideListener = Keyboard.addListener(
			"keyboardDidHide",
			this._keyboardDidHide,
		);
	};

	_keyboardDidShow = (event) => {
		console.log(event.endCoordinates.height);
		if (this.state.keyboardOffset === 0) {
			this.setState({
				keyboardOffset: event.endCoordinates.height,
				// keyboardActive: true,
			});
		}
	};

	_keyboardDidHide = () => {
		// this.setState({
		//   keyboardActive: false,
		// });
		// LayoutAnimation.spring();
	};

	componentWillUnmount() {
		this.setState = (state, callback) => {
			return;
		};
		this.keyboardDidShowListener.remove();
		this.keyboardDidShowListener.remove();
	}
}
