import React, { Component } from "react";
import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	Modal,
	TextInput,
	FlatList,
} from "react-native";
import { APP_COLOURS } from "../../APP_VARS";
import channelsData from "./channels.json";

const FUNNEL_STAGES = {
	AWARENESS: "Awareness",
	CONSIDERATION: "Consideration",
	CONVERSION: "Conversion",
	LOYALTY: "Loyalty",
};

const PERFORMANCE_METRICS = {
	REACH: "Reach",
	ENGAGEMENT: "Engagement",
	CLICKS: "Clicks",
	SALES: "Sales",
	ROI: "ROI",
};

const UNIT_METRICS = {
	TOTAL_UNITS: 10000, // Total units available
	UNITS_SOLD: 6500, // Current units sold
	AVERAGE_PRICE: 299.99, // Average unit price
	TOTAL_SPEND: 150000, // Total marketing spend
	TARGET_CPU: 20, // Target Cost Per Unit
	TARGET_ROAS: 3.5, // Target Return on Ad Spend
};

const INDUSTRY_BENCHMARKS = {
	SELL_THROUGH: {
		target: 80, // 80% sell-through
		timeframe: 8, // 8 weeks
		current: 65, // Current performance (example)
		weeksElapsed: 6, // Weeks elapsed (example)
	},
	UNIT_ECONOMICS: {
		target_cpu: 20, // Target Cost Per Unit
		target_roas: 3.5, // Target Return on Ad Spend
		target_conversion: 4, // Target Conversion Rate %
	},
};

const COST_METRICS = {
	CPM: "CPM",
	CPC: "CPC",
	TOTAL_SPEND: "totalSpend",
	IMPRESSIONS: "impressions",
	CLICKS: "clicks",
};

export default class ChannelPerformance extends Component {
	constructor(props) {
		super(props);
		this.state = {
			channels: channelsData.channels,
			showAddModal: false,
			selectedChannel: null,
			activeFilter: null,
			sortMetric: null,
			totalBudget: "",
			selectedGoal: null,
			showBudgetModal: false,
			showRetailerModal: false,
			selectedRetailers: [],
		};
	}

	getPerformanceColor = (score) => {
		if (score >= 75) return APP_COLOURS.GREEN;
		if (score >= 50) return APP_COLOURS.YELLOW;
		return APP_COLOURS.RED;
	};

	calculateSpendSuggestion = (channel) => {
		const performanceAvg =
			Object.values(channel.performance).reduce((a, b) => a + b, 0) / 5;
		const funnelScore = channel.funnelContribution[channel.primaryFocus];

		// Complex algorithm to suggest spend adjustments
		const currentEfficiency = (performanceAvg * funnelScore) / 100;
		const currentSpend = channel.spendAllocation;

		if (currentEfficiency > 70 && currentSpend < 40) {
			return {
				suggestion: "INCREASE",
				amount: "+5-10%",
				reason: "High performance in primary focus area with room for growth",
			};
		} else if (currentEfficiency < 40 && currentSpend > 20) {
			return {
				suggestion: "DECREASE",
				amount: "-5-10%",
				reason: "Underperforming in key metrics",
			};
		}
		return {
			suggestion: "MAINTAIN",
			amount: "0%",
			reason: "Current spend aligned with performance",
		};
	};

	getSortedChannels = () => {
		const { channels, activeFilter, sortMetric, selectedRetailers } =
			this.state;

		let filteredChannels = channels;

		// Apply retailer filter if any retailers are selected
		if (selectedRetailers.length > 0) {
			filteredChannels = channels.filter((channel) =>
				selectedRetailers.includes(channel.retailer),
			);
		}

		if (!activeFilter) return filteredChannels;

		return [...filteredChannels].sort((a, b) => {
			if (sortMetric === "funnel") {
				return (
					b.funnelContribution[activeFilter] -
					a.funnelContribution[activeFilter]
				);
			}
			return b.performance[activeFilter] - a.performance[activeFilter];
		});
	};

	calculateBudgetAllocation = (channels) => {
		const { totalBudget, selectedGoal } = this.state;
		if (!totalBudget || !selectedGoal) return null;

		const budget = parseFloat(totalBudget);
		if (isNaN(budget)) return null;

		// Calculate weighted scores based on selected goal
		const channelsWithScores = channels.map((channel) => {
			let score;
			if (Object.values(FUNNEL_STAGES).includes(selectedGoal)) {
				// For funnel stages, combine funnel contribution with ROI
				score =
					channel.funnelContribution[selectedGoal] * 0.7 +
					channel.performance[PERFORMANCE_METRICS.ROI] * 0.3;
			} else {
				// For performance metrics, use the metric directly
				score = channel.performance[selectedGoal];
			}
			return { ...channel, score };
		});

		// Calculate total score
		const totalScore = channelsWithScores.reduce(
			(sum, channel) => sum + channel.score,
			0,
		);

		// Calculate budget allocation
		return channelsWithScores.map((channel) => ({
			...channel,
			recommendedBudget: (channel.score / totalScore) * budget,
		}));
	};

	calculateSellThroughProgress = () => {
		const { target, current, timeframe, weeksElapsed } =
			INDUSTRY_BENCHMARKS.SELL_THROUGH;
		const progressPercentage = (current / target) * 100;
		const timeProgressPercentage = (weeksElapsed / timeframe) * 100;
		const isOnTrack = progressPercentage >= timeProgressPercentage;

		return {
			progressPercentage,
			timeProgressPercentage,
			isOnTrack,
			remainingWeeks: timeframe - weeksElapsed,
			projectedFinal: (current / weeksElapsed) * timeframe,
		};
	};

	calculateUnitMetrics = () => {
		const {
			TOTAL_UNITS,
			UNITS_SOLD,
			AVERAGE_PRICE,
			TOTAL_SPEND,
			TARGET_CPU,
			TARGET_ROAS,
		} = UNIT_METRICS;

		const currentCPU = TOTAL_SPEND / UNITS_SOLD;
		const currentROAS = (UNITS_SOLD * AVERAGE_PRICE) / TOTAL_SPEND;
		const projectedUnits = TOTAL_SPEND / TARGET_CPU;
		const unitGap = projectedUnits - UNITS_SOLD;
		const conversionRate = (UNITS_SOLD / this.getTotalReach()) * 100;

		return {
			currentCPU,
			currentROAS,
			projectedUnits,
			unitGap,
			conversionRate,
			unitsRemaining: TOTAL_UNITS - UNITS_SOLD,
			revenue: UNITS_SOLD * AVERAGE_PRICE,
			isEfficient: currentCPU <= TARGET_CPU,
			isProfitable: currentROAS >= TARGET_ROAS,
		};
	};

	getTotalReach = () => {
		return this.state.channels.reduce((total, channel) => {
			return (
				total + channel.performance[PERFORMANCE_METRICS.REACH] * 1000
			); // Assuming reach is in thousands
		}, 0);
	};

	getUniqueRetailers = () => {
		return [
			...new Set(this.state.channels.map((channel) => channel.retailer)),
		];
	};

	toggleRetailerSelection = (retailer) => {
		this.setState((prevState) => {
			const currentSelected = [...prevState.selectedRetailers];
			const index = currentSelected.indexOf(retailer);

			if (index === -1) {
				currentSelected.push(retailer);
			} else {
				currentSelected.splice(index, 1);
			}

			return { selectedRetailers: currentSelected };
		});
	};

	calculateEfficiencyMetrics = (channel) => {
		const { costMetrics } = channel;

		// Calculate actual CPM and CPC
		const actualCPM =
			(costMetrics.totalSpend / costMetrics.impressions) * 1000;
		const actualCPC = costMetrics.totalSpend / costMetrics.clicks;

		// Calculate efficiency (lower is better)
		const cpmEfficiency = (costMetrics.CPM / actualCPM) * 100;
		const cpcEfficiency = (costMetrics.CPC / actualCPC) * 100;

		return {
			actualCPM,
			actualCPC,
			cpmEfficiency,
			cpcEfficiency,
			projectedImpressions:
				costMetrics.totalSpend / (costMetrics.CPM / 1000),
			projectedClicks: costMetrics.totalSpend / costMetrics.CPC,
		};
	};

	calculateEstimatedMetrics = (channel, recommendedBudget) => {
		const { costMetrics, performance } = channel;

		// Calculate estimated impressions based on CPM
		const estimatedImpressions =
			recommendedBudget / (costMetrics.CPM / 1000);

		// Calculate estimated clicks based on historical CTR
		const historicalCTR = costMetrics.clicks / costMetrics.impressions;
		const estimatedClicks = estimatedImpressions * historicalCTR;

		// Calculate estimated reach based on historical reach rate
		const reachRate = performance[PERFORMANCE_METRICS.REACH] / 100;
		const estimatedReach = estimatedImpressions * reachRate;

		// Calculate estimated engagement based on historical performance
		const engagementRate =
			performance[PERFORMANCE_METRICS.ENGAGEMENT] / 100;
		const estimatedEngagements = estimatedImpressions * engagementRate;

		return {
			impressions: Math.round(estimatedImpressions),
			clicks: Math.round(estimatedClicks),
			reach: Math.round(estimatedReach),
			engagements: Math.round(estimatedEngagements),
			cpm: costMetrics.CPM,
			cpc: costMetrics.CPC,
			ctr: (historicalCTR * 100).toFixed(2),
		};
	};

	renderRetailerModal = () => {
		const retailers = this.getUniqueRetailers();

		return (
			<Modal
				visible={this.state.showRetailerModal}
				transparent={true}
				animationType='slide'>
				<View style={styles.modalOverlay}>
					<View style={styles.retailerModalContent}>
						<Text style={styles.modalTitle}>
							Filter by Retailer
						</Text>

						<FlatList
							data={retailers}
							renderItem={({ item }) => (
								<TouchableOpacity
									style={styles.retailerItem}
									onPress={() =>
										this.toggleRetailerSelection(item)
									}>
									<View style={styles.retailerCheckbox}>
										{this.state.selectedRetailers.includes(
											item,
										) && (
											<View
												style={
													styles.retailerCheckboxSelected
												}
											/>
										)}
									</View>
									<Text style={styles.retailerItemText}>
										{item}
									</Text>
								</TouchableOpacity>
							)}
							keyExtractor={(item) => item}
						/>

						<View style={styles.modalButtons}>
							<TouchableOpacity
								style={[styles.modalButton, styles.clearButton]}
								onPress={() =>
									this.setState({ selectedRetailers: [] })
								}>
								<Text style={styles.modalButtonText}>
									Clear All
								</Text>
							</TouchableOpacity>

							<TouchableOpacity
								style={[styles.modalButton, styles.closeButton]}
								onPress={() =>
									this.setState({ showRetailerModal: false })
								}>
								<Text style={styles.modalButtonText}>
									Apply
								</Text>
							</TouchableOpacity>
						</View>
					</View>
				</View>
			</Modal>
		);
	};

	renderCostMetricsLegend = () => (
		<View style={styles.metricsLegend}>
			<Text style={styles.legendTitle}>Cost Metrics Guide:</Text>
			<View style={styles.legendItem}>
				<Text style={styles.legendTerm}>CPM (Cost Per Mille)</Text>
				<Text style={styles.legendDefinition}>
					Cost per thousand impressions
				</Text>
			</View>
			<View style={styles.legendItem}>
				<Text style={styles.legendTerm}>CPC (Cost Per Click)</Text>
				<Text style={styles.legendDefinition}>
					Average cost for each click
				</Text>
			</View>
			<View style={styles.legendItem}>
				<Text style={styles.legendTerm}>Green Values</Text>
				<Text style={styles.legendDefinition}>
					Meeting or exceeding targets
				</Text>
			</View>
			<View style={styles.legendItem}>
				<Text style={styles.legendTerm}>Red Values</Text>
				<Text style={styles.legendDefinition}>
					Underperforming against targets
				</Text>
			</View>
		</View>
	);

	render() {
		const sortedChannels = this.getSortedChannels();
		const budgetAllocation = this.calculateBudgetAllocation(sortedChannels);
		const filterTabs = [
			{ id: null, label: "All" },
			{ id: FUNNEL_STAGES.AWARENESS, label: "Awareness" },
			{ id: FUNNEL_STAGES.CONSIDERATION, label: "Consideration" },
			{ id: FUNNEL_STAGES.CONVERSION, label: "Conversion" },
			{ id: FUNNEL_STAGES.LOYALTY, label: "Loyalty" },
			{ id: PERFORMANCE_METRICS.ROI, label: "ROI" },
			{ id: PERFORMANCE_METRICS.REACH, label: "Reach" },
		];

		return (
			<View style={styles.container}>
				<View style={styles.header}>
					<Text style={styles.title}>
						Channel Performance & Focus
					</Text>
				</View>
				<View style={styles.header}>
					<View style={styles.headerButtons}>
						<TouchableOpacity
							style={styles.retailerFilterButton}
							onPress={() =>
								this.setState({ showRetailerModal: true })
							}>
							<Text style={styles.retailerFilterButtonText}>
								Filter Retailers (
								{this.state.selectedRetailers.length || "All"})
							</Text>
						</TouchableOpacity>
						<TouchableOpacity
							style={styles.budgetButton}
							onPress={() =>
								this.setState({ showBudgetModal: true })
							}>
							<Text style={styles.budgetButtonText}>
								Strategy & Focus Calulator
							</Text>
						</TouchableOpacity>
					</View>
				</View>

				{/* Spend strategy Modal */}
				<Modal
					visible={this.state.showBudgetModal}
					transparent={true}
					animationType='slide'>
					<View style={styles.modalOverlay}>
						<View style={styles.budgetModalContent}>
							<Text style={styles.modalTitle}>
								Spend strategy
							</Text>

							<View style={styles.budgetInputContainer}>
								<Text style={styles.label}>
									Total Budget (R)
								</Text>
								<TextInput
									style={styles.budgetInput}
									value={this.state.totalBudget}
									onChangeText={(value) =>
										this.setState({ totalBudget: value })
									}
									keyboardType='numeric'
									placeholder='Enter total budget'
								/>
							</View>

							<Text style={styles.label}>Optimization Goal</Text>
							<ScrollView
								horizontal
								showsHorizontalScrollIndicator={false}
								style={styles.goalScroll}>
								{[
									...Object.values(FUNNEL_STAGES),
									...Object.values(PERFORMANCE_METRICS),
								].map((goal) => (
									<TouchableOpacity
										key={goal}
										style={[
											styles.goalChip,
											this.state.selectedGoal === goal &&
												styles.goalChipActive,
										]}
										onPress={() =>
											this.setState({
												selectedGoal: goal,
											})
										}>
										<Text
											style={[
												styles.goalChipText,
												this.state.selectedGoal ===
													goal &&
													styles.goalChipTextActive,
											]}>
											{goal}
										</Text>
									</TouchableOpacity>
								))}
							</ScrollView>

							{budgetAllocation && (
								<View style={styles.allocationResults}>
									<Text style={styles.allocationTitle}>
										Recommended Allocation
									</Text>
									{budgetAllocation.map((channel) => (
										<View key={channel.id}>
											<View style={styles.allocationItem}>
												<Text
													style={
														styles.allocationChannel
													}>
													{channel.name}
												</Text>
												<Text
													style={
														styles.allocationAmount
													}>
													R{" "}
													{channel.recommendedBudget.toFixed(
														2,
													)}
													<Text
														style={
															styles.allocationPercentage
														}>
														{" "}
														(
														{(
															(channel.recommendedBudget /
																parseFloat(
																	this.state
																		.totalBudget,
																)) *
															100
														).toFixed(1)}
														%)
													</Text>
												</Text>
											</View>

											{/* Add Estimated Metrics Section */}
											<View
												style={styles.estimatedMetrics}>
												{(() => {
													const metrics =
														this.calculateEstimatedMetrics(
															channel,
															channel.recommendedBudget,
														);
													return (
														<View
															style={
																styles.metricsContainer
															}>
															<View
																style={
																	styles.metricsRow
																}>
																<View
																	style={
																		styles.metricBox
																	}>
																	<Text
																		style={
																			styles.metricBoxLabel
																		}>
																		Est.
																		Reach
																	</Text>
																	<Text
																		style={
																			styles.metricBoxValue
																		}>
																		{(
																			metrics.reach /
																			1000
																		).toFixed(
																			1,
																		)}
																		K
																	</Text>
																</View>
																<View
																	style={
																		styles.metricBox
																	}>
																	<Text
																		style={
																			styles.metricBoxLabel
																		}>
																		Est.
																		Impressions
																	</Text>
																	<Text
																		style={
																			styles.metricBoxValue
																		}>
																		{(
																			metrics.impressions /
																			1000
																		).toFixed(
																			1,
																		)}
																		K
																	</Text>
																</View>
															</View>
															<View
																style={
																	styles.metricsRow
																}>
																<View
																	style={
																		styles.metricBox
																	}>
																	<Text
																		style={
																			styles.metricBoxLabel
																		}>
																		Est.
																		Clicks
																	</Text>
																	<Text
																		style={
																			styles.metricBoxValue
																		}>
																		{metrics.clicks.toLocaleString()}
																	</Text>
																</View>
																<View
																	style={
																		styles.metricBox
																	}>
																	<Text
																		style={
																			styles.metricBoxLabel
																		}>
																		Est.
																		Engagements
																	</Text>
																	<Text
																		style={
																			styles.metricBoxValue
																		}>
																		{metrics.engagements.toLocaleString()}
																	</Text>
																</View>
															</View>
															<View
																style={
																	styles.costMetricsRow
																}>
																<Text
																	style={
																		styles.costMetric
																	}>
																	CPM: R
																	{metrics.cpm.toFixed(
																		2,
																	)}
																</Text>
																<Text
																	style={
																		styles.costMetric
																	}>
																	CPC: R
																	{metrics.cpc.toFixed(
																		2,
																	)}
																</Text>
																<Text
																	style={
																		styles.costMetric
																	}>
																	CTR:{" "}
																	{
																		metrics.ctr
																	}
																	%
																</Text>
															</View>
														</View>
													);
												})()}
											</View>
										</View>
									))}
								</View>
							)}

							<TouchableOpacity
								style={styles.closeButton}
								onPress={() =>
									this.setState({ showBudgetModal: false })
								}>
								<Text style={styles.closeButtonText}>
									Close
								</Text>
							</TouchableOpacity>
						</View>
					</View>
				</Modal>

				<View style={styles.filterContainer}>
					<ScrollView
						horizontal
						showsHorizontalScrollIndicator={false}
						style={styles.filterScroll}>
						{filterTabs.map((tab) => (
							<TouchableOpacity
								key={tab.id || "all"}
								style={[
									styles.filterTab,
									this.state.activeFilter === tab.id &&
										styles.filterTabActive,
								]}
								onPress={() => {
									this.setState({
										activeFilter: tab.id,
										sortMetric: Object.values(
											FUNNEL_STAGES,
										).includes(tab.id)
											? "funnel"
											: "performance",
									});
								}}>
								<Text
									style={[
										styles.filterTabText,
										this.state.activeFilter === tab.id &&
											styles.filterTabTextActive,
									]}>
									{tab.label}
								</Text>
							</TouchableOpacity>
						))}
					</ScrollView>
				</View>

				<ScrollView>
					<View style={styles.channelsGrid}>
						<TouchableOpacity
							style={styles.addButton}
							onPress={() =>
								this.setState({ showAddModal: true })
							}>
							<Text style={styles.addButtonText}>
								+ Add Channel
							</Text>
						</TouchableOpacity>
						{sortedChannels.map((channel) => (
							<View key={channel.id} style={styles.channelCard}>
								<View style={styles.channelHeader}>
									<Text style={styles.channelName}>
										{channel.name}
									</Text>
									<Text style={styles.channelType}>
										{channel.type}
									</Text>
								</View>

								<View style={styles.focusSection}>
									<View style={styles.focusItem}>
										<Text style={styles.focusLabel}>
											Primary Focus:
										</Text>
										<Text style={styles.focusValue}>
											{channel.primaryFocus}
										</Text>
									</View>
									<View style={styles.focusItem}>
										<Text style={styles.focusLabel}>
											Secondary Focus:
										</Text>
										<Text style={styles.focusValue}>
											{channel.secondaryFocus}
										</Text>
									</View>
								</View>

								<Text style={styles.sectionTitle}>
									Performance Metrics
								</Text>
								<View style={styles.metricsGrid}>
									{Object.entries(channel.performance).map(
										([metric, score]) => (
											<View
												key={metric}
												style={styles.metricItem}>
												<Text
													style={styles.metricLabel}>
													{metric}
												</Text>
												<Text
													style={[
														styles.metricScore,
														{
															color: this.getPerformanceColor(
																score,
															),
														},
													]}>
													{score}%
												</Text>
											</View>
										),
									)}
								</View>

								<Text style={styles.sectionTitle}>
									Funnel Contribution
								</Text>
								<View style={styles.funnelContainer}>
									{Object.entries(
										channel.funnelContribution,
									).map(([stage, contribution]) => (
										<View
											key={stage}
											style={styles.funnelStage}>
											<Text style={styles.stageLabel}>
												{stage}
											</Text>
											<View
												style={styles.contributionBar}>
												<View
													style={[
														styles.contributionFill,
														{
															width: `${contribution}%`,
														},
													]}
												/>
											</View>
											<Text
												style={
													styles.contributionValue
												}>
												{contribution}%
											</Text>
										</View>
									))}
								</View>

								<View style={styles.spendSection}>
									<Text style={styles.sectionTitle}>
										Spend Analysis
									</Text>
									<Text style={styles.currentSpend}>
										Current Allocation:{" "}
										{channel.spendAllocation}%
									</Text>
									{(() => {
										const suggestion =
											this.calculateSpendSuggestion(
												channel,
											);
										return (
											<View
												style={[
													styles.suggestionBox,
													{
														backgroundColor:
															suggestion.suggestion ===
															"INCREASE"
																? "#e3f4e1"
																: suggestion.suggestion ===
																  "DECREASE"
																? "#fee2e2"
																: "#f3f4f6",
													},
												]}>
												<Text
													style={
														styles.suggestionTitle
													}>
													{suggestion.suggestion}{" "}
													{suggestion.amount}
												</Text>
												<Text
													style={
														styles.suggestionReason
													}>
													{suggestion.reason}
												</Text>
											</View>
										);
									})()}
								</View>

								<Text style={styles.sectionTitle}>
									Cost Efficiency
								</Text>
								<View style={styles.costMetricsContainer}>
									{this.renderCostMetricsLegend()}
									<View style={styles.costMetricRow}>
										<View style={styles.costMetricItem}>
											<Text
												style={styles.costMetricLabel}>
												CPM (Target)
											</Text>
											<Text
												style={styles.costMetricValue}>
												R
												{channel.costMetrics.CPM.toFixed(
													2,
												)}
											</Text>
										</View>
										<View style={styles.costMetricItem}>
											<Text
												style={styles.costMetricLabel}>
												CPM (Actual)
											</Text>
											<Text
												style={[
													styles.costMetricValue,
													{
														color:
															this.calculateEfficiencyMetrics(
																channel,
															).cpmEfficiency >=
															100
																? APP_COLOURS.GREEN
																: APP_COLOURS.RED,
													},
												]}>
												R
												{this.calculateEfficiencyMetrics(
													channel,
												).actualCPM.toFixed(2)}
											</Text>
										</View>
									</View>

									<View style={styles.costMetricRow}>
										<View style={styles.costMetricItem}>
											<Text
												style={styles.costMetricLabel}>
												CPC (Target)
											</Text>
											<Text
												style={styles.costMetricValue}>
												R
												{channel.costMetrics.CPC.toFixed(
													2,
												)}
											</Text>
										</View>
										<View style={styles.costMetricItem}>
											<Text
												style={styles.costMetricLabel}>
												CPC (Actual)
											</Text>
											<Text
												style={[
													styles.costMetricValue,
													{
														color:
															this.calculateEfficiencyMetrics(
																channel,
															).cpcEfficiency >=
															100
																? APP_COLOURS.GREEN
																: APP_COLOURS.RED,
													},
												]}>
												R
												{this.calculateEfficiencyMetrics(
													channel,
												).actualCPC.toFixed(2)}
											</Text>
										</View>
									</View>

									<View style={styles.metricsOverview}>
										<Text
											style={styles.metricsOverviewTitle}>
											Campaign Reach
										</Text>
										<Text
											style={styles.metricsOverviewText}>
											Impressions:{" "}
											{channel.costMetrics.impressions.toLocaleString()}
										</Text>
										<Text
											style={styles.metricsOverviewText}>
											Clicks:{" "}
											{channel.costMetrics.clicks.toLocaleString()}
										</Text>
										<Text
											style={styles.metricsOverviewText}>
											CTR:{" "}
											{(
												(channel.costMetrics.clicks /
													channel.costMetrics
														.impressions) *
												100
											).toFixed(2)}
											%
										</Text>
									</View>
								</View>
							</View>
						))}
					</View>
				</ScrollView>
				{this.renderRetailerModal()}
			</View>
		);
	}
}

const styles = {
	container: {
		flex: 1,
		padding: 20,
		backgroundColor: "#f8fafc",
	},
	title: {
		fontSize: 24,
		fontWeight: "bold",
		color: APP_COLOURS.BG2,
		marginBottom: 20,
	},
	channelCard: {
		backgroundColor: "white",
		borderRadius: 10,
		padding: 20,
		marginBottom: 20,
		shadowColor: "#000",
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.1,
		shadowRadius: 4,
		elevation: 3,
		maxWidth: 500,
	},
	channelHeader: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: 15,
	},
	channelName: {
		fontSize: 18,
		fontWeight: "600",
		color: APP_COLOURS.BG2,
	},
	channelType: {
		fontSize: 14,
		color: APP_COLOURS.PURPLE,
		backgroundColor: "#f3f4f6",
		padding: 5,
		borderRadius: 5,
	},
	focusSection: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginBottom: 20,
	},
	focusItem: {
		flex: 1,
	},
	focusLabel: {
		fontSize: 12,
		color: "#666",
		marginBottom: 5,
	},
	focusValue: {
		fontSize: 14,
		fontWeight: "500",
		color: APP_COLOURS.BG2,
	},
	sectionTitle: {
		fontSize: 16,
		fontWeight: "600",
		color: APP_COLOURS.BG2,
		marginBottom: 10,
		marginTop: 15,
	},
	metricsGrid: {
		flexDirection: "row",
		flexWrap: "wrap",
		gap: 15,
		marginBottom: 20,
	},
	metricItem: {
		width: "calc(20% - 12px)",
		alignItems: "center",
	},
	metricLabel: {
		fontSize: 12,
		color: "#666",
		marginBottom: 5,
	},
	metricScore: {
		fontSize: 16,
		fontWeight: "600",
	},
	funnelContainer: {
		marginBottom: 20,
	},
	funnelStage: {
		marginBottom: 10,
	},
	stageLabel: {
		fontSize: 12,
		color: "#666",
		marginBottom: 5,
	},
	contributionBar: {
		height: 8,
		backgroundColor: "#f3f4f6",
		borderRadius: 4,
		overflow: "hidden",
	},
	contributionFill: {
		height: "100%",
		backgroundColor: APP_COLOURS.PURPLE,
	},
	contributionValue: {
		fontSize: 12,
		color: APP_COLOURS.BG2,
		marginTop: 2,
	},
	spendSection: {
		borderTopWidth: 1,
		borderTopColor: "#f3f4f6",
		paddingTop: 15,
	},
	currentSpend: {
		fontSize: 14,
		color: APP_COLOURS.BG2,
		marginBottom: 10,
	},
	suggestionBox: {
		padding: 15,
		borderRadius: 8,
		marginTop: 10,
	},
	suggestionTitle: {
		fontSize: 14,
		fontWeight: "600",
		color: APP_COLOURS.BG2,
		marginBottom: 5,
	},
	suggestionReason: {
		fontSize: 12,
		color: "#666",
	},
	header: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: 20,
	},
	addButton: {
		backgroundColor: APP_COLOURS.PURPLE,
		paddingVertical: 10,
		paddingHorizontal: 20,
		borderRadius: 5,
		alignItems: "center",
	},
	addButtonText: {
		color: "white",
		fontSize: 14,
		fontWeight: "600",
	},
	filterContainer: {
		marginBottom: 20,
		borderBottomWidth: 1,
		borderBottomColor: "#e5e7eb",
	},
	filterScroll: {
		flexGrow: 0,
		paddingBottom: 10,
	},
	filterTab: {
		paddingHorizontal: 16,
		paddingVertical: 8,
		marginRight: 8,
		borderRadius: 20,
		backgroundColor: "#f3f4f6",
	},
	filterTabActive: {
		backgroundColor: APP_COLOURS.PURPLE,
	},
	filterTabText: {
		fontSize: 14,
		color: "#666",
		fontWeight: "500",
	},
	filterTabTextActive: {
		color: "#fff",
	},
	channelsGrid: {
		flexDirection: "row",
		flexWrap: "wrap",
		gap: 20,
		paddingBottom: 20,
	},
	headerButtons: {
		flexDirection: "row",
		gap: 10,
	},
	budgetButton: {
		backgroundColor: APP_COLOURS.GREEN,
		paddingVertical: 10,
		paddingHorizontal: 20,
		borderRadius: 5,
		alignItems: "center",
	},
	budgetButtonText: {
		color: "white",
		fontSize: 14,
		fontWeight: "600",
	},
	modalOverlay: {
		flex: 1,
		backgroundColor: "rgba(0, 0, 0, 0.5)",
		justifyContent: "center",
		alignItems: "center",
	},
	budgetModalContent: {
		backgroundColor: "white",
		borderRadius: 10,
		padding: 20,
		width: "90%",
		maxWidth: 600,
		maxHeight: "90%",
	},
	budgetInputContainer: {
		marginBottom: 20,
	},
	budgetInput: {
		borderWidth: 1,
		borderColor: "#ddd",
		borderRadius: 5,
		padding: 12,
		fontSize: 16,
		marginTop: 5,
	},
	goalScroll: {
		flexGrow: 0,
		marginBottom: 20,
	},
	goalChip: {
		paddingHorizontal: 16,
		paddingVertical: 8,
		marginRight: 8,
		borderRadius: 20,
		backgroundColor: "#f3f4f6",
	},
	goalChipActive: {
		backgroundColor: APP_COLOURS.PURPLE,
	},
	goalChipText: {
		fontSize: 14,
		color: "#666",
	},
	goalChipTextActive: {
		color: "white",
	},
	allocationResults: {
		backgroundColor: "#f8fafc",
		padding: 15,
		borderRadius: 8,
		marginTop: 20,
	},
	allocationTitle: {
		fontSize: 16,
		fontWeight: "600",
		color: APP_COLOURS.BG2,
		marginBottom: 15,
	},
	allocationItem: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		paddingVertical: 10,
		borderBottomWidth: 1,
		borderBottomColor: "#eee",
	},
	allocationChannel: {
		fontSize: 14,
		color: APP_COLOURS.BG2,
		flex: 1,
	},
	allocationAmount: {
		fontSize: 14,
		fontWeight: "500",
		color: APP_COLOURS.PURPLE,
	},
	allocationPercentage: {
		fontSize: 12,
		color: "#666",
	},
	closeButton: {
		marginTop: 20,
		padding: 15,
		backgroundColor: APP_COLOURS.RED,
		borderRadius: 5,
		alignItems: "center",
	},
	closeButtonText: {
		color: "white",
		fontSize: 14,
		fontWeight: "600",
	},
	retailerFilterButton: {
		backgroundColor: APP_COLOURS.BG2,
		paddingVertical: 10,
		paddingHorizontal: 20,
		borderRadius: 5,
		alignItems: "center",
	},
	retailerFilterButtonText: {
		color: "white",
		fontSize: 14,
		fontWeight: "600",
	},
	retailerModalContent: {
		backgroundColor: "white",
		borderRadius: 10,
		padding: 20,
		width: "90%",
		maxWidth: 600,
		maxHeight: "80%",
	},
	retailerItem: {
		flexDirection: "row",
		alignItems: "center",
		paddingVertical: 12,
		borderBottomWidth: 1,
		borderBottomColor: "#eee",
	},
	retailerCheckbox: {
		width: 20,
		height: 20,
		borderWidth: 2,
		borderColor: APP_COLOURS.PURPLE,
		borderRadius: 4,
		marginRight: 12,
		justifyContent: "center",
		alignItems: "center",
	},
	retailerCheckboxSelected: {
		width: 12,
		height: 12,
		backgroundColor: APP_COLOURS.PURPLE,
		borderRadius: 2,
	},
	retailerItemText: {
		fontSize: 16,
		color: APP_COLOURS.BG2,
	},
	modalButtons: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginTop: 20,
	},
	modalButton: {
		flex: 1,
		paddingVertical: 12,
		borderRadius: 5,
		alignItems: "center",
		marginHorizontal: 5,
	},
	clearButton: {
		backgroundColor: "#f3f4f6",
	},
	modalButtonText: {
		color: "white",
		fontSize: 14,
		fontWeight: "600",
	},
	costMetricsContainer: {
		backgroundColor: "#f8fafc",
		padding: 15,
		borderRadius: 8,
		marginBottom: 20,
	},
	costMetricRow: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginBottom: 15,
	},
	costMetricItem: {
		flex: 1,
		alignItems: "center",
	},
	costMetricLabel: {
		fontSize: 12,
		color: "#666",
		marginBottom: 5,
	},
	costMetricValue: {
		fontSize: 16,
		fontWeight: "600",
		color: APP_COLOURS.BG2,
	},
	metricsOverview: {
		borderTopWidth: 1,
		borderTopColor: "#e5e7eb",
		paddingTop: 15,
	},
	metricsOverviewTitle: {
		fontSize: 14,
		fontWeight: "600",
		color: APP_COLOURS.BG2,
		marginBottom: 8,
	},
	metricsOverviewText: {
		fontSize: 13,
		color: "#666",
		marginBottom: 4,
	},
	metricsLegend: {
		backgroundColor: "#f8fafc",
		padding: 12,
		borderRadius: 6,
		marginBottom: 15,
		borderLeftWidth: 3,
		borderLeftColor: APP_COLOURS.PURPLE,
	},
	legendTitle: {
		fontSize: 13,
		fontWeight: "600",
		color: APP_COLOURS.BG2,
		marginBottom: 8,
	},
	legendItem: {
		marginBottom: 6,
	},
	legendTerm: {
		fontSize: 12,
		fontWeight: "500",
		color: APP_COLOURS.BG2,
	},
	legendDefinition: {
		fontSize: 11,
		color: "#666",
		marginTop: 2,
	},
	estimatedMetrics: {
		backgroundColor: "#f8fafc",
		padding: 12,
		borderRadius: 6,
		marginTop: 8,
		marginBottom: 16,
	},
	metricsContainer: {
		gap: 12,
	},
	metricsRow: {
		flexDirection: "row",
		justifyContent: "space-between",
		gap: 8,
	},
	metricBox: {
		flex: 1,
		backgroundColor: "white",
		padding: 10,
		borderRadius: 6,
		alignItems: "center",
	},
	metricBoxLabel: {
		fontSize: 12,
		color: "#666",
		marginBottom: 4,
	},
	metricBoxValue: {
		fontSize: 14,
		fontWeight: "600",
		color: APP_COLOURS.BG2,
	},
	costMetricsRow: {
		flexDirection: "row",
		justifyContent: "space-around",
		backgroundColor: "white",
		padding: 10,
		borderRadius: 6,
	},
	costMetric: {
		fontSize: 12,
		color: APP_COLOURS.PURPLE,
		fontWeight: "500",
	},
};
