import React from "react";
import { View, StyleSheet } from "react-native";
import { darkTheme } from "./theme";

export default function HTMLRenderer({ html }) {
	if (!html) return null;

	return (
		<View style={styles.container}>
			<div
				className='html-content'
				dangerouslySetInnerHTML={{ __html: html }}
			/>
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
	},
});
