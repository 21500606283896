import React, { useState, useRef, useEffect } from "react";
import {
	View,
	Text,
	TextInput,
	TouchableOpacity,
	ActivityIndicator,
	StyleSheet,
	Modal,
	ScrollView,
} from "react-native";
import { APP_COLOURS } from "../../APP_VARS";
import AutoHeightImage from "../AutoHeightImageComp";
import pdfIcon from "../../../assets/images/document_icon.png";
import {
	getSuppliers,
	uploadBookingConfirmation,
	updateCampaign,
} from "../../CRUDHELPER";
import moment from "moment";
import axios from "axios";

const sendDiscordNotification = async (
	message,
	username = "tetrice",
	avatar_url = "",
) => {
	const webhookUrl =
		"https://discord.com/api/webhooks/1191734945278869555/Xy3r8fIe6cj_fqFPgOvq-CTacA693Y8XJFzi5Q5OYIvOPvwAroZrTgSUw3TtDyB5zMCs";

	try {
		const response = await axios.post(webhookUrl, {
			content: message,
			username: username,
			avatar_url: avatar_url,
		});

		return {
			success: true,
			message: "Discord notification sent successfully",
		};
	} catch (error) {
		console.error("Error sending Discord notification:", error);
		throw {
			success: false,
			message: "Failed to send Discord notification",
			error: error.message,
		};
	}
};

const BookingConfirmation = ({ campaign, onSubmit }) => {
	const [selectedSupplier, setSelectedSupplier] = useState("");
	const [quoteNumber, setQuoteNumber] = useState("");
	const [totalExclVat, setTotalExclVat] = useState("");
	const [document, setDocument] = useState(null);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [showSupplierDropdown, setShowSupplierDropdown] = useState(false);
	const [suppliers, setSuppliers] = useState([]);
	const [loadingSuppliers, setLoadingSuppliers] = useState(true);
	const [searchQuery, setSearchQuery] = useState("");
	const fileInputRef = useRef(null);
	const [poNumber, setPoNumber] = useState("");
	const [poDocument, setPoDocument] = useState(null);
	const [isSubmittingPo, setIsSubmittingPo] = useState(false);
	const poFileInputRef = useRef(null);

	useEffect(() => {
		fetchSuppliers();
	}, []);

	const fetchSuppliers = async () => {
		try {
			await getSuppliers((status, result) => {
				console.log("Suppliers response:", result);

				if (status === 200) {
					if (Array.isArray(result)) {
						setSuppliers(result);
					} else {
						console.error("Expected array of suppliers");
						alert("Failed to load suppliers");
					}
				} else {
					console.error("Failed to fetch suppliers");
					alert("Failed to load suppliers");
				}
				setLoadingSuppliers(false);
			});
		} catch (error) {
			console.error("Error fetching suppliers:", error);
			alert("Failed to load suppliers");
			setLoadingSuppliers(false);
		}
	};

	const handleDocumentPick = (event) => {
		const file = event.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.onloadend = () => {
				setDocument({
					name: file.name,
					type: file.type,
					size: file.size,
					base64String: reader.result,
				});
			};
			reader.readAsDataURL(file);
		} else {
			alert("Please select a file");
		}
	};

	const handlePoDocumentPick = (event) => {
		const file = event.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.onloadend = () => {
				setPoDocument({
					name: file.name,
					type: file.type,
					size: file.size,
					base64String: reader.result,
				});
			};
			reader.readAsDataURL(file);
		}
	};

	const handleSubmit = async () => {
		if (!selectedSupplier || !quoteNumber || !totalExclVat || !document) {
			alert("Please fill in all fields and upload a quotation");
			return;
		}

		setIsSubmitting(true);

		const documentData = {
			supplier: selectedSupplier,
			quoteNumber,
			totalExclVat: parseFloat(totalExclVat),
			campaignDetails: campaign,
		};

		try {
			await uploadBookingConfirmation(
				"perf_booking_confirmations",
				documentData,
				document,
				async (status, result) => {
					if (status === 200) {
						delete result.campaignDetails;

						const updatedCampaign = {
							...campaign,
							bookingConfirmation: result,
							status: "Awaiting PO",
						};

						// Send Discord notification
						try {
							const notificationMessage =
								`🎯 New Booking Confirmation Submitted\n\n` +
								`Campaign: ${campaign.title}\n` +
								`Supplier: ${selectedSupplier}\n` +
								`Quote Number: ${quoteNumber}\n` +
								`Total (Excl. VAT): R ${currencyFormat(
									parseFloat(totalExclVat),
								)}\n` +
								`Status: Awaiting PO`;

							await sendDiscordNotification(notificationMessage);
						} catch (discordError) {
							console.error(
								"Failed to send Discord notification:",
								discordError,
							);
							// Continue with the rest of the process even if Discord notification fails
						}

						await updateCampaign(
							updatedCampaign,
							(updateStatus) => {
								if (updateStatus === 200) {
									alert(
										"Booking confirmation submitted successfully",
									);
									onSubmit && onSubmit(updatedCampaign);
								} else {
									alert(
										"Booking confirmation uploaded but failed to update campaign status",
									);
								}
								setIsSubmitting(false);
							},
						);
					} else {
						alert("Failed to submit booking confirmation");
						setIsSubmitting(false);
					}
				},
			);
		} catch (error) {
			console.error("Error submitting booking confirmation:", error);
			alert("Failed to submit booking confirmation");
			setIsSubmitting(false);
		}
	};

	const handlePoSubmit = async () => {
		if (!poNumber || !poDocument) {
			alert("Please fill in all fields and upload a purchase order");
			return;
		}

		setIsSubmittingPo(true);

		const documentData = {
			poNumber,
			campaignId: campaign._id,
			bookingConfirmationId: campaign.bookingConfirmation.id,
		};

		try {
			await uploadBookingConfirmation(
				"perf_purchase_orders",
				documentData,
				poDocument,
				async (status, result) => {
					if (status === 200) {
						const updatedCampaign = {
							...campaign,
							purchaseOrder: result,
							status: "Campaign Booked & Approved",
						};

						await updateCampaign(
							updatedCampaign,
							(updateStatus) => {
								if (updateStatus === 200) {
									alert(
										"Purchase order submitted successfully",
									);
									onSubmit && onSubmit(updatedCampaign);
								} else {
									alert(
										"Purchase order uploaded but failed to update campaign status",
									);
								}
								setIsSubmittingPo(false);
							},
						);
					} else {
						alert("Failed to submit purchase order");
						setIsSubmittingPo(false);
					}
				},
			);
		} catch (error) {
			console.error("Error submitting purchase order:", error);
			alert("Failed to submit purchase order");
			setIsSubmittingPo(false);
		}
	};

	const filteredSuppliers = suppliers.filter((supplier) =>
		(
			supplier.tradingName?.toLowerCase() ||
			supplier.registeredName?.toLowerCase() ||
			""
		).includes(searchQuery.toLowerCase()),
	);

	const renderSupplierModal = () => (
		<Modal
			animationType='fade'
			transparent={true}
			visible={showSupplierDropdown}
			onRequestClose={() => setShowSupplierDropdown(false)}>
			<View style={styles.modalOverlay}>
				<View style={styles.modalContent}>
					<View style={styles.modalHeader}>
						<Text style={styles.modalTitle}>Select Supplier</Text>
						<TouchableOpacity
							onPress={() => setShowSupplierDropdown(false)}
							style={styles.closeButton}>
							<Text style={styles.closeButtonText}>×</Text>
						</TouchableOpacity>
					</View>

					<View style={styles.searchContainer}>
						<TextInput
							style={styles.searchInput}
							placeholder='Search suppliers...'
							value={searchQuery}
							onChangeText={setSearchQuery}
						/>
					</View>

					<ScrollView style={styles.suppliersList}>
						{filteredSuppliers.map((supplier) => (
							<TouchableOpacity
								key={supplier.id}
								style={styles.supplierItem}
								onPress={() => {
									setSelectedSupplier(
										supplier.tradingName ||
											supplier.registeredName,
									);
									setShowSupplierDropdown(false);
									setSearchQuery("");
								}}>
								<Text style={styles.supplierName}>
									{supplier.tradingName ||
										supplier.registeredName}
								</Text>
								{supplier.tradingName &&
									supplier.registeredName && (
										<Text style={styles.supplierSubtext}>
											{supplier.registeredName}
										</Text>
									)}
							</TouchableOpacity>
						))}
					</ScrollView>
				</View>
			</View>
		</Modal>
	);

	const renderBookingConfirmationDetails = () => {
		if (!campaign.bookingConfirmation) return null;

		const { bookingConfirmation } = campaign;
		const hasPurchaseOrder = campaign.purchaseOrder;

		return (
			<View style={styles.confirmationDetails}>
				<Text
					style={[
						styles.statusText,
						hasPurchaseOrder
							? styles.statusApproved
							: styles.statusPending,
					]}>
					{hasPurchaseOrder
						? "Campaign Approved"
						: "Awaiting Purchase Order"}
				</Text>

				<View style={styles.detailRow}>
					<Text style={styles.detailLabel}>Supplier:</Text>
					<Text style={styles.detailValue}>
						{bookingConfirmation.supplier}
					</Text>
				</View>

				<View style={styles.detailRow}>
					<Text style={styles.detailLabel}>Quote Number:</Text>
					<Text style={styles.detailValue}>
						{bookingConfirmation.quoteNumber}
					</Text>
				</View>

				<View style={styles.detailRow}>
					<Text style={styles.detailLabel}>Total (Excl. VAT):</Text>
					<Text style={styles.detailValue}>
						R{" "}
						{currencyFormat(bookingConfirmation?.totalExclVat || 0)}
					</Text>
				</View>

				<View style={styles.detailRow}>
					<Text style={styles.detailLabel}>Submitted:</Text>
					<Text style={styles.detailValue}>
						{moment(bookingConfirmation.createdAt).format(
							"DD MMM YYYY HH:mm",
						)}
					</Text>
				</View>

				<TouchableOpacity
					style={styles.documentLink}
					onPress={() =>
						window.open(bookingConfirmation.imageData.url, "_blank")
					}>
					<AutoHeightImage width={24} source={pdfIcon} />
					<Text style={styles.documentLinkText}>
						View Booking Confirmation
					</Text>
				</TouchableOpacity>

				{hasPurchaseOrder ? (
					<>
						<View style={styles.sectionDivider} />
						<View style={styles.detailRow}>
							<Text style={styles.detailLabel}>PO Number:</Text>
							<Text style={styles.detailValue}>
								{campaign.purchaseOrder.poNumber}
							</Text>
						</View>
						<View style={styles.detailRow}>
							<Text style={styles.detailLabel}>Uploaded:</Text>
							<Text style={styles.detailValue}>
								{moment(
									campaign.purchaseOrder.createdAt,
								).format("DD MMM YYYY HH:mm")}
							</Text>
						</View>
						<TouchableOpacity
							style={styles.documentLink}
							onPress={() =>
								window.open(
									campaign.purchaseOrder.imageData.url,
									"_blank",
								)
							}>
							<AutoHeightImage width={24} source={pdfIcon} />
							<Text style={styles.documentLinkText}>
								View Purchase Order
							</Text>
						</TouchableOpacity>
					</>
				) : (
					<>
						<View style={styles.sectionDivider} />
						<View style={styles.uploadSection}>
							<Text style={styles.uploadTitle}>
								Upload Purchase Order
							</Text>
							<View style={styles.inputContainer}>
								<Text style={styles.label}>PO Number</Text>
								<TextInput
									style={styles.input}
									value={poNumber}
									onChangeText={setPoNumber}
									placeholder='Enter PO number'
								/>
							</View>
							<View style={styles.inputContainer}>
								<Text style={styles.label}>
									Purchase Order Document
								</Text>
								<input
									type='file'
									onChange={handlePoDocumentPick}
									ref={poFileInputRef}
									style={{ display: "none" }}
								/>
								<TouchableOpacity
									style={styles.uploadButton}
									onPress={() =>
										poFileInputRef.current?.click()
									}>
									{poDocument ? (
										<View style={styles.documentPreview}>
											<AutoHeightImage
												width={24}
												source={pdfIcon}
											/>
											<Text
												style={styles.documentName}
												numberOfLines={1}>
												{poDocument.name}
											</Text>
										</View>
									) : (
										<Text style={styles.uploadButtonText}>
											Select Document
										</Text>
									)}
								</TouchableOpacity>
							</View>
							<TouchableOpacity
								style={[
									styles.submitButton,
									(!poNumber || !poDocument) &&
										styles.submitButtonDisabled,
								]}
								onPress={handlePoSubmit}
								disabled={
									isSubmittingPo || !poNumber || !poDocument
								}>
								{isSubmittingPo ? (
									<ActivityIndicator color='#FFF' />
								) : (
									<Text style={styles.submitButtonText}>
										Submit Purchase Order
									</Text>
								)}
							</TouchableOpacity>
						</View>
					</>
				)}
			</View>
		);
	};

	return (
		<View style={styles.container}>
			<Text style={styles.title}>Booking Confirmation</Text>

			{campaign.bookingConfirmation ? (
				renderBookingConfirmationDetails()
			) : (
				<>
					{/* Supplier Selection */}
					<View style={styles.inputContainer}>
						<Text style={styles.label}>Select Supplier</Text>
						<TouchableOpacity
							style={styles.dropdownButton}
							onPress={() =>
								!loadingSuppliers &&
								setShowSupplierDropdown(true)
							}>
							{loadingSuppliers ? (
								<ActivityIndicator
									size='small'
									color={APP_COLOURS.PURPLE}
								/>
							) : (
								<Text style={styles.dropdownButtonText}>
									{selectedSupplier || "Select a supplier"}
								</Text>
							)}
						</TouchableOpacity>
					</View>

					{/* Quote Number Input */}
					<View style={styles.inputContainer}>
						<Text style={styles.label}>Quote Number</Text>
						<TextInput
							style={styles.input}
							value={quoteNumber}
							onChangeText={setQuoteNumber}
							placeholder='Enter quote number'
						/>
					</View>

					{/* Total Excluding VAT Input */}
					<View style={styles.inputContainer}>
						<Text style={styles.label}>Total (Excluding VAT)</Text>
						<TextInput
							style={styles.input}
							value={totalExclVat}
							onChangeText={setTotalExclVat}
							placeholder='Enter total amount'
							keyboardType='numeric'
						/>
					</View>

					{/* Document Upload */}
					<View style={styles.inputContainer}>
						<Text style={styles.label}>Upload Quotation (PDF)</Text>
						<input
							type='file'
							onChange={handleDocumentPick}
							ref={fileInputRef}
							style={{ display: "none" }}
						/>
						<TouchableOpacity
							style={styles.uploadButton}
							onPress={() => fileInputRef.current?.click()}>
							{document ? (
								<View style={styles.documentPreview}>
									<AutoHeightImage
										width={24}
										source={pdfIcon}
									/>
									<Text
										style={styles.documentName}
										numberOfLines={1}>
										{document.name}
									</Text>
								</View>
							) : (
								<Text style={styles.uploadButtonText}>
									Select PDF
								</Text>
							)}
						</TouchableOpacity>
					</View>

					{/* Submit Button */}
					<TouchableOpacity
						style={[
							styles.submitButton,
							(!selectedSupplier ||
								!quoteNumber ||
								!totalExclVat ||
								!document) &&
								styles.submitButtonDisabled,
						]}
						onPress={handleSubmit}
						disabled={
							isSubmitting ||
							!selectedSupplier ||
							!quoteNumber ||
							!totalExclVat ||
							!document
						}>
						{isSubmitting ? (
							<ActivityIndicator color='#FFF' />
						) : (
							<Text style={styles.submitButtonText}>
								Submit Booking Confirmation
							</Text>
						)}
					</TouchableOpacity>

					{/* Render the modal */}
					{renderSupplierModal()}
				</>
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		padding: 20,
		backgroundColor: "#FFF",
		borderRadius: 10,
	},
	title: {
		fontSize: 18,
		fontWeight: "600",
		marginBottom: 20,
		color: APP_COLOURS.BG3,
	},
	inputContainer: {
		marginBottom: 20,
	},
	label: {
		fontSize: 14,
		marginBottom: 8,
		color: APP_COLOURS.BG3,
		fontWeight: "500",
	},
	input: {
		borderWidth: 1,
		borderColor: APP_COLOURS.BG5,
		borderRadius: 8,
		padding: 10,
		fontSize: 14,
	},
	dropdownButton: {
		borderWidth: 1,
		borderColor: APP_COLOURS.BG5,
		borderRadius: 8,
		padding: 10,
	},
	dropdownButtonText: {
		fontSize: 14,
		color: APP_COLOURS.BG3,
	},
	uploadButton: {
		borderWidth: 1,
		borderColor: APP_COLOURS.BG5,
		borderRadius: 8,
		padding: 10,
		alignItems: "center",
	},
	uploadButtonText: {
		color: APP_COLOURS.BLUE,
		fontSize: 14,
	},
	documentPreview: {
		flexDirection: "row",
		alignItems: "center",
		gap: 10,
	},
	documentName: {
		fontSize: 14,
		color: APP_COLOURS.BG3,
		flex: 1,
	},
	submitButton: {
		backgroundColor: APP_COLOURS.PURPLE,
		padding: 15,
		borderRadius: 25,
		alignItems: "center",
		marginTop: 20,
	},
	submitButtonDisabled: {
		opacity: 0.5,
	},
	submitButtonText: {
		color: "#FFF",
		fontSize: 16,
		fontWeight: "600",
	},
	modalOverlay: {
		flex: 1,
		backgroundColor: "rgba(0, 0, 0, 0.5)",
		justifyContent: "center",
		alignItems: "center",
	},
	modalContent: {
		backgroundColor: "#FFF",
		borderRadius: 10,
		width: "80%",
		maxWidth: 500,
		maxHeight: "80%",
		padding: 20,
	},
	modalHeader: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: 20,
	},
	modalTitle: {
		fontSize: 18,
		fontWeight: "600",
		color: APP_COLOURS.BG3,
	},
	closeButton: {
		padding: 5,
	},
	closeButtonText: {
		fontSize: 24,
		color: APP_COLOURS.BG3,
		fontWeight: "600",
	},
	searchContainer: {
		marginBottom: 15,
	},
	searchInput: {
		borderWidth: 1,
		borderColor: APP_COLOURS.BG5,
		borderRadius: 8,
		padding: 10,
		fontSize: 14,
	},
	suppliersList: {
		maxHeight: 400,
	},
	supplierItem: {
		padding: 15,
		borderBottomWidth: 1,
		borderBottomColor: APP_COLOURS.BG5,
	},
	supplierName: {
		fontSize: 16,
		color: APP_COLOURS.BG3,
		marginBottom: 4,
	},
	supplierSubtext: {
		fontSize: 12,
		color: APP_COLOURS.BG3,
		opacity: 0.7,
	},
	confirmationDetails: {
		backgroundColor: "#f8f9fa",
		padding: 20,
		borderRadius: 10,
		marginTop: 10,
	},
	statusText: {
		fontSize: 16,
		fontWeight: "600",
		color: APP_COLOURS.ORANGE,
		marginBottom: 20,
		textAlign: "center",
	},
	detailRow: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginBottom: 15,
		borderBottomWidth: 1,
		borderBottomColor: APP_COLOURS.BG5,
		paddingBottom: 10,
	},
	detailLabel: {
		fontSize: 14,
		color: APP_COLOURS.BG3,
		fontWeight: "500",
	},
	detailValue: {
		fontSize: 14,
		color: APP_COLOURS.BG3,
		maxWidth: "60%",
		textAlign: "right",
	},
	documentLink: {
		flexDirection: "row",
		alignItems: "center",
		backgroundColor: "#fff",
		padding: 15,
		borderRadius: 8,
		marginTop: 10,
		borderWidth: 1,
		borderColor: APP_COLOURS.BG5,
	},
	documentLinkText: {
		marginLeft: 10,
		color: APP_COLOURS.BLUE,
		fontSize: 14,
		flex: 1,
	},
	sectionDivider: {
		height: 1,
		backgroundColor: APP_COLOURS.BG5,
		marginVertical: 20,
	},
	uploadSection: {
		marginTop: 10,
	},
	uploadTitle: {
		fontSize: 16,
		fontWeight: "600",
		color: APP_COLOURS.BG3,
		marginBottom: 15,
	},
	statusPending: {
		color: APP_COLOURS.ORANGE,
	},
	statusApproved: {
		color: APP_COLOURS.GREEN,
	},
});

const currencyFormat = (num) => {
	if (num === null || num === undefined) return "0.00";
	const number = typeof num === "string" ? parseFloat(num) : num;
	if (isNaN(number)) return "0.00";
	return number.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export default BookingConfirmation;
