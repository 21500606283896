import React from "react";
import { View, Text, StyleSheet } from "react-native";
import { darkTheme } from "./theme";

const STATUS_GROUPS = [
	{
		title: "Active",
		statuses: [
			{
				status: "pending",
				color: "#607D8B",
				description: "Not started yet",
			},
			{
				status: "in-progress",
				color: "#2196F3",
				description: "Currently working on",
			},
			{
				status: "on-hold",
				color: "#FF9800",
				description: "Temporarily paused",
			},
		],
	},
	{
		title: "Final States",
		statuses: [
			{
				status: "completed",
				color: "#4CAF50",
				description: "Work finished",
			},
			{
				status: "archived",
				color: "#9E9E9E",
				description: "Stored for reference",
				showOnlyIf: "completed", // Only show archive option for completed tasks
			},
		],
	},
];

export default function TaskStatusSelector({ currentStatus, onStatusChange }) {
	// If task is archived, show a special message
	if (currentStatus === "archived") {
		return (
			<View style={styles.container}>
				<Text style={styles.label}>Status</Text>
				<View style={styles.archivedContainer}>
					<View style={styles.archivedBadge}>
						<Text style={styles.archivedText}>ARCHIVED</Text>
					</View>
					<Text style={styles.archivedDescription}>
						This task has been archived and can no longer be
						modified.
					</Text>
				</View>
			</View>
		);
	}

	return (
		<View style={styles.container}>
			<Text style={styles.label}>Status</Text>
			<View style={styles.statusGroups}>
				{STATUS_GROUPS.map((group) => (
					<View key={group.title} style={styles.statusGroup}>
						<Text style={styles.groupTitle}>{group.title}</Text>
						<View style={styles.statusList}>
							{group.statuses
								.filter(
									(status) =>
										!status.showOnlyIf ||
										currentStatus === status.showOnlyIf,
								)
								.map((status) => (
									<View
										key={status.status}
										onClick={() =>
											onStatusChange(status.status)
										}
										style={[
											styles.statusButton,
											{
												backgroundColor: status.color,
												opacity:
													currentStatus ===
													status.status
														? 1
														: 0.6,
											},
										]}>
										<Text style={styles.statusText}>
											{status.status
												.split("-")
												.map(
													(word) =>
														word
															.charAt(0)
															.toUpperCase() +
														word.slice(1),
												)
												.join(" ")}
										</Text>
										{status.description && (
											<Text
												style={
													styles.statusDescription
												}>
												{status.description}
											</Text>
										)}
									</View>
								))}
						</View>
					</View>
				))}
			</View>
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		marginBottom: darkTheme.spacing.lg,
	},
	label: {
		fontSize: 16,
		fontWeight: "500",
		color: darkTheme.colors.text.primary,
		marginBottom: darkTheme.spacing.sm,
	},
	statusGroups: {
		gap: darkTheme.spacing.md,
	},
	statusGroup: {
		gap: darkTheme.spacing.sm,
	},
	groupTitle: {
		fontSize: 14,
		color: darkTheme.colors.text.secondary,
		marginBottom: darkTheme.spacing.xs,
	},
	statusList: {
		flexDirection: "row",
		flexWrap: "wrap",
		gap: darkTheme.spacing.sm,
	},
	statusButton: {
		padding: darkTheme.spacing.sm,
		borderRadius: darkTheme.borderRadius.sm,
		cursor: "pointer",
		minWidth: 120,
		transition: "opacity 0.2s ease",
		"&:hover": {
			opacity: 0.8,
		},
	},
	statusText: {
		color: "#000",
		fontWeight: "500",
		fontSize: 14,
		textAlign: "center",
	},
	statusDescription: {
		color: "#000",
		fontSize: 12,
		marginTop: 4,
		opacity: 0.8,
		textAlign: "center",
	},
	archivedContainer: {
		backgroundColor: `${darkTheme.colors.secondary}22`,
		padding: darkTheme.spacing.lg,
		borderRadius: darkTheme.borderRadius.md,
		alignItems: "center",
		gap: darkTheme.spacing.md,
	},
	archivedBadge: {
		backgroundColor: darkTheme.colors.secondary,
		paddingVertical: darkTheme.spacing.xs,
		paddingHorizontal: darkTheme.spacing.md,
		borderRadius: darkTheme.borderRadius.sm,
	},
	archivedText: {
		color: "#FFF",
		fontWeight: "bold",
		fontSize: 14,
	},
	archivedDescription: {
		color: darkTheme.colors.text.secondary,
		textAlign: "center",
		fontSize: 14,
	},
});
