import React, { Component } from "react";
import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	TextInput,
} from "react-native";
import { APP_COLOURS } from "../../APP_VARS";

export default class HashTagSelector extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedTags: [],
			customTags: [],
			searchQuery: "",
		};
	}

	toggleTag = (tag, isCustom = false) => {
		let selectedTags = [...this.state.selectedTags];
		let customTags = [...this.state.customTags];

		if (selectedTags.includes(tag)) {
			selectedTags = selectedTags.filter((t) => t !== tag);
			if (isCustom) {
				customTags = customTags.filter((t) => t !== tag);
			}
		} else {
			selectedTags.push(tag);
			if (isCustom && !customTags.includes(tag)) {
				customTags.push(tag);
			}
			// Call onAdd prop when adding a new tag
			if (this.props.onAdd) {
				this.props.onAdd(tag);
			}
		}

		this.setState({ selectedTags, customTags });
	};

	// Add search filter method
	filterTags = (hashtags) => {
		const { searchQuery } = this.state;
		if (!searchQuery) return hashtags;
		return hashtags.filter((tag) =>
			tag.toLowerCase().includes(searchQuery.toLowerCase()),
		);
	};

	renderHashtagButtons() {
		const predefinedHashtags = [
			`#ElizabethArden`,
			`#AlwaysArden`,
			`#EightHourCream #HydratedSkin #WinterSkincare #BestMoisturizer`,
			`#SkincareEssentials #MustHaveSkincare`,
		];

		// Combine predefined and custom hashtags
		const hashtags = [...predefinedHashtags, ...this.state.customTags];
		const filteredTags = this.filterTags(hashtags);

		// Show custom tag option only if search doesn't match existing tags
		if (filteredTags.length === 0 && this.state.searchQuery) {
			const customTag = this.state.searchQuery.startsWith("#")
				? this.state.searchQuery
				: `#${this.state.searchQuery}`;

			if (!this.state.customTags.includes(customTag)) {
				return (
					<TouchableOpacity
						style={{}}
						onPress={() => this.toggleTag(customTag, true)}>
						<View
							style={{
								width: "auto",
								justifyContent: "center",
								alignItems: "center",
								backgroundColor:
									this.state.selectedTags.includes(customTag)
										? APP_COLOURS.BLUE
										: APP_COLOURS.BLACK_TRANS,
								padding: 10,
								borderRadius: 10,
								marginRight: 5,
							}}>
							<Text
								style={{
									fontSize: 12,
									color: this.state.selectedTags.includes(
										customTag,
									)
										? "#FFF"
										: APP_COLOURS.BG2,
									fontWeight: "500",
								}}>
								Add custom: {customTag}
							</Text>
						</View>
					</TouchableOpacity>
				);
			}
		}

		return filteredTags.map((d, i) => (
			<TouchableOpacity
				key={i}
				style={{}}
				onPress={() =>
					this.toggleTag(d, this.state.customTags.includes(d))
				}>
				<View
					style={{
						width: "auto",
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: this.state.selectedTags.includes(d)
							? APP_COLOURS.BLUE
							: APP_COLOURS.BLACK_TRANS,
						padding: 10,
						borderRadius: 10,
						marginRight: 5,
					}}>
					<Text
						style={{
							fontSize: 12,
							color: this.state.selectedTags.includes(d)
								? "#FFF"
								: APP_COLOURS.BG2,
							fontWeight: "500",
						}}>
						{d}
					</Text>
				</View>
			</TouchableOpacity>
		));
	}

	render() {
		return (
			<View
				style={{
					borderWidth: 1,
					borderColor: "#000",
					borderRadius: 12,
					padding: 10,
				}}>
				<Text
					style={{
						fontWeight: "400",
						fontFamily: "Gotham",

						fontSize: 12,
						color: APP_COLOURS.BG2,
						marginBottom: 4,
						position: "absolute",
						top: -12,
						left: 9,
						backgroundColor: "#FFF",
						padding: 4,
						paddingVertical: 1,
						borderRadius: 8,
						zIndex: 99,
					}}>
					HashTags
				</Text>

				{/* Add search input */}
				<TextInput
					style={{
						borderWidth: 1,
						borderColor: APP_COLOURS.BLACK_TRANS,
						borderRadius: 8,
						padding: 8,
						marginBottom: 10,
						fontSize: 12,
					}}
					placeholder='Search hashtags...'
					value={this.state.searchQuery}
					onChangeText={(text) =>
						this.setState({ searchQuery: text })
					}
				/>

				<ScrollView horizontal showsHorizontalScrollIndicator={false}>
					{this.renderHashtagButtons()}
				</ScrollView>
			</View>
		);
	}
}
