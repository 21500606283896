let e = "https://us-central1-tetricepm-5f972.cloudfunctions.net/app";
// let e = "http://127.0.0.1:5001/tetricepm-5f972/us-central1/app";
//
export const api_login = e + "/pm/login";

export const api = e;

export const api_crudCreate = api + "/pm/c";
export const api_crudRead = api + "/pm/r";

export const api_crudReadUnass = api + "/pm/runass";

export const api_crudUpdate = api + "/pm/u";
export const api_crudDelete = api + "/pm/d";
export const api_crudDeleteWhere = api + "/pm/crudDeleteWhere";

export const api_crudWithImage = api + "/pm/cwimg";
export const api_crudWithHtml = api + "/pm/cwhtml";
export const api_crudDeleteProposalPage = api + "/pm/deleteProposalPage";

export const api_crudDeleteDocument = api + "/pm/cdelimg";
export const api_sendBasicEmail = api + "/pm/sendBasicEmail";
export const api_sendUserIdEmail = api + "/pm/sendUserIdEmail";

export const api_sendDiscordMessage = api + "/pm/sendDiscordMessage";

export const api_getUsers = api + "/pm/users";
export const api_createPostApproval = api + "/pm/createPostApproval";
export const api_creatSurveyFeedback = api + "/pm/creatSurveyFeedback";

//
export const api_contentApprovalRequest = api + "/pm/contentapprovalopen";

export const api_crudGetProjects = api + "/pm/projects";
export const api_crudGetTasks = api + "/pm/tasks";

export const api_crudDeleteWithImage = api + "/pm/cdelimg";

export const api_timeboxerEstimate = api + "/pm/timeboxer/estimate";
export const api_timeboxerSessions = api + "/pm/timeboxer/sessions";
export const api_timeboxerTasks = api + "/pm/timeboxer/tasks";

export const api_getsuppliers = api + "/pm/suppliers";

export const api_deleteCalendarImage = api + "/pm/delccimg";
