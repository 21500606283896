import React, { Component } from "react";
import { View, Text, ScrollView } from "react-native";
import { APP_COLOURS } from "../../APP_VARS";

const INDUSTRY_BENCHMARKS = {
	SELL_THROUGH: {
		target: 80,
		timeframe: 8,
		current: 65,
		weeksElapsed: 6,
	},
	UNIT_ECONOMICS: {
		target_cpu: 20,
		target_roas: 3.5,
		target_conversion: 4,
	},
};

export default class IndustryBenchmarks extends Component {
	calculateSellThroughProgress = () => {
		const { target, current, timeframe, weeksElapsed } =
			INDUSTRY_BENCHMARKS.SELL_THROUGH;
		const progressPercentage = (current / target) * 100;
		const timeProgressPercentage = (weeksElapsed / timeframe) * 100;
		const isOnTrack = progressPercentage >= timeProgressPercentage;

		return {
			progressPercentage,
			timeProgressPercentage,
			isOnTrack,
			remainingWeeks: timeframe - weeksElapsed,
			projectedFinal: (current / weeksElapsed) * timeframe,
		};
	};

	render() {
		const sellThroughMetrics = this.calculateSellThroughProgress();

		return (
			<ScrollView style={styles.container}>
				<View style={styles.benchmarkContainer}>
					<View style={styles.benchmarkHeader}>
						<Text style={styles.benchmarkTitle}>
							Sell-Through Performance
						</Text>
						<Text style={styles.benchmarkSubtitle}>
							{INDUSTRY_BENCHMARKS.SELL_THROUGH.target}%
							Sell-Through in{" "}
							{INDUSTRY_BENCHMARKS.SELL_THROUGH.timeframe} Weeks
						</Text>
					</View>

					<View style={styles.benchmarkMetrics}>
						<View style={styles.benchmarkMetric}>
							<Text style={styles.metricLabel}>
								Current Sell-Through
							</Text>
							<Text
								style={[
									styles.metricValue,
									{
										color: sellThroughMetrics.isOnTrack
											? APP_COLOURS.GREEN
											: APP_COLOURS.RED,
									},
								]}>
								{INDUSTRY_BENCHMARKS.SELL_THROUGH.current}%
							</Text>
						</View>

						<View style={styles.benchmarkMetric}>
							<Text style={styles.metricLabel}>
								Weeks Remaining
							</Text>
							<Text style={styles.metricValue}>
								{sellThroughMetrics.remainingWeeks}
							</Text>
						</View>

						<View style={styles.benchmarkMetric}>
							<Text style={styles.metricLabel}>
								Projected Final
							</Text>
							<Text
								style={[
									styles.metricValue,
									{
										color:
											sellThroughMetrics.projectedFinal >=
											INDUSTRY_BENCHMARKS.SELL_THROUGH
												.target
												? APP_COLOURS.GREEN
												: APP_COLOURS.RED,
									},
								]}>
								{sellThroughMetrics.projectedFinal.toFixed(1)}%
							</Text>
						</View>
					</View>

					<View style={styles.progressContainer}>
						<View style={styles.progressTrack}>
							<View
								style={[
									styles.progressFill,
									{
										width: `${INDUSTRY_BENCHMARKS.SELL_THROUGH.current}%`,
									},
								]}
							/>
							<View
								style={[
									styles.timelineMarker,
									{
										left: `${sellThroughMetrics.timeProgressPercentage}%`,
									},
								]}
							/>
						</View>
						<View style={styles.progressLabels}>
							<Text style={styles.progressLabel}>0%</Text>
							<Text style={styles.progressLabel}>
								Target:{" "}
								{INDUSTRY_BENCHMARKS.SELL_THROUGH.target}%
							</Text>
							<Text style={styles.progressLabel}>100%</Text>
						</View>
					</View>

					<Text style={styles.benchmarkInsight}>
						{sellThroughMetrics.isOnTrack
							? "On track to meet industry benchmark"
							: `Need to increase sell-through by ${(
									INDUSTRY_BENCHMARKS.SELL_THROUGH.target -
									sellThroughMetrics.projectedFinal
							  ).toFixed(1)}% to meet benchmark`}
					</Text>
				</View>

				{/* Add more benchmark sections as needed */}
			</ScrollView>
		);
	}
}

const styles = {
	container: {
		flex: 1,
	},
	benchmarkContainer: {
		backgroundColor: "white",
		borderRadius: 10,
		padding: 20,
		marginBottom: 20,
		shadowColor: "#000",
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.1,
		shadowRadius: 4,
		elevation: 3,
	},
	// ... copy relevant styles from ChannelPerformance.js ...
	// Include all styles used in the render method above
};
