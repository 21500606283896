import React, { useState } from 'react';
import { View, Text, TextInput, StyleSheet, ScrollView, Pressable } from 'react-native-web';
import { PlusIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';

const NDPList = ({ ndps, selectedNDP, onSelectNDP, onAddNDP }) => {
    const [searchQuery, setSearchQuery] = useState('');

    const filteredNDPs = ndps.filter((ndp) =>
        ndp.productName?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <View style={styles.container}>
            <View style={styles.header}>
                <Text style={styles.title}>NDP List</Text>
                <Pressable
                    style={({ pressed }) => ({
                        ...styles.addButton,
                        opacity: pressed ? 0.8 : 1,
                    })}
                    onPress={onAddNDP}
                >
                    <PlusIcon style={{ width: 20, height: 20, color: '#ffffff' }} />
                </Pressable>
            </View>

            <View style={styles.searchContainer}>
                <MagnifyingGlassIcon style={{ width: 20, height: 20, color: '#6b7280', marginRight: 8 }} />
                <TextInput
                    style={styles.searchInput}
                    placeholder="Search NDPs..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </View>

            <ScrollView style={styles.list}>
                {filteredNDPs.map((ndp) => (
                    <Pressable
                        key={ndp.id}
                        style={({ pressed }) => [
                            styles.listItem,
                            selectedNDP?.id === ndp.id && styles.selectedItem,
                            pressed && styles.pressedItem,
                        ]}
                        onPress={() => onSelectNDP(ndp)}
                    >
                        <View style={styles.listItemContent}>
                            <Text 
                                style={[
                                    styles.listItemTitle,
                                    selectedNDP?.id === ndp.id && styles.selectedItemText
                                ]}
                                numberOfLines={1}
                            >
                                {ndp.productName || "Untitled NDP"}
                            </Text>
                            <Text 
                                style={[
                                    styles.listItemSubtitle,
                                    selectedNDP?.id === ndp.id && styles.selectedItemText
                                ]}
                                numberOfLines={1}
                            >
                                {ndp.inStoreDate || "No date set"}
                            </Text>
                        </View>
                    </Pressable>
                ))}
            </ScrollView>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        width: 240,
        borderRightWidth: 1,
        borderRightColor: '#e5e7eb',
        backgroundColor: '#f9fafb',
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 16,
        borderBottomWidth: 1,
        borderBottomColor: '#e5e7eb',
    },
    title: {
        fontSize: 18,
        fontWeight: '600',
        color: '#111827',
    },
    addButton: {
        padding: 8,
        borderRadius: 6,
        backgroundColor: '#4f46e5',
    },
    searchContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        padding: 8,
        borderBottomWidth: 1,
        borderBottomColor: '#e5e7eb',
    },
    searchInput: {
        flex: 1,
        height: 36,
        padding: 8,
        borderRadius: 6,
        backgroundColor: '#ffffff',
        borderWidth: 1,
        borderColor: '#e5e7eb',
        fontSize: 14,
        color: '#374151',
    },
    list: {
        flex: 1,
    },
    listItem: {
        padding: 12,
        borderBottomWidth: 1,
        borderBottomColor: '#e5e7eb',
    },
    selectedItem: {
        backgroundColor: '#4f46e5',
    },
    pressedItem: {
        opacity: 0.8,
    },
    listItemContent: {
        gap: 4,
    },
    listItemTitle: {
        fontSize: 14,
        fontWeight: '500',
        color: '#111827',
    },
    listItemSubtitle: {
        fontSize: 12,
        color: '#6b7280',
    },
    selectedItemText: {
        color: '#ffffff',
    },
});

export default NDPList;
