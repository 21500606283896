import React, { Component } from "react";
import {
	View,
	Text,
	TextInput,
	ActivityIndicator,
	ScrollView,
	TouchableOpacity,
	Image,
} from "react-native";
import { APP_COLOURS, Avatars, RETAILERS, driveImg } from "../../APP_VARS";
import AutoHeightImage from "../AutoHeightImageComp";

import moment from "moment";
import {
	crudCreate,
	crudDelete,
	crudReadUnass,
	crudUpdate,
	sendDiscordMessage,
} from "../../CRUDHELPER";
import AddListing from "./AddListing";
import TopTabs from "../TopTabs";
import SmallerTabs from "../TopTabs/SmallerTabs";
import MediumTabs from "../TopTabs/MediumTabs";
import StatusItemListings from "../DailyPlanner/StatusItemListings";
import UpdateListing from "./UpdateListing";
import ImageScaling from "./ImageScaling";

function isOdd(num) {
	return num % 2;
}

export default class ListingRequests extends Component {
	constructor(props) {
		super(props);
		this.state = {
			driveImg: driveImg,
			listingRequests: [],

			user_data: "",

			crudDocumentName: "dm_listing_requests",
			updateListing: false,

			selectedRetailer: "",

			selectedTab: "Requests",
			showImageScaling: false,
		};
	}

	componentDidMount() {
		this.getDeviceData();
	}

	getDeviceData = async () => {
		try {
			const value = await localStorage.getItem("user_data");
			if (value !== null) {
				const d = JSON.parse(value);
				await this.setState({
					user_data: d,
					accessToken: d.accessToken,
				});
				this.fetchListings();
			}
		} catch (error) {
			// Error retrieving data
		}
	};

	fetchListings = () => {
		this.setState({
			loading: true,
		});
		crudReadUnass(this.state.crudDocumentName, "", async (status, d) => {
			console.log("messages", status, d);
			if (status === 200) {
				await this.setState({
					listingRequests: d,
					loading: false,
				});
			}
		});
	};

	render() {
		return (
			<View
				style={{
					flex: 1,
					padding: 5,
				}}>
				<View
					style={{
						width: "100%",
						paddingBottom: 2,
						flexDirection: "row",
					}}>
					<View
						style={{
							flexDirection: "row",
							alignItems: "center",
						}}>
						<View>
							<Text
								style={{
									fontSize: 16,
									color: APP_COLOURS.BG2,
									fontWeight: "800",
									padding: 1,
									marginRight: 15,
								}}>
								Listing requests
							</Text>

							<Text
								style={{
									fontSize: 12,
									color: APP_COLOURS.BG2,
									fontWeight: "300",
									padding: 1,
									marginRight: 15,
									maxWidth: 200,
								}}>
								Break down of all listing requests by platform
							</Text>
						</View>
					</View>
					<View
						style={{
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "space-between",
						}}>
						<MediumTabs
							selectedSubPage={(d) => {
								this.setState({
									selectedTab: d,
								});
								console.log(d);
							}}
							tabs={["Requests", "Review", "Complete"]}
						/>
					</View>

					<View
						style={{
							// flex: 1,

							flexDirection: "row",
							alignItems: "center",
							height: 45,
						}}>
						<TouchableOpacity
							style={{
								marginRight: 10,
								marginLeft: 10,
							}}
							onPress={() => {
								this.setState({
									showInput: !this.state.showInput,
								});
							}}>
							<AutoHeightImage
								width={19}
								source={require("../../../assets/images/searchIcon.png")}
							/>
						</TouchableOpacity>
						{this.state.loading ? (
							<ActivityIndicator color={APP_COLOURS.PRIMARY} />
						) : null}
						<View
							style={{
								// height: "auto",
								// width: "100%",
								flex: 1,
								minWidth: 200,
								// maxWidth: 400,
								backgroundColor: APP_COLOURS.BLACK_TRANS,
								borderRadius: 13,
							}}>
							{this.state.showInput ? (
								<TextInput
									style={{
										fontWeight: "400",
										fontFamily: "Gotham",

										width: "100%",
										paddingLeft: 15,
										padding: 10,
										fontSize: 12,
										borderRadius: 13,
										marginRight: 15,
										color: APP_COLOURS.BG2,
										// backgroundColor:
										// 	APP_COLOURS.WHITE_TRANSPARENT4,
										backgroundColor: "#E8E8E8",
										fontWeight: "500",
									}}
									placeholderTextColor={"#797979"}
									autoCapitalize='sentences'
									clearButtonMode='while-editing'
									autoCompleteType='off'
									editable={true}
									autoCorrect={true}
									autoFocus
									keyboardType='default'
									textContentType='none'
									placeholder={` Search `}
									// defaultValue={this.state.user_data.email}
									defaultValue={this.state.filterSearch}
									onChangeText={(text) => {
										console.log(text);
										this.setState({
											filterSearch: text,
										});
									}}
								/>
							) : null}
						</View>

						<TouchableOpacity
							style={{
								// marginRight: 15,
								marginLeft: 15,
								backgroundColor: APP_COLOURS.BG2,
								borderRadius: 15,
								flexDirection: "row",
								paddingVertical: 10,
								alignItems: "center",
								paddingHorizontal: 15,
							}}
							onPress={async () => {
								this.setState({
									newListingForm: !this.state.newListingForm,
								});
							}}>
							<Text
								style={{
									color: "#FFF",
									fontSize: 14,
									marginRight: 10,
								}}>
								+
							</Text>
							<Text
								style={{
									color: "#FFF",
									fontSize: 12,
								}}>
								New
							</Text>
						</TouchableOpacity>

						<TouchableOpacity
							style={{
								marginLeft: 15,
								backgroundColor: APP_COLOURS.BG2,
								borderRadius: 15,
								flexDirection: "row",
								paddingVertical: 10,
								alignItems: "center",
								paddingHorizontal: 15,
							}}
							onPress={() =>
								this.setState({ showImageScaling: true })
							}>
							<Text style={{ color: "#FFF", fontSize: 12 }}>
								Image Scaling
							</Text>
						</TouchableOpacity>
					</View>
					<View
						style={{
							flex: 1,
							flexDirection: "row",
							alignItems: "center",
							// justifyContent: "space-between",
						}}>
						<TouchableOpacity
							style={{}}
							onPress={() =>
								this.setState({
									selectedRetailer: "",
								})
							}>
							<View
								style={{
									width: "auto",
									//  height: 50,
									justifyContent: "center",
									alignItems: "center",

									padding: 10,
									borderRadius: 12,
									marginRight: 5,
								}}>
								<Text
									style={{
										fontSize: 12,
										color: APP_COLOURS.BLUE,
										fontWeight: "400",
										fontFamily: "Gotham",
									}}>
									All
								</Text>
							</View>
						</TouchableOpacity>
						<SmallerTabs
							selectedSubPage={(d) => {
								this.setState({
									selectedRetailer: d,
								});
								console.log(d);
							}}
							tabs={RETAILERS.map((d) => d.title)}
						/>
					</View>
				</View>
				<View
					style={{
						flex: 1,
					}}>
					<ScrollView>
						{this.state.listingRequests

							.sort((a, b) => {
								return (
									moment(a.due_date).unix() -
									moment(b.due_date).unix()
								);
							})

							.filter((df) =>
								this.state.selectedRetailer !== ""
									? df.retailer ===
									  this.state.selectedRetailer
									: df,
							)

							.filter((df) => {
								{
									/* return df; */
								}
								if (this.state.selectedTab === "Requests") {
									return (
										df.status !== "Review" &&
										df.status !== "Complete"
									);
								} else if (
									this.state.selectedTab === "Review"
								) {
									return df.status === "Review";
								} else if (
									this.state.selectedTab === "Complete"
								) {
									return df.status === "Complete";
								}
							})
							.filter((d_) => {
								let filter = d_;

								if (
									typeof this.state.filterSearch !==
										"undefined" &&
									this.state.filterSearch !== ""
								) {
									let text_ = this.state.filterSearch;
									var outString = text_.replace(
										/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
										"",
									);

									let join_names = `${d_.title} ${d_.barcode} ${d_.retailer}`;

									join_names = join_names.replace(
										/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
										"",
									);

									return join_names
										.toLowerCase()
										.includes(outString.toLowerCase());
								}

								return filter;
							})

							.map((d, i) => this.listingItem(d))}
					</ScrollView>
				</View>

				{this.state.updateListing ? (
					<UpdateListing
						{...this.state}
						selectedProduct={this.state.selectedProduct}
						addEntry={(newListing) => {
							let user_data = JSON.parse(
								localStorage.getItem("user_data"),
							);

							// 							sendDiscordMessage(
							// 								`${user_data.first_name} ${user_data.last_name}`,
							// 								`[Updated Listing]
							// - Brand: ${newListing.brand.title}
							// - Title: ${newListing.title}
							// - Start: ${moment(newListing.start_date).format("DD/MM/YY")}
							// 							`,
							// 								`${window.location.origin}${
							// 									Avatars[parseInt(user_data.avatar)].pp
							// 								}`,
							// 								"0QPZYrY6zpAnJXqX9gQl",
							// 							);

							// 							if (user_data.id !== "0QPZYrY6zpAnJXqX9gQl") {
							// 								sendDiscordMessage(
							// 									`${user_data.first_name} ${user_data.last_name}`,
							// 									`[Updated Listing]
							// - Brand: ${newListing.brand.title}
							// - Title: ${newListing.title}
							// - Start: ${moment(newListing.start_date).format("DD MMM YYYY")}
							// 							`,
							// 									`${window.location.origin}${
							// 										Avatars[parseInt(user_data.avatar)].pp
							// 									}`,
							// 									user_data.id,
							// 								);
							// 							}

							crudUpdate(
								this.state.crudDocumentName,
								newListing,
								async () => {
									this.setState({
										newListingForm: false,
										updateListing: false,
										selectedProduct: "",
									});
									await this.fetchListings();
								},
							);
						}}
						deleteListing={() => {
							crudDelete(
								this.state.crudDocumentName,
								this.state.selectedProduct,
								() => {
									this.setState({
										newListingForm: false,
										updateListing: false,
										selectedProduct: "",
									});

									this.fetchListings();
								},
							);
						}}
						close={() =>
							this.setState({
								newListingForm: false,
								updateListing: false,
								selectedProduct: "",
							})
						}
					/>
				) : null}

				{this.state.newListingForm ? (
					<AddListing
						{...this.state}
						selectedProject={this.state.filterSearch}
						addEntry={(newListing) => {
							let user_data = JSON.parse(
								localStorage.getItem("user_data"),
							);

							sendDiscordMessage(
								`${user_data.first_name} ${user_data.last_name}`,
								`[New Listing]
- Brand: ${newListing.brand.title}
- Title: ${newListing.title}
- Start: ${moment(newListing.start_date).format("DD/MM/YY")}
							`,
								`${window.location.origin}${
									Avatars[parseInt(user_data.avatar)].pp
								}`,
								"0QPZYrY6zpAnJXqX9gQl",
							);

							if (user_data.id !== "0QPZYrY6zpAnJXqX9gQl") {
								sendDiscordMessage(
									`${user_data.first_name} ${user_data.last_name}`,
									`[New Listing]
- Brand: ${newListing.brand.title}
- Title: ${newListing.title}
- Start: ${moment(newListing.start_date).format("DD MMM YYYY")}
							`,
									`${window.location.origin}${
										Avatars[parseInt(user_data.avatar)].pp
									}`,
									user_data.id,
								);
							}

							crudCreate(
								this.state.crudDocumentName,
								newListing,
								async () => {
									this.setState({
										newListingForm: false,
										updateListing: false,
										selectedProduct: "",
									});
									await this.fetchListings();
								},
							);
						}}
						close={() =>
							this.setState({
								newListingForm: false,
								selectedProduct: "",
							})
						}
					/>
				) : null}

				<ImageScaling
					visible={this.state.showImageScaling}
					onClose={() => this.setState({ showImageScaling: false })}
				/>
			</View>
		);
	}

	listingItem = (d, i, fs, size) => {
		return (
			<View
				key={`task_${d.id}`}
				style={{
					width: "100%",
					backgroundColor: isOdd(i)
						? "white"
						: APP_COLOURS.WHITE_TRANSPARENT6,
					// backgroundColor: "#FFF",

					// alignItems: "center",
					zIndex: 10,
					borderRadius: 8,
					// opacity: d.status === "Completed" ? 0.3 : 1,
					paddingLeft: 3,
					overflow: "hidden",
					borderTopColor: "#E8E8E8",
					borderTopWidth: 1,
					marginRight: 5,
					padding: 5,
					// justifyContent: "center",
					// alignItems: "center",
				}}>
				<View
					style={{
						width: "100%",
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
					}}>
					<StatusItemListings
						{...this.props}
						{...d}
						fs={fs}
						handleStatusChange={async (_id, _status) => {
							let user_data = JSON.parse(
								localStorage.getItem("user_data"),
							);

							const mapThrough = await d.assignment_ids.forEach(
								async (id) => {
									await sendDiscordMessage(
										`${user_data.first_name} ${user_data.last_name}`,
										`### *Listing update 
							[Listing Status] - *${_status}*
							> ${d.title}
							`,
										`${window.location.origin}${
											Avatars[parseInt(user_data.avatar)]
												.pp
										}`,
										id,
									);
								},
							);
							crudUpdate(
								this.state.crudDocumentName,
								{
									id: _id,
									status: _status,
								},
								() => {
									this.fetchListings();
								},
							);
						}}
					/>

					<View
						style={{
							width: 0.5,
							backgroundColor: APP_COLOURS.BG2,
							height: 30,
							marginLeft: 3,
						}}
					/>
					<Text
						ellipsizeMode='tail'
						style={{
							color: APP_COLOURS.BG4,
							fontSize: 12,

							width: 90,
							marginHorizontal: 5,
						}}>
						{d.brand.title}
					</Text>
					{/* <Text
						ellipsizeMode='tail'
						style={{
							color: APP_COLOURS.BG4,
							fontSize: 12,

							width: 90,
							marginHorizontal: 5,
						}}>
						{d.retailer}
					</Text> */}

					<View
						style={{
							width: 0.5,
							backgroundColor: APP_COLOURS.BG2,
							height: 30,
						}}
					/>

					<Text
						ellipsizeMode='tail'
						style={{
							color: APP_COLOURS.BG4,
							fontSize: 12,

							width: 90,
							marginHorizontal: 5,
						}}>
						{d.barcode}
					</Text>

					<View
						style={{
							width: 0.5,
							backgroundColor: APP_COLOURS.BG2,
							height: 30,
						}}
					/>

					<View
						style={{
							width: 0.5,
							backgroundColor: APP_COLOURS.BG2,
							height: 30,
						}}
					/>
					<TouchableOpacity
						style={{
							flex: 1,
							// width: "100%",
							justifyContent: "center",
							// maxWidth: 1200,
							// minWidth: 250,
							marginHorizontal: 5,
						}}
						onPress={() => {
							this.setState({
								updateListing: true,
								selectedProduct: d,
							});
							console.log(d);
						}}>
						<View
							style={{
								width: "100%",
								padding: fs / 2,
								// justifyContent: "center",
								minWidth: 200,
								minHeight: 20,
							}}>
							<Text
								ellipsizeMode='tail'
								style={{
									width: "100%",
									color: "#000",
									fontSize: 15,
									flexWrap: "wrap",
									// minHeight: 50,
									// paddingTop: 20,
								}}>
								{d.title}
							</Text>
						</View>
					</TouchableOpacity>
					<View>{this.imageViewer(d)}</View>
					<Text
						ellipsizeMode='tail'
						style={{
							fontSize: 13,
							flexWrap: "wrap",
							// opacity: 0.7,
							fontWeight: "300",
							width: 70,
						}}>
						{moment(d.due_date).format("DD MMM YY")}
					</Text>
				</View>

				<View></View>
			</View>
		);
	};

	imageViewer = (d) => {
		let images = typeof d.images !== "undefined" ? d.images : [];

		return (
			<View
				style={{
					flexDirection: "row",
				}}>
				{images.map((img, index) => (
					<View
						key={index}
						style={{
							margin: 2,
							alignItems: "center",
							justifyContent: "center",
							padding: 2,
							borderRadius: 3,
							backgroundColor: "#FFF",
						}}>
						<Image
							source={{ uri: img.imageData.url }}
							style={{
								width: 25,
								height: 25,
								resizeMode: "contain",
							}}
						/>
					</View>
				))}
			</View>
		);
	};
}
